import React, { useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles, Theme } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useGetCoursesForAdminQuery, AdminCourseSearchInput } from '../../../gen/graphql';
import {
  StatusLabels,
  CourseSupportLabels,
  lessonTypeLabels,
  contractTypeLabels,
  typeLabels,
} from '../../const/Course';
import { getUTCDatetime, formatRequestDate } from '../../const/Util';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));
type SearchFormInput = Omit<AdminCourseSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminCourseSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const instructorId = params.get('instructorID') ?? '';
    const studentId = params.get('studentID') ?? '';
    const planId = params.get('planID') ?? '';
    const status = params.get('status') ?? '';
    const startDate = params.get('startDate') ?? '';
    const endDate = params.get('endDate') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      instructorID: parseInt(instructorId) > 0 ? parseInt(instructorId) : undefined,
      studentID: parseInt(studentId) > 0 ? parseInt(studentId) : undefined,
      planID: parseInt(planId) > 0 ? parseInt(planId) : undefined,
      status: parseInt(status) ?? undefined,
      startDate: startDate ? getUTCDatetime(startDate) : undefined,
      endDate: endDate ? getUTCDatetime(endDate) : undefined,
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetCoursesForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminCourseSearchInput): void => {
      navigate({
        pathname: '/courses',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 再検索
  const refetchCourses = useCallback(
    async (params: AdminCourseSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      instructorID: currentSearchParams.instructorID,
      studentID: currentSearchParams.studentID,
      planID: currentSearchParams.planID,
      status: currentSearchParams.status,
      startDate: currentSearchParams.startDate,
      endDate: currentSearchParams.endDate,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      if (input.startDate) {
        params.startDate = formatRequestDate(new Date(input.startDate));
      }

      if (input.endDate) {
        params.endDate = formatRequestDate(new Date(input.endDate));
      }
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchCourses(params);
      }
      createHistory(params);
    },
    [refetchCourses, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Course
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="planID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="planId"
                          label="PlanID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="studentID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="studentId"
                          label="StudentID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="instructorID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="instructorId"
                          label="InstructorID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="startDate"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="startDate"
                            label="StartDate"
                            format="yyyy/MM/dd"
                            value={value ?? null}
                            fullWidth
                            inputRef={ref}
                            {...rest}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="endDate"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="endDate"
                            label="EndDate"
                            format="yyyy/MM/dd"
                            value={value ?? null}
                            fullWidth
                            inputRef={ref}
                            {...rest}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(StatusLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Plan</TableCell>
                  <TableCell align="right">Student</TableCell>
                  <TableCell align="right">Instructor</TableCell>
                  <TableCell align="right">StartDate</TableCell>
                  <TableCell align="right">EndDate</TableCell>
                  <TableCell align="right">Consultant</TableCell>
                  <TableCell align="right">LessonType</TableCell>
                  <TableCell align="right">LessonPlace</TableCell>
                  <TableCell align="right">Objective</TableCell>
                  <TableCell align="right">Curriculum</TableCell>
                  <TableCell align="right">JobChange</TableCell>
                  <TableCell align="right">CareerSupport</TableCell>
                  <TableCell align="right">CustomerAcquisition</TableCell>
                  <TableCell align="right">DelayInPayment</TableCell>
                  <TableCell align="right">BreakInContact</TableCell>
                  <TableCell align="right">ContractType</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">FinishedLessonCount</TableCell>
                  <TableCell align="right">CanceledLessonCount</TableCell>
                  <TableCell align="right">FinishedSessionCount</TableCell>
                  <TableCell align="right">PlannedSessionCount</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.coursesForAdmin?.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/courses/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.plan?.name}</TableCell>
                    <TableCell align="right">
                      <Link to={`/students/${row.student?.id}`}>
                        {row.student?.user?.personalInfo?.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/instructors/${row.instructor?.id}`}>
                        {row.instructor?.user?.personalInfo?.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{row.startDate}</TableCell>
                    <TableCell align="right">{row.endDate}</TableCell>
                    <TableCell align="right">{row.consultant}</TableCell>
                    <TableCell align="right">
                      {row.lessonType !== null && row.lessonType !== undefined
                        ? lessonTypeLabels[row.lessonType]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">{row.lessonPlace}</TableCell>
                    <TableCell align="right">{row.objective}</TableCell>
                    <TableCell align="right">{row.curriculum}</TableCell>
                    <TableCell align="right">
                      {row.jobChange !== null && row.jobChange !== undefined
                        ? CourseSupportLabels[row.jobChange]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {row.careerSupport !== null && row.careerSupport !== undefined
                        ? CourseSupportLabels[row.careerSupport]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {row.customerAcquisition !== null && row.customerAcquisition !== undefined
                        ? CourseSupportLabels[row.customerAcquisition]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">{row.delayInPayment ? 'あり' : 'なし'}</TableCell>
                    <TableCell align="right">{row.breakInContact ? 'あり' : 'なし'}</TableCell>
                    <TableCell align="right">
                      {row.contractType !== null && row.contractType !== undefined
                        ? contractTypeLabels[row.contractType]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {row.type !== null && row.type !== undefined ? typeLabels[row.type] : '-'}
                    </TableCell>
                    <TableCell align="right">{row.finishedLessonCount}回</TableCell>
                    <TableCell align="right">{row.canceledLessonCount}回</TableCell>
                    <TableCell align="right">{row.coaching?.finishedSessionCount ?? 0}回</TableCell>
                    <TableCell align="right">{row.coaching?.plannedSessionCount ?? 0}回</TableCell>
                    <TableCell align="right">
                      {row.status !== null && row.status !== undefined
                        ? StatusLabels[row.status]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.coursesForAdmin?.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
