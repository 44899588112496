import React, { useState, useContext } from 'react';
import { ApolloClientProvider } from './ApolloClientProvider';
import { CurrentUserFragment as User } from '../gen/graphql';

type AuthStateData = {
  isLogin: boolean;
  isChangePassword: boolean;
  cognitoUser: null;
  lmsUser: User | null;
};
type AuthState = {
  authState: AuthStateData;
  setAuth: (authState: AuthStateData) => void;
};

export const AuthContext = React.createContext<AuthState>({
  authState: {
    isLogin: false,
    isChangePassword: false,
    cognitoUser: null,
    lmsUser: null,
  },
  setAuth: () => {
    // no-op
  },
});

export const AuthProvider = AuthContext.Provider;

type ProviderProps = {
  children: React.ReactNode;
};

export const CommonProvider: React.FC<ProviderProps> = ({ children }) => {
  const [authState, setAuth] = useState(useContext(AuthContext).authState);

  return (
    <ApolloClientProvider>
      <AuthProvider value={{ authState, setAuth }}>{children}</AuthProvider>
    </ApolloClientProvider>
  );
};
