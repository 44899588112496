import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

// Material UI Other
import { Typography, Button, LinearProgress } from '@material-ui/core';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetMailSettingForAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { formatDate } from '../../const/Util';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ mail_setting_id: string }>().mail_setting_id;
  const id = Number(paramID);

  const { data, loading } = useGetMailSettingForAdminQuery({
    variables: { id },
  });
  const mailSetting = data?.getMailSettingForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Mail Setting Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/mailSettings/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          {mailSetting && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{mailSetting.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link to={`/users/${mailSetting.userID}`}>
                      {mailSetting.user.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    AttendanceStatusChanged
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.attendanceStatusChanged]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    BackSchool
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.backSchool]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ClaimApplied
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.claimApplied]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    LessonLogUnregistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.lessonLogUnregistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    LessonRegistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.lessonRegistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    LessonRemind
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.lessonRemind]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    LessonUnregistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.lessonUnregistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    LessonScheduleUpdated
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.lessonScheduleUpdated]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    MessageReceived
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.messageReceived]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Notice
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.notice]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    QuestionAnswerRegistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.questionAnswerRegistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    QuestionCommentRegistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.questionCommentRegistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    QuestionRegistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.questionRegistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReportCommentRegistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.studyReportCommentRegistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReportAchieved
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.studyReportAchieved]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SpotLessonRegistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.spotLessonRegistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SpotLessonRemind
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.spotLessonRemind]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SpotLessonCancel
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.spotLessonCancel]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SpotLessonLogUnregistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.spotLessonLogUnregistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TicketRemind
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.ticketRemind]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SessionRemind
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.sessionRemind]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PracticeSubmitRegistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.practiceSubmitRegistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PracticeSubmitReRegistered
                  </TableCell>
                  <TableCell>{ValidLabels[mailSetting.practiceSubmitReRegistered]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PracticeSubmitDetailReviewCommentRegistered
                  </TableCell>
                  <TableCell>
                    {ValidLabels[mailSetting.practiceSubmitDetailReviewCommentRegistered]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{formatDate(new Date(mailSetting.createdAt))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{formatDate(new Date(mailSetting.updatedAt))}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
