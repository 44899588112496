import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  AdminSessionReportInput,
  useGetSessionReportForAdminQuery,
  useUpdateSessionReportForAdminMutation,
} from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import {
  AppBar,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';

import { Form } from './Form';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { SessionReportDefaultValues, sessionReportSchema } from '../../../formSchema/sessionReport';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ session_report_id: string }>().session_report_id ?? '0';

  const [showLoader, setShowLoader] = useState(false);

  const formId = 'session-report-edit-form';
  const form = useForm<AdminSessionReportInput>({
    resolver: yupResolver(sessionReportSchema),
    defaultValues: SessionReportDefaultValues,
  });

  const { loading } = useGetSessionReportForAdminQuery({
    variables: { id },
    onCompleted: ({ sessionReportForAdmin: data }) => {
      if (!data) {
        return;
      }

      form.reset({
        sessionID: data?.sessionID,
        sessionPhase: data?.session?.phase,
        content: data?.content,
        preparations: data?.preparations.map((preparation) => {
          return { type: preparation.type };
        }),
        preparationOthers: data?.preparationOthers,
        isOnSchedule: data?.isOnSchedule,
        delayReason: data?.delayReason,
        announcements: data?.announcements.map((announcement) => {
          return { type: announcement.type };
        }),
        hasAnnouncementsDetail: data?.hasAnnouncementsDetail,
        responseDeadline: data?.responseDeadline ? data.responseDeadline : undefined,
        coachingMemo: data?.coachingMemo,
        status: data?.status,
      });
    },
  });

  const [updateSessionReport, { error }] = useUpdateSessionReportForAdminMutation();

  const handleClickUpdate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminSessionReportInput): Promise<void> => {
        setShowLoader(true);

        try {
          await updateSessionReport({
            variables: {
              id: id,
              input: input,
            },
          });
        } catch {
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [updateSessionReport, id, navigate],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Session Report Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {(loading || showLoader) && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Form formId={formId} form={form} onSubmit={handleClickUpdate} error={error} isUpdate />
          <Button
            type="submit"
            form={formId}
            variant="contained"
            color="primary"
            style={{ margin: '20px 0 0 0' }}
          >
            Save
          </Button>
        </Paper>
      </main>
    </div>
  );
};
