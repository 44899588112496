import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Box, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  AdminBannerCidInput,
  BannerCidDeviceType,
  BannerCidType,
  useGetBannerCidQuery,
  useUpdateBannerCidMutation,
  Valid,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';
import { strToEnum } from '../../../utils/common';
import { CidDeviceTypeLabels, CidTypeLabels } from '../../const/Banner';
import { ValidLabels } from '../../const/Valid';
import { getApiErrorMessage } from '../../../common/error/Error';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ cid: string }>().cid;
  const id = Number(paramID);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminBannerCidInput>({
    bannerID: 1,
    type: BannerCidType.TopCenter,
    deviceType: BannerCidDeviceType.Pc,
    cid: '',
    status: Valid.Valid,
  });

  const { loading } = useGetBannerCidQuery({
    variables: { id },
    onCompleted: ({ bannerCidForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      const { bannerID, type, deviceType, cid, status } = data;
      setUpdateInput({ bannerID, type, deviceType, cid, status });
    },
  });
  const [updateBannerCid, { error: updateError, loading: updating }] = useUpdateBannerCidMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      try {
        await updateBannerCid({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch {
        return;
      }

      navigate(-1);
    }, [navigate, updateBannerCid, id, updateInput]),
  );

  const handleChangeBannerID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    const bannerID = parseInt(value);
    if (bannerID > 0) {
      setUpdateInput((current) => ({ ...current, bannerID }));
    }
  }, []);

  const handleChangeType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      const type = strToEnum(String(value), BannerCidType);
      if (type) {
        setUpdateInput((current) => ({ ...current, type }));
      }
    },
    [],
  );

  const handleChangeDeviceType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      const deviceType = strToEnum(String(value), BannerCidDeviceType);
      if (deviceType) {
        setUpdateInput((current) => ({ ...current, deviceType }));
      }
    },
    [],
  );

  const handleChangeCid = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, cid: value }));
  }, []);

  const handleChangeStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      const status = strToEnum(String(value), Valid);
      if (status) {
        setUpdateInput((current) => ({ ...current, status }));
      }
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Banner Cid Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || updating) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="bannerId"
                name="bannerID"
                label="BannerId"
                type="number"
                placeholder="sort"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.bannerID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeBannerID}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="type">Type</InputLabel>
                <Select
                  value={updateInput.type}
                  onChange={handleChangeType}
                  input={<Input name="type" id="type" />}
                >
                  {Object.entries(CidTypeLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="deviceType">DeviceType</InputLabel>
                <Select
                  value={updateInput.deviceType}
                  onChange={handleChangeDeviceType}
                  input={<Input name="deviceType" id="deviceType" />}
                >
                  {Object.entries(CidDeviceTypeLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="cid"
                name="cid"
                label="Cid"
                type="text"
                placeholder="cid"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.cid}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeCid}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput.status}
                  onChange={handleChangeStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{getApiErrorMessage(error)}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
