import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetAbTestSettingForAdminQuery } from '../../../gen/graphql';

import {
  AppBar,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { TargetDeviceTypeLabels, DeviceTypeLabels, DeviceTypeIds } from '../../const/AbTest';
import { ValidLabels } from '../../const/Valid';
import { formatDateYearMonthDate } from '../../const/Util';

const useStyles = makeStyles((_theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paper: {
    flex: 1,
    margin: '24px auto',
  },
}));

export const Detail: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();

  const id = useParams<{ ab_test_id: string }>().ab_test_id ?? '';

  // 対象データ取得
  const { data, loading } = useGetAbTestSettingForAdminQuery({
    variables: { id },
  });
  const abTestSetting = data?.abTestSettingForAdmin;
  // Device毎のパラメーター
  const abTestSettingDevices = abTestSetting?.abTestSettingDevices
    ?.slice() // NOTE: エラー対応 Uncaught TypeError: Cannot assign to read only property '0' of object '[object Array]'
    .sort((a, b) => DeviceTypeIds[a.deviceType] - DeviceTypeIds[b.deviceType]);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                A/Bテスト 詳細
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/ab_tests/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paper}>
          {abTestSetting && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    項目
                  </TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    リダイレクト元URL
                  </TableCell>
                  <TableCell>
                    <Link to={abTestSetting.originURL} target="_blank">
                      {abTestSetting.originURL}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    利用する設定
                  </TableCell>
                  <TableCell>{TargetDeviceTypeLabels[abTestSetting.targetDeviceType]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    開始日
                  </TableCell>
                  <TableCell>
                    {formatDateYearMonthDate(new Date(abTestSetting.startDate))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    終了日
                  </TableCell>
                  <TableCell>{formatDateYearMonthDate(new Date(abTestSetting.endDate))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ステータス
                  </TableCell>
                  <TableCell>{ValidLabels[abTestSetting.status]}</TableCell>
                </TableRow>
                {abTestSettingDevices?.map((abTestSettingDevice) => (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {DeviceTypeLabels[abTestSettingDevice.deviceType]}の設定
                      </TableCell>
                      <TableCell>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>リダイレクト元配信比率</TableCell>
                              <TableCell>{abTestSettingDevice.originRate}</TableCell>
                            </TableRow>
                            {abTestSettingDevice.abTestSettingDeviceDestinations.map(
                              (abTestSettingDeviceDestination, i) => (
                                <TableRow>
                                  <TableCell>リダイレクト先{i + 1}</TableCell>
                                  <TableCell>
                                    <Table>
                                      <TableBody>
                                        <TableRow>
                                          <TableCell>URL</TableCell>
                                          <TableCell>
                                            <Link
                                              to={abTestSettingDeviceDestination.destinationURL}
                                              target="_blank"
                                            >
                                              {abTestSettingDeviceDestination.destinationURL}
                                            </Link>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell>配信比率</TableCell>
                                          <TableCell>
                                            {abTestSettingDeviceDestination.destinationRate}
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableCell>
                                </TableRow>
                              ),
                            )}
                          </TableBody>
                        </Table>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
                <TableRow>
                  <TableCell component="th" scope="row">
                    メモ
                  </TableCell>
                  <TableCell>{abTestSetting.note}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{abTestSetting.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{abTestSetting.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
