import React, { useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';

import {
  AdminSessionReportSearchInput,
  useGetSessionReportsForAdminQuery,
  HasAnnouncementsType,
  AdminSessionReportInput,
  useCreateSessionReportForAdminMutation,
  SessionPhase,
} from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Paper, Grid, Toolbar, AppBar } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import { strToEnum } from '../../../utils/common';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { strToValid, ValidLabels } from '../../const/Valid';
import { Announcements, Preparations, ResponseDeadline, Schedules } from '../../const/Session';
import { AdminSessionReportSearchFormInput, SearchForm } from './SearchForm';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { Form } from './Form';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { SessionReportDefaultValues, sessionReportSchema } from '../../../formSchema/sessionReport';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const [open, showCreateDialog] = React.useState(false);

  const currentSearchParams = useMemo((): AdminSessionReportSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const sessionID = params.get('sessionID') ?? '';
    const sessionPhase = params.get('sessionPhase') ?? '';
    const courseID = params.get('courseID') ?? '';
    const coachingID = params.get('coachingID') ?? '';
    const instructorID = params.get('instructorID') ?? '';
    const studentID = params.get('studentID') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: id.length === 26 ? id : undefined, // ULIDなので26の桁数チェック
      sessionID: sessionID.length === 26 ? sessionID : undefined, // ULIDなので26の桁数チェック
      sessionPhase: strToEnum(sessionPhase, SessionPhase),
      courseID: parseInt(courseID) > 0 ? parseInt(courseID) : undefined,
      coachingID: coachingID.length === 26 ? coachingID : undefined, // ULIDなので26の桁数チェック
      instructorID: parseInt(instructorID) > 0 ? parseInt(instructorID) : undefined,
      studentID: parseInt(studentID) > 0 ? parseInt(studentID) : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminSessionReportSearchInput): void => {
      navigate({
        pathname: '/sessionReports',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const { data, loading, refetch } = useGetSessionReportsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  const searchForm = useForm<AdminSessionReportSearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      sessionID: currentSearchParams.sessionID,
      sessionPhase: currentSearchParams.sessionPhase,
      courseID: currentSearchParams.courseID,
      coachingID: currentSearchParams.coachingID,
      instructorID: currentSearchParams.instructorID,
      studentID: currentSearchParams.studentID,
      status: currentSearchParams.status,
    },
    // 検索してもヒットしないだけの為、バリデーションなし
  });

  // 検索
  const searchSessionReports = useCallback(
    async (input: AdminSessionReportSearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  const handleCreateDialogOpen = useCallback(() => showCreateDialog(true), []);
  const handleCreateDialogClose = useCallback(() => showCreateDialog(false), []);

  const [createSessionReport, { error: createError }] = useCreateSessionReportForAdminMutation();

  const createFormId = 'session-report-create-form';
  const createForm = useForm<AdminSessionReportInput>({
    resolver: yupResolver(sessionReportSchema),
    defaultValues: SessionReportDefaultValues,
  });

  const handleClickCreate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminSessionReportInput): Promise<void> => {
        try {
          await createSessionReport({ variables: { input } });
        } catch {
          return;
        }

        // ダイアログを閉じて入力フォーム初期化
        showCreateDialog(false);
        createForm.reset();

        // 再検索
        refetch({ input: currentSearchParams });
      },
      [createForm, createSessionReport, currentSearchParams, refetch],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Session Report
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleCreateDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={searchSessionReports} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Session ID</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">HasPreparations</TableCell>
                  <TableCell align="right">Preparations</TableCell>
                  <TableCell align="right">PreparationOthers</TableCell>
                  <TableCell align="right">IsOnSchedule</TableCell>
                  <TableCell align="right">DelayReason</TableCell>
                  <TableCell align="right">HasAnnouncements</TableCell>
                  <TableCell align="right">Announcements</TableCell>
                  <TableCell align="right">HasAnnouncementsDetail</TableCell>
                  <TableCell align="right">ResponseDeadline</TableCell>
                  <TableCell align="right">CoachingMemo</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.sessionReportsForAdmin?.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link to={`/sessionReports/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/sessions/${row.sessionID}`}>{row.sessionID}</Link>
                    </TableCell>
                    <TableCell align="right">{row.content ?? '-'}</TableCell>
                    <TableCell align="right">{row.hasPreparations}</TableCell>
                    <TableCell align="right">
                      {row.preparations?.map((v) => {
                        return <p key={v.id}>{Preparations[v.type]}</p>;
                      }) ?? '-'}
                    </TableCell>
                    <TableCell align="right">{row.preparationOthers ?? '-'}</TableCell>
                    <TableCell align="right">
                      {row.isOnSchedule ? Schedules[row.isOnSchedule] : '-'}
                    </TableCell>
                    <TableCell align="right">{row.delayReason ?? '-'}</TableCell>
                    <TableCell align="right">
                      {row.hasAnnouncements === HasAnnouncementsType.Yes ? 'はい' : 'いいえ'}
                    </TableCell>
                    <TableCell align="right">
                      {row.announcements?.map((v) => {
                        return <p key={v.id}>{Announcements[v.type]}</p>;
                      }) ?? '-'}
                    </TableCell>
                    <TableCell align="right">{row.hasAnnouncementsDetail ?? '-'}</TableCell>
                    <TableCell align="right">
                      {row.responseDeadline ? ResponseDeadline[row.responseDeadline] : '-'}
                    </TableCell>
                    <TableCell align="right">{row.coachingMemo ?? '-'}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.sessionReportsForAdmin?.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      {/* Create */}
      <Dialog open={open} onClose={handleCreateDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <Form
            formId={createFormId}
            form={createForm}
            onSubmit={handleClickCreate}
            error={createError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" form={createFormId} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
