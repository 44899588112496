import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles, Theme } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  useGetStudyReportForAdminQuery,
  useUpdateStudyReportForAdminMutation,
  AdminStudyReportInput,
  Valid,
} from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ study_report_id: string }>().study_report_id;
  const id = Number(paramID);

  const [data, setData] = React.useState<AdminStudyReportInput>({
    userID: 0,
    date: '2019-01-01 00:00',
    content: '',
    status: Valid.Valid,
  });

  const [updateStudyReport, updateStudyReportData] = useUpdateStudyReportForAdminMutation();

  const getStudyReportData = useGetStudyReportForAdminQuery({
    variables: { id },
    onCompleted: ({ getStudyReportForAdmin: studyReport }) => {
      setData({
        userID: studyReport?.user.id ?? 0,
        content: studyReport?.content ?? '',
        date: getDate(studyReport?.date ?? ''),
        status: studyReport?.status ?? Valid.Invalid,
      });
    },
  });

  const loading = getStudyReportData.loading || updateStudyReportData.loading;

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!event.target.name) return;
    const val = ['userId'].includes(event.target.name)
      ? parseInt(String(event.target.value))
      : event.target.value === ''
        ? undefined
        : event.target.value;

    setData({
      ...data,
      [event.target.name]: val,
    });
  };

  const formatDateParam = React.useCallback((date: string) => {
    if (!date) return '';
    const d = new Date(date);
    return (
      d.getFullYear() +
      '-' +
      zeroPadding(d.getMonth() + 1) +
      '-' +
      zeroPadding(d.getDate()) +
      'T' +
      zeroPadding(d.getHours()) +
      ':' +
      zeroPadding(d.getMinutes()) +
      ':00Z'
    );
  }, []);

  const handleSubmit = useSafeAsyncCallback(
    React.useCallback(async () => {
      try {
        await updateStudyReport({
          variables: {
            id: id,
            input: {
              userID: data.userID,
              content: data.content,
              date: formatDateParam(data.date),
              status: data.status,
            },
          },
        });
      } catch {
        return;
      }

      navigate(-1);
    }, [data, formatDateParam, id, updateStudyReport, navigate]),
  );

  const handleChangeDate = (date: Date | null) => {
    if (date) {
      const dateString = date.toISOString();
      setData({ ...data, date: dateString });
    }
  };

  const getDate = (date: string) => {
    if (!date) return '';
    const d = new Date(date);
    return (
      d.getUTCFullYear() +
      '-' +
      zeroPadding(d.getUTCMonth() + 1) +
      '-' +
      zeroPadding(d.getUTCDate()) +
      ' ' +
      zeroPadding(d.getUTCHours()) +
      ':' +
      zeroPadding(d.getUTCMinutes())
    );
  };

  const zeroPadding = (num: number) => `0${num}`.substr(-2);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                StudyReport Edit
              </Typography>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="userId"
                name="userID"
                label="UserID"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={data.userID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChange}
                disabled
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="content"
                name="content"
                label="Content"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={data.content}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChange}
              />
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="date"
                  label="Date"
                  format="yyyy-MM-dd HH:mm"
                  value={data.date}
                  onChange={handleChangeDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={data.status}
                  onChange={handleChange}
                  input={<Input name="status" id="status" />}
                >
                  <MenuItem value={Valid.Valid}>{ValidLabels.VALID}</MenuItem>
                  <MenuItem value={Valid.Invalid}>{ValidLabels.INVALID}</MenuItem>
                </Select>
              </FormControl>
            </div>
            {updateStudyReportData.error && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateStudyReportData.error.graphQLErrors.map((error, index) => (
                  <div key={index}>
                    {error.path} : {error.message}
                  </div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
