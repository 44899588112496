import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetInstructorQuery } from '../../../gen/graphql';
import { StatusLabels } from '../../const/Instructor';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ instructor_id: string }>().instructor_id;
  const id = Number(paramID);

  const { data, loading } = useGetInstructorQuery({
    variables: { id },
  });
  const instructor = data?.instructorForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Instructor Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/instructors/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        {instructor && (
          <Paper className={classes.paperContent}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{instructor.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/${instructor.user.id}`}>
                      {instructor.user.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ScInstructorID
                  </TableCell>
                  <TableCell>{instructor.scInstructorID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ConfirmChannelID
                  </TableCell>
                  <TableCell>{instructor.confirmChannelID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    LastName
                  </TableCell>
                  <TableCell>{instructor.personalInfo?.lastName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    FirstName
                  </TableCell>
                  <TableCell>{instructor.personalInfo?.firstName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    LastKanaName
                  </TableCell>
                  <TableCell>{instructor.personalInfo?.lastKanaName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    FirstKanaName
                  </TableCell>
                  <TableCell>{instructor.personalInfo?.firstKanaName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ZoomUserID
                  </TableCell>
                  <TableCell>{instructor.zoomUserID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    GoogleMeetURL
                  </TableCell>
                  <TableCell>{instructor.googleMeetURL}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IsCoach
                  </TableCell>
                  <TableCell>{instructor.isCoach ? 'TRUE' : 'FALSE'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{StatusLabels[instructor.status]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{instructor.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{instructor.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        )}
      </main>
    </div>
  );
};
