import React from 'react';
import { Grid, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

interface PageHeaderProps {
  title: string;
  handleCreateDialogOpen?: () => void;
}

export const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  handleCreateDialogOpen,
  ..._props
}) => {
  return (
    <Toolbar>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs>
          <Typography color="inherit" variant="h5" component="h1">
            {title}
          </Typography>
        </Grid>
        {handleCreateDialogOpen && (
          <Grid item>
            <Button variant="outlined" color="inherit" onClick={handleCreateDialogOpen}>
              Create Record
            </Button>
          </Grid>
        )}
      </Grid>
    </Toolbar>
  );
};
