import React, { useState, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Theme, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { GeneralChangeEvent } from '../../../types/Common';
import { useGetTermChangeLogsQuery, AdminTermChangeLogSearchInput } from '../../../gen/graphql';
import { TermChangeReasonLabels } from '../../const/TermChangeReason';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminTermChangeLogSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const courseId = params.get('courseID') ?? '';
    const termChangeReasonID = params.get('termChangeReasonID') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      courseID: parseInt(courseId) > 0 ? parseInt(courseId) : undefined,
      termChangeReasonID:
        parseInt(termChangeReasonID) > 0 ? parseInt(termChangeReasonID) : undefined,
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetTermChangeLogsQuery({
    variables: {
      input: currentSearchParams,
    },
  });
  const termChangeLogs = data?.termChangeLogs.items ?? [];
  const total = data?.termChangeLogs.total ?? 0;

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // Search form inputs
  const [searchInput, setSearchInput] =
    useState<Omit<AdminTermChangeLogSearchInput, 'limit' | 'page'>>(currentSearchParams);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminTermChangeLogSearchInput): void => {
      navigate({
        pathname: '/termChangeLogs',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 再検索
  const refetchTermChangeLogs = useCallback(
    async (params: AdminTermChangeLogSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  // 検索ボタン押下
  const handleClickSearch = useCallback(async (): Promise<void> => {
    const params = {
      ...searchInput,
      limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
      page: 1,
    };
    // NOTE:
    // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
    // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
    if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
      await refetchTermChangeLogs(params);
    }
    createHistory(params);
  }, [refetchTermChangeLogs, createHistory, currentSearchParams, searchInput]);

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  const handleChangeSearchId = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setSearchInput((current) => ({
      ...current,
      id: parseInt(value) > 0 ? parseInt(value) : undefined,
    }));
  }, []);

  const handleChangeSearchCourseId = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setSearchInput((current) => ({
        ...current,
        courseID: parseInt(value) > 0 ? parseInt(value) : undefined,
      }));
    },
    [],
  );

  const handleChangeSearchTermChangeReasonId = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setSearchInput((current) => ({
        ...current,
        termChangeReasonID: parseInt(String(value)),
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                TermChangeLog
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="id"
                      name="id"
                      label="ID"
                      type="number"
                      fullWidth
                      value={searchInput.id}
                      onChange={handleChangeSearchId}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="courseId"
                      name="courseID"
                      label="CourseId"
                      type="number"
                      fullWidth
                      value={searchInput.courseID}
                      onChange={handleChangeSearchCourseId}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="termChangeReasonId">TermChangeReason</InputLabel>
                      <Select
                        value={searchInput.termChangeReasonID}
                        onChange={handleChangeSearchTermChangeReasonId}
                        input={<Input name="termChangeReasonId" id="statermChangeReasonIdtus" />}
                      >
                        <MenuItem>選択なし</MenuItem>
                        {Object.entries(TermChangeReasonLabels).map(([value, label]) => (
                          <MenuItem value={value} key={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="button" onClick={handleClickSearch}>
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Course</TableCell>
                  <TableCell align="right">TermChangeReason</TableCell>
                  <TableCell align="right">AfterStartDate</TableCell>
                  <TableCell align="right">AfterEndDate</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {termChangeLogs.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/courses/${row.course.id}`}>{row.course.plan.name}</Link>
                    </TableCell>
                    <TableCell align="right">{row.termChangeReason.reason}</TableCell>
                    <TableCell align="right">{row.afterStartDate}</TableCell>
                    <TableCell align="right">{row.afterEndDate}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
