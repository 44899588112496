import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { HasAnnouncementsType, useGetSessionReportForAdminQuery } from '../../../gen/graphql';
import {
  Announcements,
  PhaseLabels,
  Preparations,
  ResponseDeadline,
  Schedules,
} from '../../const/Session';
import { ValidLabels } from '../../const/Valid';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Paper, Grid, TableContainer } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ session_report_id: string }>().session_report_id ?? '0';

  const { data, loading } = useGetSessionReportForAdminQuery({
    variables: { id },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Session Report Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/sessionReports/${data?.sessionReportForAdmin?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{data?.sessionReportForAdmin?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Session
                  </TableCell>
                  <TableCell>
                    <Link to={`/sessions/${data?.sessionReportForAdmin?.sessionID}`}>
                      {data?.sessionReportForAdmin?.sessionID}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Session Phase
                  </TableCell>
                  <TableCell>
                    {data?.sessionReportForAdmin?.session?.phase
                      ? PhaseLabels[data?.sessionReportForAdmin?.session?.phase]
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Content
                  </TableCell>
                  <TableCell>
                    <Content>{data?.sessionReportForAdmin?.content ?? '-'}</Content>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Preparations
                  </TableCell>
                  <TableCell>
                    {data?.sessionReportForAdmin?.preparations.map((v) => {
                      return <p key={v.id}>{Preparations[v.type]}</p>;
                    }) ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PreparationOthers
                  </TableCell>
                  <TableCell>
                    <Content>{data?.sessionReportForAdmin?.preparationOthers ?? '-'}</Content>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Schedule
                  </TableCell>
                  <TableCell>
                    {data?.sessionReportForAdmin?.isOnSchedule
                      ? Schedules[data?.sessionReportForAdmin?.isOnSchedule]
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    DelayReason
                  </TableCell>
                  <TableCell>{data?.sessionReportForAdmin?.delayReason ?? '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    HasAnnouncements
                  </TableCell>
                  <TableCell>
                    {data?.sessionReportForAdmin?.hasAnnouncements === HasAnnouncementsType.Yes
                      ? 'はい'
                      : 'いいえ'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Announcements
                  </TableCell>
                  <TableCell>
                    {data?.sessionReportForAdmin?.announcements.map((v) => {
                      return <p key={v.id}>{Announcements[v.type]}</p>;
                    }) ?? '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    HasAnnouncementDetail
                  </TableCell>
                  <TableCell>
                    <Content>{data?.sessionReportForAdmin?.hasAnnouncementsDetail ?? '-'}</Content>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ResponseDeadline
                  </TableCell>
                  <TableCell>
                    {data?.sessionReportForAdmin?.responseDeadline
                      ? ResponseDeadline[data?.sessionReportForAdmin?.responseDeadline]
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>
                    {data?.sessionReportForAdmin?.status
                      ? ValidLabels[data?.sessionReportForAdmin?.status]
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{data?.sessionReportForAdmin?.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{data?.sessionReportForAdmin?.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </main>
    </div>
  );
};

const Content = styled.div`
  white-space: pre-wrap;
`;
