import * as yup from 'yup';

import { AdminNoticeInput, Valid, UserRole } from '../gen/graphql';

export const noticeSchema = yup.object({
  title: yup.string().required().max(2000).trim(),
  content: yup.string().required().max(2000).trim(),
  status: yup.string().required().oneOf(Object.values(Valid)),
  sendMail: yup.bool().required(),
  userRoles: yup
    .array()
    .of(yup.string().required().oneOf(Object.values(UserRole)))
    .required()
    .min(1),
});

export const NoticeDefaultValues: AdminNoticeInput = {
  title: '',
  content: '',
  status: Valid.Invalid,
  sendMail: false,
  userRoles: [],
};
