import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles, Theme } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { useGetPricesQuery, AdminPriceSearchInput, Valid } from '../../../gen/graphql';
import { strToEnum } from '../../../utils/common';
import { ValidLabels } from '../../const/Valid';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { SearchForm } from './SearchForm';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

type SearchFormInput = Omit<AdminPriceSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminPriceSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const providerPriceID = params.get('providerPriceID') ?? '';
    const productID = params.get('productID') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      providerPriceID: providerPriceID.trim().length > 0 ? providerPriceID.trim() : undefined,
      productID: parseInt(productID) > 0 ? parseInt(productID) : undefined,
      status: strToEnum(status, Valid),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetPricesQuery({
    variables: { input: currentSearchParams },
    notifyOnNetworkStatusChange: true,
  });

  // 検索条件からURLを作成してhistoryにpush
  const createHistory = useCallback(
    (params: AdminPriceSearchInput): void => {
      navigate({
        pathname: '/prices',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      providerPriceID: currentSearchParams.providerPriceID,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const handleChangePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const handleChangePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Price
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={search} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Product</TableCell>
                  <TableCell align="right">ProviderPriceID</TableCell>
                  <TableCell align="right">UnitAmount</TableCell>
                  <TableCell align="right">UnitAmountDecimal</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.pricesForAdmin.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/prices/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/products/${row.product.id}`}>{row.product.name}</Link>
                    </TableCell>
                    <TableCell align="right">{row.providerPriceID}</TableCell>
                    <TableCell align="right">{row.unitAmount}</TableCell>
                    <TableCell align="right">{row.unitAmountDecimal}</TableCell>
                    <TableCell align="right">{row.type}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.pricesForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={({ target: { value } }) => handleChangePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
