import { useCallback, useMemo } from 'react';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import {
  AdminPracticeSubmitDetailSearchInput,
  useGetPracticeSubmitDetailsForAdminQuery,
  Valid,
  PracticeSubmitDetailReviewStatus,
} from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Grid, Paper } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AdminPracticeSubmitDetailSearchFormInput, SearchForm } from './SearchForm';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ValidLabels } from '../../const/Valid';
import { ListPagination } from '../../common/ListPagination';
import { PageHeader } from '../../common/PageHeader';
import { strToEnum } from '../../../utils/common';
import { PracticeSubmitDetailReviewStatusLabels } from '../../const/PracticeSubmitDetail';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminPracticeSubmitDetailSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const practiceSubmitID = params.get('practiceSubmitID') ?? '';
    const reviewStatus = params.get('reviewStatus') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: id.length === 26 ? id : undefined, // ULIDなので26の桁数チェック
      practiceSubmitID: practiceSubmitID.length === 26 ? practiceSubmitID : undefined, // ULIDなので26の桁数チェック
      reviewStatus: strToEnum(reviewStatus, PracticeSubmitDetailReviewStatus),
      status: strToEnum(status, Valid),
    };
  }, [location.search]);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminPracticeSubmitDetailSearchInput): void => {
      navigate({
        pathname: '/practiceSubmitDetails',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const { data, loading, refetch } = useGetPracticeSubmitDetailsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  const searchForm = useForm<AdminPracticeSubmitDetailSearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      practiceSubmitID: currentSearchParams.practiceSubmitID,
      reviewStatus: currentSearchParams.reviewStatus,
      status: currentSearchParams.status,
    },
    // 検索してもヒットしないだけの為、バリデーションなし
  });

  // 検索
  const searchPractices = useCallback(
    async (input: AdminPracticeSubmitDetailSearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <PageHeader title="Practice Submit Detail" />
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={searchPractices} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Practice Submit ID</TableCell>
                  <TableCell align="right">URL</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">Review Status</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.practiceSubmitDetailsForAdmin?.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link to={`/practiceSubmitDetails/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/practiceSubmits/${row.practiceSubmitID}`}>
                        {row.practiceSubmitID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{row.url ?? '-'}</TableCell>
                    <TableCell align="right">{row.content ?? '-'}</TableCell>
                    <TableCell align="right">
                      {PracticeSubmitDetailReviewStatusLabels[row.reviewStatus]}
                    </TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <ListPagination
            count={data?.practiceSubmitDetailsForAdmin?.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
