import React, { useState, useMemo, useCallback } from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, Theme, makeStyles } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// React Router
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

// React Hook
import { useForm } from 'react-hook-form';

// GraphQL
import { ValidLabels, strToValid } from '../../const/Valid';
import {
  useGetChatRoomForAdminQuery,
  useCreateChatRoomForAdminMutation,
  AdminChatRoomSearchInput,
  AdminChatRoomCreateInput,
  Valid,
  UserFragment,
} from '../../../gen/graphql';

// Other
import queryString from 'query-string';
import { formatDate } from '../../const/Util';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { UserSelectBox } from './UserSelectBox';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  dialogForm: {
    display: 'flex',
    flexFlow: 'column',
  },
  dialogTitle: {
    color: '#fff',
    backgroundColor: '#009be5',
  },
  dialogContent: {
    background: '#EAEFF1',
    padding: '24px 24px',
    width: 530,
  },
  dialogPaper: {
    padding: '24px',
    marginBottom: '24px',
  },
}));

export interface SearchParam {
  id?: number;
  title?: string;
  userID?: number;
  status?: number;
}

export interface CreateParam {
  title: string;
  status: Valid;
  users: UserFragment[];
}

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminChatRoomSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const userID = params.get('userID') ?? '';
    const studentID = params.get('studentID') ?? '';
    const title = params.get('title') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      userID: parseInt(userID) > 0 ? parseInt(userID) : undefined,
      studentID: parseInt(studentID) > 0 ? parseInt(studentID) : undefined,
      title: title !== '' ? title : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  const createChatRoomParams: CreateParam = {
    title: '',
    status: Valid.Valid,
    users: [],
  };

  const { data, refetch, loading } = useGetChatRoomForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  // search hook form
  const { register: searchRegister, handleSubmit: searchHandleSubmit } =
    useForm<AdminChatRoomSearchInput>({
      defaultValues: currentSearchParams,
    });

  // create hook form
  const createForm = useForm<CreateParam>({
    defaultValues: createChatRoomParams,
  });

  // fetch graphql loading
  const [showLoader, setShowLoader] = useState(false);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminChatRoomSearchInput): void => {
      navigate({
        pathname: '/chatRooms',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 検索
  const search = useCallback(
    async (input: AdminChatRoomSearchInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // データ登録
  const [addChatRoom, { error: addError }] = useCreateChatRoomForAdminMutation();

  // データ作成ボタン
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(
      async (param: CreateParam): Promise<void> => {
        setShowLoader(true);
        try {
          await addChatRoom({
            variables: {
              input: {
                title: param.title,
                status: param.status,
                users: param.users?.map((v) => v.id),
              } as AdminChatRoomCreateInput,
            },
          });
        } catch (e) {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          return;
        } finally {
          setShowLoader(false);
        }

        // ダイアログを閉じて入力フォーム初期化
        setShowAddDialog(false);
        // 入力フォームを初期化
        createForm.reset();

        // 再検索
        search(currentSearchParams);
      },
      [addChatRoom, createForm, currentSearchParams, search],
    ),
  );

  // UI操作
  const [open, setShowAddDialog] = useState(false);
  const handleAddDialogOpen = () => {
    setShowAddDialog(true);
  };
  const handleAddDialogClose = () => {
    setShowAddDialog(false);
  };

  // ページネーション
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  const onChangeUser = useCallback(
    (value?: UserFragment[]) => {
      if (value) {
        createForm.setValue('users', value);
      }
    },
    [createForm],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                ChatRoom
              </Typography>
            </Grid>
            <Grid item>
              <Button
                className={classes.searchButton}
                variant="outlined"
                color="inherit"
                onClick={handleAddDialogOpen}
              >
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || (showLoader && <LinearProgress />)}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          {/* wrap submit on hook form handle submit */}
          <form onSubmit={searchHandleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={2}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="id"
                      label="ID"
                      type="number"
                      fullWidth
                      inputProps={{ min: 1 }}
                      {...searchRegister('id')}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="userID"
                      label="UserID"
                      type="number"
                      fullWidth
                      inputProps={{ min: 1 }}
                      {...searchRegister('userID')}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="studentID"
                      label="StudentID"
                      type="number"
                      fullWidth
                      inputProps={{ min: 1 }}
                      {...searchRegister('studentID')}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="title"
                      label="Title"
                      type="text"
                      fullWidth
                      {...searchRegister('title')}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <InputLabel htmlFor="status">Status</InputLabel>
                    <Select
                      input={<Input name="status" id="status" />}
                      {...searchRegister('status')}
                    >
                      {Object.entries(ValidLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">ChatMessage</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.chatRoomsForAdmin.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/chatRooms/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/chatRooms/${row.id}`}>{row.title}</Link>
                    </TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/chatMessages?chatRoomID=${row.id}`}>ChatMessages</Link>
                    </TableCell>
                    <TableCell align="right">{formatDate(new Date(row.createdAt!))}</TableCell>
                    <TableCell align="right">{formatDate(new Date(row.updatedAt!))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.chatRoomsForAdmin?.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <form className={classes.dialogForm} onSubmit={createForm.handleSubmit(handleClickAdd)}>
          <DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
            Create Record
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Paper className={classes.dialogPaper}>
              <Typography color="textSecondary" gutterBottom>
                Chat Room
              </Typography>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Title"
                type="text"
                defaultValue={createForm.watch('title')}
                fullWidth
                {...createForm.register('title')}
              />
              <Grid>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={createForm.watch('status')}
                  input={<Input name="status" id="status" />}
                  {...createForm.register('status')}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Typography color="textSecondary" gutterBottom>
                Users
              </Typography>
              <UserSelectBox control={createForm.control} onChange={onChangeUser} />
            </Paper>
            {addError && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...kj
                </Typography>
                {addError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAddDialogClose} color="primary">
              Cancel
            </Button>
            <Button type={'submit'} color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
