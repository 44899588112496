import * as yup from 'yup';

import { AdminBannerInput, Valid } from '../gen/graphql';

export const bannerSchema = yup.object({
  imageURL: yup.string().required().trim(),
  isNotLoginUser: yup.bool().required(),
  isTargetFree: yup.bool().required(),
  isTargetGraduate: yup.bool().required(),
  isTargetInstructor: yup.bool().required(),
  isTargetStudent: yup.bool().required(),
  isTargetSubscriptionUser: yup.bool().required(),
  isTargetTeamUser: yup.bool().required(),
  link: yup.string().required().trim(),
  title: yup.string().required().trim(),
  sort: yup.number().required(),
  status: yup.string().required().oneOf(Object.values(Valid)),
});

export const BannerDefaultValues: AdminBannerInput = {
  title: '',
  link: '',
  imageURL: '',
  sort: 1,
  status: Valid.Valid,
  isNotLoginUser: true,
  isTargetFree: true,
  isTargetSubscriptionUser: true,
  isTargetStudent: true,
  isTargetGraduate: true,
  isTargetInstructor: true,
  isTargetTeamUser: true,
};
