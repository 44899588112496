import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';

import { ApolloError } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import { ValidLabels } from '../../const/Valid';
import { DeviceTypeLabels, TargetDeviceTypeLabels } from '../../const/AbTest';
import { getApiErrorMessage } from '../../../common/error/Error';

import { AdminAbTestSettingInput } from '../../../gen/graphql';

import { RepeatInputFields } from './RepeatInputFields';

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  disableFlex: {
    display: 'block',
  },
}));

interface Props {
  formId: string;
  form: UseFormReturn<AdminAbTestSettingInput>;
  onSubmit: (input: AdminAbTestSettingInput) => void;
  error: ApolloError | undefined;
}

export const Form: React.FC<Props> = ({ formId, form, onSubmit, error }): JSX.Element => {
  const { fields } = useFieldArray({
    control: form.control,
    name: 'abTestSettingDeviceInputs',
  });

  const classes = useStyles();
  const formErrors = form.formState.errors;

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Controller
        name="originURL"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="originURL"
            label="リダイレクト元URL"
            type="text"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            style={{ margin: '10px 0' }}
            error={!!formErrors.originURL}
            helperText={formErrors.originURL?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.targetDeviceType}>
        <InputLabel htmlFor="targetDeviceType" shrink>
          使用するデバイス設定
        </InputLabel>
        <Controller
          name="targetDeviceType"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="targetDevice" id="targetDevice" />}
              error={!!formErrors.targetDeviceType}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(TargetDeviceTypeLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.targetDeviceType?.message}</FormHelperText>
      </FormControl>
      <Controller
        name="startDate"
        control={form.control}
        render={({ field: { ref, value, onChange, ...rest } }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="dense"
              id="startDate"
              label="開始日"
              fullWidth
              InputLabelProps={{ shrink: true }}
              style={{ margin: '10px 0 ' }}
              value={value || null}
              format="yyyy/MM/dd"
              onChange={(date) => {
                if (date) {
                  date.setHours(0, 0, 0, 0);
                }
                onChange(date);
              }}
              error={!!formErrors.startDate}
              helperText={formErrors.startDate?.message}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              views={['year', 'month', 'date']}
              inputRef={ref}
              {...rest}
            />
          </MuiPickersUtilsProvider>
        )}
      />
      <Controller
        name="endDate"
        control={form.control}
        render={({ field: { ref, value, onChange, ...rest } }) => (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="dense"
              id="endDate"
              label="終了日"
              fullWidth
              InputLabelProps={{ shrink: true }}
              style={{ margin: '10px 0 ' }}
              value={value || null}
              format="yyyy/MM/dd"
              onChange={(date) => {
                if (date) {
                  date.setHours(23, 59, 59);
                }
                onChange(date);
              }}
              error={!!formErrors.endDate}
              helperText={formErrors.endDate?.message}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              views={['year', 'month', 'date']}
              inputRef={ref}
              {...rest}
            />
          </MuiPickersUtilsProvider>
        )}
      />
      <FormControl fullWidth error={!!formErrors.status}>
        <InputLabel htmlFor="status" shrink>
          ステータス
        </InputLabel>
        <Controller
          name="status"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select error={!!formErrors.status} inputRef={ref} {...rest}>
              {Object.entries(ValidLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      {fields.map((abTestDevice, index) => {
        return (
          <Accordion key={abTestDevice.id} style={{ margin: '16px 0' }}>
            <AccordionSummary>{DeviceTypeLabels[abTestDevice.deviceType]}の設定</AccordionSummary>
            <AccordionDetails className={classes.disableFlex}>
              <TextField
                type="hidden"
                name={`abTestSettingDeviceInputs.${index}.deviceType`}
                value={abTestDevice.deviceType}
              />
              <Controller
                name={`abTestSettingDeviceInputs.${index}.originRate`}
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    margin="dense"
                    id={`abTestSettingDeviceInputs.${index}.originRate`}
                    label="リダイレクト元配信比率"
                    type="number"
                    InputProps={{
                      inputProps: { min: 0, max: 100 },
                    }}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!formErrors.abTestSettingDeviceInputs?.[index]?.originRate}
                    helperText={formErrors.abTestSettingDeviceInputs?.[index]?.originRate?.message}
                    ref={ref}
                    {...rest}
                  />
                )}
              />
              <RepeatInputFields control={form.control} errors={formErrors} type={index} />
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Controller
        name="note"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="note"
            label="メモ"
            type="text"
            fullWidth
            multiline
            minRows={3}
            maxRows={Infinity}
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.note}
            helperText={formErrors.note?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      {error?.graphQLErrors && (
        <Box className={classes.inputError}>
          <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
          {error.graphQLErrors.map((error, index) => (
            <div key={index}>{getApiErrorMessage(error)}</div>
          ))}
        </Box>
      )}
    </form>
  );
};
