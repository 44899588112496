import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ admin_id: string }>().admin_id;
  const id = Number(paramID);

  const { data, loading } = useGetAdminQuery({
    variables: { id },
  });
  const admin = data?.admin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Admin Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/admins/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        {admin && (
          <Paper className={classes.paperContent}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{admin.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/${admin.user.id}`}>{admin.user.personalInfo?.name}</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{ValidLabels[admin.status]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{admin.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{admin.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        )}
      </main>
    </div>
  );
};
