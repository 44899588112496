import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AdminPriceSearchInput } from '../../../gen/graphql';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((_theme: Theme) => ({
  block: {
    display: 'block',
  },
}));

export type AdminPriceSearchFormInput = Omit<AdminPriceSearchInput, 'limit' | 'page'>;

interface Props {
  form: UseFormReturn<AdminPriceSearchFormInput>;
  onSubmit: (input: AdminPriceSearchFormInput) => void;
}

export const SearchForm: React.FC<Props> = ({ form, onSubmit }) => {
  const classes = useStyles();

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div>
            <Typography>検索</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <Controller
                name="id"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="ID"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="providerPriceID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="providerPriceID"
                    label="ProviderPriceID"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="productID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="productID"
                    label="productID"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Controller
                  name="status"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value={undefined}></MenuItem>
                      {Object.entries(ValidLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4} />
          </Grid>
        </AccordionDetails>
        <Divider light />
        <AccordionActions>
          <Button size="small" color="primary" type="submit">
            <SearchIcon className={classes.block} />
            Search
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  );
};
