import React from 'react';
import styled from 'styled-components';

interface InputLabelProps {
  children: string;
  for: string;
}
export const InputLabel: React.FC<InputLabelProps> = (props) => {
  return <Label htmlFor={props.for}>{props.children}</Label>;
};

const Label = styled.label`
  font-size: 1rem;
  margin-right: 0.5rem;
`;
