import React, { useState, useEffect, FormEventHandler } from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, Theme, makeStyles } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  useCreateStudyReportForAdminMutation,
  useGetStudyReportsForAdminQuery,
  AdminStudyReportInput,
  AdminStudyReportSearchInput,
  Valid,
} from '../../../gen/graphql';

import { CsvRepositoryOnAPI } from '../../../infrastructure/csv/CsvRepositoryOnAPI';
import * as Sentry from '@sentry/browser';
import { ValidLabels } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  csvBtn: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 99,
    backgroundColor: '#009be5',
    color: 'white',
    padding: '10px 13px',
    textDecoration: 'none',
    borderRadius: '10px',
    boxShadow: '3px 3px 4px black',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

const defaultAddData = {
  userID: 0,
  date: '2019-01-01 00:00',
  content: '',
  status: Valid.Valid,
};

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const csvRepository = new CsvRepositoryOnAPI();

  const [csvExportable, _setCsvExportable] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCurrentQueryObject = () => {
    return queryString.parse(location.search);
  };

  // Add Dialog
  const [open, showAddDialog] = useState(false);
  const [addData, setAddData] = useState<AdminStudyReportInput>(defaultAddData);

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  function handleAdd() {
    postData();
  }

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (!event.target.name) return;
    const val = ['userId'].includes(event.target.name)
      ? parseInt(String(event.target.value))
      : event.target.value === ''
        ? undefined
        : event.target.value;

    setAddData({
      ...addData,
      [event.target.name]: val,
    });
  };

  // Fetch Data
  const [searchParam, setSearchParam] = useState<AdminStudyReportSearchInput>({
    page: 1,
    limit: 15,
  });

  const studyReportsData = useGetStudyReportsForAdminQuery({
    variables: {
      input: {
        id: searchParam.id,
        page: searchParam.page,
        limit: searchParam.limit,
        startDate: searchParam.startDate,
        endDate: searchParam.endDate,
        userID: searchParam.userID,
        status: searchParam.status,
      },
    },
  });

  const [createStudyReport, createStudyReportData] = useCreateStudyReportForAdminMutation();

  const formatDateParam = React.useCallback((date: string) => {
    if (!date) return '';
    const d = new Date(date);
    return (
      d.getFullYear() +
      '-' +
      zeroPadding(d.getMonth() + 1) +
      '-' +
      zeroPadding(d.getDate()) +
      'T' +
      zeroPadding(d.getHours()) +
      ':' +
      zeroPadding(d.getMinutes()) +
      ':00Z'
    );
  }, []);

  const postData = useSafeAsyncCallback(
    React.useCallback(async () => {
      try {
        await createStudyReport({
          variables: {
            input: {
              userID: addData.userID,
              content: addData.content,
              date: formatDateParam(addData.date),
              status: addData.status,
            },
          },
        });
        setAddData(defaultAddData);
        showAddDialog(false);
        studyReportsData.refetch();
      } catch {
        return;
      }
    }, [addData, createStudyReport, formatDateParam, studyReportsData]),
  );

  const setQuery = (queryObject: Record<string, unknown>) => {
    navigate({
      pathname: '/studyReports',
      search: queryString.stringify(queryObject),
    });
  };

  const handleSearchInput = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const { name, value } = event.target;
    if (!name) return;

    setSearchParam({
      ...searchParam,
      [name]: value ? value : undefined,
    });

    const queryObject = getCurrentQueryObject();
    queryObject[name] = String(value);
    setQuery(queryObject);
  };

  const handleSearchSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const queryObject = getCurrentQueryObject();
    for (const [key, value] of Object.entries(searchParam)) {
      queryObject[key] = String(value);
    }
    queryObject.page = '1';

    setQuery(queryObject);
  };

  function handleChangePerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam((prev) => ({
      ...prev,
      limit: parseInt(event.target.value),
    }));
    const queryObject = getCurrentQueryObject();
    queryObject['limit'] = String(event.target.value);

    setQuery(queryObject);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChangePage(event: any, page: number) {
    // setState(Material UI は page=0 はじまり, LMS API は page=1 はじまり)
    setSearchParam((prev) => ({
      ...prev,
      page: page + 1,
    }));
    const queryObject = getCurrentQueryObject();
    queryObject['page'] = String(event.target.value);

    setQuery(queryObject);
  }

  const loading = createStudyReportData.loading || studyReportsData.loading;

  const csvFileDownload = () => {
    const queryObject = getCurrentQueryObject();
    const query = queryString.stringify(queryObject);
    const url = `/api/v1/admin/csv/study_reports?${query}`;

    csvRepository.getCsv('study_reports', url).catch((error) => {
      Sentry.captureException(error);
    });
  };

  useEffect(() => {
    const queryObject = getCurrentQueryObject();
    for (const key in queryObject) {
      setSearchParam((prev) => ({
        ...prev,
        [key]: queryObject[key],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChangeDate = (date: any) => {
    setAddData({ ...addData, date: date });
  };

  const handleChangeSearchStartDate: (
    date: MaterialUiPickersDate | null,
    value?: string | null,
  ) => void = (date) => {
    setSearchParam({
      ...searchParam,
      startDate: formatDateSearch(date),
    });
  };

  const handleChangeSearchEndDate: (date: MaterialUiPickersDate, value?: string | null) => void = (
    date,
  ) => {
    setSearchParam({
      ...searchParam,
      endDate: formatDateSearch(date),
    });
  };

  const formatDateSearch = (date: MaterialUiPickersDate) => {
    if (!date) return '';
    const d = new Date(date);
    return (
      d.getFullYear() +
      '-' +
      zeroPadding(d.getMonth() + 1) +
      '-' +
      zeroPadding(d.getDate()) +
      'T00:00:00+09:00'
    );
  };

  const zeroPadding = (num: number) => `0${num}`.substr(-2);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                StudyReport
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSearchSubmit}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="id"
                      name="id"
                      label="ID"
                      type="number"
                      fullWidth
                      value={searchParam.id}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="userId"
                      name="userID"
                      label="UserID"
                      type="number"
                      fullWidth
                      value={searchParam.userID}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        views={['year', 'month', 'date']}
                        margin="normal"
                        id="startDate"
                        label="StartDate"
                        value={searchParam.startDate ? searchParam.startDate : null}
                        onChange={handleChangeSearchStartDate}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        views={['year', 'month', 'date']}
                        margin="normal"
                        id="endDate"
                        label="EndDate"
                        value={searchParam.endDate ? searchParam.endDate : null}
                        onChange={handleChangeSearchEndDate}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Select
                        value={searchParam.status}
                        onChange={handleSearchInput}
                        input={<Input name="status" id="status" />}
                      >
                        <MenuItem value={Valid.Invalid}>{ValidLabels.INVALID}</MenuItem>
                        <MenuItem value={Valid.Valid}>{ValidLabels.VALID}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">StudyReportDetails</TableCell>
                  <TableCell align="right">StudyReportComments</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studyReportsData.data &&
                  studyReportsData.data.getStudyReportsForAdmin.items.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        <Link to={`/studyReports/${row.id}`}>{row.id}</Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`/users/${row.user.id}`}>{row.user.personalInfo?.name}</Link>
                      </TableCell>
                      <TableCell align="right">{row.date}</TableCell>
                      <TableCell align="right">{row.content}</TableCell>
                      <TableCell align="right">
                        <Link to={`/studyReportDetails?studyReportId=${row.id}`}>
                          StudyReportDetails
                        </Link>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link to={`/studyReportComments?studyReportId=${row.id}`}>
                          StudyReportComments
                        </Link>
                      </TableCell>
                      <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                      <TableCell align="right">{row.createdAt}</TableCell>
                      <TableCell align="right">{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={studyReportsData.data?.getStudyReportsForAdmin.total ?? 0}
            rowsPerPage={searchParam.limit}
            page={searchParam.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePerPage}
          />
        </Paper>
      </main>
      {csvExportable ? (
        <button onClick={csvFileDownload} id="csvLink" className={classes.csvBtn}>
          CSV出力
        </button>
      ) : (
        <button id="csvLink" className={classes.csvBtn}>
          CSV出力中
        </button>
      )}
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="userId"
            name="userID"
            label="UserID"
            type="number"
            defaultValue={addData.userID}
            fullWidth
            onChange={handleChange}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="content"
            name="content"
            label="Content"
            type="text"
            defaultValue={addData.content}
            fullWidth
            onChange={handleChange}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
          />
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="date"
                label="申請時間"
                format="yyyy-MM-dd HH:mm"
                value={addData.date}
                onChange={handleChangeDate}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={addData.status}
                onChange={handleChange}
                input={<Input name="status" id="status" />}
              >
                <MenuItem value={Valid.Invalid}>{ValidLabels.INVALID}</MenuItem>
                <MenuItem value={Valid.Valid}>{ValidLabels.VALID}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {createStudyReportData.error && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {createStudyReportData.error.graphQLErrors.map((error, index) => (
                <div key={index}>
                  {error.path} : {error.message}
                </div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
