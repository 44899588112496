import React, { useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  useGetPracticesForAdminQuery,
  useCreatePracticeForAdminMutation,
  AdminPracticeSearchInput,
  AdminPracticeInput,
  Valid,
} from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Paper, Grid, AppBar } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';

import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { ListPagination } from '../../common/ListPagination';
import { CreateDialog } from '../../common/CreateDialog';
import { PageHeader } from '../../common/PageHeader';
import { ValidLabels } from '../../const/Valid';
import { AdminPracticeSearchFormInput, SearchForm } from './SearchForm';
import { Form } from './Form';
import { PracticeDefaultValues, practiceSchema } from '../../../formSchema/practice';
import TableCell from '@material-ui/core/TableCell';
import { strToBoolean, strToEnum } from '../../../utils/common';
import { AuthorizationTypeLabels } from '../../const/Authorization';
import { PracticeDifficultyLabels } from '../../const/PracticeDifficulty';
import { IsAiReviewEnabledLabels } from '../../const/Practice';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const [open, showCreateDialog] = React.useState(false);

  const currentSearchParams = useMemo((): AdminPracticeSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const programID = params.get('programID') ?? '';
    const key = params.get('key') ?? '';
    const title = params.get('title') ?? '';
    const isAiReviewEnabled = params.get('isAiReviewEnabled') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: id.length === 26 ? id : undefined, // ULIDなので26の桁数チェック
      programID: parseInt(programID) > 0 ? parseInt(programID) : undefined,
      key: key.trim().length > 0 ? key : undefined,
      title: title.trim().length > 0 ? title : undefined,
      isAiReviewEnabled: isAiReviewEnabled ? strToBoolean(isAiReviewEnabled) : undefined,
      status: strToEnum(status, Valid),
    };
  }, [location.search]);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminPracticeSearchInput): void => {
      navigate({
        pathname: '/practices',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const { data, loading, refetch } = useGetPracticesForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  const searchForm = useForm<AdminPracticeSearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      programID: currentSearchParams.programID,
      key: currentSearchParams.key,
      title: currentSearchParams.title,
      status: currentSearchParams.status,
      isAiReviewEnabled: currentSearchParams.isAiReviewEnabled,
    },
    // 検索してもヒットしないだけの為、バリデーションなし
  });

  // 検索
  const searchPractices = useCallback(
    async (input: AdminPracticeSearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  const handleCreateDialogOpen = useCallback(() => showCreateDialog(true), []);
  const handleCreateDialogClose = useCallback(() => showCreateDialog(false), []);

  const [createPractice, { error: createError }] = useCreatePracticeForAdminMutation();

  const createFormID = 'practice-create-form';
  const createForm = useForm<AdminPracticeInput>({
    resolver: yupResolver(practiceSchema),
    defaultValues: PracticeDefaultValues,
  });

  const handleClickCreate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminPracticeInput): Promise<void> => {
        try {
          await createPractice({ variables: { input } });
        } catch {
          return;
        }

        // ダイアログを閉じて入力フォーム初期化
        showCreateDialog(false);
        createForm.reset();

        // 再検索
        refetch({ input: currentSearchParams });
      },
      [createForm, createPractice, currentSearchParams, refetch],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <PageHeader title="Practice" handleCreateDialogOpen={handleCreateDialogOpen} />
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={searchPractices} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Program ID</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Key</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Require Time</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">Answer</TableCell>
                  <TableCell align="right">ソート順</TableCell>
                  <TableCell align="right">公開範囲</TableCell>
                  <TableCell align="right">難易度</TableCell>
                  <TableCell align="right">AI Review</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.practicesForAdmin?.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link to={`/practices/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/programs/${row.programID}`}>{row.programID}</Link>
                    </TableCell>
                    <TableCell align="right">{row.title ?? '-'}</TableCell>
                    <TableCell align="right">{row.key ?? '-'}</TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                    <TableCell align="right">{row.requireTime}分</TableCell>
                    <TableCell align="right">{row.content ?? '-'}</TableCell>
                    <TableCell align="right">{row.answer ?? '-'}</TableCell>
                    <TableCell align="right">{row.sortNumber}</TableCell>
                    <TableCell align="right">{AuthorizationTypeLabels[row.type]}</TableCell>
                    <TableCell align="right">{PracticeDifficultyLabels[row.difficulty]}</TableCell>
                    <TableCell align="right">
                      {row.isAiReviewEnabled
                        ? IsAiReviewEnabledLabels.true
                        : IsAiReviewEnabledLabels.false}
                    </TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <ListPagination
            count={data?.practicesForAdmin?.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      {/* Create */}
      <CreateDialog open={open} handleClose={handleCreateDialogClose} formID={createFormID}>
        <Form
          formID={createFormID}
          form={createForm}
          onSubmit={handleClickCreate}
          error={createError}
        />
      </CreateDialog>
    </div>
  );
};
