import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { LessonQuestionnaireSendLogSearchInput } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((_theme: Theme) => ({
  block: {
    display: 'block',
  },
}));

export type LessonQuestionnaireSendLogSearchFromInput = Omit<
  LessonQuestionnaireSendLogSearchInput,
  'limit' | 'page'
>;

interface Props {
  form: UseFormReturn<LessonQuestionnaireSendLogSearchFromInput>;
  onSubmit: (input: LessonQuestionnaireSendLogSearchFromInput) => void;
}

export const SearchForm: React.FC<Props> = ({ form, onSubmit }) => {
  const classes = useStyles();

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div>
            <Typography>検索</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <Controller
                name="id"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="ID"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="courseID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="courseId"
                    label="CourseId"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="lessonID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="lessonId"
                    label="LessonId"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="instructorID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="instructorId"
                    label="InstructorId"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="studentID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="studentId"
                    label="StudentId"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider light />
        <AccordionActions>
          <Button size="small" color="primary" type="submit">
            <SearchIcon className={classes.block} />
            Search
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  );
};
