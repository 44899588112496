import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  PracticeSubmitDetailInput,
  useGetPracticeSubmitDetailForAdminQuery,
  useUpdatePracticeSubmitDetailForAdminMutation,
} from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { AppBar, Button, Grid, LinearProgress, Paper } from '@material-ui/core';

import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { PageHeader } from '../../common/PageHeader';
import { Form } from './Form';
import {
  PracticeSubmitDetailDefaultValues,
  practiceSubmitDetailSchema,
} from '../../../formSchema/practiceSubmitDetail';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ practice_submit_detail_id: string }>().practice_submit_detail_id ?? '';

  const [showLoader, setShowLoader] = useState(false);

  const formID = 'practice-submit-detail-edit-form';
  const form = useForm<PracticeSubmitDetailInput>({
    resolver: yupResolver(practiceSubmitDetailSchema),
    defaultValues: PracticeSubmitDetailDefaultValues,
  });

  const { loading } = useGetPracticeSubmitDetailForAdminQuery({
    variables: { id },
    onCompleted: ({ practiceSubmitDetailForAdmin: data }) => {
      if (!data) {
        return;
      }

      form.reset({
        practiceSubmitID: data?.practiceSubmitID,
        url: data?.url,
        content: data?.content,
        reviewStatus: data?.reviewStatus,
        status: data?.status,
      });
    },
  });

  const [updatePractice, { error }] = useUpdatePracticeSubmitDetailForAdminMutation();

  const handleClickUpdate = useSafeAsyncCallback(
    useCallback(
      async (input: PracticeSubmitDetailInput): Promise<void> => {
        setShowLoader(true);

        try {
          await updatePractice({
            variables: {
              id: id,
              input: input,
            },
          });
        } catch {
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [updatePractice, id, navigate],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <PageHeader title="Practice Submit Detail Edit" />
      </AppBar>
      {(loading || showLoader) && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Form formID={formID} form={form} onSubmit={handleClickUpdate} error={error} isUpdate />
          <Button
            type="submit"
            form={formID}
            variant="contained"
            color="primary"
            style={{ margin: '20px 0 0 0' }}
          >
            Save
          </Button>
        </Paper>
      </main>
    </div>
  );
};
