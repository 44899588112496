import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  AdminTagInput,
  AdminTagSearchInput,
  Valid,
  useAddTagForAdminMutation,
  useGetTagsForAdminQuery,
  UserRole,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { strToEnum } from '../../../utils/common';
import { ValidLabels } from '../../const/Valid';

import { TagDefaultValues, tagSchema } from '../../../formSchema/tag';
import { AdminTagSearchFormInput, SearchForm } from './SearchForm';
import { Form } from './Form';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

type SearchFormInput = Omit<AdminTagSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminTagSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const name = params.get('name') ?? '';
    const status = params.get('status') ?? '';
    const userRole = params.get('userRole') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      name: name.trim().length > 0 ? name : undefined,
      status: strToEnum(status, Valid),
      userRole: strToEnum(userRole, UserRole),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetTagsForAdminQuery({
    variables: { input: currentSearchParams },
  });

  const [createTag, { error: createError }] = useAddTagForAdminMutation();

  // Add Dialog
  const [open, showCreateDialog] = React.useState(false);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminTagSearchInput): void => {
      navigate({
        pathname: '/tags',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      name: currentSearchParams.name,
      status: currentSearchParams.status,
      userRole: currentSearchParams.userRole,
    },
  });

  // 検索
  const searchTag = useCallback(
    async (input: AdminTagSearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  const createFormId = 'tag-create-form';
  const createForm = useForm<AdminTagInput>({
    resolver: yupResolver(tagSchema),
    defaultValues: TagDefaultValues,
  });

  const handleClickCreate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminTagInput): Promise<void> => {
        try {
          await createTag({ variables: { input } });
        } catch {
          return;
        }

        // ダイアログを閉じて入力フォーム初期化
        showCreateDialog(false);
        createForm.reset();

        // 再検索
        refetch({ input: currentSearchParams });
      },
      [createForm, createTag, currentSearchParams, refetch],
    ),
  );

  const handleCreateDialogOpen = useCallback(() => showCreateDialog(true), []);
  const handleCreateDialogClose = useCallback(() => showCreateDialog(false), []);

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Tag
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleCreateDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={searchTag} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">Overview</TableCell>
                  <TableCell align="right">UserRole</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.getTagsForAdmin.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/tags/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{row.overview}</TableCell>
                    <TableCell align="right">
                      {row.tagPermissions.map((n) => n.userRole).join(', ')}
                    </TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.getTagsForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={handleCreateDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <Form
            formId={createFormId}
            form={createForm}
            onSubmit={handleClickCreate}
            error={createError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" form={createFormId} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
