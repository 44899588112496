import * as yup from 'yup';

import { AdminChatMessageInput, Valid } from '../gen/graphql';

export const chatMessageSearchSchema = yup.object({
  id: yup
    .number()
    .integer()
    .min(0)
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value)),
  chatRoomID: yup
    .number()
    .integer()
    .min(0)
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value)),
  userID: yup
    .number()
    .integer()
    .min(0)
    .nullable()
    .transform((value) => (isNaN(value) ? undefined : value)),
  periodStart: yup.date().nullable(),
  periodEnd: yup.date().nullable(),
  status: yup
    .string()
    .nullable()
    .transform((_, originalValue) => {
      return originalValue === '' ? null : originalValue;
    })
    .oneOf([...Object.values(Valid), null]),
});

export const chatMessageSchema = yup.object({
  content: yup.string().required().max(2000).trim(),
  status: yup.string().required().oneOf(Object.values(Valid)),
});

export const ChatMessageDefaultValues: AdminChatMessageInput = {
  content: '',
  status: Valid.Valid,
};

export type SearchChatMessage = yup.InferType<typeof chatMessageSearchSchema>;
