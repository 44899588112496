import React, { useCallback, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Material UI
import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Toolbar,
} from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  TweetCommentFragment,
  TweetCommentStatus,
  useBanTweetCommentForAdminMutation,
  useDeleteTweetCommentForAdminMutation,
  useGetAdminTweetCommentDetailQuery,
} from '../../../gen/graphql';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
  textContent: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  },
  deleteButton: {
    marginLeft: '0.5rem',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ tweet_comment_id: string }>().tweet_comment_id ?? '0';

  const [updated, setUpdated] = useState<TweetCommentFragment | null>(null);
  const [isOpenDeleteConfirmDialog, setIsOpenDeleteConfirmDialog] = useState(false);
  const [isOpenBanConfirmDialog, setIsOpenBanConfirmDialog] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');

  const { data, loading } = useGetAdminTweetCommentDetailQuery({
    variables: {
      tweetCommentID: id,
    },
  });

  const tweetComment = updated ?? data?.adminTweetCommentDetailV1;

  const [deleteTweetComment] = useDeleteTweetCommentForAdminMutation();
  const [banTweetComment] = useBanTweetCommentForAdminMutation();
  const sending = useRef(false);

  const closeDeleteConfirmDialog = (): void => setIsOpenDeleteConfirmDialog(false);
  const openDeleteConfirmDialog = (): void => setIsOpenDeleteConfirmDialog(true);
  const closeBanConfirmDialog = (): void => setIsOpenBanConfirmDialog(false);
  const openBanConfirmDialog = (): void => setIsOpenBanConfirmDialog(true);

  const handleClickDeleteButton = useCallback(async (): Promise<void> => {
    // 2度押し対応
    if (sending.current) {
      return;
    }
    sending.current = true;

    setSnackMessage('処理中');
    try {
      const res = await deleteTweetComment({
        variables: {
          tweetCommentID: id,
        },
      });
      if (res.data?.deleteTweetCommentForAdmin) {
        setUpdated(res.data.deleteTweetCommentForAdmin);
      }
      closeDeleteConfirmDialog();
      setSnackMessage('コメントを削除しました');
    } catch (_) {
      setSnackMessage('コメントの削除に失敗しました');
    } finally {
      sending.current = false;
    }
  }, [deleteTweetComment, id]);

  const handleClickBanButton = useCallback(async (): Promise<void> => {
    // 2度押し対応
    if (sending.current) {
      return;
    }
    sending.current = true;

    setSnackMessage('処理中');
    try {
      const res = await banTweetComment({
        variables: {
          tweetCommentID: id,
        },
      });
      if (res.data?.banTweetCommentForAdmin) {
        setUpdated(res.data.banTweetCommentForAdmin);
      }
      closeBanConfirmDialog();
      setSnackMessage('コメントをBanしました');
    } catch (_) {
      setSnackMessage('コメントのBanに失敗しました');
    } finally {
      sending.current = false;
    }
  }, [banTweetComment, id]);

  return (
    <>
      <AppBar component="div" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Tweet Comment Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Button onClick={() => navigate(-1)} variant="contained">
              ＜ Back
            </Button>
          </Grid>
          {tweetComment && (
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={openBanConfirmDialog}
                disabled={tweetComment.tweetCommentStatus !== TweetCommentStatus.Active}
              >
                Ban
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={openDeleteConfirmDialog}
                disabled={tweetComment.tweetCommentStatus === TweetCommentStatus.Deleted}
                className={classes.deleteButton}
              >
                Delete
              </Button>
            </Grid>
          )}
        </Grid>
        {tweetComment && (
          <Paper className={classes.paperContent}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TweetCommentID
                  </TableCell>
                  <TableCell>{tweetComment.tweetCommentID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TweetID
                  </TableCell>
                  <TableCell>
                    <Link to={`/tweets/${tweetComment.tweetID}`}>{tweetComment.tweetID}</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/${tweetComment.user.id}`}>
                      {tweetComment.user.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Content
                  </TableCell>
                  <TableCell className={classes.textContent}>{tweetComment.content}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TweetCommentStatus
                  </TableCell>
                  <TableCell>{tweetComment.tweetCommentStatus}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{tweetComment.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{tweetComment.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        )}
      </main>
      <Dialog
        open={isOpenDeleteConfirmDialog}
        onClose={closeDeleteConfirmDialog}
        aria-labelledby="alert-delete-dialog-title"
        aria-describedby="alert-delete-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-delete-dialog-description">
            この操作は取り消せません。こちらのコメントをタイムラインに表示することはできなくなりますが、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteConfirmDialog} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleClickDeleteButton} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isOpenBanConfirmDialog}
        onClose={closeBanConfirmDialog}
        aria-labelledby="alert-ban-dialog-title"
        aria-describedby="alert-ban-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-ban-dialog-description">
            この操作は取り消せません。こちらのコメントを投稿者本人のタイムライン以外に表示することはできなくなりますが、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeBanConfirmDialog} color="primary">
            キャンセル
          </Button>
          <Button onClick={handleClickBanButton} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackMessage.length > 0}
        autoHideDuration={6000}
        onClose={() => setSnackMessage('')}
        message={snackMessage}
      />
    </>
  );
};
