import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  useGetSubscriptionContractForAdminQuery,
  useGetSubscriptionContractLogsForAdminQuery,
} from '../../../gen/graphql';
import {
  subscriptionContractPhaseLabels,
  exitFeeStatusLabels,
} from '../../const/SubscriptionContract';
import { stripePaymentIntentURL } from '../../../utils/stripe';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Paper, Grid, TableContainer } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'hidden',
    margin: '24px 0',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

// 一旦最大件数を指定する。件数が増えてきてPaging等が必要になったら対応
const LOG_LIMIT = 100;

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ subscription_contract_id: string }>().subscription_contract_id ?? '';

  const { data: contractData, loading: contractLoading } = useGetSubscriptionContractForAdminQuery({
    variables: { id },
  });
  const subscriptionContract = contractData?.subscriptionContractForAdmin ?? undefined;

  const { data: logsData, loading: logLoading } = useGetSubscriptionContractLogsForAdminQuery({
    variables: {
      input: {
        limit: LOG_LIMIT,
        page: 1,
        subscriptionContractID: id,
      },
    },
  });
  const logs = logsData?.subscriptionContractLogsForAdmin.items ?? [];

  const loading = contractLoading || logLoading;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                SubscriptionContract Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/subscriptionContracts/${subscriptionContract?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{subscriptionContract?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    stripeSubscriptionID
                  </TableCell>
                  <TableCell>
                    <Link to={`/stripeSubscriptions/${subscriptionContract?.stripeSubscriptionID}`}>
                      {subscriptionContract?.stripeSubscriptionID}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Price
                  </TableCell>
                  <TableCell>
                    <Link to={`/prices/${subscriptionContract?.priceID}`}>
                      {subscriptionContract?.priceID}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Product
                  </TableCell>
                  <TableCell>
                    <Link to={`/products/${subscriptionContract?.price?.product.id}`}>
                      {subscriptionContract?.price?.product.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PeriodStart
                  </TableCell>
                  <TableCell>{subscriptionContract?.periodStart}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PeriodEnd
                  </TableCell>
                  <TableCell>{subscriptionContract?.periodEnd}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    EndDate
                  </TableCell>
                  <TableCell>{subscriptionContract?.endDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CanceledAt
                  </TableCell>
                  <TableCell>{subscriptionContract?.canceledAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Phase
                  </TableCell>
                  <TableCell>
                    {subscriptionContract?.phase
                      ? subscriptionContractPhaseLabels[subscriptionContract?.phase]
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ExitFeeStatus
                  </TableCell>
                  <TableCell>
                    {subscriptionContract?.exitFeeStatus
                      ? exitFeeStatusLabels[subscriptionContract?.exitFeeStatus]
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ExitFeePaymentIntentID
                  </TableCell>
                  <TableCell>
                    {subscriptionContract?.exitFeePaymentIntentID ? (
                      <Link
                        target="_blank"
                        to={stripePaymentIntentURL(subscriptionContract?.exitFeePaymentIntentID)}
                      >
                        {subscriptionContract?.exitFeePaymentIntentID}
                      </Link>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{subscriptionContract?.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{subscriptionContract?.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Typography color="inherit" variant="h6" component="h2">
          SubscriptionContractLog 最大{LOG_LIMIT}件
        </Typography>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>stripeSubscriptionID</TableCell>
                  <TableCell>subscriptionContractID</TableCell>
                  <TableCell align="right">priceID</TableCell>
                  <TableCell align="right">productName</TableCell>
                  <TableCell align="right">periodStart</TableCell>
                  <TableCell align="right">periodEnd</TableCell>
                  <TableCell align="right">endDate</TableCell>
                  <TableCell align="right">canceledAt</TableCell>
                  <TableCell align="right">phase</TableCell>
                  <TableCell align="right">exitFeeStatus</TableCell>
                  <TableCell align="right">exitFeePaymentIntentID</TableCell>
                  <TableCell align="right">recordType</TableCell>
                  <TableCell align="right">action</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs?.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell>{log.id}</TableCell>
                    <TableCell>{log.subscriptionContractID}</TableCell>
                    <TableCell>
                      <Link to={`/stripeSubscriptions/${log.stripeSubscriptionID}`}>
                        {log.stripeSubscriptionID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/prices/${log.priceID}`}>{log.priceID}</Link>
                    </TableCell>
                    <TableCell align="right">{log.price?.product.name}</TableCell>
                    <TableCell align="right">{log.periodStart}</TableCell>
                    <TableCell align="right">{log.periodEnd}</TableCell>
                    <TableCell align="right">{log.endDate}</TableCell>
                    <TableCell align="right">{log.canceledAt}</TableCell>
                    <TableCell align="right">
                      {log.phase ? subscriptionContractPhaseLabels[log.phase] : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {log.exitFeeStatus ? exitFeeStatusLabels[log.exitFeeStatus] : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {log?.exitFeePaymentIntentID ? (
                        <Link
                          target="_blank"
                          to={stripePaymentIntentURL(log?.exitFeePaymentIntentID)}
                        >
                          {log?.exitFeePaymentIntentID}
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>{log.subscriptionChangeHistory?.recordType}</TableCell>
                    <TableCell>{log.subscriptionChangeHistory?.action}</TableCell>
                    <TableCell align="right">{log.createdAt}</TableCell>
                    <TableCell align="right">{log.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Paper>
      </main>
    </div>
  );
};
