import React, { useCallback } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Snackbar, Toolbar } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { CancelAtPeriodEndLabels, PhaseLabels } from '../../const/Subscription';
import { SnsTypeLabels } from '../../const/User';
import {
  useGetSubscriptionsByUserIdQuery,
  useResendTemporaryPasswordMutation,
  useWithdrawMutation,
  useGetUserQuery,
  UserTagFragment as UserTag,
  useGetStripeSubscriptionsForAdminQuery,
  TeamMemberRole,
} from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { stripeCustomerTypeLabels } from '../../const/stripeCustomer';
import { stripeSubscriptionURL } from '../../../utils/stripe';
import { providerSubscriptionStatusPhaseLabels } from '../../const/StripeSubscription';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflow: 'hidden',
    margin: '24px 0',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

// 一旦最大件数を指定する。件数が増えてきてPaging等が必要になったら対応
const SUBSCRIPTION_LIMIT = 30;

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ user_id: string }>().user_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);
  const [passwordReset, setPasswordReset] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState('');

  const { data: subscriptionsData } = useGetSubscriptionsByUserIdQuery({
    variables: { userID: id },
  });
  const subscriptions = subscriptionsData?.subscriptionsByUserIDForAdmin ?? [];

  // ユーザー取得
  const {
    data: userData,
    loading,
    refetch,
  } = useGetUserQuery({
    variables: { id },
  });
  const user = userData?.userForAdmin;
  const cognitoUser = userData?.cognitoUserForAdmin;

  const { data: stripeSubscriptionData } = useGetStripeSubscriptionsForAdminQuery({
    variables: {
      input: {
        stripeCustomerID: user?.stripeCustomerID,
        page: 1,
        limit: SUBSCRIPTION_LIMIT,
      },
    },
    skip: !user?.stripeCustomerID,
  });
  const stripeSubscriptions = stripeSubscriptionData?.stripeSubscriptionsForAdmin.items ?? [];

  // 退会処理
  const [withdrawMutation] = useWithdrawMutation();
  const handleUserWithdraw = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (
        window.confirm(
          '対象のユーザーは、ただちに侍テラコヤがご利用いただけなくなります。本当に退会させますか？',
        )
      ) {
        setShowLoader(true);
        setSnackBar('処理中...');

        try {
          await withdrawMutation({
            variables: {
              id: id,
            },
          });
          setSnackBar('処理は正常に完了しました');
        } catch (e) {
          setSnackBar('問題が発生しました');
          return;
        } finally {
          // 退会ボタンを非活性化するためにユーザー情報を最新化
          refetch({ id: id });
          setShowLoader(false);
        }
      }
    }, [refetch, id, withdrawMutation]),
  );

  // パスワードリセット処理
  const [resendTemporaryPasswordMutation] = useResendTemporaryPasswordMutation();
  const resendTemporaryPassword = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (
        window.confirm(
          '対象のユーザー宛に仮パスワード発行メールが送信されます。ただちにこれまでの仮パスワードは利用できなくなりますが、本当に変更しますか？',
        )
      ) {
        setShowLoader(true);
        setPasswordReset(true);
        setSnackBar('処理中...');

        try {
          await resendTemporaryPasswordMutation({
            variables: {
              id: id,
            },
          });
          setSnackBar('処理は正常に完了しました');
        } catch (e) {
          setSnackBar(
            'パスワードリセットに失敗しました。お急ぎの場合はシステム部に詳細を共有してください',
          );
          return;
        } finally {
          setShowLoader(false);
        }
      }
    }, [id, resendTemporaryPasswordMutation]),
  );

  const userTags = (userTags: UserTag[] | undefined) => {
    let result = '';
    userTags?.forEach((tag) => {
      result += `${tag.tag?.name},`;
    });
    return result.slice(0, -1);
  };

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                ユーザー詳細
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {showLoader || loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/users/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Typography color="inherit" variant="h6" component="h2">
          基本データ
        </Typography>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ユーザーID
                </TableCell>
                <TableCell>{user?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  システムID
                </TableCell>
                <TableCell>{user?.personalInfo?.sub}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ストライプ連携データ
                </TableCell>
                <TableCell>
                  <Link to={`/stripeCustomers/${user?.stripeCustomerID}`}>
                    {user?.stripeCustomerID ?? ''}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  マイページURL
                </TableCell>
                <TableCell>
                  <a
                    href={`${process.env.REACT_APP_FRONT_USER_ENDPOINT}/users/${user?.id}/profile`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    リンク
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  メール通知設定
                </TableCell>
                <TableCell>
                  <Link to={`/mailSettings/${user?.mailSetting?.id}`}>{user?.mailSetting?.id}</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  名前
                </TableCell>
                <TableCell>{user?.personalInfo?.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ふりがな
                </TableCell>
                <TableCell>{user?.personalInfo?.kanaName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ニックネーム
                </TableCell>
                <TableCell>{user?.nickName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  SNSログイン
                </TableCell>
                <TableCell>{user?.personalInfo?.oauthUserName ?? '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  メールアドレス
                </TableCell>
                <TableCell>
                  <p>{user?.personalInfo?.email}</p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  パスワード
                </TableCell>
                <TableCell>
                  {user?.id === 0 ? (
                    <p>
                      何か問題が発生しています。
                      <br />
                      ※システム部までご連絡いただけますと幸いです。
                    </p>
                  ) : !user?.available ? (
                    <p>
                      パスワードリセットできません
                      <br />
                      ※解約・退会済みで無いかをご確認の上、システム部までお問い合わせください
                    </p>
                  ) : user?.personalInfo?.oauthUserName ? (
                    <p>SNSアカウント連携ユーザーのため、パスワードリセットできません</p>
                  ) : cognitoUser?.userStatus !== 'FORCE_CHANGE_PASSWORD' ? (
                    <p>
                      パスワード設定済みのため、パスワードリセットできません
                      <br />
                      ※ログイン画面「パスワードを忘れた方はこちら」からパスワードを再設定することが可能です
                    </p>
                  ) : (
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={resendTemporaryPassword}
                      disabled={passwordReset}
                    >
                      仮パスワードをリセットする
                    </Button>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  電話番号
                </TableCell>
                <TableCell>{user?.personalInfo?.phone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ユーザーアイコン
                </TableCell>
                <TableCell>
                  <img width="200px" height="200px" src={user?.image} alt="ユーザーアイコン" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  プログラミング経験
                </TableCell>
                <TableCell>{user?.userTags ? userTags(user?.userTags) : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  生徒権限
                </TableCell>
                <TableCell>{user?.isStudent ? '有効' : '無効'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  講師権限
                </TableCell>
                <TableCell>{user?.isInstructor ? '有効' : '無効'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  管理者権限
                </TableCell>
                <TableCell>{user?.isAdmin ? '有効' : '無効'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  法人管理者権限
                </TableCell>
                <TableCell>
                  {user?.activeTeamMembers
                    ?.filter((activeTeamMember) => activeTeamMember.role === TeamMemberRole.Admin)
                    .map((activeTeamMemberOfAdminRole) => (
                      <Link
                        key={activeTeamMemberOfAdminRole.id}
                        className={classes.block}
                        to={`/teamMembers/${activeTeamMemberOfAdminRole.id}`}
                      >
                        {activeTeamMemberOfAdminRole.team?.companyName
                          ? activeTeamMemberOfAdminRole.team?.companyName
                          : activeTeamMemberOfAdminRole.id}
                      </Link>
                    ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  法人メンバー権限
                </TableCell>
                <TableCell>
                  {user?.activeTeamMembers
                    ?.filter((activeTeamMember) => activeTeamMember.role === TeamMemberRole.General)
                    .map((activeTeamMemberOfGeneralRole) => (
                      <Link
                        key={activeTeamMemberOfGeneralRole.id}
                        className={classes.block}
                        to={`/teamMembers/${activeTeamMemberOfGeneralRole.id}`}
                      >
                        {activeTeamMemberOfGeneralRole.team?.companyName
                          ? activeTeamMemberOfGeneralRole.team?.companyName
                          : activeTeamMemberOfGeneralRole.id}
                      </Link>
                    ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  サブスク権限
                </TableCell>
                <TableCell>{user?.snsType ? SnsTypeLabels[user?.snsType] : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ストライプID
                </TableCell>
                <TableCell>
                  {user?.personalInfo?.providerCustomerID
                    ? user?.personalInfo?.providerCustomerID
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  アカウント削除
                </TableCell>
                <TableCell>{user?.status ? '未削除' : '削除済み'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  退会処理
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: '20px 0 0 0' }}
                    onClick={handleUserWithdraw}
                    disabled={!user?.isWithdrawable}
                  >
                    退会させる
                  </Button>
                  <ul>
                    <li>
                      受講生またはインストラクターは、こちらから退会処理を実施することができません。
                    </li>
                    <li>
                      プレミアムプランを契約中のユーザーは先にプレミアムの契約を解約完了する必要があります。
                    </li>
                    <li>一度退会すると同じメールアドレスを使って再度登録することはできません。</li>
                    <li>退会するとこれまで登録した学習ログなどはご覧いただけなくなります。</li>
                    <li>退会しても質問や学習ログなどの情報は公開され続けます。</li>
                    <li>退会後もデータ保護の観点から、投稿頂いた内容や情報は保持されます。</li>
                    <li>
                      サブスクリプションが契約期間中の場合でも、退会後はただちにサービスをご利用いただけなくなります。
                    </li>
                    <li>サブスクリプションは期間途中で解約した場合でも返金されません。</li>
                    <li>一度退会するとアカウントを復旧することはできません。</li>
                  </ul>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  最終アクセス日時
                </TableCell>
                <TableCell>{user?.lastAccessedAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  作成日時
                </TableCell>
                <TableCell>{user?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  更新日時
                </TableCell>
                <TableCell>{user?.updatedAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  退会日時
                </TableCell>
                <TableCell>{user?.withdrewAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>

        <Typography color="inherit" variant="h6" component="h2">
          課金データ 最大{SUBSCRIPTION_LIMIT}件
        </Typography>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">stripeCustomerID</TableCell>
                  <TableCell align="right">StripeCustomerType</TableCell>
                  <TableCell align="right">priceID</TableCell>
                  <TableCell align="right">productName</TableCell>
                  <TableCell align="right">providerID</TableCell>
                  <TableCell align="right">providerQuantity</TableCell>
                  <TableCell align="right">providerStatus</TableCell>
                  <TableCell align="right">isCurrent</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stripeSubscriptions?.map((stripeSubscription) => (
                  <TableRow key={stripeSubscription.id}>
                    <TableCell>
                      <Link to={`/stripeSubscriptions/${stripeSubscription.id}`}>
                        {stripeSubscription.id}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/stripeCustomers/${stripeSubscription.stripeCustomerID}`}>
                        {stripeSubscription.stripeCustomerID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {stripeSubscription.stripeCustomer?.stripeCustomerType
                        ? stripeCustomerTypeLabels[
                            stripeSubscription.stripeCustomer?.stripeCustomerType
                          ]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/prices/${stripeSubscription.priceID}`}>
                        {stripeSubscription.priceID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{stripeSubscription.price?.product.name}</TableCell>
                    <TableCell align="right">
                      <Link
                        target="_blank"
                        to={stripeSubscriptionURL(stripeSubscription.providerID)}
                      >
                        {stripeSubscription.providerID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{stripeSubscription.providerQuantity}</TableCell>
                    <TableCell align="right">
                      {stripeSubscription.providerStatus
                        ? providerSubscriptionStatusPhaseLabels[stripeSubscription.providerStatus]
                        : '-'}
                    </TableCell>
                    <TableCell align="center">
                      {!!stripeSubscription.subscriptionCurrent?.stripeSubscriptionID && '〇'}
                    </TableCell>
                    <TableCell align="right">{stripeSubscription.createdAt}</TableCell>
                    <TableCell align="right">{stripeSubscription.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Paper>

        {subscriptions.length > 0 && (
          <>
            <Typography color="inherit" variant="h6" component="h2">
              旧課金データ
            </Typography>
            <Paper className={classes.paper}>
              <Grid style={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="right">ProviderSubscriptionID</TableCell>
                      <TableCell align="right">StartDate</TableCell>
                      <TableCell align="right">EndDate</TableCell>
                      <TableCell align="right">Phase</TableCell>
                      <TableCell align="right">CancelAtPeriodEnd</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">CreatedAt</TableCell>
                      <TableCell align="right">UpdatedAt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subscriptions?.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          <Link to={`/subscriptions/${row.id}`}>{row.id}</Link>
                        </TableCell>
                        <TableCell align="right">{row.providerSubscriptionID}</TableCell>
                        <TableCell align="right">{row.startDate}</TableCell>
                        <TableCell align="right">{row.endDate}</TableCell>
                        <TableCell align="right">{PhaseLabels[row.phase]}</TableCell>
                        <TableCell align="right">
                          {CancelAtPeriodEndLabels[row.cancelAtPeriodEnd]}
                        </TableCell>
                        <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                        <TableCell align="right">{row.createdAt}</TableCell>
                        <TableCell align="right">{row.updatedAt}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Paper>
          </>
        )}

        <Typography color="inherit" variant="h6" component="h2">
          各種データ
        </Typography>
        <Paper className={classes.paperContent}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  チャットデータ
                </TableCell>
                <TableCell>
                  <Link to={`/chatRooms?userID=${id}`}>リンク</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  スポットレッスンデータ
                </TableCell>
                <TableCell>
                  <Link to={`/spotLessons?userID=${id}`}>リンク</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  質問データ
                </TableCell>
                <TableCell>
                  <Link to={`/questions?userID=${id}`}>リンク</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  学習ログデータ
                </TableCell>
                <TableCell>
                  <Link to={`/studyReports?userID=${id}`}>リンク</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  課題提出データ
                </TableCell>
                <TableCell>
                  <Link to={`/practiceSubmits?userID=${id}`}>リンク</Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
      <Snackbar
        open={snackBar !== ''}
        autoHideDuration={6000}
        onClose={() => {
          setSnackBar('');
        }}
        message={snackBar}
      />
    </div>
  );
};
