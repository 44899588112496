export const NONE_SNS_USER = 0;
export const FREE_SNS_USER = 1;
export const SUBSCRIPTION_SNS_USER_PERSONAL = 2;
export const SUBSCRIPTION_SNS_USER_TEAM = 4;

export const SnsTypeLabels: { [index: number]: string } = {
  [NONE_SNS_USER]: '無効',
  [FREE_SNS_USER]: '無料会員',
  [SUBSCRIPTION_SNS_USER_PERSONAL]: '有料会員(個人)',
  [SUBSCRIPTION_SNS_USER_TEAM]: '有料会員(法人)',
} as const;
