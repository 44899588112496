import React, { useState, useContext, useEffect } from 'react';

// Material UI
import { Paper, Grid, makeStyles, Theme } from '@material-ui/core';
// Material UI Dialog
// Material UI Table
// Material UI Expansion Panel
// Material UI Form
import TextField from '@material-ui/core/TextField';
// Material UI Icon
// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

// Other
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';

import axios from 'axios';

import { AuthContext } from '../../context/CommonProvider';
import { useGetCurrentUserWithPermissionsLazyQuery } from '../../gen/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 360,
    margin: '48px auto',
    padding: '48px',
  },
  mainContent: {
    flex: 1,
    padding: '48px',
    background: '#eaeff1',
  },
  textField: {
    width: '100%',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  button: {
    width: '100%',
    height: 48,
    marginTop: 24,
  },
}));

export const REGEX_PASSWORD = /^(?=.*?[a-z])(?=.*[A-Z])(?=.*?\d)(?=.*?[!-/:-@[-`{-~])[!-~]{8,}$/i;

export interface InputParam {
  email: string;
  password: string;
}

export const Login = (): JSX.Element => {
  const classes = useStyles();

  const { authState, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [getCurrentUser, { refetch: refetchCurrentUser }] =
    useGetCurrentUserWithPermissionsLazyQuery();

  const [inputData, setInputData] = useState<InputParam>({
    email: '',
    password: '',
  });

  useEffect(() => {
    setUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function setUserData() {
    try {
      // const session = await Auth.currentSession();
      const user = await Auth.currentAuthenticatedUser();
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + user.signInUserSession.idToken.jwtToken;
      const { data } = await getCurrentUser();

      if (data?.currentUserWithPermissions?.user?.isAdmin) {
        setAuth({
          ...authState,
          isLogin: true,
          cognitoUser: user,
          lmsUser: data.currentUserWithPermissions?.user,
        });
        navigate('/');
      } else {
        setError('管理ユーザーとして登録されていません。');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }

  const handleInput: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    setInputData({
      ...inputData,
      [event.target.name]: event.target.value,
    });
    validate(event.target.name, event.target.value);
  };

  const [error, setError] = React.useState('');

  const validate = (field: string, value: string) => {
    let error = '';
    if (field === 'email' && !value) {
      error = 'メールアドレスを入力してください';
    } else if (field === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = '正しい形式のメールアドレスを入力してください';
    } else if (field === 'password' && !value) {
      error = 'パスワードを入力してください';
    } else if (field === 'password' && value.length < 8) {
      error = 'パスワードは8文字以上してください';
    } else if (!REGEX_PASSWORD.test(value)) {
      error = '大文字・小文字・数字・記号のいずれかが足りません';
    }
    setError(error);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    try {
      const user = await Auth.signIn(inputData.email, inputData.password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setAuth({
          ...authState,
          isLogin: true,
          cognitoUser: user,
        });
        navigate('/changeTemporaryPassword');
        return;
      }

      // set Authorization header
      axios.defaults.headers.common['Authorization'] =
        'Bearer ' + user.signInUserSession.idToken.jwtToken;
      const { data } = await refetchCurrentUser();

      if (data.currentUserWithPermissions?.user?.isAdmin) {
        setAuth({
          ...authState,
          isLogin: true,
          cognitoUser: user,
          lmsUser: data.currentUserWithPermissions?.user,
        });
        navigate('/');
      } else {
        setError('管理ユーザーとして登録されていません。');
        return;
      }
    } catch (error) {
      setError((error as Error).message);
    }
  };

  return (
    <div className={classes.mainContent} style={{ height: '100vh' }}>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" alignItems="center">
            <Grid item style={{ margin: 24 }}>
              <Typography variant="h4">侍テラコヤ</Typography>
            </Grid>
            <Grid style={{ width: '100%' }}>
              <Divider />
            </Grid>
            <Grid item style={{ margin: 24 }}>
              ログイン
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <TextField
                id="email"
                label="Email"
                className={classes.textField}
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={handleInput}
              />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <TextField
                id="password"
                label="Password"
                className={classes.textField}
                type="password"
                name="password"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={handleInput}
              />
            </Grid>
            {error && (
              <Grid item style={{ marginTop: 12, color: '#FF3333' }}>
                {error}
              </Grid>
            )}
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              type="submit"
            >
              ログイン
            </Button>
            <Grid item style={{ marginTop: 36 }}>
              パスワードを忘れた場合は<Link to="/changePassword">こちら</Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
