export interface Ticket {
  id?: number;
  userID?: number;
  invoiceID?: number;
  expireAt?: string;
  isUsed?: number;
  type?: number;
  createdAt?: string;
  updatedAt?: string;
}

export const TicketTypeValues = {
  TRIAL: 0,
  SUBSCRIPTION_NEW: 1,
  SUBSCRIPTION_CONTINUE: 2,
  BUY: 3,
  OPERATIONAL: 4,
  SPOT_LESSON_BUY: 5,
  FREE: 6,
  PRREMIUM_NEW: 7,
  PRREMIUM_CONTINUE: 8,
} as const;

export const TicketTypeLabels: { [index: number]: string } = {
  [TicketTypeValues.TRIAL]: 'TRIAL',
  [TicketTypeValues.SUBSCRIPTION_NEW]: 'SUBSCRIPTION_NEW',
  [TicketTypeValues.SUBSCRIPTION_CONTINUE]: 'SUBSCRIPTION_CONTINUE',
  [TicketTypeValues.BUY]: 'BUY',
  [TicketTypeValues.OPERATIONAL]: 'OPERATIONAL',
  [TicketTypeValues.SPOT_LESSON_BUY]: 'SPOT_LESSON_BUY',
  [TicketTypeValues.FREE]: 'FREE',
  [TicketTypeValues.PRREMIUM_NEW]: 'PRREMIUM_NEW',
  [TicketTypeValues.PRREMIUM_CONTINUE]: 'PRREMIUM_CONTINUE',
} as const;
