import React, { useCallback } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Snackbar, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

// Material UI Other
import { Typography, Button, LinearProgress } from '@material-ui/core';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  useGetTeamForAdminQuery,
  useDeleteTeamForAdminMutation,
  Valid,
} from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ team_id: string }>().team_id ?? '0';

  const [snackBar, setSnackBar] = React.useState('');
  const [showLoader, setShowLoader] = React.useState(false);

  const { data, loading, refetch } = useGetTeamForAdminQuery({
    variables: { id },
  });
  const team = data?.teamForAdmin;

  // 退会処理
  const [deleteTeamMutation] = useDeleteTeamForAdminMutation();
  const deleteTeam = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (window.confirm('チームを無効化しますか？')) {
        setShowLoader(true);
        setSnackBar('処理中...');

        try {
          await deleteTeamMutation({
            variables: {
              id: id,
            },
          });
          setSnackBar('処理は正常に完了しました');
        } catch (e) {
          setSnackBar(
            'チームを無効化するのに失敗しました。お急ぎの場合はシステム部に詳細を共有してください',
          );
          return;
        } finally {
          // 退会ボタンを非活性化するためにユーザー情報を最新化
          refetch({ id: id });
          setShowLoader(false);
        }
      }
    }, [refetch, id, deleteTeamMutation]),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Team Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/teams/${team?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{team?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CompanyName
                </TableCell>
                <TableCell>{team?.companyName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  AdminEmail
                </TableCell>
                <TableCell>{team?.adminEmail}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  PaymentMethodType
                </TableCell>
                <TableCell>{team?.paymentMethodType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Image
                </TableCell>
                <TableCell>
                  {team?.image ? (
                    <img width="200px" height="200px" src={team?.image} alt="法人アイコン" />
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>{team?.status ? ValidLabels[team?.status] : ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  退会処理
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={deleteTeam}
                    disabled={team?.status === Valid.Invalid}
                  >
                    チームを無効化する
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{team?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{team?.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
      <Snackbar
        open={snackBar !== ''}
        autoHideDuration={6000}
        onClose={() => {
          setSnackBar('');
        }}
        message={snackBar}
      />
    </div>
  );
};
