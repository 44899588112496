import { InvoiceStatus } from '../../gen/graphql';

export const InvoiceStatusLabels: { [key: string]: string } = {
  [InvoiceStatus.Draft]: '未決済',
  [InvoiceStatus.Open]: '決済進行中',
  [InvoiceStatus.Paid]: '決済済み',
  [InvoiceStatus.Uncollectible]: 'キャンセル',
  [InvoiceStatus.Void]: '決済失敗',
} as const;

export const strToInvoiceStatus = (str: string): InvoiceStatus | undefined => {
  return Object.values(InvoiceStatus).find((v) => v === str);
};
