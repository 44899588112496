import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import styled from 'styled-components';

// Other
import {
  StatusLabels,
  CourseSupportLabels,
  lessonTypeLabels,
  contractTypeLabels,
  typeLabels,
} from '../../const/Course';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Bool, useGetCourseForAdminQuery } from '../../../gen/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
  textContent: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-wrap',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ course_id: string }>().course_id;
  const id = Number(paramID);

  const { data, loading } = useGetCourseForAdminQuery({
    variables: { id },
  });

  const course = data?.courseForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Course Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/courses/${course?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          {course && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{course.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Plan
                  </TableCell>
                  <TableCell>{course.plan?.name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Student
                  </TableCell>
                  <TableCell>
                    <Link to={`/students/${course.student?.id}`}>
                      {course.student?.user?.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Instructor
                  </TableCell>
                  <TableCell>
                    <Link to={`/instructors/${course.instructor?.id}`}>
                      {course.instructor?.user?.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Coach
                  </TableCell>
                  <TableCell>
                    <Link to={`/instructors/${course.coaching?.coach?.id}`}>
                      {course.coaching?.coach?.user?.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StartDate
                  </TableCell>
                  <TableCell>{course.startDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    EndDate
                  </TableCell>
                  <TableCell>{course.endDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    AbsentStartDate
                  </TableCell>
                  <TableCell>{course.absentStartDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    AbsentEndDate
                  </TableCell>
                  <TableCell>{course.absentEndDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Absent2StartDate
                  </TableCell>
                  <TableCell>{course.absent2StartDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Absent2EndDate
                  </TableCell>
                  <TableCell>{course.absent2EndDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Absent3StartDate
                  </TableCell>
                  <TableCell>{course.absent3StartDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Absent3EndDate
                  </TableCell>
                  <TableCell>{course.absent3EndDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    InstructorChangeStartDate
                  </TableCell>
                  <TableCell>{course.instructorChangeStartDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    InstructorChangeEndDate
                  </TableCell>
                  <TableCell>{course.instructorChangeEndDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    InstructorChange2StartDate
                  </TableCell>
                  <TableCell>{course.instructorChange2StartDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    InstructorChange2EndDate
                  </TableCell>
                  <TableCell>{course.instructorChange2EndDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    InstructorChange3StartDate
                  </TableCell>
                  <TableCell>{course.instructorChange3StartDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    InstructorChange3EndDate
                  </TableCell>
                  <TableCell>{course.instructorChange3EndDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Consultant
                  </TableCell>
                  <TableCell>{course.consultant}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    LessonType
                  </TableCell>
                  <TableCell>
                    {course.lessonType ? lessonTypeLabels[course.lessonType] : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    LessonPlace
                  </TableCell>
                  <TableCell>{course.lessonPlace}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Objective
                  </TableCell>
                  <TableCell>{course.objective}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Curriculum
                  </TableCell>
                  <TableCell>{course.curriculum}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    JobChange
                  </TableCell>
                  <TableCell>
                    {course.jobChange ? CourseSupportLabels[course.jobChange] : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CareerSupport
                  </TableCell>
                  <TableCell>
                    {course.careerSupport ? CourseSupportLabels[course.careerSupport] : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    案件獲得サポート
                  </TableCell>
                  <TableCell>
                    {course.customerAcquisition
                      ? CourseSupportLabels[course.customerAcquisition]
                      : '-'}
                  </TableCell>
                </TableRow>{' '}
                <TableRow>
                  <TableCell component="th" scope="row">
                    入金遅滞
                  </TableCell>
                  <TableCell>{course.delayInPayment ? 'あり' : 'なし'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    音信不通
                  </TableCell>
                  <TableCell>{course.breakInContact ? 'あり' : 'なし'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    契約書種別
                  </TableCell>
                  <TableCell>
                    {course.contractType ? contractTypeLabels[course.contractType] : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    タイプ
                  </TableCell>
                  <TableCell>{course.type ? typeLabels[course.type] : '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    FinishedLessonCount
                  </TableCell>
                  <TableCell>{course.finishedLessonCount}回</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CanceledLessonCount
                  </TableCell>
                  <TableCell>{course.canceledLessonCount}回</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    InstructorMemo
                  </TableCell>
                  <TableCell className={classes.textContent}>
                    {course.instructorMemo ?? ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    教材費
                  </TableCell>
                  <TableCell>{course.teachingMaterialCost ? 'あり' : 'なし'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    レッスン時間上限
                  </TableCell>
                  <TableCell>{course.lessonMinutesUpperLimit}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    コーチング
                  </TableCell>
                  <TableCell>
                    {course.coaching?.withCoaching === Bool.True ? 'あり' : 'なし'}
                  </TableCell>
                </TableRow>
                {course.studentInformation && (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        顧客情報
                      </TableCell>
                      <TableCell>
                        <StudentInformationContent>
                          {course.studentInformation.customerInformation}
                        </StudentInformationContent>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        引継ぎ情報
                      </TableCell>
                      <TableCell>
                        <StudentInformationContent>
                          {course.studentInformation.transferInformation}
                        </StudentInformationContent>
                      </TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{course.status ? StatusLabels[course.status] : '-'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{course.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{course.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
        {course?.coaching && (
          <>
            <Typography color="inherit" variant="h6" component="h2">
              Coaching
            </Typography>
            <Paper className={classes.paperContent}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>項目</TableCell>
                    <TableCell>値</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      CoachingID
                    </TableCell>
                    <TableCell>{course?.coaching.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      PlannedSessionCount
                    </TableCell>
                    <TableCell>{course?.coaching.plannedSessionCount}回</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      FinishedSessionCount
                    </TableCell>
                    <TableCell>{course?.coaching.finishedSessionCount}回</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      CreatedAt
                    </TableCell>
                    <TableCell>{course?.coaching.createdAt}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      UpdatedAt
                    </TableCell>
                    <TableCell>{course?.coaching.updatedAt}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </>
        )}
      </main>
    </div>
  );
};

const StudentInformationContent = styled.div`
  white-space: pre-wrap;
`;
