import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Box, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import queryString from 'query-string';
import {
  AdminBannerCidInput,
  AdminBannerCidSearchInput,
  AdminBannerSearchInput,
  BannerCidDeviceType,
  BannerCidType,
  useCreateBannerCidMutation,
  useGetBannerCidsQuery,
  Valid,
} from '../../../gen/graphql';
import { strToEnum } from '../../../utils/common';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';
import { CidDeviceTypeLabels, CidTypeLabels } from '../../const/Banner';
import { ValidLabels } from '../../const/Valid';
import { getApiErrorMessage } from '../../../common/error/Error';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

// 登録フォームの初期値
const AddInputDefaultValues: AdminBannerCidInput = {
  bannerID: 1,
  type: BannerCidType.TopCenter,
  deviceType: BannerCidDeviceType.Pc,
  cid: '',
  status: Valid.Valid,
};
type SearchFormInput = Omit<AdminBannerCidSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminBannerCidSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const bannerID = params.get('bannerID') ?? '';
    const type = params.get('type') ?? '';
    const deviceType = params.get('deviceType') ?? '';
    const cid = params.get('cid') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      bannerID: parseInt(bannerID) > 0 ? parseInt(bannerID) : undefined,
      type: strToEnum(type, BannerCidType),
      deviceType: strToEnum(deviceType, BannerCidDeviceType),
      cid: cid.trim().length > 0 ? cid.trim() : undefined,
      status: strToEnum(status, Valid),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetBannerCidsQuery({
    variables: { input: currentSearchParams },
    notifyOnNetworkStatusChange: true,
  });

  const [createBannerCid, { error: createError, loading: updating }] = useCreateBannerCidMutation();
  // Add Dialog
  const [isOpenAddDialog, setIsOpenAddDialog] = useState(false);
  const [addInput, setAddInput] = useState<AdminBannerCidInput>(AddInputDefaultValues);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminBannerSearchInput): void => {
      navigate({
        pathname: '/banner_cids',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // Create Record > Add
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      try {
        await createBannerCid({
          variables: {
            input: addInput,
          },
        });
        await refetch({ input: currentSearchParams });
      } catch {
        return;
      }

      // ダイアログを閉じて入力フォーム初期化
      setIsOpenAddDialog(false);
      setAddInput(AddInputDefaultValues);
    }, [createBannerCid, refetch, currentSearchParams, addInput]),
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      bannerID: currentSearchParams.bannerID,
      type: currentSearchParams.type,
      deviceType: currentSearchParams.deviceType,
      cid: currentSearchParams.cid,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  const handleChangeAddBannerID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    const bannerID = parseInt(value);
    if (bannerID > 0) {
      setAddInput((current) => ({ ...current, bannerID }));
    }
  }, []);

  const handleChangeAddType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      const type = strToEnum(String(value), BannerCidType);
      if (type) {
        setAddInput((current) => ({ ...current, type }));
      }
    },
    [],
  );

  const handleChangeAddDeviceType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      const deviceType = strToEnum(String(value), BannerCidDeviceType);
      if (deviceType) {
        setAddInput((current) => ({ ...current, deviceType }));
      }
    },
    [],
  );

  const handleChangeAddCid = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, cid: value }));
  }, []);

  const handleChangeAddStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      const status = strToEnum(String(value), Valid);
      if (status) {
        setAddInput((current) => ({ ...current, status }));
      }
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                BannerCid
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="inherit" onClick={() => setIsOpenAddDialog(true)}>
              CreateRecord
            </Button>
          </Grid>
        </Toolbar>
        {(loading || updating) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="bannerID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="bannerID"
                          label="BannerID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="type">Type</InputLabel>
                      <Controller
                        name="type"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(CidTypeLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="deviceType">DeviceType</InputLabel>
                      <Controller
                        name="deviceType"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(CidDeviceTypeLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="cid"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="cid"
                          label="Cid"
                          type="text"
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">BannerId</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">DeviceType</TableCell>
                  <TableCell align="right">Cid</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.bannerCidsForAdmin.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/banner_cids/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.bannerID}</TableCell>
                    <TableCell align="right">{CidTypeLabels[row.type]}</TableCell>
                    <TableCell align="right">{CidDeviceTypeLabels[row.deviceType]}</TableCell>
                    <TableCell align="right">{row.cid}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.bannerCidsForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>

      <Dialog
        open={isOpenAddDialog}
        onClose={() => setIsOpenAddDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="bannerId"
            name="bannerID"
            label="BannerId"
            type="number"
            defaultValue={addInput.bannerID}
            fullWidth
            onChange={handleChangeAddBannerID}
            inputProps={{ min: 1 }}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="type">Type</InputLabel>
              <Select
                value={addInput.type}
                onChange={handleChangeAddType}
                input={<Input name="type" id="type" />}
              >
                {Object.entries(CidTypeLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="deviceType">DeviceType</InputLabel>
              <Select
                value={addInput.deviceType}
                onChange={handleChangeAddDeviceType}
                input={<Input name="deviceType" id="deviceType" />}
              >
                {Object.entries(CidDeviceTypeLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <TextField
            autoFocus
            margin="dense"
            id="cid"
            name="cid"
            label="Cid"
            type="text"
            defaultValue={addInput.cid}
            fullWidth
            onChange={handleChangeAddCid}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={addInput.status}
                onChange={handleChangeAddStatus}
                input={<Input name="status" id="status" />}
              >
                {Object.entries(ValidLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {createError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {createError.graphQLErrors.map((error, index) => (
                <div key={index}>{getApiErrorMessage(error)}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpenAddDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
