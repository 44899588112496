import { Bool } from '../../gen/graphql';

export const IsDefaultLabels = {
  false: '未選択',
  true: '選択中',
} as const;

export const IsAvailableLabels = {
  [Bool.False]: '決済エラー',
  [Bool.True]: '正常',
} as const;
