import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  AppBar,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Theme,
  Toolbar,
  Typography,
  makeStyles,
} from '@material-ui/core';

import { AbTestDefaultValues, abTestSchema } from '../../../formSchema/abTest';
import { DeviceTypeListForFormSort } from '../../const/AbTest';
import {
  useGetAbTestSettingForAdminQuery,
  useUpdateAbTestSettingForAdminMutation,
  AdminAbTestSettingInput,
  AdminAbTestSettingDeviceInput,
} from '../../../gen/graphql';

import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

import { Form } from './Form';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  disableFlex: {
    display: 'block',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export const Update: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();

  const id = useParams<{ ab_test_id: string }>().ab_test_id ?? '';

  const formId = 'abtest-edit-form';
  const form = useForm<AdminAbTestSettingInput>({
    resolver: yupResolver(abTestSchema),
    defaultValues: AbTestDefaultValues,
  });

  const [showLoader, setShowLoader] = useState(false);

  const { loading } = useGetAbTestSettingForAdminQuery({
    variables: { id },
    onCompleted: ({ abTestSettingForAdmin: data }) => {
      if (!data) {
        return;
      }

      // AdminAbTestSettingDeviceInput をソート順に並べ替え、取得データをフォームデータに詰め替え
      const abTestSettingDeviceInputs: AdminAbTestSettingDeviceInput[] =
        DeviceTypeListForFormSort.map((targetDeviceType) => {
          const tempAbTestSettingDevice = data.abTestSettingDevices?.find(
            (e) => e.deviceType === targetDeviceType,
          );
          return tempAbTestSettingDevice !== undefined
            ? {
                deviceType: tempAbTestSettingDevice.deviceType,
                originRate: tempAbTestSettingDevice.originRate,
                abTestSettingDeviceDestinationInputs:
                  tempAbTestSettingDevice.abTestSettingDeviceDestinations?.map(
                    (abTestSettingDeviceDestination) => {
                      return {
                        destinationRate: abTestSettingDeviceDestination.destinationRate,
                        destinationURL: abTestSettingDeviceDestination.destinationURL,
                      };
                    },
                  ),
              }
            : {
                // デバイスタイプが設定されていない場合は空のフォームデータを設定
                deviceType: targetDeviceType,
                originRate: 0,
                abTestSettingDeviceDestinationInputs: [],
              };
        });

      form.reset({
        startDate: data.startDate,
        endDate: data.endDate,
        note: data.note,
        originURL: data.originURL,
        targetDeviceType: data.targetDeviceType,
        status: data.status,
        abTestSettingDeviceInputs: abTestSettingDeviceInputs,
      });
    },
  });

  const [updateAbTestSetting, { error }] = useUpdateAbTestSettingForAdminMutation();

  const handleClickUpdate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminAbTestSettingInput): Promise<void> => {
        setShowLoader(true);

        try {
          await updateAbTestSetting({
            variables: {
              id: id,
              input: input,
            },
          });
        } catch {
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [updateAbTestSetting, id, navigate],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                AB Test Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Form formId={formId} form={form} onSubmit={handleClickUpdate} error={error} />
          <Button
            type="submit"
            form={formId}
            variant="contained"
            color="primary"
            style={{ margin: '20px 0 0 0' }}
          >
            Save
          </Button>
        </Paper>
      </main>
    </div>
  );
};
