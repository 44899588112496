// React
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

// Material UI
import {
  Box,
  Typography,
  makeStyles,
  Theme,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';

//  Apollo
import { ApolloError } from '@apollo/client';

// Others
import { AdminTicketInput } from '../../../gen/graphql';
import { TicketTypeValues } from '../../../types/Ticket';

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

interface Props {
  formId: string;
  form: UseFormReturn<AdminTicketInput>;
  onSubmit: (input: AdminTicketInput) => void;
  error: ApolloError | undefined;
}

export const Form: React.FC<Props> = ({ formId, form, onSubmit, error }): JSX.Element => {
  const classes = useStyles();
  const formErrors = form.formState.errors;

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
      <div>
        <Controller
          name="userID"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              autoFocus
              margin="dense"
              id="userId"
              label="UserID"
              type="number"
              placeholder="UserID"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{ margin: '10px 0' }}
              error={!!formErrors.userID}
              helperText={formErrors.userID?.message}
              inputRef={ref}
              {...rest}
            />
          )}
        />
      </div>
      <div>
        <Controller
          name="expireAt"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <TextField
              autoFocus
              margin="dense"
              id="expireAt"
              label="ExpireAt"
              type="text"
              placeholder="expireAt"
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              style={{ margin: '10px 0' }}
              error={!!formErrors.expireAt}
              helperText={formErrors.expireAt?.message}
              inputRef={ref}
              {...rest}
            />
          )}
        />
      </div>
      <div>
        <FormControl>
          <InputLabel htmlFor="isUsed">IsUsed</InputLabel>
          <Controller
            name="isUsed"
            control={form.control}
            render={({ field: { ref, ...rest } }) => (
              <Select error={!!formErrors.isUsed} inputRef={ref} {...rest}>
                <MenuItem value={1}>使用済</MenuItem>
                <MenuItem value={0}>未使用</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </div>
      <div>
        <FormControl>
          <InputLabel htmlFor="type">Type</InputLabel>
          <Controller
            name="type"
            control={form.control}
            render={({ field: { ref, ...rest } }) => (
              <Select error={!!formErrors.type} inputRef={ref} {...rest}>
                {Object.entries(TicketTypeValues).map((type, i) => (
                  <MenuItem key={i} value={type[1]}>
                    {type[0]}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </div>
      {error?.graphQLErrors && (
        <Box className={classes.inputError}>
          <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
          {error.graphQLErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Box>
      )}
    </form>
  );
};
