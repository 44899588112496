import * as yup from 'yup';

import { AdminCourseProgramStepInput, Valid } from '../gen/graphql';

export const courseProgramStepSchema = yup.object({
  courseProgramID: yup.string().required().max(100).trim(),
  title: yup.string().required().max(100).trim(),
  description: yup.string().required().max(255).trim(),
  number: yup
    .number()
    .required()
    .min(1)
    .transform((value) => (isNaN(value) ? undefined : value)),
  status: yup.string().required().oneOf(Object.values(Valid)),
  courseProgramStepItems: yup
    .array()
    .of(
      yup
        .object()
        .required()
        .shape({
          number: yup
            .number()
            .required()
            .min(1)
            .transform((value) => (isNaN(value) ? undefined : value)),
          programID: yup
            .number()
            .required()
            .min(1)
            .transform((value) => (isNaN(value) ? undefined : value)),
        }),
    )
    .required(),
});

export const CourseProgramStepDefaultValues: AdminCourseProgramStepInput = {
  courseProgramID: '',
  title: '',
  description: '',
  number: 0,
  status: Valid.Valid,
  courseProgramStepItems: [],
};
