import { Bool, SubscriptionPhase } from '../../gen/graphql';

export const PhaseLabels = {
  [SubscriptionPhase.Unknown]: 'UNKNOWN',
  [SubscriptionPhase.Incomplete]: 'INCOMPLETE',
  [SubscriptionPhase.IncompleteExpired]: 'INCOMPLETE_EXPIRED',
  [SubscriptionPhase.Trialing]: 'TRIALING',
  [SubscriptionPhase.Active]: 'ACTIVE',
  [SubscriptionPhase.PastDue]: 'PAST_DUE',
  [SubscriptionPhase.Canceled]: 'CANCELED',
  [SubscriptionPhase.Unpaid]: 'UNPAID',
} as const;

export const CancelAtPeriodEndLabels = {
  [Bool.True]: 'True',
  [Bool.False]: 'False',
} as const;
