import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

interface CreateDialogProps {
  open: boolean;
  handleClose: () => void;
  children: React.ReactNode;
  formID?: string;
}

export const CreateDialog: React.FC<CreateDialogProps> = ({
  open,
  handleClose,
  children,
  formID,
  ..._props
}) => {
  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button type="submit" form={formID} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
