import { GraphQLError } from 'graphql';
import { ErrorCode } from '../../gen/graphql';

// APIエラー時に表示するメッセージ共通処理
// NOTE: 個別処理が必要な場合はどう実装するか要検討
export const getApiErrorMessage = (e: GraphQLError, defaultErrorMessage?: string): string => {
  const errorCode = e.extensions?.code;
  // 共通エラー
  if (errorCode === ErrorCode.CommonRecordNotFound) {
    if (e.message.includes('BannerCid is not found.')) {
      return '指定されたBannerIdは存在しません。';
    }
    return '対象のレコードが見つかりません';
  }

  // ABテスト
  if (errorCode === ErrorCode.AbTestDuplicatedDeviceType) {
    return 'デバイスタイプが重複しています';
  }
  if (errorCode === ErrorCode.AbTestStartDateIsAfterEndDate) {
    return '開始日は終了日よりも前の日付を設定してください';
  }
  if (errorCode === ErrorCode.AbTestDuplicatedOriginUrl) {
    return 'すでに有効な配信元URLが設定されています。既存配信元URLを無効にするか、別の配信元URLを指定してください。';
  }

  // バナー
  if (errorCode === ErrorCode.BannerIdAndTypeCombinationAlreadyExists) {
    return 'このBannerIdとTypeの組み合わせは既に存在しています。';
  }

  return defaultErrorMessage ?? 'システムエラーが発生しました';
};
