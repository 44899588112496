import React, { useState, useCallback } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Form
import { FormControl, Input, InputLabel, Select } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

// Material UI Other
import { MenuItem, Typography, Button, LinearProgress } from '@material-ui/core';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import {
  UpdateTeamMemberInput,
  useGetTeamMemberForAdminQuery,
  useUpdateTeamMemberForAdminMutation,
  TeamMemberRole,
} from '../../../gen/graphql';
import { TeamMemberRoleLabels } from '../../const/TeamMember';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';
import { strToEnum } from '../../../utils/common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

// 表示専用Parameter
export interface ReadOnlyParam {
  user: string;
  team: string;
}

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ team_member_id: string }>().team_member_id ?? '0';

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<UpdateTeamMemberInput>({
    role: TeamMemberRole.General,
  });
  // InstructorID更新対象ではないが表示用に保持している
  const [readOnlyInput, setReadOnlyInput] = useState<ReadOnlyParam>({
    user: '',
    team: '',
  });

  const { loading } = useGetTeamMemberForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ teamMemberForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        role: data.role,
      });
      setReadOnlyInput({
        user: data.user.personalInfo?.name ?? '',
        team: data.team.companyName ?? '',
      });
    },
  });

  const [updateTeamMember, { error: updateError }] = useUpdateTeamMemberForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateTeamMember({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateTeamMember, updateInput, id, navigate]),
  );

  const handleChangeRole = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        role: strToEnum(String(value), TeamMemberRole) ?? TeamMemberRole.General,
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                TeamMember Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="user"
                name="user"
                label="User"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={readOnlyInput.user}
                fullWidth
                style={{ margin: '10px 0' }}
                disabled
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="team"
                name="team"
                label="Team"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={readOnlyInput.team}
                fullWidth
                style={{ margin: '10px 0' }}
                disabled
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="role">role</InputLabel>
                <Select
                  value={updateInput?.role}
                  onChange={handleChangeRole}
                  input={<Input name="role" id="role" />}
                >
                  {Object.entries(TeamMemberRoleLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
