import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  useGetStripeCustomerForAdminQuery,
  useGetSubscriptionChangeHistoriesForAdminQuery,
  useGetStripeSubscriptionsForAdminQuery,
  useGetSubscriptionContractsForAdminQuery,
  SubscriptionChangeHistoryFragment as SubscriptionChangeHistory,
} from '../../../gen/graphql';
import { stripeCustomerTypeLabels } from '../../const/stripeCustomer';
import { providerSubscriptionStatusPhaseLabels } from '../../const/StripeSubscription';
import {
  subscriptionContractPhaseLabels,
  exitFeeStatusLabels,
} from '../../const/SubscriptionContract';
import {
  stripeCustomerURL,
  stripeEventURL,
  stripeSubscriptionURL,
  stripePaymentIntentURL,
} from '../../../utils/stripe';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Paper, Grid, TableContainer } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'hidden',
    margin: '24px 0',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

// 一旦最大件数を指定する。件数が増えてきてPaging等が必要になったら対応
const SUBSCRIPTION_LIMIT = 30;
const CONTRACT_LIMIT = 50;
const HISTORY_LIMIT = 200;

export const Detail = (): JSX.Element => {
  const changeHistoryType = (subscriptionChangeHistory: SubscriptionChangeHistory): string => {
    if (subscriptionChangeHistory.stripeSubscriptionLogID) {
      return 'stripeSubscriptionLog:' + subscriptionChangeHistory.stripeSubscriptionLogID;
    }
    if (subscriptionChangeHistory.subscriptionContractLogID) {
      return 'subscriptionContractLog:' + subscriptionChangeHistory.subscriptionContractLogID;
    }
    if (subscriptionChangeHistory.subscriptionCurrentLogID) {
      return 'subscriptionCurrentLog:' + subscriptionChangeHistory.subscriptionCurrentLogID;
    }
    return '';
  };

  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ stripe_customer_id: string }>().stripe_customer_id ?? '';

  const { data: customerData, loading: customerLoading } = useGetStripeCustomerForAdminQuery({
    variables: { id },
  });
  const stripeCustomer = customerData?.stripeCustomerForAdmin ?? undefined;

  const { data: stripeSubscriptionData, loading: stripeSubscriptionLoading } =
    useGetStripeSubscriptionsForAdminQuery({
      variables: {
        input: {
          limit: SUBSCRIPTION_LIMIT,
          page: 1,
          stripeCustomerID: id,
        },
      },
    });
  const subscriptions = stripeSubscriptionData?.stripeSubscriptionsForAdmin.items ?? [];

  const { data: contractData, loading: contractLoading } = useGetSubscriptionContractsForAdminQuery(
    {
      variables: {
        input: {
          limit: CONTRACT_LIMIT,
          page: 1,
          stripeCustomerID: id,
        },
      },
    },
  );
  const contracts = contractData?.subscriptionContractsForAdmin.items ?? [];

  const { data: historyData, loading: historyLoading } =
    useGetSubscriptionChangeHistoriesForAdminQuery({
      variables: {
        input: {
          limit: HISTORY_LIMIT,
          page: 1,
          stripeCustomerID: id,
        },
      },
    });
  const histories = historyData?.subscriptionChangeHistoriesForAdmin.items ?? [];

  const loading = customerLoading || historyLoading || contractLoading || stripeSubscriptionLoading;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                StripeCustomer Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/StripeCustomers/${stripeCustomer?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{stripeCustomer?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StripeCustomerType
                  </TableCell>
                  <TableCell>
                    {stripeCustomer?.stripeCustomerType
                      ? stripeCustomerTypeLabels[stripeCustomer?.stripeCustomerType]
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ProviderID
                  </TableCell>
                  <TableCell>
                    <Link target="_blank" to={stripeCustomerURL(stripeCustomer?.providerID)}>
                      {stripeCustomer?.providerID}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    TeamID
                  </TableCell>
                  <TableCell>{stripeCustomer?.team ? stripeCustomer?.team?.id : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Team
                  </TableCell>
                  <TableCell>
                    {stripeCustomer?.team ? (
                      <Link to={`/teams/${stripeCustomer?.team?.id}`}>
                        {stripeCustomer?.team?.companyName}
                      </Link>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UserID
                  </TableCell>
                  <TableCell>{stripeCustomer?.user ? stripeCustomer?.user?.id : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    {stripeCustomer?.user ? (
                      <Link to={`/users/${stripeCustomer?.user?.id}`}>
                        {stripeCustomer?.user?.personalInfo?.name}
                      </Link>
                    ) : (
                      ''
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SubscriptionCurrent
                  </TableCell>
                  <TableCell>
                    {stripeCustomer?.subscriptionCurrent ? (
                      stripeCustomer?.subscriptionCurrent.stripeSubscriptionID ? (
                        <Link
                          to={`/stripeSubscriptions/${stripeCustomer?.subscriptionCurrent?.stripeSubscriptionID}`}
                        >
                          {stripeCustomer?.subscriptionCurrent?.stripeSubscriptionID}
                        </Link>
                      ) : (
                        '申請中'
                      )
                    ) : (
                      '未申請'
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{stripeCustomer?.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{stripeCustomer?.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Typography color="inherit" variant="h6" component="h2">
          StripeSubscription 最大{SUBSCRIPTION_LIMIT}件
        </Typography>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">stripeCustomerID</TableCell>
                  <TableCell align="right">StripeCustomerType</TableCell>
                  <TableCell align="right">priceID</TableCell>
                  <TableCell align="right">productName</TableCell>
                  <TableCell align="right">providerID</TableCell>
                  <TableCell align="right">providerQuantity</TableCell>
                  <TableCell align="right">providerStatus</TableCell>
                  <TableCell align="right">isCurrent</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscriptions?.map((subscription) => (
                  <TableRow key={subscription.id}>
                    <TableCell>
                      <Link to={`/stripeSubscriptions/${subscription.id}`}>{subscription.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/stripeCustomers/${subscription.stripeCustomerID}`}>
                        {subscription.stripeCustomerID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {subscription.stripeCustomer?.stripeCustomerType
                        ? stripeCustomerTypeLabels[subscription.stripeCustomer?.stripeCustomerType]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/prices/${subscription.priceID}`}>{subscription.priceID}</Link>
                    </TableCell>
                    <TableCell align="right">{subscription.price?.product.name}</TableCell>
                    <TableCell align="right">
                      <Link target="_blank" to={stripeSubscriptionURL(subscription.providerID)}>
                        {subscription.providerID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{subscription.providerQuantity}</TableCell>
                    <TableCell align="right">
                      {subscription.providerStatus
                        ? providerSubscriptionStatusPhaseLabels[subscription.providerStatus]
                        : '-'}
                    </TableCell>
                    <TableCell align="center">
                      {!!subscription.subscriptionCurrent?.stripeSubscriptionID && '〇'}
                    </TableCell>
                    <TableCell align="right">{subscription.createdAt}</TableCell>
                    <TableCell align="right">{subscription.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Paper>

        <Typography color="inherit" variant="h6" component="h2">
          SubscriptionContract 最大{CONTRACT_LIMIT}件
        </Typography>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">stripeSubscriptionID</TableCell>
                  <TableCell align="right">priceID</TableCell>
                  <TableCell align="right">productName</TableCell>
                  <TableCell align="right">periodStart</TableCell>
                  <TableCell align="right">periodEnd</TableCell>
                  <TableCell align="right">endDate</TableCell>
                  <TableCell align="right">canceledAt</TableCell>
                  <TableCell align="right">phase</TableCell>
                  <TableCell align="right">exitFeeStatus</TableCell>
                  <TableCell align="right">exitFeePaymentIntentID</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts?.map((contract) => (
                  <TableRow key={contract.id}>
                    <TableCell>
                      <Link to={`/subscriptionContracts/${contract.id}`}>{contract.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/stripeSubscriptions/${contract.stripeSubscriptionID}`}>
                        {contract.stripeSubscriptionID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/prices/${contract.priceID}`}>{contract.priceID}</Link>
                    </TableCell>
                    <TableCell align="right">{contract.price?.product.name}</TableCell>
                    <TableCell align="right">{contract.periodStart}</TableCell>
                    <TableCell align="right">{contract.periodEnd}</TableCell>
                    <TableCell align="right">{contract.endDate}</TableCell>
                    <TableCell align="right">{contract.canceledAt}</TableCell>
                    <TableCell align="right">
                      {contract.phase ? subscriptionContractPhaseLabels[contract.phase] : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {contract.exitFeeStatus ? exitFeeStatusLabels[contract.exitFeeStatus] : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {contract?.exitFeePaymentIntentID ? (
                        <Link
                          target="_blank"
                          to={stripePaymentIntentURL(contract?.exitFeePaymentIntentID)}
                        >
                          {contract?.exitFeePaymentIntentID}
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell align="right">{contract.createdAt}</TableCell>
                    <TableCell align="right">{contract.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Paper>

        <Typography color="inherit" variant="h6" component="h2">
          SubscriptionChangeHistory 最大{HISTORY_LIMIT}件
        </Typography>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">changedBy</TableCell>
                  <TableCell align="right">action</TableCell>
                  <TableCell align="right">webhookEventID</TableCell>
                  <TableCell align="right">Type:ID</TableCell>
                  <TableCell align="right">recordType</TableCell>
                  <TableCell align="right">createdAt</TableCell>
                  <TableCell align="right">updatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {histories.map((history) => (
                  <TableRow key={history.id}>
                    <TableCell>{history.id}</TableCell>
                    <TableCell align="right" scope="row">
                      {history.changedBy ? (
                        <Link to={`/users/${history.changedBy}`}>{history.changedBy}</Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>{history.action}</TableCell>
                    <TableCell align="right">
                      {history.webhookEventID ? (
                        <Link target="_blank" to={stripeEventURL(history.webhookEventID)}>
                          {history.webhookEventID}
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>{changeHistoryType(history)}</TableCell>
                    <TableCell>{history.recordType}</TableCell>
                    <TableCell align="right">{history.createdAt}</TableCell>
                    <TableCell align="right">{history.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Paper>
      </main>
    </div>
  );
};
