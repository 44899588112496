import React, { useMemo, useCallback } from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, Theme, makeStyles } from '@material-ui/core';

// Material UI Form
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// React Router
import { useNavigate, useParams } from 'react-router-dom';

// Hook Form
import { useForm } from 'react-hook-form';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

// GraphQL
import {
  useUpdateChatRoomForAdminMutation,
  useGetChatRoomForAdminQuery,
  AdminChatRoomInput,
  AdminChatRoomSearchInput,
  Valid,
  UserFragment,
} from '../../../gen/graphql';

// Other
import { ValidLabels } from '../../const/Valid';
import { UserSelectBox } from './UserSelectBox';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  form: {
    '& > *': {
      marginBottom: theme.spacing(0.8),
    },
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export interface UpdateParam {
  id: number;
  title: string;
  status: Valid;
  users: UserFragment[];
}

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ chat_room_id: string }>().chat_room_id;
  const id = Number(paramID);

  // GraphQL化に伴う実装
  const updateParams = useMemo((): UpdateParam => {
    return {
      id: id,
      title: '',
      status: Valid.Valid,
      users: [],
    };
  }, [id]);

  const { control, register, handleSubmit, watch, setValue } = useForm<UpdateParam>({
    defaultValues: updateParams,
  });

  const [updateChatRoom, { error: updateError }] = useUpdateChatRoomForAdminMutation();

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // データ作成ボタン
  const handleClickUpdate = useSafeAsyncCallback(
    useCallback(
      async (param: UpdateParam): Promise<void> => {
        setShowLoader(true);
        try {
          await updateChatRoom({
            variables: {
              input: {
                id: param.id,
                title: param.title,
                status: param.status,
                users: param.users?.map((v) => v.id),
              } as AdminChatRoomInput,
            },
          });
        } catch (e) {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [navigate, updateChatRoom],
    ),
  );

  // 検索パラメータ
  const searchParam = useMemo((): AdminChatRoomSearchInput => {
    return {
      id: id,
      limit: 1,
      page: 1,
    };
  }, [id]);

  const { loading } = useGetChatRoomForAdminQuery({
    variables: {
      input: searchParam,
    },
    onCompleted: ({ chatRoomsForAdmin: data }) => {
      if (!data) return;
      const title = data.items[0].title;
      const status = data.items[0].status;
      const users = data.items[0].chatMembers
        .filter((cm) => cm.status === Valid.Valid)
        .map((cm) => cm.user);
      setValue('title', title);
      setValue('status', status);
      setValue('users', users);
    },
  });

  const onChangeUser = useCallback(
    (value?: UserFragment[]) => {
      if (value) {
        setValue('users', value);
      }
    },
    [setValue],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                ChatRoom Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {showLoader || loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form
            onSubmit={handleSubmit(handleClickUpdate)}
            noValidate
            autoComplete="off"
            className={classes.form}
          >
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Title"
                type="text"
                placeholder="Ruby on Rails 基礎編"
                InputLabelProps={{
                  shrink: true,
                }}
                value={watch('title')}
                fullWidth
                style={{ margin: '10px 0' }}
                {...register('title')}
              />
            </div>
            <div>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={watch('status')}
                input={<Input name="status" id="status" />}
                {...register('status')}
              >
                {Object.entries(ValidLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div>
              <Typography color="textSecondary" gutterBottom>
                Users
              </Typography>
              <UserSelectBox
                control={control}
                onChange={onChangeUser}
                defaultValue={watch('users')}
              />
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
