import React, { useRef, useCallback, ChangeEvent } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Grid, Box, makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';

import { Auth } from 'aws-amplify';

// Material UI Tab
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// Material UI Card
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

// Material UI Form
import TextField from '@material-ui/core/TextField';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

// Other
import { Link } from 'react-router-dom';
import {
  useGetCurrentUserWithPermissionsQuery,
  useUpdateCurrentUserImageMutation,
} from '../../../gen/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  uploadImageBtn: {
    marginLeft: theme.spacing(2),
  },
  card: {
    marginBottom: theme.spacing(3),
  },
  listItemLabel: {
    width: '240px',
  },
  passwordSettingBtn: {
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export const Profile = (): JSX.Element => {
  const classes = useStyles();

  const { data, loading } = useGetCurrentUserWithPermissionsQuery();
  const user = data?.currentUserWithPermissions?.user;

  const currentPasswordRef = useRef(document.createElement('input'));
  const newPasswordRef = useRef(document.createElement('input'));
  const newPassword2Ref = useRef(document.createElement('input'));
  const buttonRef = useRef(document.createElement('button'));

  const [updateImage, { loading: updating, error: updateError }] =
    useUpdateCurrentUserImageMutation();

  function handleUpdatePassword() {
    updatePassword();
  }

  const handleImageChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const image = e.target.files?.[0];
      if (!image) {
        return;
      }
      try {
        await updateImage({ variables: { image } });
      } catch {
        // Sentryへの送信は共通のエラーハンドラで対応しているためここでは握りつぶす
      }
    },
    [updateImage],
  );

  async function updatePassword() {
    if (newPasswordRef.current.value !== newPassword2Ref.current.value) {
      // TODO パスワードが一致していませんエラー
      return false;
    }
    if (
      currentPasswordRef.current.value === '' ||
      newPasswordRef.current.value === '' ||
      newPassword2Ref.current.value === ''
    )
      return false;

    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(
          user,
          currentPasswordRef.current.value,
          newPasswordRef.current.value,
        );
      })
      // eslint-disable-next-line no-console
      .then((data) => console.log(data))
      // eslint-disable-next-line no-console
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Account
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Tabs value={0} textColor="inherit">
          <Link to="/profile">
            <Tab textColor="inherit" label="Profile" />
          </Link>
          <Link to="/mailSetting">
            <Tab textColor="inherit" label="MailSetting" />
          </Link>
        </Tabs>
        {(loading || updating) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Card className={classes.card}>
          <CardContent>
            <List
              subheader={
                <Grid container justifyContent="space-between">
                  <ListSubheader>管理者情報</ListSubheader>
                  <Link to={`/users/${user?.id}/update`}>
                    <ListSubheader>Edit</ListSubheader>
                  </Link>
                </Grid>
              }
            >
              <ListItem>
                <ListItemText primary="ID" />
                <ListItemSecondaryAction>{user?.id}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="Name" />
                <ListItemSecondaryAction>{user?.personalInfo?.name}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="KanaName" />
                <ListItemSecondaryAction>{user?.personalInfo?.kanaName}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="NickName" />
                <ListItemSecondaryAction>{user?.nickName}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="Email" />
                <ListItemSecondaryAction>{user?.personalInfo?.email}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="IsInstructor" />
                <ListItemSecondaryAction>{user?.isInstructor}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="IsStudent" />
                <ListItemSecondaryAction>{user?.isStudent}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="CreatedAt" />
                <ListItemSecondaryAction>{user?.createdAt}</ListItemSecondaryAction>
              </ListItem>
              <ListItem>
                <ListItemText primary="UpdatedAt" />
                <ListItemSecondaryAction>{user?.updatedAt}</ListItemSecondaryAction>
              </ListItem>
            </List>
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <List subheader={<ListSubheader>プロフィール画像</ListSubheader>}>
              <ListItem>
                <ListItemText>
                  <Avatar src={user?.image} alt="My Avatar" />
                </ListItemText>
                <input
                  accept="image/*"
                  type="file"
                  id="upload-img"
                  onChange={handleImageChange}
                  style={{ display: 'none' }}
                ></input>
                <Button
                  htmlFor="upload-img"
                  variant="contained"
                  component="label"
                  className={classes.uploadImageBtn}
                >
                  Upload
                </Button>
              </ListItem>
            </List>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
          </CardContent>
        </Card>
        <Card className={classes.card}>
          <CardContent>
            <List subheader={<ListSubheader>パスワード設定</ListSubheader>}>
              <ListItem>
                <ListItemText className={classes.listItemLabel}>現在のパスワード</ListItemText>
                <TextField
                  id="currentPassword"
                  inputRef={currentPasswordRef}
                  label="（例）%Samurai1234"
                  type="password"
                  name="currentPassword"
                  autoComplete="currentPassword"
                  margin="normal"
                  variant="outlined"
                />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.listItemLabel}>新しいパスワード</ListItemText>
                <TextField
                  id="newPassword"
                  inputRef={newPasswordRef}
                  label="（例）%Samurai1234"
                  type="password"
                  name="newPassword"
                  autoComplete="newPassword"
                  margin="normal"
                  variant="outlined"
                />
              </ListItem>
              <ListItem>
                <ListItemText className={classes.listItemLabel}>
                  新しいパスワード（確認用）
                </ListItemText>
                <TextField
                  id="newPassword2"
                  inputRef={newPassword2Ref}
                  label="（例）%Samurai1234"
                  type="password"
                  name="newPassword2"
                  autoComplete="newPassword2"
                  margin="normal"
                  variant="outlined"
                />
              </ListItem>
            </List>
          </CardContent>
          <Button
            ref={buttonRef}
            variant="contained"
            color="primary"
            className={classes.passwordSettingBtn}
            onClick={handleUpdatePassword}
          >
            設定変更
          </Button>
        </Card>
      </main>
    </div>
  );
};
