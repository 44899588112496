import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  AdminChatMessageInput,
  useGetChatMessageForAdminQuery,
  useUpdateChatMessageForAdminMutation,
} from '../../../gen/graphql';

import { AppBar, Button, Grid, LinearProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

import { Form } from './Form';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { ChatMessageDefaultValues, chatMessageSchema } from '../../../formSchema/chatMessage';
import { PageHeader } from '../../common/PageHeader';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ chat_message_id: string }>().chat_message_id;
  const id = Number(paramID);

  const [showLoader, setShowLoader] = useState(false);

  const formId = 'chat-message-edit-form';
  const form = useForm<AdminChatMessageInput>({
    resolver: yupResolver(chatMessageSchema),
    defaultValues: ChatMessageDefaultValues,
  });

  const { loading } = useGetChatMessageForAdminQuery({
    variables: { id },
    onCompleted: ({ chatMessageForAdmin: data }) => {
      if (!data) {
        return;
      }

      form.reset({
        content: data?.content,
        status: data?.status,
      });
    },
  });

  const [updateChatMessage, { error }] = useUpdateChatMessageForAdminMutation();

  const handleClickUpdate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminChatMessageInput): Promise<void> => {
        setShowLoader(true);

        try {
          await updateChatMessage({
            variables: {
              id: id,
              input: input,
            },
          });
        } catch {
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [updateChatMessage, id, navigate],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <PageHeader title="ChatMessage Edit" />
      </AppBar>
      {(loading || showLoader) && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Form formId={formId} form={form} onSubmit={handleClickUpdate} error={error} isUpdate />
          <Button
            type="submit"
            form={formId}
            variant="contained"
            color="primary"
            style={{ margin: '20px 0 0 0' }}
          >
            Save
          </Button>
        </Paper>
      </main>
    </div>
  );
};
