import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles, Theme } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetStudyReportForAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ study_report_id: string }>().study_report_id;
  const id = Number(paramID);

  const studyReportData = useGetStudyReportForAdminQuery({
    variables: { id },
  });

  const studyReport = studyReportData.data?.getStudyReportForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                StudyReport Detail
              </Typography>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
        {studyReportData.loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/studyReports/${studyReport?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          {studyReport && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{studyReport.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/${studyReport.user.id}`}>
                      {studyReport.user.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Content
                  </TableCell>
                  <TableCell>{studyReport.content}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Date
                  </TableCell>
                  <TableCell>{studyReport.date}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReportDetails
                  </TableCell>
                  <TableCell>
                    <Link to={`/studyReportDetails?studyReportId=${studyReport.id}`}>
                      StudyReportDetails
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{ValidLabels[studyReport.status]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReportComments
                  </TableCell>
                  <TableCell>
                    <Link to={`/studyReportComments?studyReportId=${studyReport.id}`}>
                      StudyReportComments
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{studyReport.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{studyReport.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
