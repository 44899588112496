import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AdminPracticeSubmitSearchInput } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((_theme: Theme) => ({
  block: {
    display: 'block',
  },
}));

export type AdminPracticeSubmitSearchFormInput = Omit<
  AdminPracticeSubmitSearchInput,
  'limit' | 'page'
>;

interface Props {
  form: UseFormReturn<AdminPracticeSubmitSearchFormInput>;
  onSubmit: (input: AdminPracticeSubmitSearchFormInput) => void;
}

export const SearchForm: React.FC<Props> = ({ form, onSubmit }) => {
  const classes = useStyles();

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div>
            <Typography>検索</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <Controller
                name="id"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="ID"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="practiceID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="practiceID"
                    label="Practice ID"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="userID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="userID"
                    label="User ID"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Controller
                  name="status"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value="">&nbsp;</MenuItem>
                      {Object.entries(ValidLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider light />
        <AccordionActions>
          <Button size="small" color="primary" type="submit">
            <SearchIcon className={classes.block} />
            Search
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  );
};
