import { zonedTimeToUtc } from 'date-fns-tz';
import { isValid } from 'date-fns';
import { parse } from 'date-fns/esm';
import { utcToZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';

export const getYYYYMM = (input: string): string => {
  const parseDate = parse(input, 'yyyyMM', new Date());
  if (!isValid(parseDate)) return input;
  const jstDate = utcToZonedTime(parseDate.toISOString(), 'Asia/Tokyo');
  // 申請月（YYYYMM）をパースするための処理。
  // toISOStringを利用するとUTCで-9hour減らされて送信されるため date-fns-tz.format を使用
  const formattedDateString = format(jstDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
  return formattedDateString;
};

export const getUTCDatetime = (input: string | undefined): string => {
  if (!input) return '';
  if (!isValid(new Date(input))) return '';
  return zonedTimeToUtc(input, 'Asia/Tokyo').toISOString();
};

export const zeroPadding = (num: number): string => `0${num}`.substr(-2);

export const formatDate = (date: Date): string =>
  `${date.getFullYear()}/${zeroPadding(date.getMonth() + 1)}/${zeroPadding(
    date.getDate(),
  )} ${zeroPadding(date.getHours())}:${zeroPadding(date.getMinutes())}`;

export const formatDateYearMonthDate = (date: Date): string =>
  `${date.getFullYear()}/${zeroPadding(date.getMonth() + 1)}/${zeroPadding(date.getDate())}`;

export const getFileName = (tempFileName: string): string => {
  const decodeTempFileName = decodeURI(tempFileName);
  const result = decodeTempFileName.replace(/\+/g, ' ');
  return result;
};

export const formatRequestDate = (date: Date | null | undefined): string => {
  if (!date) return '';

  return (
    date.getFullYear() +
    '-' +
    zeroPadding(date.getMonth() + 1) +
    '-' +
    zeroPadding(date.getDate()) +
    'T' +
    '00:00:00+09:00'
  );
};
