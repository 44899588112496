import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Other
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminCourseProgramStepInput,
  useUpdateCourseProgramStepForAdminMutation,
  useGetCourseProgramStepForAdminQuery,
} from '../../../gen/graphql';
import {
  CourseProgramStepDefaultValues,
  courseProgramStepSchema,
} from '../../../formSchema/courseProgramStep';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { PageHeader } from '../../common/PageHeader';
import { Form } from './Form';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ course_program_step_id: string }>().course_program_step_id ?? '0';

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  const formID = 'course-program-step-edit-form';
  const form = useForm<AdminCourseProgramStepInput>({
    resolver: yupResolver(courseProgramStepSchema),
    defaultValues: CourseProgramStepDefaultValues,
  });

  const { loading } = useGetCourseProgramStepForAdminQuery({
    variables: { id },
    onCompleted: ({ courseProgramStepForAdmin: data }) => {
      if (!data) {
        return;
      }

      form.reset({
        courseProgramID: data.courseProgramID,
        title: data.title,
        description: data.description,
        number: data.number,
        status: data.status,
        courseProgramStepItems: data.courseProgramStepItems
          ? data.courseProgramStepItems?.map((courseProgramStepItem) => {
              return {
                number: courseProgramStepItem.number,
                programID: courseProgramStepItem.program?.id,
              };
            })
          : [],
      });
    },
  });

  const [updateCourseProgramStep, { error }] = useUpdateCourseProgramStepForAdminMutation();

  const handleClickUpdate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminCourseProgramStepInput): Promise<void> => {
        setShowLoader(true);

        try {
          await updateCourseProgramStep({
            variables: {
              id: id,
              input: input,
            },
          });
        } catch {
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [updateCourseProgramStep, id, navigate],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <PageHeader title="CourseProgramStep Edit" />
      </AppBar>
      {(loading || showLoader) && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Form formID={formID} form={form} onSubmit={handleClickUpdate} error={error} isUpdate />
          <Button
            type="submit"
            form={formID}
            variant="contained"
            color="primary"
            style={{ margin: '20px 0 0 0' }}
          >
            Save
          </Button>
        </Paper>
      </main>
    </div>
  );
};
