import * as yup from 'yup';
import { Valid, AuthorizationType } from '../gen/graphql';
import { ChapterInput } from '../components/pages/chapter/Update';

export const chapterSchema = yup.object({
  programID: yup
    .number()
    .required()
    .min(1)
    .transform((value) => (isNaN(value) ? undefined : value)), // TODO number型のNan対応を共通化 (@see https://github.com/jquense/yup/issues/1330)
  key: yup.string().nullable().max(100).trim(),
  qaKey: yup.string().nullable().max(100).trim(),
  number: yup
    .number()
    .nullable()
    .min(0)
    .transform((value) => (isNaN(value) ? undefined : value)),
  title: yup.string().required().max(100).trim(),
  description: yup.string().nullable().max(255).trim(),
  content: yup.string().required().trim(),
  requireTime: yup
    .number()
    .required()
    .min(1)
    .transform((value) => (isNaN(value) ? undefined : value)),
  type: yup.string().required().oneOf(Object.values(AuthorizationType)),
  status: yup.string().required().oneOf(Object.values(Valid)),
  sortNumber: yup
    .number()
    .nullable()
    .min(0)
    .transform((value) => (isNaN(value) ? undefined : value)),
});

export const ChapterDefaultValues: ChapterInput = {
  content: '',
  description: '',
  key: '',
  number: 0,
  programID: 0,
  qaKey: '',
  requireTime: 0,
  status: Valid.Valid,
  title: '',
  type: AuthorizationType.Public,
  sortNumber: 0,
};
