import { CourseLessonType } from '../../gen/graphql';

export const COURSE_STATUS_INVALID = 0;
export const COURSE_STATUS_VALID = 1;
export const COURSE_STATUS_BEFORE_FIRST_LESSON = 2;
export const COURSE_STATUS_BEFORE_ASSIGN = 3;
export const COURSE_STATUS_INSTRUCTOR_CHANGE = 4;
export const COURSE_STATUS_ABSENCE = 5;
export const COURSE_STATUS_GRADUATE = 6;
export const COURSE_STATUS_CANCEL = 7;
export const COURSE_STATUS_ABSENCE_AND_INSTRUCTOR_CHANGE = 8;
export const COURSE_STATUS_CANCELING = 9;

export const StatusLabels: { [index: number]: string } = {
  [COURSE_STATUS_INVALID]: '削除',
  [COURSE_STATUS_VALID]: '受講中',
  [COURSE_STATUS_BEFORE_FIRST_LESSON]: '初回レッスン日待ち',
  [COURSE_STATUS_BEFORE_ASSIGN]: 'アサイン待ち',
  [COURSE_STATUS_INSTRUCTOR_CHANGE]: 'インスト交代中',
  [COURSE_STATUS_ABSENCE]: '休学中',
  [COURSE_STATUS_GRADUATE]: '卒業済み',
  [COURSE_STATUS_CANCEL]: '解約',
  [COURSE_STATUS_ABSENCE_AND_INSTRUCTOR_CHANGE]: '休学/インスト交代中',
  [COURSE_STATUS_CANCELING]: '解約中',
} as const;

// jobChange careerSupport customerAcquisition
export const CourseSupportLabels: { [index: number]: string } = {
  0: '希望しない',
  1: '希望する',
} as const;

export const lessonTypeLabels: { [index: string]: string } = {
  [CourseLessonType.Online]: 'オンライン',
  [CourseLessonType.FaceToFace]: '対面',
} as const;

export const contractTypeLabels: { [index: number]: string } = {
  0: '-',
  1: '~2020年5月契約書(期間)',
  2: '2020年5月~契約書(レッスン回数)',
  3: '2021年1月~契約書(休学廃止)',
} as const;

export const typeLabels: { [index: number]: string } = {
  1: '個人向け',
  2: '法人向け',
} as const;
