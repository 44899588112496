import {
  SessionPhase,
  SessionReportPreparationType,
  IsOnScheduleType,
  SessionReportAnnouncementType,
  ResponseDeadlineType,
} from '../../gen/graphql';

export const PhaseLabels: { [index: string]: string } = {
  [SessionPhase.NotCompleted]: '未完了',
  [SessionPhase.Completed]: '完了',
  [SessionPhase.Canceled]: 'キャンセル',
  [SessionPhase.CanceledByStudent]: 'キャンセル（受講生都合）',
} as const;

// セッション前の準備項目
export const Preparations: { [index: string]: string } = {
  [SessionReportPreparationType.CheckStudentInfoTask]:
    '受講生情報確認（Q&A、セッション履歴、カリキュラムなど）',
  [SessionReportPreparationType.MessageTask]: '受講生のメッセージ対応',
  [SessionReportPreparationType.AdminRequestTask]: '運営からの依頼対応',
  [SessionReportPreparationType.OtherTask]: 'その他',
} as const;

// 学習スケジュール
export const Schedules: { [index: string]: string } = {
  [IsOnScheduleType.TooLate]: '遅れている',
  [IsOnScheduleType.BitLate]: 'やや遅れている',
  [IsOnScheduleType.OnSchedule]: '進捗通り',
  [IsOnScheduleType.BitForward]: 'やや進んでいる',
  [IsOnScheduleType.TooForward]: '進んでいる',
} as const;

// 運営への連携項目
export const Announcements: { [index: string]: string } = {
  [SessionReportAnnouncementType.StudentMental]: '受講生のメンタル面などについて共有したい',
  [SessionReportAnnouncementType.ReconsiderCurricurum]:
    '目標・カリキュラム内容の見直し相談について',
  [SessionReportAnnouncementType.InstructToInstructor]: 'インストラクターの指導を改善してほしい',
  [SessionReportAnnouncementType.ShareInfoToInstructor]: 'インストラクターに情報共有してほしい',
  [SessionReportAnnouncementType.ChangeToInstructor]: 'インストラクター交代について相談したい',
  [SessionReportAnnouncementType.TalkToJobchamgeSupport]: '転職サポートに連携したい',
  [SessionReportAnnouncementType.TalkToExtensionCourse]: '延長コースについて相談したい',
  [SessionReportAnnouncementType.TalkToChangeCourse]: 'コース変更について相談したい',
  [SessionReportAnnouncementType.TalkToPayment]: '支払いについて相談したい',
  [SessionReportAnnouncementType.TalkToCancel]: '解約について相談したい',
  [SessionReportAnnouncementType.TalkToServiceDiscontent]: 'サービスの不満について',
  [SessionReportAnnouncementType.Other]: 'その他',
} as const;

// 運営への対応期限
export const ResponseDeadline: { [index: string]: string } = {
  [ResponseDeadlineType.Asap]: '急ぎ対応希望',
  [ResponseDeadlineType.FewDays]: '1~3営業日以内の対応希望',
  [ResponseDeadlineType.SevenDays]: '7営業日以内の対応希望',
} as const;
