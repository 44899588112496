export const strToBoolean = (str: string): boolean => str.toLowerCase() === 'true';

// 第一引数で渡された文字列を第二引数で渡されたenumの型に変換する(文字列的に一致する場合のみ)
export const strToEnum = <T extends Record<string, T[K]>, K extends keyof T>(
  str: string,
  enumType: T,
): T[K] | undefined => Object.values(enumType).find((e) => e === str);

export const minutesToHours = (minute: number | undefined | null): number => {
  if (!minute) {
    return 0;
  }
  return Math.ceil(minute / 60);
};

export const FETCH_ROWS_LIMIT = 25;

export const ROWS_PER_PAGE_OPTIONS: number[] = [10, 25, 50];
