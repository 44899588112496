import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  AdminBannerInput,
  useGetBannerQuery,
  useUpdateBannerMutation,
  useUploadImagesMutation,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { Form } from './Form';
import { BannerDefaultValues, bannerSchema } from '../../../formSchema/banner';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  uploadImageBtn: {
    marginLeft: theme.spacing(2),
  },
  imageStyle: {
    width: '100%',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ banner_id: string }>().banner_id;
  const id = Number(paramID);
  const [currentImgUrl, setCurrentImgUrl] = useState<string>('');

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  const { loading } = useGetBannerQuery({
    variables: { id },
    onCompleted: ({ bannerForAdmin: data }) => {
      if (!data) {
        return;
      }

      form.reset({
        title: data.title,
        imageURL: data.imageURL,
        link: data.link,
        sort: data.sort,
        status: data.status,
        isNotLoginUser: data.isNotLoginUser,
        isTargetFree: data.isTargetFree,
        isTargetSubscriptionUser: data.isTargetSubscriptionUser,
        isTargetStudent: data.isTargetStudent,
        isTargetGraduate: data.isTargetGraduate,
        isTargetInstructor: data.isTargetInstructor,
        isTargetTeamUser: data.isTargetTeamUser,
      });

      setCurrentImgUrl(data.imageURL);
    },
  });
  const [updateBanner, { error: updateError }] = useUpdateBannerMutation();

  const formId = 'banner-edit-form';
  const form = useForm<AdminBannerInput>({
    resolver: yupResolver(bannerSchema),
    defaultValues: BannerDefaultValues,
  });

  const [image, setImage] = useState<File | null>(null);
  const imageSrc = useMemo(
    () => (image ? URL.createObjectURL(image) : currentImgUrl ? currentImgUrl : ''),
    [currentImgUrl, image],
  );

  const [uploadImages] = useUploadImagesMutation();

  const uploadImage = useCallback(
    async (image: File): Promise<string> => {
      try {
        const uploadedFiles = await uploadImages({
          variables: {
            files: [image],
          },
        });
        const files = uploadedFiles.data?.uploadFiles ?? [];
        return files[0].s3FilePath;
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return '';
      }
    },
    [uploadImages],
  );

  const handleClickSave = useSafeAsyncCallback(
    useCallback(
      async (input: AdminBannerInput): Promise<void> => {
        setShowLoader(true);

        try {
          if (image) {
            input.imageURL = await uploadImage(image);
          }

          await updateBanner({
            variables: {
              id: id,
              input: input,
            },
          });
        } catch {
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [navigate, image, uploadImage, updateBanner, id],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Banner Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Form
            formId={formId}
            form={form}
            onSubmit={handleClickSave}
            error={updateError}
            imageSrc={imageSrc}
            setImage={setImage}
          />
          <Button
            type="submit"
            form={formId}
            variant="contained"
            color="primary"
            style={{ margin: '20px 0 0 0' }}
          >
            Save
          </Button>
        </Paper>
      </main>
    </div>
  );
};
