import React, { useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  useGetChaptersForAdminQuery,
  useAddChapterForAdminMutation,
  AdminChapterSearchInput,
  AdminChapterInput,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { AuthorizationTypeLabels } from '../../const/Authorization';
import queryString from 'query-string';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { chapterSchema, ChapterDefaultValues } from '../../../formSchema/chapter';
import { ChapterInput } from './Update';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

type SearchFormInput = Omit<AdminChapterSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminChapterSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const programId = params.get('programID') ?? '';
    const key = params.get('key') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      programID: parseInt(programId) > 0 ? parseInt(programId) : undefined,
      key: key.trim().length > 0 ? key : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetChaptersForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
    notifyOnNetworkStatusChange: true,
  });

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      programID: currentSearchParams.programID,
      key: currentSearchParams.key,
      status: currentSearchParams.status,
    },
    // 検索フォームはバリデーションなし(検索してもヒットしないだけなので)
  });

  const addForm = useForm<ChapterInput>({
    resolver: yupResolver(chapterSchema),
    defaultValues: ChapterDefaultValues,
  });
  const addFormErrors = addForm.formState.errors;

  // Add Dialog
  const [open, showAddDialog] = React.useState(false);

  // データ登録
  const [addChapterMutation, { error: addError, loading: adding }] =
    useAddChapterForAdminMutation();

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminChapterSearchInput): void => {
      navigate({
        pathname: '/chapters',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 検索
  const searchChapters = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  const addChapter = useSafeAsyncCallback(
    useCallback(
      async (chapterInput: ChapterInput): Promise<void> => {
        const adminChapterInput: AdminChapterInput = {
          programID: chapterInput.programID,
          key: chapterInput.key ?? '',
          qaKey: chapterInput.qaKey ?? '',
          number: chapterInput.number ?? 0,
          title: chapterInput.title,
          description: chapterInput.description ?? '',
          content: chapterInput.content,
          requireTime: chapterInput.requireTime,
          type: chapterInput.type,
          status: chapterInput.status,
          sortNumber: chapterInput.sortNumber ?? 0,
        };
        try {
          await addChapterMutation({ variables: { input: adminChapterInput } });
        } catch {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          return;
        }

        // ダイアログを閉じて入力フォーム初期化
        showAddDialog(false);
        addForm.reset();

        // 再検索
        refetch({ input: currentSearchParams });
      },
      [addForm, refetch, currentSearchParams, addChapterMutation],
    ),
  );

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Chapter
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || adding) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(searchChapters)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="programID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="programId"
                          label="ProgramID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="key"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="key"
                          label="key"
                          type="text"
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">ProgramID</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Key</TableCell>
                  <TableCell align="right">QAKey</TableCell>
                  <TableCell align="right">章番号</TableCell>
                  <TableCell align="right">ソート順</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">RequireTime</TableCell>
                  <TableCell align="right">公開範囲</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.chaptersForAdmin?.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/chapters/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/programs/${row.programElement?.program!.id}`}>
                        {row.programElement?.program!.title}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/chapters/${row.id}`}>{row.title}</Link>
                    </TableCell>
                    <TableCell align="right">{row.key}</TableCell>
                    <TableCell align="right">{row.qaKey}</TableCell>
                    <TableCell align="right">{row.number}</TableCell>
                    <TableCell align="right">{row.sortNumber}</TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                    <TableCell align="right">{row.content.substr(0, 20)}...</TableCell>
                    <TableCell align="right">{row.requireTime}分</TableCell>
                    <TableCell align="right">{AuthorizationTypeLabels[row.type]}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.chaptersForAdmin?.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <Controller
            name="programID"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="programId"
                label="ProgramID"
                type="number"
                fullWidth
                inputProps={{ min: 1 }}
                error={!!addFormErrors.programID}
                helperText={addFormErrors.programID?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="key"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="key"
                label="Key"
                type="text"
                fullWidth
                placeholder="text/chapter_01/section_01/text.md"
                error={!!addFormErrors.key}
                helperText={addFormErrors.key?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="qaKey"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="qaKey"
                label="qaKey"
                type="text"
                fullWidth
                placeholder="questions/sections_01"
                error={!!addFormErrors.qaKey}
                helperText={addFormErrors.qaKey?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="number"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="number"
                label="章番号"
                type="number"
                fullWidth
                inputProps={{ min: 0 }}
                error={!!addFormErrors.number}
                helperText={addFormErrors.number?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="sortNumber"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="sortNumber"
                label="ソート順"
                type="number"
                fullWidth
                inputProps={{ min: 0 }}
                error={!!addFormErrors.sortNumber}
                helperText={addFormErrors.sortNumber?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="title"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Title"
                type="text"
                fullWidth
                error={!!addFormErrors.title}
                helperText={addFormErrors.title?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="description"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="description"
                label="Description"
                type="text"
                multiline={true}
                minRows={5}
                maxRows={Infinity}
                fullWidth
                error={!!addFormErrors.description}
                helperText={addFormErrors.description?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="content"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="content"
                label="Content"
                type="text"
                multiline={true}
                minRows={5}
                maxRows={Infinity}
                fullWidth
                error={!!addFormErrors.content}
                helperText={addFormErrors.content?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="requireTime"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="requireTime"
                label="RequireTime"
                type="number"
                fullWidth
                inputProps={{ min: 1 }}
                error={!!addFormErrors.requireTime}
                helperText={addFormErrors.requireTime?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="status">公開範囲</InputLabel>
              <Controller
                name="type"
                control={addForm.control}
                render={({ field: { ref, ...rest } }) => (
                  <Select
                    input={<Input name="type" id="type" />}
                    error={!!addFormErrors.type}
                    inputRef={ref}
                    {...rest}
                  >
                    {Object.entries(AuthorizationTypeLabels).map(([value, label]) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Controller
                name="status"
                control={addForm.control}
                render={({ field: { ref, ...rest } }) => (
                  <Select
                    input={<Input name="status" id="status" />}
                    error={!!addFormErrors.status}
                    inputRef={ref}
                    {...rest}
                  >
                    {Object.entries(ValidLabels).map(([value, label]) => (
                      <MenuItem value={value} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={addForm.handleSubmit(addChapter)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
