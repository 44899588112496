import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import {
  AdminSessionReportInput,
  SessionReportAnnouncementInput,
  SessionReportPreparationInput,
} from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { ApolloError } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Typography,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';

import {
  Announcements,
  PhaseLabels,
  Preparations,
  ResponseDeadline,
  Schedules,
} from '../../const/Session';
import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

interface Props {
  formId: string;
  form: UseFormReturn<AdminSessionReportInput>;
  onSubmit: (input: AdminSessionReportInput) => void;
  error: ApolloError | undefined;
  isUpdate?: boolean;
}

export const Form: React.FC<Props> = ({ formId, form, onSubmit, error, isUpdate }): JSX.Element => {
  const classes = useStyles();
  const formErrors = form.formState.errors;

  const handleCheckPreparation = (checked: SessionReportPreparationInput) => {
    const { preparations } = form.getValues();
    const newPreparations = preparations.map((el) => el.type).includes(checked.type)
      ? preparations.filter((a) => a.type !== checked.type)
      : preparations.concat({ type: checked.type });
    form.setValue('preparations', newPreparations);
  };

  const handleCheckAnnouncement = (checked: SessionReportAnnouncementInput) => {
    const { announcements } = form.getValues();
    const newAnnouncements = announcements.map((el) => el.type).includes(checked.type)
      ? announcements.filter((a) => a.type !== checked.type)
      : announcements.concat({ type: checked.type });
    form.setValue('announcements', newAnnouncements);
  };

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Controller
        name="sessionID"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="sessionID"
            label="SessionID"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.sessionID}
            helperText={formErrors.sessionID?.message}
            inputRef={ref}
            disabled={isUpdate}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.sessionPhase}>
        <InputLabel htmlFor="sessionPhase">Session Phase</InputLabel>
        <Controller
          name="sessionPhase"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select error={!!formErrors.sessionPhase} inputRef={ref} {...rest}>
              {Object.entries(PhaseLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.sessionPhase?.message}</FormHelperText>
      </FormControl>
      <Controller
        name="content"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="content"
            label="Content"
            type="text"
            fullWidth
            multiline
            minRows={5}
            maxRows={Infinity}
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            placeholder="セッション内容を記載してください"
            error={!!formErrors.content}
            helperText={formErrors.content?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.preparations}>
        <FormLabel>Preparations</FormLabel>
        <Controller
          name="preparations"
          control={form.control}
          render={({ field: { ref: _ref, ..._rest } }) => (
            <>
              {Object.entries(Preparations).map(([value, label]) => (
                <FormControlLabel
                  key={value}
                  label={label}
                  control={
                    <Checkbox
                      onChange={() =>
                        handleCheckPreparation({ type: value } as SessionReportPreparationInput)
                      }
                      checked={
                        !!form
                          .getValues()
                          .preparations.find((preparation) => preparation.type === value)
                      }
                    />
                  }
                />
              ))}
            </>
          )}
        />
        <FormHelperText>{formErrors.preparations?.message}</FormHelperText>
      </FormControl>
      <Controller
        name="preparationOthers"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="preparationOthers"
            label="PreparationOthers"
            type="text"
            fullWidth
            multiline
            minRows={5}
            maxRows={Infinity}
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            placeholder="前後の準備でその他を選択した場合は詳細を記載してください"
            error={!!formErrors.preparationOthers}
            helperText={formErrors.preparationOthers?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.isOnSchedule}>
        <InputLabel htmlFor="isOnSchedule">IsOnSchedule</InputLabel>
        <Controller
          name="isOnSchedule"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select error={!!formErrors.isOnSchedule} inputRef={ref} {...rest}>
              {Object.entries(Schedules).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.isOnSchedule?.message}</FormHelperText>
      </FormControl>
      <Controller
        name="delayReason"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="delayReason"
            label="DelayReason"
            type="text"
            fullWidth
            multiline
            minRows={5}
            maxRows={Infinity}
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            placeholder="「遅れている」「少し遅れている」を選択した場合は詳細を記載してください"
            error={!!formErrors.delayReason}
            helperText={formErrors.delayReason?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.announcements}>
        <FormLabel>Announcements</FormLabel>
        <Controller
          name="announcements"
          control={form.control}
          render={({ field: { ref: _ref, ..._rest } }) => (
            <>
              {Object.entries(Announcements).map(([value, label]) => (
                <FormControlLabel
                  key={value}
                  label={label}
                  control={
                    <Checkbox
                      onChange={() =>
                        handleCheckAnnouncement({ type: value } as SessionReportAnnouncementInput)
                      }
                      checked={
                        !!form
                          .getValues()
                          .announcements.find((announcement) => announcement.type === value)
                      }
                    />
                  }
                />
              ))}
            </>
          )}
        />
        <FormHelperText>{formErrors.announcements?.message}</FormHelperText>
      </FormControl>
      <Controller
        name="hasAnnouncementsDetail"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="hasAnnouncementsDetail"
            label="HasAnnouncementsDetail"
            type="text"
            fullWidth
            multiline
            minRows={5}
            maxRows={Infinity}
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            placeholder="運営への連携事項を記載してください"
            error={!!formErrors.delayReason}
            helperText={formErrors.delayReason?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.responseDeadline}>
        <InputLabel htmlFor="responseDeadline">ResponseDeadline</InputLabel>
        <Controller
          name="responseDeadline"
          control={form.control}
          render={({ field: { ref, value, ...rest } }) => (
            <Select
              error={!!formErrors.responseDeadline}
              inputRef={ref}
              value={value ?? ''}
              {...rest}
            >
              <MenuItem value={undefined}>&nbsp;</MenuItem>
              {Object.entries(ResponseDeadline).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.responseDeadline?.message}</FormHelperText>
      </FormControl>
      <Controller
        name="coachingMemo"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="coachingMemo"
            label="CoachingMemo"
            type="text"
            fullWidth
            multiline
            minRows={5}
            maxRows={Infinity}
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            placeholder="メモがあれば記載してください"
            error={!!formErrors.coachingMemo}
            helperText={formErrors.coachingMemo?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.status}>
        <InputLabel htmlFor="status">Status</InputLabel>
        <Controller
          name="status"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="status" id="status" />}
              error={!!formErrors.status}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(ValidLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.status?.message}</FormHelperText>
      </FormControl>
      {error?.graphQLErrors && (
        <Box className={classes.inputError}>
          <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
          {error.graphQLErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Box>
      )}
    </form>
  );
};
