import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import styled from 'styled-components';
import media from 'styled-media-query';

import { AdminCourseProgramStepInput, useGetProgramsForAdminQuery } from '../../../gen/graphql';

import FormControl from '@material-ui/core/FormControl';
import { FormHelperText } from '@material-ui/core';
import TrashIcon from '../../../static/image/icon_trash.svg';
import { InputWithSearch, Option } from '../../common/InputWithSearch';
import { HorizontalInput } from '../../common/HorizontalInput';

interface Props {
  form: UseFormReturn<AdminCourseProgramStepInput>;
}

/**
 * 各フィールドの使用教材の選択肢
 * フィルタリングする際に使用
 */
interface ProgramOptions {
  [fieldId: string]: Option[];
}

export const ProgramInputFields: React.FC<Props> = ({ form }): JSX.Element => {
  const [masterPrograms, setMasterPrograms] = useState<Option[]>([]); // 教材のマスターデータ
  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'courseProgramStepItems',
  });
  const formErrors = form.formState.errors;

  const [programOptions, setProgramOptions] = useState<ProgramOptions>({});

  const { refetch } = useGetProgramsForAdminQuery({
    variables: {
      input: {},
    },
    onCompleted: ({ programsForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      const options = data.items.map((program) => {
        return {
          ...program,
          name: program.title,
        };
      });

      // 各フィールドの使用教材の選択肢をセット
      const newProgramOptions: ProgramOptions = {};
      fields.forEach((field) => {
        newProgramOptions[field.id] = options;
      });
      setProgramOptions(newProgramOptions);

      // マスターデータとして選択肢を持っておく
      setMasterPrograms(options);
    },
  });

  useEffect(() => {
    // fields が変更（append, remove）された際に、field.id が変わってしまうので、再度選択肢をセットする
    const newProgramOptions: ProgramOptions = {};
    fields.forEach((field) => {
      newProgramOptions[field.id] = masterPrograms;
    });
    setProgramOptions(newProgramOptions);
  }, [masterPrograms, fields]);

  const searchPrograms = useCallback(
    async (fieldId: string, query: string): Promise<void> => {
      try {
        const { data } = await refetch({
          input: {
            limit: 100, // 既存が100件だったため100件で指定している
            page: 1,
            word: query,
          },
        });

        // 検索結果を選択肢にセット
        const options = data.programsForAdmin.items.map((program) => {
          return {
            ...program,
            name: program.title,
          };
        });
        setProgramOptions((prev) => {
          return {
            ...prev,
            [fieldId]: options,
          };
        });
      } catch {
        return;
      }
    },
    [refetch],
  );

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Row key={item.id}>
            <Container>
              <Controller
                name={`courseProgramStepItems.${index}.number`}
                control={form.control}
                render={({ field: { onChange, value } }) => (
                  <FormControl error={!!formErrors.courseProgramStepItems?.[index]?.number}>
                    <HorizontalInput
                      name="number"
                      label="ナンバー"
                      type="number"
                      width="5rem"
                      value={String(value)}
                      onChange={onChange}
                      key={index}
                      min={1}
                      error={!!formErrors.courseProgramStepItems?.[index]?.number}
                    />
                    <FormHelperText>
                      {formErrors.courseProgramStepItems?.[index]?.number?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              <Controller
                name={`courseProgramStepItems.${index}.programID`}
                control={form.control}
                render={({ field: { value } }) => (
                  <FormControl error={!!formErrors.courseProgramStepItems?.[index]?.programID}>
                    <InputWithSearch
                      label="使用教材"
                      options={programOptions[item.id]}
                      handleInput={(value) => searchPrograms(item.id, value)}
                      width="13rem"
                      onSelect={(programID) =>
                        form.setValue(`courseProgramStepItems.${index}.programID`, programID ?? 0)
                      }
                      defaultValue={masterPrograms.find((v) => v.id === value)}
                      deletable
                      error={!!formErrors.courseProgramStepItems?.[index]?.programID}
                    />
                    <FormHelperText>
                      {formErrors.courseProgramStepItems?.[index]?.programID?.message}
                    </FormHelperText>
                  </FormControl>
                )}
              />
              <Icon onClick={() => remove(index)} src={TrashIcon} alt="ゴミ箱" />
            </Container>
          </Row>
        );
      })}
      <Add
        onClick={() => {
          append({ number: 0, programID: 0 });
        }}
      >
        + 内容を追加する
      </Add>
    </>
  );
};

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

const Add = styled.p`
  color: #e2001b;
  font-weight: 600;
  cursor: pointer;
`;

const Icon = styled.img`
  margin-left: 1.5rem;
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${media.lessThan('large')`
    margin-left: 1rem;
  `}
`;

const Container = styled.div`
  display: flex;
  & > * + * {
    margin-left: 1.5rem;
  }
  ${media.lessThan('large')`
    > div {
      flex-wrap: wrap;
    }
    & > * + * {
      margin-left: 0.7rem;
    }
    label {
      display: block;
      width: 100%;
      margin: 0 auto 0.5rem;
    }
  `}
  ${media.lessThan('medium')`
    display: block;
    padding: 1rem;
    background: #fff;
    border: 1px solid #ddd;
    & > * + * {
      margin: 1rem auto 0;
    }
    label {
      width: 6em;
      margin: 0 0.5rem 0 0;
    }
  `}
  ${media.lessThan('small')`
    width: calc(100% - 1rem - 24px);
    > div {
      flex-wrap: wrap;
    }
    label {
      width: 100%;
      margin: 0 auto 0.5rem;
      font-size: 0.9rem;
    }
  `}
`;
