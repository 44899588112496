import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles, Theme } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

// Material UI Table
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';

// Material UI Other
import { Typography, Button, LinearProgress } from '@material-ui/core';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetProgramForAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { ProgramCategoryLabels, ProgramTypeLabels } from '../../const/Program';
import { minutesToHours } from '../../../utils/common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ program_id: string }>().program_id;
  const id = Number(paramID);

  const { data, loading } = useGetProgramForAdminQuery({
    variables: { id },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Program Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/programs/${data?.programForAdmin?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{data?.programForAdmin?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Title
                </TableCell>
                <TableCell>{data?.programForAdmin?.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Description
                </TableCell>
                <TableCell>{data?.programForAdmin?.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ChapterCount
                </TableCell>
                <TableCell>{data?.programForAdmin?.chapterCount}章</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  RequireTime
                </TableCell>
                <TableCell>
                  {minutesToHours(data?.programForAdmin?.requireTime)}
                  時間
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Icon
                </TableCell>
                <TableCell>
                  <img width="100px" height="100px" src={data?.programForAdmin?.icon} alt="" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Thumbnail
                </TableCell>
                <TableCell>
                  <img width="600px" height="300px" src={data?.programForAdmin?.thumbnail} alt="" />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Repository
                </TableCell>
                <TableCell>{data?.programForAdmin?.repository}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Summary
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.summary ? (
                    <ReactMarkdown children={data?.programForAdmin?.summary} />
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  SummaryImage1
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.summaryImage1 ? (
                    <img
                      width="800px"
                      height="400px"
                      src={data?.programForAdmin?.summaryImage1}
                      alt=""
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  SummaryImage2
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.summaryImage2 ? (
                    <img
                      width="800px"
                      height="400px"
                      src={data?.programForAdmin?.summaryImage2}
                      alt=""
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  SummaryImage3
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.summaryImage3 ? (
                    <img
                      width="800px"
                      height="400px"
                      src={data?.programForAdmin?.summaryImage3}
                      alt=""
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  SummaryImage4
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.summaryImage4 ? (
                    <img
                      width="800px"
                      height="400px"
                      src={data?.programForAdmin?.summaryImage4}
                      alt=""
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  SummaryImage5
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.summaryImage5 ? (
                    <img
                      width="800px"
                      height="400px"
                      src={data?.programForAdmin?.summaryImage5}
                      alt=""
                    />
                  ) : (
                    ''
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Type
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.type
                    ? ProgramTypeLabels[data?.programForAdmin?.type]
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Category
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.category
                    ? ProgramCategoryLabels[data?.programForAdmin?.category]
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UserRole
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.programPermissions
                    ? data.programForAdmin.programPermissions.map((n) => n.userRole).join(', ')
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Number
                </TableCell>
                <TableCell>{data?.programForAdmin?.number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  QuestionCount
                </TableCell>
                <TableCell>{data?.programForAdmin?.questionCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  AnswerCount
                </TableCell>
                <TableCell>{data?.programForAdmin?.answerCount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Link to={`/chapters?programID=${data?.programForAdmin?.id}`}>Chapters</Link>
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.programElements?.map((c) => (
                    <Link className={classes.block} to={`/chapters/${c.chapter?.id}`}>
                      {c.chapter?.title}
                    </Link>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Tags
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.tags?.map((tag) => (
                    <Link className={classes.block} to={`/tags/${tag.id}`}>
                      {tag.name}
                    </Link>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>
                  {data?.programForAdmin?.status ? ValidLabels[data?.programForAdmin?.status] : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{data?.programForAdmin?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{data?.programForAdmin?.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
    </div>
  );
};
