import React from 'react';

// Material UI
import { Grid } from '@material-ui/core';

// Material UI Form
import { TextField } from '@material-ui/core';

// Material UI Icon
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Material UI Other
import { Button } from '@material-ui/core';

import { useUploadImagesMutation } from '../../gen/graphql';

interface ImageFieldProps {
  name: string;
  path: string;
  width: number;
  height: number;
  placeholder?: string;
  onImageUploaded: (name: string, path: string) => void;
  setShowLoader: (value: React.SetStateAction<boolean>) => void;
}

export const ImageField: React.FC<ImageFieldProps> = (props: ImageFieldProps) => {
  const [uploadImages] = useUploadImagesMutation();

  const handleImageChange: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    event.persist(); // https://blog.nakanishy.com/react-persist.html

    props.setShowLoader(true);

    if (!event.target.files) return;
    const file = event.target.files[0];

    try {
      const uploadedFiles = await uploadImages({
        variables: {
          files: [file],
        },
      });
      const files = uploadedFiles.data?.uploadFiles ?? [];

      props.onImageUploaded(event.target.name, files[0].s3FilePath);
    } catch {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
    } finally {
      props.setShowLoader(false);
    }
  };

  return (
    <div>
      <TextField
        autoFocus
        margin="dense"
        id={props.name}
        name={props.name}
        label={props.name.charAt(0).toUpperCase() + props.name.slice(1)}
        type="text"
        placeholder={
          props.placeholder ? props.placeholder : `https://lms.sejuku.net/images/${props.name}.jpg`
        }
        InputLabelProps={{
          shrink: true,
        }}
        value={props.path}
        fullWidth
        style={{ margin: '10px 0' }}
        disabled
      />
      <Grid container alignItems="flex-start">
        <Grid item>
          <img
            width={`${props.width}px`}
            height={`${props.height}px`}
            src={props.path}
            alt={`${props.name}`}
          />
        </Grid>
        <Grid item>
          <input
            accept="image/*"
            type="file"
            id={`upload-${props.name}`}
            name={props.name}
            onChange={handleImageChange}
            style={{ display: 'none' }}
          ></input>
          <Button
            htmlFor={`upload-${props.name}`}
            component="label"
            variant="contained"
            style={{ margin: '0 16px' }}
          >
            <CloudUploadIcon />
            &nbsp;UPLOAD
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
