import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetInvoiceForAdminQuery } from '../../../gen/graphql';
import { InvoiceStatusLabels } from '../../const/Invoice';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  buttonArea: {
    marginTop: theme.spacing(2),
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ invoice_id: string }>().invoice_id;
  const id = Number(paramID);

  const { data, loading } = useGetInvoiceForAdminQuery({
    variables: { id },
  });

  const invoice = data?.getInvoiceForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Invoice Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Alert severity="error">
          こちらは古いデータのため参照するのは避けてください。Stripeの管理画面、もしくは「課金データ」メニューから最新の情報をご確認ください。
        </Alert>
        <Grid className={classes.buttonArea} container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          {invoice && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{invoice.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/${invoice.user.id}`}>{invoice.user.personalInfo?.name}</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    InvoiceItems
                  </TableCell>
                  <TableCell>
                    <Link to={`/invoiceItems?invoiceId=${invoice.id}`}>InvoiceItems</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ProviderInvoiceID
                  </TableCell>
                  <TableCell>{invoice.providerInvoiceID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    AmountDue
                  </TableCell>
                  <TableCell>{invoice.amountDue}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    AmountPaid
                  </TableCell>
                  <TableCell>{invoice.amountPaid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    AmountRemaining
                  </TableCell>
                  <TableCell>{invoice.amountRemaining}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Tax
                  </TableCell>
                  <TableCell>{invoice.tax}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Subtotal
                  </TableCell>
                  <TableCell>{invoice.subtotal}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    URL
                  </TableCell>
                  <TableCell>{invoice.url}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{InvoiceStatusLabels[invoice.status]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{invoice.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{invoice.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
