import React, { useState, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Other
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { formatRequestDate, getUTCDatetime } from '../../const/Util';
import {
  findProgress,
  progresses,
  phaseLabels,
  lessonTypeLabels,
  completedLabels,
  canceledLabels,
  studentAssistanceLabels,
  instructorAssistanceLabels,
  lessonCSVLabels,
  completedLabelsForInput,
  canceledLabelsForInput,
} from '../../const/Lesson';
import {
  contractTypeLabels,
  lessonTypeLabels as courseLessonTypeLabels,
  StatusLabels as courseStatusLabels,
} from '../../const/Course';
import { StatusLabels as instructorStatusLabels } from '../../const/Instructor';
import { GeneralChangeEvent } from '../../../types/Common';
import {
  useGetLessonsForAdminQuery,
  AdminLessonSearchInput,
  useAddLessonForAdminMutation,
  AdminLessonInput,
  useGetLessonAssistancesQuery,
  useGetCsvLessonsForAdminLazyQuery,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { strToBool } from '../../const/Bool';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { createCSV } from '../../../utils/csv';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  csvBtn: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 99,
    backgroundColor: '#009be5',
    color: 'white',
    padding: '10px 13px',
    textDecoration: 'none',
    borderRadius: '10px',
    boxShadow: '3px 3px 4px black',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

// 登録フォームの初期値
const addInputDefaultValues = {
  courseID: 0,
  instructorID: 0,
  title: '',
  phase: 0,
  completed: 0,
  type: 0,
  progress: 5,
  startAt: '',
  endAt: '',
  taughtSkill: '',
  homework: '',
  prepare: '',
  delayReason: '',
  lessonsAssistances: [],
  assistanceDetail: '',
  studentAssistance: [],
  instructorAssistance: [],
};

type SearchFormInput = Omit<AdminLessonSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminLessonSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const canceled = params.get('canceled') ?? '';
    const completed = params.get('completed') ?? '';
    const courseID = params.get('courseID') ?? '';
    const instructorID = params.get('instructorID') ?? '';
    const title = params.get('title') ?? '';
    const phase = params.get('phase') ?? '';
    const type = params.get('type') ?? '';
    const startAt = params.get('startAt') ?? '';
    const endAt = params.get('endAt') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      canceled: strToBool(canceled),
      completed: strToBool(completed),
      courseID: parseInt(courseID) > 0 ? parseInt(courseID) : undefined,
      instructorID: parseInt(instructorID) > 0 ? parseInt(instructorID) : undefined,
      title: title !== '' ? title : undefined,
      phase: parseInt(phase) >= 0 ? parseInt(phase) : undefined,
      type: parseInt(type) >= 0 ? parseInt(type) : undefined,
      startAt: startAt ? getUTCDatetime(startAt) : undefined,
      endAt: endAt ? getUTCDatetime(endAt) : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetLessonsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });
  const plans = data?.lessonsForAdmin.items ?? [];
  const total = data?.lessonsForAdmin.total ?? 0;

  // CSVダウンロード
  const [getLessonLazy] = useGetCsvLessonsForAdminLazyQuery({
    onCompleted: (data) => {
      const lessonData = data.csvLessonsForAdmin ?? [];
      const csvRecords: string[][] = lessonData.map((lesson) => {
        return [
          lesson.course?.id,
          lesson.course?.plan.name,
          lesson.course?.startDate,
          lesson.course?.endDate,
          lesson.course?.absentStartDate,
          lesson.course?.absentEndDate,
          lesson.course?.absent2StartDate,
          lesson.course?.absent2EndDate,
          lesson.course?.absent3StartDate,
          lesson.course?.absent3EndDate,
          lesson.course?.instructorChangeStartDate,
          lesson.course?.instructorChangeEndDate,
          lesson.course?.instructorChange2StartDate,
          lesson.course?.instructorChange2EndDate,
          lesson.course?.instructorChange3StartDate,
          lesson.course?.instructorChange3EndDate,
          lesson.course?.plannedLessonCount,
          lesson.course?.finishedLessonCount,
          lesson.course?.lessonType ? courseLessonTypeLabels[lesson.course?.lessonType] : '',
          lesson.course?.contractType ? contractTypeLabels[lesson.course?.contractType] : '',
          lesson.course?.status ? courseStatusLabels[lesson.course?.status] : '',
          lesson.instructor?.id,
          lesson.instructor?.user.id,
          lesson.instructor?.user.personalInfo?.name,
          lesson.instructor?.scInstructorID,
          lesson.instructor ? instructorStatusLabels[lesson.instructor?.status] : '',
          lesson.course?.student?.id,
          lesson.course?.student?.user.id,
          lesson.course?.student?.user.personalInfo?.name,
          lesson.course?.student?.scStudentID,
          lesson.course?.student?.status,
          lesson.id,
          lesson.title,
          lesson.canceled ? canceledLabels[lesson.canceled] : '',
          lesson.type ? lessonTypeLabels[lesson.type] : '',
          lesson.startAt,
          lesson.endAt,
          lesson.phase ? phaseLabels[lesson.phase] : '',
          lesson.taughtSkill,
          lesson.homework,
          lesson.prepare,
          findProgress(lesson.progress).label,
          lesson.delayReason,
          lesson.lessonsAssistances?.map((a) => a.name).join('\n'),
          lesson.assistanceDetail,
          lesson.studentAssistance
            .filter((a) => studentAssistanceLabels.length > a)
            .map((a) => studentAssistanceLabels[a])
            .join('\n'),
          lesson.instructorAssistance
            .filter((a) => instructorAssistanceLabels.length > a)
            .map((a) => instructorAssistanceLabels[a])
            .join('\n'),
          lesson.completed ? completedLabels[lesson.completed] : '',
          lesson.status ? ValidLabels[lesson.status] : '',
        ].map((record) => (record ? String(record) : ''));
      });
      createCSV(lessonCSVLabels, csvRecords, 'lesson');
    },
    // クエリパラメータの変化がない時に二回目以降のダウンロードが動かないため毎回onCompletedを動作させる
    notifyOnNetworkStatusChange: true,
  });
  const csvFileDownload = () => {
    getLessonLazy({
      variables: { input: currentSearchParams },
    });
  };

  const { data: lessonsAssistancesData, loading: lessonsAssistancesLoading } =
    useGetLessonAssistancesQuery();
  const lessonsAssistances = lessonsAssistancesData?.lessonAssistances ?? [];

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  const [addInput, setAddInput] = useState<AdminLessonInput>(addInputDefaultValues);

  // Add Dialog
  const [open, showAddDialog] = React.useState(false);

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  // データ登録
  const [addLesson, { error: addError }] = useAddLessonForAdminMutation();

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminLessonSearchInput): void => {
      navigate({
        pathname: '/lessons',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 再検索
  const refetchLessons = useCallback(
    async (params: AdminLessonSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      canceled: currentSearchParams.canceled,
      completed: currentSearchParams.completed,
      courseID: currentSearchParams.courseID,
      instructorID: currentSearchParams.instructorID,
      title: currentSearchParams.title,
      phase: currentSearchParams.phase,
      type: currentSearchParams.type,
      startAt: currentSearchParams.startAt,
      endAt: currentSearchParams.endAt,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };

      if (input.startAt) {
        params.startAt = formatRequestDate(new Date(input.startAt));
      }

      if (input.endAt) {
        params.endAt = formatRequestDate(new Date(input.endAt));
      }

      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchLessons(params);
      }
      createHistory(params);
    },
    [refetchLessons, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // データ作成ボタンクリック
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await addLesson({
          variables: {
            courseID: addInput.courseID,
            input: addInput,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      // ダイアログを閉じて入力フォーム初期化
      showAddDialog(false);
      setAddInput(addInputDefaultValues);

      // 再検索
      refetchLessons(currentSearchParams);
    }, [addLesson, refetchLessons, currentSearchParams, addInput]),
  );

  const handleChangeAddTitle = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, title: value }));
  }, []);

  const handleChangeAddCourseID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      courseID: parseInt(value),
    }));
  }, []);

  const handleChangeAddInstructorID = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        instructorID: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeAddPhase = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({ ...current, phase: parseInt(String(value)) }));
    },
    [],
  );

  const handleChangeAddType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({ ...current, type: parseInt(String(value)) }));
    },
    [],
  );

  const handleChangeAddStartAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setAddInput((current) => ({
        ...current,
        startAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeAddEndAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setAddInput((current) => ({
        ...current,
        endAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeAddTaughtSkill = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({ ...current, taughtSkill: value }));
    },
    [],
  );

  const handleChangeAddHomework = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, homework: value }));
  }, []);

  const handleChangeAddPrepare = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, prepare: value }));
  }, []);

  const handleChangeAddProgress = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({ ...current, progress: parseInt(String(value)) }));
    },
    [],
  );

  const handleChangeAddDelayReason = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({ ...current, delayReason: value }));
    },
    [],
  );

  // CheckBoxListへの保存処理
  // CheckOff（すでに存在している）：除外
  // CheckON(存在してない場合)：追加
  const addNumValueToCheckBoxList = (val: number, array: number[]): number[] => {
    return array.includes(val) ? array.filter((a) => a !== val) : array.concat(val);
  };

  const handleChangeAddLessonsAssistances = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        lessonsAssistances: addNumValueToCheckBoxList(parseInt(value), current.lessonsAssistances),
      }));
    },
    [],
  );

  const handleChangeAddInstructorAssistance = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        instructorAssistance: addNumValueToCheckBoxList(
          parseInt(value),
          current.instructorAssistance,
        ),
      }));
    },
    [],
  );

  const handleChangeAddStudentAssistance = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        studentAssistance: addNumValueToCheckBoxList(parseInt(value), current.studentAssistance),
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Lesson
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || lessonsAssistancesLoading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="title"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="title"
                          label="Title"
                          type="text"
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="courseID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="courseID"
                          label="CourseID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="instructorID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="instructorID"
                          label="InstructorID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="phase">Phase</InputLabel>
                      <Controller
                        name="phase"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(phaseLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="type">Type</InputLabel>
                      <Controller
                        name="type"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(lessonTypeLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="startAt"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="startAt"
                            label="StartAt"
                            format="yyyy/MM/dd"
                            maxDate={searchForm.getValues().endAt}
                            value={value ?? null}
                            fullWidth
                            inputRef={ref}
                            {...rest}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="endAt"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="endAt"
                            label="EndAt"
                            format="yyyy/MM/dd"
                            value={value ?? null}
                            fullWidth
                            inputRef={ref}
                            {...rest}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="completed">Completed</InputLabel>
                      <Controller
                        name="completed"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(completedLabelsForInput).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="canceled">Canceled</InputLabel>
                      <Controller
                        name="canceled"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(canceledLabelsForInput).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Course</TableCell>
                  <TableCell align="right">Instructor</TableCell>
                  <TableCell align="right">StartAt</TableCell>
                  <TableCell align="right">EndAt</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">TaughtSkill</TableCell>
                  <TableCell align="right">Homework</TableCell>
                  <TableCell align="right">Prepare</TableCell>
                  <TableCell align="right">DelayReason</TableCell>
                  <TableCell align="right">Completed</TableCell>
                  <TableCell align="right">Canceled</TableCell>
                  <TableCell align="right">Progress</TableCell>
                  <TableCell align="right">Assistances</TableCell>
                  <TableCell align="right">AssistanceDetail</TableCell>
                  <TableCell align="right">Phase</TableCell>
                  <TableCell align="right">StudentAssistance</TableCell>
                  <TableCell align="right">InstructorAssistance</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plans.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/lessons/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.title}</TableCell>
                    <TableCell align="right">
                      <Link to={`/courses/${row.course!.id}`}>{row.course!.plan!.name}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/instructors/${row.instructor?.id}`}>
                        {row.instructor?.user?.personalInfo?.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{row.startAt}</TableCell>
                    <TableCell align="right">{row.endAt}</TableCell>
                    <TableCell align="right">{lessonTypeLabels[row.type]}</TableCell>
                    <TableCell align="right">{row.taughtSkill}</TableCell>
                    <TableCell align="right">{row.homework}</TableCell>
                    <TableCell align="right">{row.prepare}</TableCell>
                    <TableCell align="right">{row.delayReason}</TableCell>
                    <TableCell align="right">{completedLabels[row.completed]}</TableCell>
                    <TableCell align="right">{canceledLabels[row.canceled]}</TableCell>
                    <TableCell align="right">{findProgress(row.progress).label}</TableCell>
                    <TableCell align="right">
                      {row.lessonsAssistances?.map((a) => a.id).join('/')}
                    </TableCell>
                    <TableCell align="right">{row.assistanceDetail}</TableCell>
                    <TableCell align="right">{phaseLabels[row.phase ? row.phase : 0]}</TableCell>
                    <TableCell align="right">
                      {row.studentAssistance
                        .filter((a) => studentAssistanceLabels.length > a)
                        .map((a) => studentAssistanceLabels[a])
                        .join('/')}
                    </TableCell>
                    <TableCell align="right">
                      {row.instructorAssistance
                        .filter((a) => instructorAssistanceLabels.length > a)
                        .map((a) => instructorAssistanceLabels[a])
                        .join('/')}
                    </TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <button onClick={csvFileDownload} id="csvLink" className={classes.csvBtn}>
        CSV出力
      </button>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="title"
            name="title"
            label="Title"
            type="text"
            defaultValue={addInput.title}
            fullWidth
            onChange={handleChangeAddTitle}
          />
          <TextField
            autoFocus
            margin="dense"
            id="courseID"
            name="courseID"
            label="CourseID"
            type="number"
            defaultValue={addInput.courseID}
            fullWidth
            onChange={handleChangeAddCourseID}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="instructorID"
            name="instructorID"
            label="InstructorID"
            type="number"
            defaultValue={addInput.instructorID}
            fullWidth
            onChange={handleChangeAddInstructorID}
            inputProps={{ min: 1 }}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="phase">Phase</InputLabel>
              <Select
                value={addInput.phase}
                onChange={handleChangeAddPhase}
                input={<Input name="phase" id="phase" />}
              >
                {Object.entries(phaseLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="type">Type</InputLabel>
              <Select
                value={addInput.type}
                onChange={handleChangeAddType}
                input={<Input name="type" id="type" />}
              >
                {Object.entries(lessonTypeLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="startAt"
                label="開始時間"
                format="yyyy-MM-dd HH:mm"
                value={addInput.startAt ? addInput.startAt : null}
                onChange={handleChangeAddStartAt}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="endAt"
                label="終了時間"
                format="yyyy-MM-dd HH:mm"
                value={addInput.endAt ? addInput.endAt : null}
                onChange={handleChangeAddEndAt}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="taughtSkill"
            name="taughtSkill"
            label="TaughtSkill"
            type="text"
            defaultValue={addInput.taughtSkill}
            fullWidth
            onChange={handleChangeAddTaughtSkill}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
          />
          <TextField
            autoFocus
            margin="dense"
            id="homework"
            name="homework"
            label="Homework"
            type="text"
            defaultValue={addInput.homework}
            fullWidth
            onChange={handleChangeAddHomework}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
          />
          <TextField
            autoFocus
            margin="dense"
            id="prepare"
            name="prepare"
            label="Prepare"
            type="text"
            defaultValue={addInput.prepare}
            fullWidth
            onChange={handleChangeAddPrepare}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="progress">Progress</InputLabel>
              <Select
                value={addInput.progress}
                onChange={handleChangeAddProgress}
                input={<Input name="progress" id="progress" />}
              >
                {progresses.map((progress) => {
                  return (
                    <MenuItem value={progress.id} key={progress.id}>
                      {progress.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <TextField
            autoFocus
            margin="dense"
            id="delayReason"
            name="delayReason"
            label="DelayReason"
            type="text"
            defaultValue={addInput.delayReason}
            fullWidth
            onChange={handleChangeAddDelayReason}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Assistances</FormLabel>
            <FormGroup>
              {lessonsAssistances.map((assistance) => {
                return (
                  <FormControlLabel
                    key={assistance.id}
                    control={
                      <Checkbox
                        name="assistance"
                        onChange={handleChangeAddLessonsAssistances}
                        value={assistance.id}
                      />
                    }
                    label={assistance.name}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">StudentAssistance</FormLabel>
            <FormGroup>
              {studentAssistanceLabels.map((studentAssistanceLabel: string, index: number) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="studentAssistance"
                        onChange={handleChangeAddStudentAssistance}
                        value={index}
                      />
                    }
                    label={studentAssistanceLabel}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">InstructorAssistance</FormLabel>
            <FormGroup>
              {instructorAssistanceLabels.map(
                (instructorAssistanceLabel: string, index: number) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="instructorAssistance"
                          onChange={handleChangeAddInstructorAssistance}
                          value={index}
                        />
                      }
                      label={instructorAssistanceLabel}
                    />
                  );
                },
              )}
            </FormGroup>
          </FormControl>
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
