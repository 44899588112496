import React, { useState, useCallback, ChangeEvent, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Form
import TextField from '@material-ui/core/TextField';

// Material UI Icon
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { useUpdateUserMutation, useGetUserQuery, AdminUserInput } from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  uploadImageBtn: {
    marginLeft: theme.spacing(2),
  },
}));

// 表示専用Parameter
export interface ReadOnlyParam {
  sub: string;
  email: string;
}

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ user_id: string }>().user_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminUserInput>({
    name: '',
    kanaName: '',
    nickName: '',
    phone: '',
    mobilePhone: '',
    image: undefined,
    skypeID: '',
  });

  const [readOnlyInput, setReadOnlyInput] = useState<ReadOnlyParam>({
    sub: '',
    email: '',
  });

  const { data, loading } = useGetUserQuery({
    variables: {
      id,
    },
    onCompleted: ({ userForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        name: data.personalInfo?.name ?? '',
        kanaName: data.personalInfo?.kanaName ?? '',
        nickName: data.nickName,
        phone: data.personalInfo?.phone,
        mobilePhone: data.personalInfo?.mobilePhone,
        skypeID: data.personalInfo?.skypeID,
      });
      setReadOnlyInput({
        sub: data.personalInfo?.sub ?? '',
        email: data.personalInfo?.email ?? '',
      });
    },
  });
  const currentImage = data?.userForAdmin?.image;

  const imageSrc = useMemo((): string => {
    if (updateInput.image) {
      return URL.createObjectURL(updateInput.image);
    }
    return currentImage ?? '';
  }, [updateInput.image, currentImage]);

  const [updateUser, { error: updateError }] = useUpdateUserMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateUser({
          variables: {
            id: id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateUser, updateInput, id, navigate]),
  );

  const handleChangeAddName = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, name: value }));
  }, []);

  const handleChangeAddKanaName = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, kanaName: value }));
  }, []);

  const handleChangeAddNickName = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, nickName: value }));
  }, []);

  const handleChangeAddPhone = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, phone: value }));
  }, []);

  const handleChangeAddMobilePhone = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({ ...current, mobilePhone: value }));
    },
    [],
  );

  const handleChangeAddSkypeId = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, skypeID: value }));
  }, []);

  const handleImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUpdateInput((current) => ({ ...current, image: e.target.files?.[0] }));
  }, []);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                User Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {showLoader || loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="sub"
                name="sub"
                label="Sub"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={readOnlyInput.sub}
                fullWidth
                style={{ margin: '10px 0' }}
                disabled
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.name}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddName}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="kanaName"
                name="kanaName"
                label="KanaName"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.kanaName}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddKanaName}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="nickName"
                name="nickName"
                label="NickName"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.nickName}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddNickName}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="email"
                name="email"
                label="Email"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={readOnlyInput.email}
                fullWidth
                style={{ margin: '10px 0' }}
                disabled
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="phone"
                name="phone"
                label="Phone"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.phone}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddPhone}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="mobilePhone"
                name="mobilePhone"
                label="MobilePhone"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.mobilePhone}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddMobilePhone}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="image"
                name="image"
                label="Image"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.image}
                fullWidth
                style={{ margin: '10px 0' }}
                disabled
              />
              <Grid container alignItems="flex-start">
                <Grid item>
                  <img width="200px" height="200px" src={imageSrc} alt="ユーザーアイコン" />
                </Grid>
                <Grid item>
                  <input
                    accept="image/*"
                    type="file"
                    id="upload-img"
                    onChange={handleImageChange}
                    style={{ display: 'none' }}
                  />
                  <Button
                    htmlFor="upload-img"
                    component="label"
                    variant="contained"
                    className={classes.uploadImageBtn}
                  >
                    <CloudUploadIcon />
                    &nbsp;UPLOAD
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="skypeId"
                name="skypeID"
                label="SkypeId"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.skypeID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddSkypeId}
              />
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
