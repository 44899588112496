import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles, Theme } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Icon

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetPaymentMethodForAdminQuery } from '../../../gen/graphql';
import { IsDefaultLabels, IsAvailableLabels } from '../../const/PaymentMethod';
import { formatDate } from '../../const/Util';
import { ValidLabels } from '../../const/Valid';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  buttonArea: {
    marginTop: theme.spacing(2),
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ payment_method_id: string }>().payment_method_id;
  const id = Number(paramID);

  const { data, loading } = useGetPaymentMethodForAdminQuery({
    variables: { id },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                PaymentMethod Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Alert severity="error">
          こちらは古いデータのため参照するのは避けてください。Stripeの管理画面、もしくは「課金データ」メニューから最新の情報をご確認ください。
        </Alert>
        <Grid className={classes.buttonArea} container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{data?.getPaymentMethodForAdmin?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  User
                </TableCell>
                <TableCell>
                  <Link to={`/users/${data?.getPaymentMethodForAdmin?.user!.id}`}>
                    {data?.getPaymentMethodForAdmin?.user!.id}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  PaymentType
                </TableCell>
                <TableCell>{data?.getPaymentMethodForAdmin?.paymentType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ProviderPaymentMethodID
                </TableCell>
                <TableCell>{data?.getPaymentMethodForAdmin?.providerPaymentMethodID}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CardNumber
                </TableCell>
                <TableCell>{data?.getPaymentMethodForAdmin?.cardNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CardBrand
                </TableCell>
                <TableCell>{data?.getPaymentMethodForAdmin?.cardBrand}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ExpireYear
                </TableCell>
                <TableCell>{data?.getPaymentMethodForAdmin?.expireYear}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ExpireMonth
                </TableCell>
                <TableCell>{data?.getPaymentMethodForAdmin?.expireMonth}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Selected
                </TableCell>
                <TableCell>
                  {data?.getPaymentMethodForAdmin?.paymentMethodDefault
                    ? IsDefaultLabels.true
                    : IsDefaultLabels.false}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  isAvailable
                </TableCell>
                <TableCell>
                  {data?.getPaymentMethodForAdmin?.isAvailable
                    ? IsAvailableLabels[data.getPaymentMethodForAdmin.isAvailable]
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>
                  {data?.getPaymentMethodForAdmin?.status
                    ? ValidLabels[data.getPaymentMethodForAdmin.status]
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>
                  {data?.getPaymentMethodForAdmin?.createdAt
                    ? formatDate(new Date(data.getPaymentMethodForAdmin.createdAt))
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>
                  {data?.getPaymentMethodForAdmin?.updatedAt
                    ? formatDate(new Date(data.getPaymentMethodForAdmin.updatedAt))
                    : ''}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
    </div>
  );
};
