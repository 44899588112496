import * as yup from 'yup';

import { PracticeSubmitDetailInput, PracticeSubmitDetailReviewStatus, Valid } from '../gen/graphql';

export const practiceSubmitDetailSchema = yup.object({
  practiceSubmitID: yup.string().required().max(100).trim(),
  url: yup.string().nullable().max(255).trim(),
  content: yup.string().nullable().max(16383).trim(),
  reviewStatus: yup.string().required().oneOf(Object.values(PracticeSubmitDetailReviewStatus)),
  status: yup.string().required().oneOf(Object.values(Valid)),
});

export const PracticeSubmitDetailDefaultValues: PracticeSubmitDetailInput = {
  practiceSubmitID: '',
  url: '',
  content: '',
  reviewStatus: PracticeSubmitDetailReviewStatus.WaitingForInstructorReview,
  status: Valid.Valid,
};
