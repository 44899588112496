import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ApplyStatusLabels } from '../../const/Claim';
import { useGetClaimForAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ claim_id: string }>().claim_id;
  const id = Number(paramID);

  const { data, loading } = useGetClaimForAdminQuery({
    variables: { id },
  });
  const claim = data?.claimForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Claim Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/claims/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{claim?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Instructor
                </TableCell>
                <TableCell>
                  <Link to={`/instructors/${claim?.instructor!.id}`}>
                    {claim?.instructor?.user.personalInfo?.name}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Month
                </TableCell>
                <TableCell>{claim?.month}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Remark
                </TableCell>
                <TableCell>{claim?.remark}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ApplyStatus
                </TableCell>
                <TableCell>
                  {claim?.applyStatus ? ApplyStatusLabels[claim?.applyStatus] : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  AppliedAt
                </TableCell>
                <TableCell>{claim?.appliedAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ClaimDetails
                </TableCell>
                <TableCell>
                  <Link to={`/claimDetails?claimID=${claim?.id}`}>ClaimDetails</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>{claim?.status ? ValidLabels[claim.status] : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{claim?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{claim?.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
    </div>
  );
};
