export const ClaimCategoryLabels: { [index: number]: string } = {
  1: 'レッスン',
  2: 'レッスン前後対応',
  3: '質問対応(担当受講生)',
  4: 'カリキュラム作成',
  5: 'レッスンキャンセル',
  6: 'キャッチアップ',
  7: 'コンサル相談',
  8: 'メンタリング',
  9: '質問対応(Q&Aサイト)',
  10: '運営側依頼対応',
  11: '書籍(教材)費用',
  12: 'リファラルインセンティブ',
  13: 'その他',
  14: '教材開発',
  15: '単発レッスン',
  16: 'タイムライン投稿',
  17: '課題レビュー時給制',
  18: 'リスキルカレッジ対応',
  19: 'シゴトル対応',
  20: '課題レビュー単価制',
  21: '法人側依頼対応',
  22: '道場レッスン',
  23: '常駐レッスン対応',
  24: '道場コンテンツ開発',
};
