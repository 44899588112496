import React, { useCallback, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Snackbar, Toolbar } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Icon
// Material UI Other
import Typography from '@material-ui/core/Typography';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  Bool,
  SubscriptionPhase,
  useGetSubscriptionQuery,
  useUpdateSubscriptionCancellationStatusMutation,
  Valid,
} from '../../../gen/graphql';
import { CancelAtPeriodEndLabels, PhaseLabels } from '../../const/Subscription';
import { ValidLabels } from '../../const/Valid';
import { isNonEmpty } from 'fp-ts/Array';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    overflow: 'hidden',
    margin: '24px 0',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  buttonArea: {
    marginTop: theme.spacing(2),
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ subscription_id: string }>().subscription_id;
  const id = Number(paramID);

  const {
    data,
    loading: fetching,
    refetch,
  } = useGetSubscriptionQuery({
    variables: { id },
    notifyOnNetworkStatusChange: true,
  });
  const subscription = data?.subscriptionForAdmin;
  const items = data?.subscriptionForAdmin?.subscriptionItems ?? [];
  const histories = data?.subscriptionForAdmin?.subscriptionHistories ?? [];
  const contractPeriod = data?.subscriptionForAdmin?.subscriptionContractPeriod;
  const contractPeriodHistories =
    data?.subscriptionForAdmin?.subscriptionContractPeriodHistories ?? [];

  const [updateCancellationStatusMutation, { loading: updating }] =
    useUpdateSubscriptionCancellationStatusMutation();

  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [cancelDialogIsOpen, setCancelDialogIsOpen] = React.useState(false);

  const openCancelDialog = useCallback((): void => setCancelDialogIsOpen(true), []);
  const closeCancelDialog = useCallback((): void => setCancelDialogIsOpen(false), []);

  const isCancelable = useMemo((): boolean => {
    if (!subscription) {
      return false;
    }

    return (
      subscription.status === Valid.Valid &&
      [
        SubscriptionPhase.Incomplete,
        SubscriptionPhase.Trialing,
        SubscriptionPhase.Active,
        SubscriptionPhase.PastDue,
      ].includes(subscription.phase)
    );
  }, [subscription]);

  const updateCancellationStatus = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setSnackBarMessage('処理中...');
      try {
        await updateCancellationStatusMutation({ variables: { id } });
        await refetch();
      } catch {
        setSnackBarMessage('エラーが発生しました。管理者にお問い合わせください。');
        return;
      }
      setSnackBarMessage('処理は正常に完了しました');
      closeCancelDialog();
    }, [closeCancelDialog, id, refetch, updateCancellationStatusMutation]),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Subscription Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(fetching || updating) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Alert severity="error">
          こちらは古いデータのため参照するのは避けてください。Stripeの管理画面、もしくは「課金データ」メニューから最新の情報をご確認ください。
        </Alert>
        <Grid className={classes.buttonArea} container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        {subscription && (
          <>
            <Paper className={classes.paperContent}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>項目</TableCell>
                    <TableCell>値</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      ID
                    </TableCell>
                    <TableCell>{subscription.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      User
                    </TableCell>
                    <TableCell>
                      <Link to={`/users/${subscription.user.id}`}>
                        {subscription.user.personalInfo?.name}
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      ProviderSubscriptionID
                    </TableCell>
                    <TableCell>{subscription.providerSubscriptionID}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      StartDate
                    </TableCell>
                    <TableCell>{subscription.startDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      EndDate
                    </TableCell>
                    <TableCell>{subscription.endDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Phase
                    </TableCell>
                    <TableCell>{PhaseLabels[subscription.phase]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      CancelAtPeriodEnd
                    </TableCell>
                    <TableCell>{CancelAtPeriodEndLabels[subscription.cancelAtPeriodEnd]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Status
                    </TableCell>
                    <TableCell>{ValidLabels[subscription.status]}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      解約処理
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ margin: '20px 0 0 0' }}
                        disabled={!isCancelable}
                        onClick={openCancelDialog}
                      >
                        {subscription.cancelAtPeriodEnd === Bool.False
                          ? '解約する'
                          : '解約を取り消す'}
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      CreatedAt
                    </TableCell>
                    <TableCell>{subscription.createdAt}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      UpdatedAt
                    </TableCell>
                    <TableCell>{subscription.updatedAt}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
            {isNonEmpty(items) && (
              <>
                <Typography color="inherit" variant="h6" component="h2">
                  Subscription Items
                </Typography>
                <Paper className={classes.paper}>
                  <Grid style={{ overflowX: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ProductID</TableCell>
                          <TableCell align="right" style={{ minWidth: 200 }}>
                            購入商品名
                          </TableCell>
                          <TableCell align="right">PriceID</TableCell>
                          <TableCell align="right">ProviderSubscriptionItemID</TableCell>
                          <TableCell align="right">ProductStatus</TableCell>
                          <TableCell align="right">ProductCreatedAt</TableCell>
                          <TableCell align="right">ProductUpdatedAt</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {items.map((item) => (
                          <TableRow key={item.id}>
                            <TableCell scope="row">
                              <Link to={`/products/${item.price.product.id}`}>
                                {item.price.product.id}
                              </Link>
                            </TableCell>
                            <TableCell scope="row">{item.price.product.name}</TableCell>
                            <TableCell align="right">
                              <Link to={`/prices/${item.price.id}`}>{item.price.id}</Link>
                            </TableCell>
                            <TableCell align="right">{item.providerSubscriptionItemID}</TableCell>
                            <TableCell align="right">
                              {ValidLabels[item.price.product.status]}
                            </TableCell>
                            <TableCell align="right">{item.price.product.createdAt}</TableCell>
                            <TableCell align="right">{item.price.product.updatedAt}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Paper>
              </>
            )}
            {isNonEmpty(histories) && (
              <>
                <Typography color="inherit" variant="h6" component="h2">
                  Subscription Histories
                </Typography>
                <Paper className={classes.paper}>
                  <Grid style={{ overflowX: 'auto' }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell align="right" style={{ minWidth: 150 }}>
                            UserID
                          </TableCell>
                          <TableCell align="right">SubscriptionID</TableCell>
                          <TableCell align="right">StartDate</TableCell>
                          <TableCell align="right">EndDate</TableCell>
                          <TableCell align="right">Status</TableCell>
                          <TableCell align="right">CreatedAt</TableCell>
                          <TableCell align="right">UpdatedAt</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {histories.map((history) => (
                          <TableRow key={history.id}>
                            <TableCell scope="row">
                              <Link to={`/subscriptionHistories/${history.id}`}>{history.id}</Link>
                            </TableCell>
                            <TableCell scope="row">
                              <Link to={`/users/${history.user.id}`}>
                                {history.user.personalInfo?.name}
                              </Link>
                            </TableCell>
                            <TableCell scope="row">
                              <Link to={`/subscriptions/${subscription.id}`}>
                                {subscription.id}
                              </Link>
                            </TableCell>
                            <TableCell scope="row">{history.startDate}</TableCell>
                            <TableCell scope="row">{history.endDate}</TableCell>
                            <TableCell align="right">{history.status ? '有効' : '無効'}</TableCell>
                            <TableCell align="right">{history.createdAt}</TableCell>
                            <TableCell align="right">{history.updatedAt}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Paper>
              </>
            )}
            {contractPeriod && (
              <>
                <Typography color="inherit" variant="h6" component="h2">
                  Subscription Contract Period
                </Typography>
                <Paper className={classes.paperContent}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>項目</TableCell>
                        <TableCell>値</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          StartDate
                        </TableCell>
                        <TableCell>{contractPeriod.startDate}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          EndDate
                        </TableCell>
                        <TableCell>{contractPeriod.endDate}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          CreatedAt
                        </TableCell>
                        <TableCell>{contractPeriod.createdAt}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          UpdatedAt
                        </TableCell>
                        <TableCell>{contractPeriod.updatedAt}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Paper>
              </>
            )}
            {isNonEmpty(contractPeriodHistories) && (
              <>
                <Typography color="inherit" variant="h6" component="h2">
                  Subscription Contract Period Histories
                </Typography>
                <Paper className={classes.paperContent}>
                  <Grid>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell align="right">StartDate</TableCell>
                          <TableCell align="right">EndDate</TableCell>
                          <TableCell align="right">CreatedAt</TableCell>
                          <TableCell align="right">UpdatedAt</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contractPeriodHistories.map((row) => (
                          <TableRow key={row.id}>
                            <TableCell component="th" scope="row">
                              {row.id}
                            </TableCell>
                            <TableCell align="right">{row.startDate}</TableCell>
                            <TableCell align="right">{row.endDate}</TableCell>
                            <TableCell align="right">{row.createdAt}</TableCell>
                            <TableCell align="right">{row.updatedAt}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Paper>
              </>
            )}
            <Dialog
              open={cancelDialogIsOpen}
              onClose={closeCancelDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {subscription?.cancelAtPeriodEnd === Bool.False
                    ? 'このSubscriptionを解約します'
                    : 'このSubscriptionの解約を取り消します'}
                  、よろしいですか？
                  <br />
                  (この操作は取り消し可能です)
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeCancelDialog} color="primary">
                  キャンセル
                </Button>
                <Button onClick={updateCancellationStatus} color="primary" autoFocus>
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </main>
      <Snackbar
        open={snackBarMessage !== ''}
        autoHideDuration={6000}
        onClose={() => setSnackBarMessage('')}
        message={snackBarMessage}
      />
    </div>
  );
};
