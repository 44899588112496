import React, { useCallback, useState } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Box, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Original Component
import { ImageField } from '../../common/ImageField';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminProgramSampleInput,
  Valid,
  useGetProgramSampleForAdminQuery,
  useUpdateProgramSampleForAdminMutation,
} from '../../../gen/graphql';

import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';
import { ValidLabels } from '../../const/Valid';
import { strToEnum } from '../../../utils/common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ program_sample_id: string }>().program_sample_id ?? '0';

  // Loading
  const [showLoader, setShowLoader] = useState(false);
  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminProgramSampleInput>({
    programID: 0,
    number: 0,
    title: '',
    description: '',
    thumbnail: '',
    url: '',
    status: Valid.Valid,
  });

  const { loading } = useGetProgramSampleForAdminQuery({
    variables: { id },
    onCompleted: ({ getProgramSampleForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        programID: data.program.id,
        number: data.number,
        title: data.title,
        description: data.description,
        thumbnail: data.thumbnail,
        url: data.url,
        status: data.status,
      });
    },
  });
  const [updateProgramSample, { error: updateError }] = useUpdateProgramSampleForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateProgramSample({
          variables: {
            id,
            input: {
              ...updateInput,
            },
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateProgramSample, updateInput, id, navigate]),
  );

  const handleChangeProgramID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, programID: parseInt(value) }));
  }, []);

  const handleChangeNumber = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, number: parseInt(value) }));
  }, []);

  const handleChangeTitle = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, title: value }));
  }, []);

  const handleChangeDescription = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, description: value }));
  }, []);

  const handleImageUploaded = (name: string, path: string) => {
    setUpdateInput((current) => ({
      ...current,
      [name]: path,
    }));
  };

  const handleChangeURL = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, url: value }));
  }, []);

  const handleChangeStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToEnum(String(value), Valid) ?? Valid.Valid,
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                ProgramSample Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="programID"
                name="programID"
                label="ProgramID"
                type="number"
                placeholder="1"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.programID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeProgramID}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="number"
                name="number"
                label="Number"
                type="number"
                placeholder="1"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.number}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeNumber}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                placeholder="ProgramSample Title"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.title}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeTitle}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                placeholder="ProgramSample Description"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.description}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeDescription}
              />
            </div>
            <div>
              <ImageField
                name="thumbnail"
                path={updateInput?.thumbnail ?? ''}
                width={600}
                height={300}
                onImageUploaded={handleImageUploaded}
                setShowLoader={setShowLoader}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="url"
                name="url"
                label="URL"
                type="text"
                placeholder="ProgramSample URL"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.url}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeURL}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput.status}
                  onChange={handleChangeStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
