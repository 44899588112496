import { Bool } from '../../gen/graphql';

export const PvdActiveLabels: { [index: string]: string } = {
  [Bool.False]: '無効',
  [Bool.True]: '有効',
} as const;

export const PvdRestrictionsFirstTimeTransactionLabels: { [index: string]: string } = {
  [Bool.False]: '-',
  [Bool.True]: '初回のみ利用可',
} as const;
