import React, { useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';
import * as Sentry from '@sentry/browser';

import {
  AdminChatMessageSearchInput,
  useGetChatMessagesForAdminQuery,
  Valid,
} from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Grid, Paper } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import TablePagination from '@material-ui/core/TablePagination';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { SearchForm } from './SearchForm';
import { strToValid, ValidLabels } from '../../const/Valid';
import { formatRequestDate } from '../../const/Util';
import { CsvRepositoryOnAPI } from '../../../infrastructure/csv/CsvRepositoryOnAPI';
import { PageHeader } from '../../common/PageHeader';
import { yupResolver } from '@hookform/resolvers/yup';
import { chatMessageSearchSchema, SearchChatMessage } from '../../../formSchema/chatMessage';

interface CurrentSearchParams extends SearchChatMessage {
  limit?: number;
  page?: number;
}

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  csvBtn: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 99,
    backgroundColor: '#009be5',
    color: 'white',
    padding: '10px 13px',
    textDecoration: 'none',
    borderRadius: '10px',
    boxShadow: '3px 3px 4px black',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const csvRepository = new CsvRepositoryOnAPI();

  const currentSearchParams = useMemo((): CurrentSearchParams => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const chatRoomId = params.get('chatRoomID') ?? '';
    const userId = params.get('userID') ?? '';
    const periodStart = params.get('periodStart') ?? '';
    const periodEnd = params.get('periodEnd') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      chatRoomID: parseInt(chatRoomId) > 0 ? parseInt(chatRoomId) : undefined,
      userID: parseInt(userId) > 0 ? parseInt(userId) : undefined,
      periodStart: periodStart ? new Date(periodStart) : undefined,
      periodEnd: periodEnd ? new Date(periodEnd) : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminChatMessageSearchInput): void => {
      navigate({
        pathname: '/chatMessages',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const shapeToGetChatMessagesForAdminQuery = (
    params: CurrentSearchParams,
  ): AdminChatMessageSearchInput => {
    return {
      chatRoomID: params.chatRoomID,
      id: params.id ?? undefined,
      limit: params.limit ?? FETCH_ROWS_LIMIT,
      page: params.page ?? 1,
      periodEnd: params.periodEnd ? formatRequestDate(params.periodEnd) : undefined,
      periodStart: params.periodStart ? formatRequestDate(params.periodStart) : undefined,
      status: params.status ?? undefined,
      userID: params.userID ?? undefined,
    };
  };

  const { data, loading, refetch } = useGetChatMessagesForAdminQuery({
    variables: {
      input: shapeToGetChatMessagesForAdminQuery(currentSearchParams),
    },
  });

  const searchForm = useForm<SearchChatMessage>({
    resolver: yupResolver(chatMessageSearchSchema),
    defaultValues: {
      id: currentSearchParams.id,
      chatRoomID: currentSearchParams.chatRoomID,
      userID: currentSearchParams.userID,
      periodStart: currentSearchParams.periodStart,
      periodEnd: currentSearchParams.periodEnd,
      status: currentSearchParams.status,
    },
  });

  // 検索
  const searchChatMessages = useCallback(
    async (input: SearchChatMessage): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };

      if (input.periodStart) {
        params.periodStart = input.periodStart;
      }

      if (input.periodEnd) {
        params.periodEnd = input.periodEnd;
      }

      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: shapeToGetChatMessagesForAdminQuery(params) });
      }
      createHistory(shapeToGetChatMessagesForAdminQuery(params));
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({
        ...shapeToGetChatMessagesForAdminQuery(currentSearchParams),
        page: page + 1,
      });
    },
    [currentSearchParams, createHistory],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({
        ...shapeToGetChatMessagesForAdminQuery(currentSearchParams),
        limit: perPage,
        page: 1,
      });
    },
    [currentSearchParams, createHistory],
  );

  const csvFileDownload = () => {
    const query = queryString.stringify({
      id: currentSearchParams.id,
      chatRoomID: currentSearchParams.chatRoomID,
      userID: currentSearchParams.userID,
      periodStart: currentSearchParams.periodStart,
      periodEnd: currentSearchParams.periodEnd,
      status:
        currentSearchParams.status === Valid.Valid
          ? '1'
          : currentSearchParams.status === Valid.Invalid
            ? '0'
            : '',
      limit: currentSearchParams.limit,
      page: currentSearchParams.page,
    });
    const url = `/api/v1/admin/csv/chat_messages?${query}`;

    csvRepository.getCsv('chat_messages', url).catch((error) => {
      Sentry.captureException(error);
    });
  };

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <PageHeader title="ChatMessage" />
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={searchChatMessages} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">ChatRoom</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.chatMessagesForAdmin?.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link to={`/chatMessages/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/chatRooms/${row.chatRoom.id}`}>{row.chatRoom.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/users/${row.user.id}`}>{row.user.personalInfo?.name}</Link>
                    </TableCell>
                    <TableCell align="right">{row.content ?? '-'}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.chatMessagesForAdmin?.total ?? 0}
            rowsPerPage={currentSearchParams.limit ?? FETCH_ROWS_LIMIT}
            page={currentSearchParams.page ? currentSearchParams.page - 1 : 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <button onClick={csvFileDownload} id="csvLink" className={classes.csvBtn}>
        CSV出力
      </button>
    </div>
  );
};
