import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import TimerIcon from '@material-ui/icons/Timer';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import ForumIcon from '@material-ui/icons/Forum';
import NoteIcon from '@material-ui/icons/Note';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import MailIcon from '@material-ui/icons/Mail';
import MessageIcon from '@material-ui/icons/Message';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import RateReviewIcon from '@material-ui/icons/RateReview';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PublishIcon from '@material-ui/icons/Publish';

const categories = [
  {
    id: 'お知らせ',
    children: [{ id: 'Notice', icon: <ContactSupportIcon />, active: false, path: '/notices' }],
  },
  {
    id: 'ユーザーデータ',
    children: [
      { id: 'ユーザー一覧', icon: <PeopleIcon />, active: false, path: '/users' },
      { id: '生徒一覧', icon: <PeopleIcon />, active: false, path: '/students' },
      { id: '講師一覧', icon: <PeopleIcon />, active: false, path: '/instructors' },
      { id: '管理者一覧', icon: <PeopleIcon />, active: false, path: '/admins' },
    ],
  },
  {
    id: 'メール設定',
    children: [{ id: 'MailSetting', icon: <MailIcon />, active: false, path: '/mailSettings' }],
  },
  {
    id: 'インスト請求データ',
    children: [
      {
        id: 'Claim',
        icon: <ReceiptIcon />,
        active: false,
        path: '/claims',
      },
      { id: 'ClaimDetail', icon: <QueryBuilderIcon />, active: false, path: '/claimDetails' },
    ],
  },
  {
    id: '契約データ',
    children: [
      { id: 'Course', icon: <InsertDriveFileIcon />, active: false, path: '/courses' },
      { id: 'Plan', icon: <InsertDriveFileIcon />, active: false, path: '/plans' },
      { id: 'Lesson', icon: <ForumIcon />, active: false, path: '/lessons' },
      { id: 'Session', icon: <ForumIcon />, active: false, path: '/sessions' },
      { id: 'SessionReport', icon: <ForumIcon />, active: false, path: '/sessionReports' },
      { id: 'Coaching', icon: <ForumIcon />, active: false, path: '/coachings' },
      {
        id: 'TermChangeLog',
        icon: <TimerIcon />,
        active: false,
        path: '/termChangeLogs',
      },
      {
        id: 'CoachingAssignLog',
        icon: <AssignmentIndIcon />,
        active: false,
        path: '/coachingAssignLogs',
      },
      { id: 'AssignLog', icon: <AssignmentIndIcon />, active: false, path: '/assignLogs' },
    ],
  },
  {
    id: '課金データ',
    children: [
      {
        id: 'StripeCustomer',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/stripeCustomers',
      },
      {
        id: 'StripeSubscriptions',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/stripeSubscriptions',
      },
      {
        id: 'SubscriptionContract',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/subscriptionContracts',
      },
      {
        id: 'PaymentIntent',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/paymentIntents',
      },
      { id: 'Product', icon: <InsertDriveFileIcon />, active: false, path: '/products' },
      { id: 'Price', icon: <InsertDriveFileIcon />, active: false, path: '/prices' },
      { id: 'Ticket', icon: <InsertDriveFileIcon />, active: false, path: '/tickets' },
    ],
  },
  {
    id: '教材データ',
    children: [
      { id: 'Program', icon: <NoteIcon />, active: false, path: '/programs' },
      { id: 'Chapter', icon: <NoteIcon />, active: false, path: '/chapters' },
      { id: 'CourseProgram', icon: <NoteIcon />, active: false, path: '/coursePrograms' },
      { id: 'CourseProgramStep', icon: <NoteIcon />, active: false, path: '/courseProgramSteps' },
      { id: 'ProgramSample', icon: <NoteIcon />, active: false, path: '/programSamples' },
    ],
  },
  {
    id: '課題管理',
    children: [
      { id: 'Practice', icon: <AssignmentIndIcon />, active: false, path: '/practices' },
      { id: 'PracticeSubmit', icon: <PublishIcon />, active: false, path: '/practiceSubmits' },
      {
        id: 'PracticeSubmitDetail',
        icon: <PublishIcon />,
        active: false,
        path: '/practiceSubmitDetails',
      },
    ],
  },
  {
    id: '質問データ',
    children: [
      {
        id: 'Tag',
        icon: <QuestionAnswerIcon />,
        active: false,
        path: '/tags',
      },
      { id: 'Question', icon: <LiveHelpIcon />, active: false, path: '/questions' },
      {
        id: 'QuestionAnswer',
        icon: <QuestionAnswerIcon />,
        active: false,
        path: '/questionAnswers',
      },
      {
        id: 'QuestionComment',
        icon: <QuestionAnswerIcon />,
        active: false,
        path: '/questionComments',
      },
    ],
  },
  {
    id: 'チャットデータ',
    children: [
      { id: 'ChatRoom', icon: <MeetingRoomIcon />, active: false, path: '/chatRooms' },
      {
        id: 'ChatMessage',
        icon: <MessageIcon />,
        active: false,
        path: '/chatMessages',
      },
    ],
  },
  {
    id: '学習ログ',
    children: [
      { id: 'StudyReport', icon: <RateReviewIcon />, active: false, path: '/studyReports' },
      {
        id: 'StudyReportDetail',
        icon: <RateReviewIcon />,
        active: false,
        path: '/studyReportDetails',
      },
      {
        id: 'StudyReportComment',
        icon: <RateReviewIcon />,
        active: false,
        path: '/studyReportComments',
      },
      {
        id: 'StudyTagSummary',
        icon: <RateReviewIcon />,
        active: false,
        path: '/studyTagSummaries',
      },
      {
        id: 'StudyFavoriteSummary',
        icon: <RateReviewIcon />,
        active: false,
        path: '/studyFavoriteSummaries',
      },
      { id: 'StudySummary', icon: <RateReviewIcon />, active: false, path: '/studySummaries' },
    ],
  },
  {
    id: 'バナー管理',
    children: [
      { id: 'Banner', icon: <AttachFileIcon />, active: false, path: '/banners' },
      { id: 'BannerCid', icon: <AttachFileIcon />, active: false, path: '/banner_cids' },
    ],
  },
  {
    id: 'キャンペーン管理',
    children: [{ id: 'Campaign', icon: <AttachFileIcon />, active: false, path: '/campaigns' }],
  },
  {
    id: 'ABテスト',
    children: [{ id: 'ABtest', icon: <AttachFileIcon />, active: false, path: '/ab_tests' }],
  },
  {
    id: 'クーポン管理',
    children: [
      { id: 'SpCoupon', icon: <AttachFileIcon />, active: false, path: '/spCoupons' },
      { id: 'SpPromotionCode', icon: <AttachFileIcon />, active: false, path: '/spPromotionCodes' },
      { id: 'SpDiscount', icon: <AttachFileIcon />, active: false, path: '/spDiscounts' },
      {
        id: 'SpDiscountCoupon',
        icon: <AttachFileIcon />,
        active: false,
        path: '/spDiscountCoupons',
      },
      {
        id: 'PromotionCodeTransaction',
        icon: <AttachFileIcon />,
        active: false,
        path: '/promotionCodeTransactions',
      },
    ],
  },
  {
    id: 'インストラクターシフト管理',
    children: [
      {
        id: 'InstructorSchedule',
        icon: <ReceiptIcon />,
        active: false,
        path: '/instructorSchedules',
      },
    ],
  },
  {
    id: 'スポットレッスン管理',
    children: [{ id: 'SpotLesson', icon: <ReceiptIcon />, active: false, path: '/spotLessons' }],
  },
  {
    id: 'タイムライン投稿管理',
    children: [
      { id: 'Tweet', icon: <ReceiptIcon />, active: false, path: '/tweets' },
      { id: 'TweetComment', icon: <ReceiptIcon />, active: false, path: '/tweetComments' },
    ],
  },
  {
    id: 'チーム管理',
    children: [
      { id: 'Team', icon: <ReceiptIcon />, active: false, path: '/teams' },
      { id: 'TeamMember', icon: <ReceiptIcon />, active: false, path: '/teamMembers' },
    ],
  },
  {
    id: 'ログデータ',
    children: [
      {
        id: 'LessonQuestionnaireSendLog',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/lessonQuestionnaireSendLogs',
      },
      {
        id: 'GraduationDateNotificationSendLog',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/graduationDateNotificationSendLogs',
      },
    ],
  },
  {
    id: '旧サブスクリプション関連データ',
    children: [
      { id: 'Invoice ※参照禁止', icon: <InsertDriveFileIcon />, active: false, path: '/invoices' },
      {
        id: 'InvoiceItem ※参照禁止',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/invoiceItems',
      },
      {
        id: 'Subscription ※参照禁止',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/subscriptions',
      },
      {
        id: 'SubscriptionHistory ※参照禁止',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/subscriptionHistories',
      },
      {
        id: 'PaymentMethod ※参照禁止',
        icon: <InsertDriveFileIcon />,
        active: false,
        path: '/paymentMethods',
      },
    ],
  },
];

const useStyles = makeStyles((theme: Theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
}));

export const Navigator = (props: DrawerProps): JSX.Element => {
  const classes = useStyles();

  const [openId, setOpenId] = React.useState('問い合わせ');

  function handleClick(id: string) {
    setOpenId(id);
  }

  return (
    <Drawer variant="permanent" {...props}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          侍テラコヤ
        </ListItem>
        <Link to="/">
          <ListItem className={clsx(classes.item, classes.itemCategory)} button>
            <ListItemIcon className={classes.itemIcon}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              Project Overview
            </ListItemText>
          </ListItem>
        </Link>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem
              button
              className={classes.categoryHeader}
              onClick={() => {
                handleClick(id);
              }}
            >
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
              {openId === id ? <ExpandLess htmlColor="white" /> : <ExpandMore htmlColor="white" />}
            </ListItem>
            <Collapse in={openId === id} timeout="auto" unmountOnExit>
              {children.map(({ id: childId, icon, active, path }) => (
                <Link to={path} key={childId}>
                  <ListItem
                    key={childId}
                    button
                    className={clsx(classes.item, active && classes.itemActiveItem)}
                  >
                    <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                    <ListItemText
                      classes={{
                        primary: classes.itemPrimary,
                      }}
                    >
                      {childId}
                    </ListItemText>
                  </ListItem>
                </Link>
              ))}
            </Collapse>
            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};
