import React, { useState, useCallback } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Grid, makeStyles } from '@material-ui/core';

// Material UI Tab
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Switch from '@material-ui/core/Switch';
import Card from '@material-ui/core/Card';

// Other
import { Link } from 'react-router-dom';
import {
  MailSettingInput,
  useGetMailSettingQuery,
  useUpdateMailSettingMutation,
  Valid,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  uploadImageBtn: {
    marginLeft: theme.spacing(2),
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  // Fetch Data
  const [currentMailSettings, setCurrentMailSettings] = useState<MailSettingInput>({
    attendanceStatusChanged: Valid.Valid,
    backSchool: Valid.Valid,
    claimApplied: Valid.Valid,
    lessonLogUnregistered: Valid.Valid,
    lessonRegistered: Valid.Valid,
    lessonRemind: Valid.Valid,
    lessonScheduleUpdated: Valid.Valid,
    lessonUnregistered: Valid.Valid,
    messageReceived: Valid.Valid,
    notice: Valid.Valid,
    practiceSubmitRegistered: Valid.Valid,
    practiceSubmitReRegistered: Valid.Valid,
    practiceSubmitDetailReviewCommentRegistered: Valid.Valid,
    questionAnswerRegistered: Valid.Valid,
    questionCommentRegistered: Valid.Valid,
    questionRegistered: Valid.Valid,
    sessionRemind: Valid.Valid,
    spotLessonCancel: Valid.Valid,
    spotLessonLogUnregistered: Valid.Valid,
    spotLessonRegistered: Valid.Valid,
    spotLessonRemind: Valid.Valid,
    studyReportAchieved: Valid.Valid,
    studyReportCommentRegistered: Valid.Valid,
    ticketRemind: Valid.Valid,
  });
  const { loading: queryLoading } = useGetMailSettingQuery({
    onCompleted: ({ getMailSetting: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setCurrentMailSettings({
        attendanceStatusChanged: data.attendanceStatusChanged,
        backSchool: data.backSchool,
        claimApplied: data.claimApplied,
        lessonLogUnregistered: data.lessonLogUnregistered,
        lessonRegistered: data.lessonRegistered,
        lessonRemind: data.lessonRemind,
        lessonScheduleUpdated: data.lessonScheduleUpdated,
        lessonUnregistered: data.lessonUnregistered,
        messageReceived: data.messageReceived,
        notice: data.notice,
        practiceSubmitRegistered: data.practiceSubmitRegistered,
        practiceSubmitReRegistered: data.practiceSubmitReRegistered,
        practiceSubmitDetailReviewCommentRegistered:
          data.practiceSubmitDetailReviewCommentRegistered,
        questionAnswerRegistered: data.questionAnswerRegistered,
        questionCommentRegistered: data.questionCommentRegistered,
        questionRegistered: data.questionRegistered,
        sessionRemind: data.sessionRemind,
        spotLessonCancel: data.spotLessonCancel,
        spotLessonLogUnregistered: data.spotLessonLogUnregistered,
        spotLessonRegistered: data.spotLessonRegistered,
        spotLessonRemind: data.spotLessonRemind,
        studyReportAchieved: data.studyReportAchieved,
        studyReportCommentRegistered: data.studyReportCommentRegistered,
        ticketRemind: data.ticketRemind,
      });
    },
  });
  const [updateMailSetting, { loading: mutationLoading }] = useUpdateMailSettingMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (!currentMailSettings) return;
    const mailSettings = {
      ...currentMailSettings,
      [e.target.name]: e.target.checked ? Valid.Valid : Valid.Invalid,
    };
    setCurrentMailSettings(mailSettings);
    putMailSettings(mailSettings);
  };

  const putMailSettings = useSafeAsyncCallback(
    useCallback(
      async (input: MailSettingInput): Promise<void> => {
        try {
          updateMailSetting({ variables: { input } });
        } catch {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          return;
        }
      },
      [updateMailSetting],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Account
              </Typography>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" color="primary" position="static" elevation={1}>
        <Tabs value={1} textColor="inherit">
          <Link to="/profile">
            <Tab textColor="inherit" label="Profile" />
          </Link>
          <Link to="/mailSetting">
            <Tab textColor="inherit" label="MailSetting" />
          </Link>
        </Tabs>
        {queryLoading || mutationLoading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Card>
          <List subheader={<ListSubheader>メール送信設定</ListSubheader>}>
            <ListItem>
              <ListItemText primary="メッセージ受信" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleChange}
                  value={currentMailSettings?.messageReceived}
                  checked={currentMailSettings?.messageReceived === Valid.Valid}
                  inputProps={{ name: 'messageReceived' }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="質問の登録" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleChange}
                  value={currentMailSettings?.questionRegistered}
                  checked={currentMailSettings?.questionRegistered === Valid.Valid}
                  inputProps={{ name: 'questionRegistered' }}
                />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText primary="請求の申請" />
              <ListItemSecondaryAction>
                <Switch
                  edge="end"
                  onChange={handleChange}
                  value={currentMailSettings?.claimApplied}
                  checked={currentMailSettings?.claimApplied === Valid.Valid}
                  inputProps={{ name: 'claimApplied' }}
                />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Card>
      </main>
    </div>
  );
};
