import {
  QuestionAnswerEvaluationType,
  QuestionAnswerStatus,
  QuestionCommentStatus,
  QuestionStatus,
} from '../../gen/graphql';

export const QuestionSolvedLabels = {
  // booleanをキーにするとindexアクセスできない
  [false.toString()]: '未解決',
  [true.toString()]: '解決済み',
} as const;

export const QuestionStatusLabels = {
  [QuestionStatus.Draft]: '下書き',
  [QuestionStatus.Invalid]: '無効',
  [QuestionStatus.Valid]: '有効',
  [QuestionStatus.Hide]: '非表示',
} as const;

export const strToQuestionStatus = (str: string): QuestionStatus | undefined => {
  return Object.values(QuestionStatus).find((v) => v === str);
};

export const QuestionAnswerStatusLabels = {
  [QuestionAnswerStatus.Invalid]: '無効',
  [QuestionAnswerStatus.Valid]: '有効',
  [QuestionAnswerStatus.Hide]: '非表示',
} as const;

export const strToQuestionAnswerStatus = (str: string): QuestionAnswerStatus | undefined => {
  return Object.values(QuestionAnswerStatus).find((v) => v === str);
};

export const QuestionCommentStatusLabels = {
  [QuestionCommentStatus.Invalid]: '無効',
  [QuestionCommentStatus.Valid]: '有効',
  [QuestionCommentStatus.Hide]: '非表示',
} as const;

export const strToQuestionCommentStatus = (str: string): QuestionCommentStatus | undefined => {
  return Object.values(QuestionCommentStatus).find((v) => v === str);
};

export const QuestionAnswerEvaluationTypeLabels = {
  [QuestionAnswerEvaluationType.Bad]: 'よくなかった',
  [QuestionAnswerEvaluationType.Good]: '良かった',
} as const;
