import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetQuestionForAdminQuery } from '../../../gen/graphql';
import {
  QuestionAnswerEvaluationTypeLabels,
  QuestionSolvedLabels,
  QuestionStatusLabels,
} from '../../const/Question';
import { formatDate } from '../../const/Util';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ question_id: string }>().question_id;
  const id = Number(paramID);

  const { data, loading } = useGetQuestionForAdminQuery({
    variables: { id },
  });
  const question = data?.getQuestionForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Question Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/questions/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          {question && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{question.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/${question.user.id}`}>
                      {question.user.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Title
                  </TableCell>
                  <TableCell>{question.title}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Content
                  </TableCell>
                  <TableCell>
                    <ReactMarkdown children={question.content} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Solved
                  </TableCell>
                  <TableCell>{QuestionSolvedLabels[question.solved.toString()]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SolvedAt
                  </TableCell>
                  <TableCell>
                    {question.solvedAt && formatDate(new Date(question.solvedAt))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    EditedAt
                  </TableCell>
                  <TableCell>
                    {question.editedAt && formatDate(new Date(question.editedAt))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    AnswerCount
                  </TableCell>
                  <TableCell>{question.answerCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{QuestionStatusLabels[question.status]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    QuestionAnswers
                  </TableCell>
                  <TableCell>
                    <Link to={`/questionAnswers?questionID=${id}`}>questionAnswers</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{formatDate(new Date(question.createdAt))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{formatDate(new Date(question.updatedAt))}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
        {question?.questionEvaluation && (
          <>
            <Typography color="inherit" variant="h6" component="h2">
              Evaluation
            </Typography>
            <Paper className={classes.paperContent}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>項目</TableCell>
                    <TableCell>値</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      EvaluationType
                    </TableCell>
                    <TableCell>
                      {
                        QuestionAnswerEvaluationTypeLabels[
                          question.questionEvaluation.evaluationType
                        ]
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      EvaluationComment
                    </TableCell>
                    <TableCell>{question.questionEvaluation.evaluationComment}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Status
                    </TableCell>
                    <TableCell>
                      {QuestionStatusLabels[question.questionEvaluation.status]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      CreatedAt
                    </TableCell>
                    <TableCell>
                      {formatDate(new Date(question.questionEvaluation.createdAt))}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      UpdatedAt
                    </TableCell>
                    <TableCell>
                      {formatDate(new Date(question.questionEvaluation.updatedAt))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </>
        )}
      </main>
    </div>
  );
};
