import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';

import {
  AppBar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { yupResolver } from '@hookform/resolvers/yup';

import { AbTestDefaultValues, abTestSchema } from '../../../formSchema/abTest';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { Form } from './Form';
import {
  useGetAbTestSettingsQuery,
  useCreateAbTestSettingForAdminMutation,
  AdminAbTestSettingSearchInput,
  AdminAbTestSettingInput,
} from '../../../gen/graphql';
import { FETCH_ROWS_LIMIT, ROWS_PER_PAGE_OPTIONS } from '../../../utils/common';
import { TargetDeviceTypeLabels } from '../../const/AbTest';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [open, showCreateDialog] = useState(false);

  const createFormId = 'abTest-create-form';

  const createForm = useForm<AdminAbTestSettingInput>({
    resolver: yupResolver(abTestSchema),
    defaultValues: AbTestDefaultValues,
  });

  const [createAbTestSetting, { error: createError }] = useCreateAbTestSettingForAdminMutation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminAbTestSettingSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetAbTestSettingsQuery({
    variables: {
      input: currentSearchParams,
    },
  });
  const abTestSettings = data?.abTestSettingsForAdmin.items ?? [];
  const total = data?.abTestSettingsForAdmin.total ?? 0;

  // レコード作成ダイアログの開閉
  const handleCreateDialogOpen = useCallback(() => showCreateDialog(true), []);
  const handleCreateDialogClose = useCallback(() => showCreateDialog(false), []);

  // 新規追加処理
  const handleClickCreate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminAbTestSettingInput): Promise<void> => {
        try {
          await createAbTestSetting({ variables: { input } });
        } catch {
          return;
        }

        // ダイアログを閉じて入力フォーム初期化
        handleCreateDialogClose();
        createForm.reset();

        // 再検索
        refetch({ input: currentSearchParams });
      },
      [createForm, createAbTestSetting, handleCreateDialogClose, currentSearchParams, refetch],
    ),
  );

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminAbTestSettingSearchInput): void => {
      navigate({
        pathname: '/ab_tests',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                AB TEST
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="inherit" onClick={() => handleCreateDialogOpen()}>
              CreateRecord
            </Button>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>

      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">URL</TableCell>
                  <TableCell align="right">Device</TableCell>
                  <TableCell align="right">Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {abTestSettings.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/ab_tests/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.originURL}</TableCell>
                    <TableCell align="right">
                      {row.targetDeviceType ? TargetDeviceTypeLabels[row.targetDeviceType] : '-'}
                    </TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>

          <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            component="div"
            count={total}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>

      {/* Create */}
      <Dialog open={open} onClose={handleCreateDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <Form
            formId={createFormId}
            form={createForm}
            onSubmit={handleClickCreate}
            error={createError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" form={createFormId} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
