import { TweetPostDestination } from '../../gen/graphql';

// 投稿先
export const DESTINATIONS = [
  {
    value: TweetPostDestination.Pro,
    label: 'Pro',
  },
  {
    value: TweetPostDestination.Plus,
    label: '侍テラコヤ',
  },
  {
    value: TweetPostDestination.All,
    label: '両方（Pro / 侍テラコヤ）',
  },
] as const;
