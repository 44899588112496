import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker';
import { Amplify } from 'aws-amplify';

// import { Provider } from "react-redux";
// import { ConnectedRouter } from "connected-react-router";
// import configureStore, { history } from "./configureStore";

// const store = configureStore(/* provide initial state if any */);

import axios from 'axios';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment:
    // 各環境におけるSentryのURLはWiki(https://samurai-7s.backlog.com/alias/wiki/1777803)に記載の通り。
    // production以外は、同じSentry上に送信されるため environment で絞り込めるようにしています。
    process.env.REACT_APP_ENV !== 'production'
      ? (process.env.REACT_APP_ENV ?? undefined)
      : undefined,
  release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
});

Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
});

// Set axios default endpoint
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

ReactDOM.render(<App />, document.getElementById('root'));

{
  /* <Provider store={store}>
<ConnectedRouter history={history}>
  <Switch>
    <Route path="/" component={Layout} />
    <Route render={() => <div>Miss</div>} />
  </Switch>
</ConnectedRouter>
</Provider> */
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
