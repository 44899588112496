import * as yup from 'yup';

import { DeviceTypeListForFormSort } from '../components/const/AbTest';
import { AdminAbTestSettingInput, DeviceType, TargetDeviceType, Valid } from '../gen/graphql';
import { isValidDate } from '../utils/date';

// yup.url()がlocalhostやポート対応していないため正規表現で対応
// see: https://github.com/jquense/yup/issues/224#issuecomment-417172609
const urlRegex =
  /^(?:([a-z0-9+.-]+):\/\/)(?:\S+(?::\S*)?@)?(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/;

export const abTestSchema = yup.object({
  originURL: yup.string().matches(urlRegex, 'Enter correct url!').required().trim(),
  targetDeviceType: yup.string().required().oneOf(Object.values(TargetDeviceType)),
  startDate: yup
    .string()
    .required()
    .test('is-date', 'Invalid Date Format', (value) => {
      return isValidDate(value);
    })
    .transform((_, originalValue) => {
      const date = new Date(originalValue);
      if (!(date instanceof Date && !isNaN(date.getTime()))) return;
      return date.toISOString();
    }),
  endDate: yup
    .string()
    .required()
    .test('is-date', 'Invalid Date Format', (value) => {
      return isValidDate(value);
    })
    .transform((_, originalValue) => {
      const date = new Date(originalValue);
      if (!(date instanceof Date && !isNaN(date.getTime()))) return;
      return date.toISOString();
    }),
  note: yup.string().required().trim(),
  status: yup.string().required().oneOf(Object.values(Valid)),
  abTestSettingDeviceInputs: yup
    .array()
    .of(
      yup.object().shape({
        deviceType: yup.string().required().oneOf(Object.values(DeviceType)),
        originRate: yup
          .number()
          .integer()
          .required()
          .min(0)
          .max(100)
          .transform((value) => (isNaN(value) ? 0 : value)),
        abTestSettingDeviceDestinationInputs: yup
          .array()
          .of(
            yup.object().shape({
              destinationURL: yup
                .string()
                .matches(urlRegex, 'Enter correct url!')
                .required()
                .trim(),
              destinationRate: yup
                .number()
                .integer()
                .required()
                .min(0)
                .max(100)
                .transform((value) => (isNaN(value) ? 0 : value)),
            }),
          )
          .required(),
      }),
    )
    .required(),
});

export const AbTestDefaultValues: AdminAbTestSettingInput = {
  originURL: '',
  targetDeviceType: TargetDeviceType.All,
  startDate: '',
  endDate: '',
  note: '',
  status: Valid.Valid,
  abTestSettingDeviceInputs: DeviceTypeListForFormSort.map((e) => {
    return {
      originRate: 0,
      deviceType: e,
      abTestSettingDeviceDestinationInputs: [],
    };
  }),
};
