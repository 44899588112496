import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import DateFnsUtils from '@date-io/date-fns';

import { AdminChatMessageSearchInput } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

import { ValidLabels } from '../../const/Valid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SearchChatMessage } from '../../../formSchema/chatMessage';

const useStyles = makeStyles((_theme: Theme) => ({
  block: {
    display: 'block',
  },
}));

export type AdminChatMessageSearchFormInput = Omit<AdminChatMessageSearchInput, 'limit' | 'page'>;

interface Props {
  form: UseFormReturn<SearchChatMessage>;
  onSubmit: (input: SearchChatMessage) => void;
}

export const SearchForm: React.FC<Props> = ({ form, onSubmit }) => {
  const classes = useStyles();
  // see 「Rules」 section
  // https://react-hook-form.com/api/useform/formstate/
  const { isValid } = form.formState;

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div>
            <Typography>検索</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <Controller
                name="id"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="ID"
                    type="number"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="chatRoomID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="chatRoomId"
                    label="ChatRoomId"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="userID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="userId"
                    label="UserId"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="periodStart"
                control={form.control}
                render={({ field: { ref, value, ...rest } }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk={true}
                      margin="normal"
                      id="periodStart"
                      label="PeriodStart"
                      format="yyyy/MM/dd"
                      value={value ?? null}
                      fullWidth
                      inputRef={ref}
                      {...rest}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="periodEnd"
                control={form.control}
                render={({ field: { ref, value, ...rest } }) => (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      autoOk={true}
                      margin="normal"
                      id="periodEnd"
                      label="PeriodEnd"
                      format="yyyy/MM/dd"
                      value={value ?? null}
                      fullWidth
                      inputRef={ref}
                      {...rest}
                    />
                  </MuiPickersUtilsProvider>
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Controller
                  name="status"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value="">&nbsp;</MenuItem>
                      {Object.entries(ValidLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider light />
        <AccordionActions>
          <Button size="small" color="primary" type="submit" disabled={!isValid}>
            <SearchIcon className={classes.block} />
            Search
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  );
};
