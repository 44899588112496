import React, { useState, useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles, Theme } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

import { TicketTypeValues, TicketTypeLabels } from '../../../types/Ticket';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  useTicketsForAdminQuery,
  AdminTicketSearchInput,
  useCreateTicketForAdminMutation,
  AdminTicketCreateInput,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { TicketCreateDefaultValues, ticketCreateSchema } from '../../../formSchema/ticket';
import { Form } from './CreateForm';

const useStyles = makeStyles((_theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
}));

type SearchFormInput = Omit<AdminTicketSearchInput, 'limit' | 'page'>;

export interface AddParam {
  userID?: number;
  expireAt?: number;
}

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // Add Dialog
  const [open, showAddDialog] = useState(false);
  const [openSubmit, showSubmitDialog] = useState(false);
  function handleAddDialogOpen() {
    showAddDialog(true);
  }
  function handleAddDialogClose() {
    showAddDialog(false);
  }
  function handleAdd() {
    handleSubmitDialogOpen();
  }

  function handleSubmitDialogOpen() {
    showSubmitDialog(true);
  }
  function handleSubmitDialogClose() {
    showSubmitDialog(false);
  }

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminTicketSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const userId = params.get('userID') ?? '';
    const isUsed = params.get('isUsed') ?? '';
    const type = params.get('type') ?? '';
    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      userID: parseInt(userId) > 0 ? parseInt(userId) : undefined,
      isUsed: parseInt(isUsed) >= 0 ? parseInt(isUsed) : undefined,
      type: parseInt(type) >= 0 ? parseInt(type) : undefined,
    };
  }, [location.search]);

  const { data, loading, refetch } = useTicketsForAdminQuery({
    variables: { input: currentSearchParams },
    notifyOnNetworkStatusChange: true,
  });
  const total = data?.ticketsForAdmin?.total ?? 0;

  // 検索条件からURLを作成してhistoryにpush
  const createHistory = useCallback(
    (params: AdminTicketSearchInput): void => {
      navigate({
        pathname: '/tickets',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      userID: currentSearchParams.userID,
      isUsed: currentSearchParams.isUsed,
      type: currentSearchParams.type,
    },
  });
  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const handleChangePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const handleChangePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // チケット作成
  const [createTicket, { error: createError }] = useCreateTicketForAdminMutation();

  const createFormId = 'ticket-create-form';
  const createForm = useForm<AdminTicketCreateInput>({
    resolver: yupResolver(ticketCreateSchema),
    defaultValues: TicketCreateDefaultValues,
  });

  const handleClickCreate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminTicketCreateInput): Promise<void> => {
        try {
          await createTicket({ variables: { input } });
        } catch {
          return;
        }

        // ダイアログを閉じて入力フォーム初期化
        showAddDialog(false);
        createForm.reset();

        // 再検索
        refetch({ input: currentSearchParams });
      },
      [createForm, createTicket, currentSearchParams, refetch],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Ticket
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="userID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="userID"
                          label="UserID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="isUsed">IsUsed</InputLabel>
                      <Controller
                        name="isUsed"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={0}>False</MenuItem>
                            <MenuItem value={1}>True</MenuItem>
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="type">Type</InputLabel>
                      <Controller
                        name="type"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(TicketTypeValues).map((type, i) => (
                              <MenuItem key={i} value={type[1]}>
                                {type[0]}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">UserID</TableCell>
                  <TableCell align="right">ExpireAt</TableCell>
                  <TableCell align="right">IsUsed</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.ticketsForAdmin?.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/tickets/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/users/${row.userID}`}>{row.userID}</Link>
                    </TableCell>
                    <TableCell align="right">{row.expireAt}</TableCell>
                    <TableCell align="right">{row.isUsed ? '使用済' : '未使用'}</TableCell>
                    <TableCell align="right">
                      {TicketTypeLabels[row.type] ? TicketTypeLabels[row.type] : ''}
                    </TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => handleChangePage(page)}
            onRowsPerPageChange={({ target: { value } }) => handleChangePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <Form
            formId={createFormId}
            form={createForm}
            onSubmit={handleClickCreate}
            error={createError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            チケットを付与する
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openSubmit}
        onClose={handleSubmitDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            この操作は取り消せません。チケットを付与するとユーザーに直ちにメールが送付されます、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmitDialogClose} color="primary">
            キャンセル
          </Button>
          <Button
            type="submit"
            form={createFormId}
            onClick={handleSubmitDialogClose}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
