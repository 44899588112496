import React, { useCallback, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { yupResolver } from '@hookform/resolvers/yup';

// Other
import {
  AdminBannerInput,
  AdminBannerSearchInput,
  useCreateBannerMutation,
  useGetBannersQuery,
  Valid,
} from '../../../gen/graphql';
import { strToBoolean, strToEnum } from '../../../utils/common';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { ValidLabels } from '../../const/Valid';
import { UserTargetLabels } from '../../const/Banner';
import { BannerDefaultValues, bannerSchema } from '../../../formSchema/banner';
import { Form } from './Form';
import { useUploadImagesMutation } from '../../../gen/graphql';
import { AdminBannerSearchFormInput, SearchForm } from './SearchForm';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const [open, showCreateDialog] = React.useState(false);

  const createFormId = 'banner-create-form';
  const createForm = useForm<AdminBannerInput>({
    resolver: yupResolver(bannerSchema),
    defaultValues: BannerDefaultValues,
  });

  const [image, setImage] = useState<File | null>(null);
  const imageSrc = useMemo(() => (image ? URL.createObjectURL(image) : ''), [image]);

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminBannerSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const title = params.get('title') ?? '';
    const imageUrl = params.get('imageURL') ?? '';
    const link = params.get('link') ?? '';
    const sort = params.get('sort') ?? '';
    const status = params.get('status') ?? '';
    const isNotLoginUser = params.get('isNotLoginUser') ?? '';
    const isTargetFree = params.get('isTargetFree') ?? '';
    const isTargetSubscriptionUser = params.get('isTargetSubscriptionUser') ?? '';
    const isTargetStudent = params.get('isTargetStudent') ?? '';
    const isTargetGraduate = params.get('isTargetGraduate') ?? '';
    const isTargetInstructor = params.get('isTargetInstructor') ?? '';
    const isTargetTeamUser = params.get('isTargetTeamUser') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      title: title.trim().length > 0 ? title.trim() : undefined,
      imageURL: imageUrl.trim().length > 0 ? imageUrl.trim() : undefined,
      link: link.trim().length > 0 ? link.trim() : undefined,
      sort: parseInt(sort) >= 0 ? parseInt(sort) : undefined,
      status: strToEnum(status, Valid),
      isNotLoginUser: isNotLoginUser ? strToBoolean(isNotLoginUser) : undefined,
      isTargetFree: isTargetFree ? strToBoolean(isTargetFree) : undefined,
      isTargetSubscriptionUser: isTargetSubscriptionUser
        ? strToBoolean(isTargetSubscriptionUser)
        : undefined,
      isTargetStudent: isTargetStudent ? strToBoolean(isTargetStudent) : undefined,
      isTargetGraduate: isTargetGraduate ? strToBoolean(isTargetGraduate) : undefined,
      isTargetInstructor: isTargetInstructor ? strToBoolean(isTargetInstructor) : undefined,
      isTargetTeamUser: isTargetTeamUser ? strToBoolean(isTargetTeamUser) : undefined,
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetBannersQuery({
    variables: { input: currentSearchParams },
    notifyOnNetworkStatusChange: true,
  });

  const [createBanner, { error: createError }] = useCreateBannerMutation();

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminBannerSearchInput): void => {
      navigate({
        pathname: '/banners',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const handleCreateDialogOpen = useCallback(() => showCreateDialog(true), []);
  const handleCreateDialogClose = useCallback(() => showCreateDialog(false), []);

  const [uploadImages] = useUploadImagesMutation();

  const uploadImage = useCallback(
    async (image: File): Promise<string> => {
      try {
        const uploadedFiles = await uploadImages({
          variables: {
            files: [image],
          },
        });
        const files = uploadedFiles.data?.uploadFiles ?? [];
        return files[0].s3FilePath;
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return '';
      }
    },
    [uploadImages],
  );

  const handleClickCreate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminBannerInput): Promise<void> => {
        try {
          if (image) {
            input.imageURL = await uploadImage(image);
          }

          await createBanner({
            variables: {
              input,
            },
          });
        } catch {
          return;
        }

        // ダイアログを閉じて入力フォーム初期化
        handleCreateDialogClose();
        createForm.reset();

        // 再検索
        refetch({ input: currentSearchParams });
      },
      [
        createBanner,
        createForm,
        currentSearchParams,
        handleCreateDialogClose,
        image,
        refetch,
        uploadImage,
      ],
    ),
  );

  const searchForm = useForm<AdminBannerSearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      title: currentSearchParams.title,
      imageURL: currentSearchParams.imageURL,
      link: currentSearchParams.link,
      sort: currentSearchParams.sort,
      isNotLoginUser: currentSearchParams.isNotLoginUser,
      isTargetFree: currentSearchParams.isTargetFree,
      isTargetSubscriptionUser: currentSearchParams.isTargetSubscriptionUser,
      isTargetStudent: currentSearchParams.isTargetStudent,
      isTargetGraduate: currentSearchParams.isTargetGraduate,
      isTargetInstructor: currentSearchParams.isTargetInstructor,
      isTargetTeamUser: currentSearchParams.isTargetTeamUser,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: AdminBannerSearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Banner
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="inherit" onClick={() => handleCreateDialogOpen()}>
              CreateRecord
            </Button>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={search} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">ImageUrl</TableCell>
                  <TableCell align="right">Link</TableCell>
                  <TableCell align="right">Sort</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">IsNotLoginUser</TableCell>
                  <TableCell align="right">IsTargetFree</TableCell>
                  <TableCell align="right">IsTargetSubscriptionUser</TableCell>
                  <TableCell align="right">IsTargetStudent</TableCell>
                  <TableCell align="right">IsTargetGraduate</TableCell>
                  <TableCell align="right">IsTargetInstructor</TableCell>
                  <TableCell align="right">IsTargetTeamUser</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.bannersForAdmin.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/banners/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.title}</TableCell>
                    <TableCell align="right">{row.imageURL}</TableCell>
                    <TableCell align="right">{row.link}</TableCell>
                    <TableCell align="right">{row.sort}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">
                      {row.isNotLoginUser ? UserTargetLabels.true : UserTargetLabels.false}
                    </TableCell>
                    <TableCell align="right">
                      {row.isTargetFree ? UserTargetLabels.true : UserTargetLabels.false}
                    </TableCell>
                    <TableCell align="right">
                      {row.isTargetSubscriptionUser
                        ? UserTargetLabels.true
                        : UserTargetLabels.false}
                    </TableCell>
                    <TableCell align="right">
                      {row.isTargetStudent ? UserTargetLabels.true : UserTargetLabels.false}
                    </TableCell>
                    <TableCell align="right">
                      {row.isTargetGraduate ? UserTargetLabels.true : UserTargetLabels.false}
                    </TableCell>
                    <TableCell align="right">
                      {row.isTargetInstructor ? UserTargetLabels.true : UserTargetLabels.false}
                    </TableCell>
                    <TableCell align="right">
                      {row.isTargetTeamUser ? UserTargetLabels.true : UserTargetLabels.false}
                    </TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.bannersForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      {/* Create */}
      <Dialog open={open} onClose={handleCreateDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <Form
            formId={createFormId}
            form={createForm}
            onSubmit={handleClickCreate}
            error={createError}
            imageSrc={imageSrc}
            setImage={setImage}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" form={createFormId} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
