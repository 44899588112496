import { Bool } from '../../gen/graphql';

interface Progress {
  id: number;
  label: string;
}

export const progresses = [
  { id: 0, label: '遅れている' },
  { id: 1, label: '少し遅れている' },
  { id: 2, label: '進捗通り' },
  { id: 3, label: '少し進んでいる' },
  { id: 4, label: '進んでいる' },
] as const;

export const invalidProgress = { id: 5, label: '-' };

export const findProgress = (progressID?: number | null): Progress => {
  if (Number.isInteger(progressID))
    return progresses.find((p) => p.id === progressID) ?? invalidProgress;
  return invalidProgress;
};

export const phaseLabels: { [index: number]: string } = {
  0: '基礎学習',
  1: 'サンプルアプリ開発',
  2: 'オリジナルアプリ開発',
  3: 'ポートフォリオ作成',
  4: '資格試験対策',
} as const;

export const lessonTypeLabels: { [index: number]: string } = {
  0: 'オンライン',
  1: '対面',
} as const;

export const completedLabels: { [index: number]: string } = {
  0: '未完了',
  1: '完了',
} as const;

export const completedLabelsForInput = {
  [Bool.False]: '未完了',
  [Bool.True]: '完了',
} as const;

export const canceledLabels: { [index: number]: string } = {
  0: '-',
  1: 'キャンセル',
} as const;

export const canceledLabelsForInput = {
  [Bool.False]: '-',
  [Bool.True]: 'キャンセル',
} as const;

export const studentAssistanceLabels: string[] = [
  'キャリアの相談に乗ってあげて欲しい',
  '学習時間の確保についてアドバイスをしてほしい',
  '受講生のやる気を上げて欲しい',
  'わたしに改善点があればヒアリングしてほしい',
];

export const instructorAssistanceLabels: string[] = [
  '転職サポートを運営と連携したい',
  '学習時間が少ない受講生へのフォローについて相談したい',
  'ITリテラシーが低い受講生への対処法を相談したい',
  'モチベーションが低い受講生への対処法を相談したい',
  '案件獲得の仕方を相談したい',
  '受講生との信頼関係構築について相談したい',
  'カリキュラム作成・修正について相談したい',
  '教材活用について相談したい',
  '宿題について相談したい',
  '受講生からの質問が多すぎて工数が多くかかるので対処法を相談したい',
  '求めるレッスンの要望が高すぎるので対応しきれないので相談したい',
  '先輩インストラクターのレッスンに同席したい',
  '今後のアサインについて相談したい',
  '受講生の連絡不備、キャンセルが多く改善されないため相談したい',
  'その他の悩みを相談したい',
];

export const lessonCSVLabels: string[] = [
  'コースID',
  'コースプラン',
  'コース開始日',
  'コース終了日',
  'コース休学開始日1',
  'コース休学終了日1',
  'コース休学開始日2',
  'コース休学終了日2',
  'コース休学開始日3',
  'コース休学終了日3',
  'インストラクター交代開始日1',
  'インストラクター交代終了日1',
  'インストラクター交代開始日2',
  'インストラクター交代終了日2',
  'インストラクター交代開始日3',
  'インストラクター交代終了日3',
  '予定レッスン回数',
  '実施済みレッスン回数',
  'コースレッスンタイプ',
  'コース契約書種別',
  'コースステータス',
  'インストラクターID',
  'インストラクターユーザーID',
  'インストラクター名',
  'SCインストラクターID',
  'インストラクターステータス',
  '受講生ID',
  '受講生ユーザーID',
  '受講生ユーザー名',
  'SC受講生ID',
  '受講生ステータス',
  'レッスンID',
  'レッスンタイトル',
  '受講生都合キャンセルフラグ',
  'レッスンタイプ',
  'レッスン開始時間',
  'レッスン終了時間',
  'レッスンフェーズ',
  '教えた技術',
  '宿題',
  'レッスン前後準備',
  '進捗',
  'スケジュールの遅延理由',
  '運営からのフォロー',
  'フォロー詳細',
  '受講生へのフォロー',
  'インストラクターへのフォロー',
  '受講履歴登録フラグ',
  'レッスンステータス',
];
