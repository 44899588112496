import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Icon

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetInvoiceItemForAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  buttonArea: {
    marginTop: theme.spacing(2),
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ invoice_item_id: string }>().invoice_item_id;
  const id = Number(paramID);

  const { data, loading } = useGetInvoiceItemForAdminQuery({
    variables: { id },
  });
  const invoiceItem = data?.getInvoiceItemForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                InvoiceItem Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Alert severity="error">
          こちらは古いデータのため参照するのは避けてください。Stripeの管理画面、もしくは「課金データ」メニューから最新の情報をご確認ください。
        </Alert>
        <Grid className={classes.buttonArea} container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          {invoiceItem && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{invoiceItem.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Invoice
                  </TableCell>
                  <TableCell>
                    <Link to={`/invoices/${invoiceItem.invoiceID}`}>{invoiceItem.invoiceID}</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Price
                  </TableCell>
                  <TableCell>
                    <Link to={`/prices/${invoiceItem.price.id}`}>{invoiceItem.price.id}</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Amount
                  </TableCell>
                  <TableCell>{invoiceItem.amount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Quantity
                  </TableCell>
                  <TableCell>{invoiceItem.quantity}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{ValidLabels[invoiceItem.status]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{invoiceItem.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{invoiceItem.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
