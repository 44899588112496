import React, { useMemo, useCallback } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import TextField from '@material-ui/core/TextField';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { CoachingAssignLogSearchInput, useGetCoachingAssignLogsQuery } from '../../../gen/graphql';
import { useForm, Controller } from 'react-hook-form';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  uploadImageBtn: {
    marginLeft: theme.spacing(2),
  },
}));

type SearchFormInput = Omit<CoachingAssignLogSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): CoachingAssignLogSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const coachID = params.get('coachID') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: id.length === 26 ? id : undefined, // ULIDなので26の桁数チェック
      coachID: parseInt(coachID) > 0 ? parseInt(coachID) : undefined,
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetCoachingAssignLogsQuery({
    variables: {
      input: currentSearchParams,
    },
    notifyOnNetworkStatusChange: true,
  });

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      coachID: currentSearchParams.coachID,
    },
    // 検索フォームはバリデーションなし(検索してもヒットしないだけなので)
  });

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: CoachingAssignLogSearchInput): void => {
      navigate({
        pathname: '/coachingAssignLogs',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 検索
  const searchAdmins = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Coaching Assign Logs
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(searchAdmins)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="string"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="coachID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="coachID"
                          label="coachID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Student</TableCell>
                  <TableCell align="right">Course</TableCell>
                  <TableCell align="right">Coach</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.coachingAssignLogs.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/coachingAssignLogs/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/students/${row.coaching.course.student.id}`}>
                        {row.coaching.course.student.user.personalInfo?.name ?? ''}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/courses/${row.coaching.course.id}`}>
                        {row.coaching.course.plan.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/instructors/${row.coachID}`}>
                        {row.coaching?.coach?.user.personalInfo?.name ?? ''}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.coachingAssignLogs.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
