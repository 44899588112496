import { format, isDate, isValid, parse, parseISO } from 'date-fns';

export const isValidDate = (value: string | null | undefined, nullable?: boolean): boolean => {
  if (nullable && value === null) return true;
  if (!value) return false;

  const parsedISODate = parseISO(value);
  if (isValid(parsedISODate)) return true;

  const jsDate = new Date(value);
  if (!(isDate(jsDate) && !isNaN(jsDate.getTime()))) return false;

  const pattern = 'yyyy/MM/dd';
  const parsedDate = parse(value, pattern, new Date());
  if (!isValid(parsedDate)) return false;

  const formattedDate = format(new Date(parsedDate), pattern);

  return value === formattedDate;
};
