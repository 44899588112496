import { DeviceType, TargetDeviceType } from '../../gen/graphql';

export const TargetDeviceTypeLabels = {
  [TargetDeviceType.All]: 'すべてのデバイスでALLの設定を使用する',
  [TargetDeviceType.Pc]: 'PCのときのみPCの設定を使用する',
  [TargetDeviceType.Sp]: 'SPのときのみSPの設定を使用する',
  [TargetDeviceType.ByDevice]: 'PCのときはPC、SPのときはSPの設定を使用する',
} as const;

export const DeviceTypeLabels = {
  [DeviceType.All]: 'ALL',
  [DeviceType.Pc]: 'PC',
  [DeviceType.Sp]: 'SP',
} as const;

// Sort用に使う
export const DeviceTypeIds = {
  [DeviceType.All]: 1,
  [DeviceType.Pc]: 2,
  [DeviceType.Sp]: 3,
} as const;

// フォーム用の並び順
export const DeviceTypeListForFormSort: DeviceType[] = [
  DeviceType.All,
  DeviceType.Pc,
  DeviceType.Sp,
];
