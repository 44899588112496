import React, { useCallback, useState } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Box, Grid, Paper, Toolbar, makeStyles } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

// Material UI Icon
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import {
  CampaignInput,
  Valid,
  useGetCampaignForAdminQuery,
  useUpdateCampaignForAdminMutation,
  useUploadImagesMutation,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { getUTCDatetime } from '../../const/Util';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  imageStyle: {
    width: '100%',
  },
}));

interface UploadImage {
  image: File | null;
  imageURL: string;
}

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ campaign_id: string }>().campaign_id ?? '0';

  const [uploadFile, setUploadFile] = useState<UploadImage>({
    image: null,
    imageURL: '',
  });
  // Loading
  const [showLoader, setShowLoader] = useState(false);
  // Form inputs
  const [updateInput, setUpdateInput] = useState<CampaignInput>({
    title: '',
    imageURL: '',
    startDate: '',
    endDate: '',
    status: Valid.Valid,
  });

  const { loading } = useGetCampaignForAdminQuery({
    variables: { id },
    onCompleted: ({ getCampaignForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        title: data.title,
        imageURL: data.imageURL,
        startDate: data.startDate,
        endDate: data.endDate,
        status: data.status,
      });
    },
  });
  const [updateCampaign, { error: updateError }] = useUpdateCampaignForAdminMutation();

  const [uploadImages] = useUploadImagesMutation();
  const uploadImage = useCallback(
    async (image: File): Promise<string> => {
      try {
        const uploadedFiles = await uploadImages({
          variables: {
            files: [image],
          },
        });
        const files = uploadedFiles.data?.uploadFiles ?? [];
        return files[0].s3FilePath;
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return '';
      }
    },
    [uploadImages],
  );

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        if (uploadFile.image) {
          updateInput.imageURL = await uploadImage(uploadFile.image);
        }
        await updateCampaign({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateInput, navigate, uploadFile.image, uploadImage, updateCampaign, id]),
  );

  const handleChangeTitle = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, title: value }));
  }, []);

  const handleChangeStartDate = useCallback(
    (date: MaterialUiPickersDate, startDate: string | null | undefined) => {
      if (!startDate) return;
      setUpdateInput((current) => ({ ...current, startDate: getUTCDatetime(startDate) }));
    },
    [],
  );

  const handleChangeEndDate = useCallback(
    (date: MaterialUiPickersDate, endDate: string | null | undefined) => {
      if (!endDate) return;
      setUpdateInput((current) => ({ ...current, endDate: getUTCDatetime(endDate) }));
    },
    [],
  );

  const handleChangeStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  const handleChangeImage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files) return;
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);

      setUploadFile({ imageURL: url, image: file });
    },
    [setUploadFile],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Campaign Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                placeholder="2022年2月までの入学お申し込みで, Amazonギフト券プレゼント"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.title}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeTitle}
              />
            </div>
            <div>
              <Grid item>
                <img
                  src={uploadFile.imageURL ? uploadFile.imageURL : updateInput.imageURL}
                  alt="キャンペーン画像"
                  className={classes.imageStyle}
                />
              </Grid>
              <Grid item>
                <input
                  accept="image/*"
                  type="file"
                  id="upload-img"
                  onChange={handleChangeImage}
                  style={{ display: 'none' }}
                ></input>
                <Button htmlFor="upload-img" component="label" variant="contained">
                  <CloudUploadIcon />
                  &nbsp;UPLOAD
                </Button>
              </Grid>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="startDate"
                  label="キャンペーン有効開始日"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput.startDate}
                  onChange={handleChangeStartDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="endDate"
                  label="キャンペーン有効終了日(未満)"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput.endDate}
                  onChange={handleChangeEndDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput.status}
                  onChange={handleChangeStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
