import React, { useState } from 'react';
import { createTheme, makeStyles, Theme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import { Navigator } from './common/Navigator';
import { Index as StudentIndex } from './pages/student/Index';
import { Detail as StudentDetail } from './pages/student/Detail';
import { Update as StudentUpdate } from './pages/student/Update';
import { Index as InstructorIndex } from './pages/instructor/Index';
import { Detail as InstructorDetail } from './pages/instructor/Detail';
import { Update as InstructorUpdate } from './pages/instructor/Update';
import { Index as AdminIndex } from './pages/admin/Index';
import { Detail as AdminDetail } from './pages/admin/Detail';
import { Update as AdminUpdate } from './pages/admin/Update';
import { Index as UserIndex } from './pages/user/Index';
import { Detail as UserDetail } from './pages/user/Detail';
import { Update as UserUpdate } from './pages/user/Update';
import { Index as MailSettingIndex } from './pages/mailSetting/Index';
import { Detail as MailSettingDetail } from './pages/mailSetting/Detail';
import { Update as MailSettingUpdate } from './pages/mailSetting/Update';
import { Index as ClaimIndex } from './pages/claim/Index';
import { Detail as ClaimDetail } from './pages/claim/Detail';
import { Update as ClaimUpdate } from './pages/claim/Update';
import { Index as ClaimDetailIndex } from './pages/claimDetail/Index';
import { Detail as ClaimDetailDetail } from './pages/claimDetail/Detail';
import { Update as ClaimDetailUpdate } from './pages/claimDetail/Update';
import { Index as CoachingIndex } from './pages/coaching/Index';
import { Detail as CoachingDetail } from './pages/coaching/Detail';
import { Index as CoachingAssignLogIndex } from './pages/coachingAssignLog/Index';
import { Detail as CoachingAssignLogDetail } from './pages/coachingAssignLog/Detail';
import { Index as CourseIndex } from './pages/course/Index';
import { Detail as CourseDetail } from './pages/course/Detail';
import { Update as CourseUpdate } from './pages/course/Update';
import { Index as PlanIndex } from './pages/plan/Index';
import { Detail as PlanDetail } from './pages/plan/Detail';
import { Update as PlanUpdate } from './pages/plan/Update';
import { Index as LessonIndex } from './pages/lesson/Index';
import { Detail as LessonDetail } from './pages/lesson/Detail';
import { Update as LessonUpdate } from './pages/lesson/Update';
import { Index as LessonQuestionnaireSendLogIndex } from './pages/lessonQuestionnaireSendLog/Index';
import { Detail as LessonQuestionnaireSendLogDetail } from './pages/lessonQuestionnaireSendLog/Detail';
import { Index as SessionIndex } from './pages/session/Index';
import { Detail as SessionDetail } from './pages/session/Detail';
import { Update as SessionUpdate } from './pages/session/Update';
import { Index as SessionReportIndex } from './pages/sessionReport/Index';
import { Detail as SessionReportDetail } from './pages/sessionReport/Detail';
import { Update as SessionReportUpdate } from './pages/sessionReport/Update';
import { Index as TermChangeLogIndex } from './pages/term_change_log/Index';
import { Index as AssignLogIndex } from './pages/assign_log/Index';
import { Index as ProgramIndex } from './pages/program/Index';
import { Detail as ProgramDetail } from './pages/program/Detail';
import { Update as ProgramUpdate } from './pages/program/Update';
import { Index as ChapterIndex } from './pages/chapter/Index';
import { Detail as ChapterDetail } from './pages/chapter/Detail';
import { Update as ChapterUpdate } from './pages/chapter/Update';
import { Index as CourseProgramIndex } from './pages/courseProgram/Index';
import { Detail as CourseProgramDetail } from './pages/courseProgram/Detail';
import { Update as CourseProgramUpdate } from './pages/courseProgram/Update';
import { Index as CourseProgramStepIndex } from './pages/courseProgramStep/Index';
import { Detail as CourseProgramStepDetail } from './pages/courseProgramStep/Detail';
import { Update as CourseProgramStepUpdate } from './pages/courseProgramStep/Update';
import { Index as TagIndex } from './pages/tag/Index';
import { Detail as TagDetail } from './pages/tag/Detail';
import { Update as TagUpdate } from './pages/tag/Update';
import { Index as QuestionIndex } from './pages/question/Index';
import { Detail as QuestionDetail } from './pages/question/Detail';
import { Update as QuestionUpdate } from './pages/question/Update';
import { Index as QuestionAnswerIndex } from './pages/questionAnswer/Index';
import { Detail as QuestionAnswerDetail } from './pages/questionAnswer/Detail';
import { Update as QuestionAnswerUpdate } from './pages/questionAnswer/Update';
import { Index as QuestionCommentIndex } from './pages/questionComment/Index';
import { Detail as QuestionCommentDetail } from './pages/questionComment/Detail';
import { Update as QuestionCommentUpdate } from './pages/questionComment/Update';
import { Index as ChatRoomIndex } from './pages/chatRoom/Index';
import { Detail as ChatRoomDetail } from './pages/chatRoom/Detail';
import { Update as ChatRoomUpdate } from './pages/chatRoom/Update';
import { Index as ChatMessageIndex } from './pages/chatMessage/Index';
import { Detail as ChatMessageDetail } from './pages/chatMessage/Detail';
import { Update as ChatMessageUpdate } from './pages/chatMessage/Update';
import { Index as NoticeIndex } from './pages/notice/Index';
import { Detail as NoticeDetail } from './pages/notice/Detail';
import { Update as NoticeUpdate } from './pages/notice/Update';
import { Index as StudyReportIndex } from './pages/studyReport/Index';
import { Detail as StudyReportDetail } from './pages/studyReport/Detail';
import { Update as StudyReportUpdate } from './pages/studyReport/Update';
import { Index as StudyReportDetailIndex } from './pages/studyReportDetail/Index';
import { Detail as StudyReportDetailDetail } from './pages/studyReportDetail/Detail';
import { Update as StudyReportDetailUpdate } from './pages/studyReportDetail/Update';
import { Index as StudyReportCommentIndex } from './pages/studyReportComment/Index';
import { Detail as StudyReportCommentDetail } from './pages/studyReportComment/Detail';
import { Update as StudyReportCommentUpdate } from './pages/studyReportComment/Update';
import { Index as StudyTagSummaryIndex } from './pages/studyTagSummary/Index';
import { Detail as StudyTagSummaryDetail } from './pages/studyTagSummary/Detail';
import { Index as StudyFavoriteSummaryIndex } from './pages/studyFavoriteSummary/Index';
import { Detail as StudyFavoriteSummaryDetail } from './pages/studyFavoriteSummary/Detail';
import { Index as StudySummaryIndex } from './pages/studySummary/Index';
import { Detail as StudySummaryDetail } from './pages/studySummary/Detail';
import { Index as BannerIndex } from './pages/banner/Index';
import { Detail as BannerDetail } from './pages/banner/Detail';
import { Update as BannerUpdate } from './pages/banner/Update';
import { Index as CampaignIndex } from './pages/campaign/Index';
import { Detail as CampaignDetail } from './pages/campaign/Detail';
import { Update as CampaignUpdate } from './pages/campaign/Update';
import { Index as AbTestIndex } from './pages/abTest/Index';
import { Detail as AbTestDetail } from './pages/abTest/Detail';
import { Update as AbTestUpdate } from './pages/abTest/Update';
import { Index as InvoiceIndex } from './pages/invoice/Index';
import { Detail as InvoiceDetail } from './pages/invoice/Detail';
import { Index as InvoiceItemIndex } from './pages/invoiceItem/Index';
import { Detail as InvoiceItemDetail } from './pages/invoiceItem/Detail';
import { Index as PaymentMethodIndex } from './pages/paymentMethod/Index';
import { Detail as PaymentMethodDetail } from './pages/paymentMethod/Detail';
import { Index as PaymentIntentIndex } from './pages/paymentIntent/Index';
import { Detail as PaymentIntentDetail } from './pages/paymentIntent/Detail';
import { Index as StripeCustomerIndex } from './pages/stripeCustomer/Index';
import { Detail as StripeCustomerDetail } from './pages/stripeCustomer/Detail';
import { Index as StripeSubscriptionIndex } from './pages/stripeSubscription/Index';
import { Detail as StripeSubscriptionDetail } from './pages/stripeSubscription/Detail';
import { Index as SubscriptionIndex } from './pages/subscription/Index';
import { Detail as SubscriptionDetail } from './pages/subscription/Detail';
import { Index as SubscriptionContractIndex } from './pages/subscriptionContract/Index';
import { Detail as SubscriptionContractDetail } from './pages/subscriptionContract/Detail';
import { Index as SubscriptionHistoryIndex } from './pages/subscriptionHistory/Index';
import { Detail as SubscriptionHistoryDetail } from './pages/subscriptionHistory/Detail';
import { Index as ProductIndex } from './pages/product/Index';
import { Detail as ProductDetail } from './pages/product/Detail';
import { Index as PriceIndex } from './pages/price/Index';
import { Detail as PriceDetail } from './pages/price/Detail';
import { Index as PromotionCodeTransactionIndex } from './pages/promotionCodeTransaction/Index';
import { Detail as PromotionCodeTransactionDetail } from './pages/promotionCodeTransaction/Detail';
import { Index as TicketIndex } from './pages/ticket/Index';
import { Detail as TicketDetail } from './pages/ticket/Detail';
import { Update as TicketUpdate } from './pages/ticket/Update';
import { Index as BannerCidIndex } from './pages/bannerCid/Index';
import { Detail as BannerCidDetail } from './pages/bannerCid/Detail';
import { Update as BannerCidUpdate } from './pages/bannerCid/Update';
import { Profile } from './pages/account/Profile';
import { Detail as ProfileMailSettingDetail } from './pages/account/MailSettingDetail';
import { Index as InstructorScheduleIndex } from './pages/instructorSchedule/Index';
import { Detail as InstructorScheduleDetail } from './pages/instructorSchedule/Detail';
import { Update as InstructorScheduleUpdate } from './pages/instructorSchedule/Update';
import { Index as SpotLessonIndex } from './pages/spotLesson/Index';
import { Detail as SpotLessonDetail } from './pages/spotLesson/Detail';
import { Update as SpotLessonUpdate } from './pages/spotLesson/Update';
import { Index as TweetIndex } from './pages/tweet/Index';
import { Detail as TweetDetail } from './pages/tweet/Detail';
import { Index as TweetCommentIndex } from './pages/tweetComment/Index';
import { Detail as TweetCommentDetail } from './pages/tweetComment/Detail';
import { Index as SpCouponIndex } from './pages/spCoupon/Index';
import { Detail as SpCouponDetail } from './pages/spCoupon/Detail';
import { Index as SpDiscountIndex } from './pages/spDiscount/Index';
import { Detail as SpDiscountDetail } from './pages/spDiscount/Detail';
import { Index as SpDiscountCouponIndex } from './pages/spDiscountCoupon/Index';
import { Detail as SpDiscountCouponDetail } from './pages/spDiscountCoupon/Detail';
import { Index as SpPromotionCodeIndex } from './pages/spPromotionCode/Index';
import { Detail as SpPromotionCodeDetail } from './pages/spPromotionCode/Detail';
import { Index as TeamIndex } from './pages/team/Index';
import { Detail as TeamDetail } from './pages/team/Detail';
import { Update as TeamUpdate } from './pages/team/Update';
import { Index as TeamMemberIndex } from './pages/teamMember/Index';
import { Detail as TeamMemberDetail } from './pages/teamMember/Detail';
import { Update as TeamMemberUpdate } from './pages/teamMember/Update';
import { Index as ProgramSampleIndex } from './pages/programSample/Index';
import { Detail as ProgramSampleDetail } from './pages/programSample/Detail';
import { Update as ProgramSampleUpdate } from './pages/programSample/Update';
import { Index as GraduationDateNotificationSendLogIndex } from './pages/graduationDateNotificationSendLog/Index';
import { Detail as GraduationDateNotificationSendLogDetail } from './pages/graduationDateNotificationSendLog/Detail';
import { Index as PracticeIndex } from './pages/practice/Index';
import { Detail as PracticeDetail } from './pages/practice/Detail';
import { Update as PracticeUpdate } from './pages/practice/Update';
import { Index as PracticeSubmitIndex } from './pages/practiceSubmit/Index';
import { Detail as PracticeSubmitDetail } from './pages/practiceSubmit/Detail';
import { Update as PracticeSubmitUpdate } from './pages/practiceSubmit/Update';
import { Index as PracticeSubmitDetailIndex } from './pages/practiceSubmitDetail/Index';
import { Detail as PracticeSubmitDetailDetail } from './pages/practiceSubmitDetail/Detail';
import { Update as PracticeSubmitDetailUpdate } from './pages/practiceSubmitDetail/Update';
import { Top } from './pages/Top';
import { Header } from './common/Header';
import { Route, Routes } from 'react-router-dom';

let theme = createTheme({
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeightProperty: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48,
    },
  },
};

const drawerWidth = 256;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100vw - 256px)',
    background: '#eaeff1',
  },
  mainContent: {
    flex: 1,
    padding: '48px 36px 48px',
    background: '#eaeff1',
  },
}));

export const Layout = (): JSX.Element => {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </nav>
        <div className={classes.appContent}>
          <Header onDrawerToggle={handleDrawerToggle} />
          <Routes>
            <Route path="/" element={<Top />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/mailSetting" element={<ProfileMailSettingDetail />} />
            <Route path="/students" element={<StudentIndex />} />
            <Route path="/students/:student_id" element={<StudentDetail />} />
            <Route path="/students/:student_id/update" element={<StudentUpdate />} />
            <Route path="/instructors" element={<InstructorIndex />} />
            <Route path="/instructors/:instructor_id" element={<InstructorDetail />} />
            <Route path="/instructors/:instructor_id/update" element={<InstructorUpdate />} />
            <Route path="/admins" element={<AdminIndex />} />
            <Route path="/admins/:admin_id" element={<AdminDetail />} />
            <Route path="/admins/:admin_id/update" element={<AdminUpdate />} />
            <Route path="/users" element={<UserIndex />} />
            <Route path="/users/:user_id" element={<UserDetail />} />
            <Route path="/users/:user_id/update" element={<UserUpdate />} />
            <Route path="/mailSettings" element={<MailSettingIndex />} />
            <Route path="/mailSettings/:mail_setting_id" element={<MailSettingDetail />} />
            <Route path="/mailSettings/:mail_setting_id/update" element={<MailSettingUpdate />} />
            <Route path="/claims" element={<ClaimIndex />} />
            <Route path="/claims/:claim_id" element={<ClaimDetail />} />
            <Route path="/claims/:claim_id/update" element={<ClaimUpdate />} />
            <Route path="/claimDetails" element={<ClaimDetailIndex />} />
            <Route path="/claimDetails/:claim_detail_id" element={<ClaimDetailDetail />} />
            <Route path="/claimDetails/:claim_detail_id/update" element={<ClaimDetailUpdate />} />
            <Route path="/coachings" element={<CoachingIndex />} />
            <Route path="/coachings/:coaching_id" element={<CoachingDetail />} />
            <Route path="/coachingAssignLogs" element={<CoachingAssignLogIndex />} />
            <Route
              path="/coachingAssignLogs/:coaching_assign_log_id"
              element={<CoachingAssignLogDetail />}
            />
            <Route path="/courses" element={<CourseIndex />} />
            <Route path="/courses/:course_id" element={<CourseDetail />} />
            <Route path="/courses/:course_id/update" element={<CourseUpdate />} />
            <Route path="/plans" element={<PlanIndex />} />
            <Route path="/plans/:plan_id" element={<PlanDetail />} />
            <Route path="/plans/:plan_id/update" element={<PlanUpdate />} />
            <Route path="/lessons" element={<LessonIndex />} />
            <Route path="/lessons/:lesson_id" element={<LessonDetail />} />
            <Route path="/lessons/:lesson_id/update" element={<LessonUpdate />} />
            <Route
              path="/lessonQuestionnaireSendLogs"
              element={<LessonQuestionnaireSendLogIndex />}
            />
            <Route
              path="/lessonQuestionnaireSendLogs/:lesson_questionnaire_send_log_id"
              element={<LessonQuestionnaireSendLogDetail />}
            />
            <Route path="/sessions" element={<SessionIndex />} />
            <Route path="/sessions/:session_id" element={<SessionDetail />} />
            <Route path="/sessions/:session_id/update" element={<SessionUpdate />} />
            <Route path="/sessionReports" element={<SessionReportIndex />} />
            <Route path="/sessionReports/:session_report_id" element={<SessionReportDetail />} />
            <Route
              path="/sessionReports/:session_report_id/update"
              element={<SessionReportUpdate />}
            />
            <Route path="/termChangeLogs" element={<TermChangeLogIndex />} />
            <Route path="/assignLogs" element={<AssignLogIndex />} />
            <Route path="/programs" element={<ProgramIndex />} />
            <Route path="/programs/:program_id" element={<ProgramDetail />} />
            <Route path="/programs/:program_id/update" element={<ProgramUpdate />} />
            <Route path="/chapters" element={<ChapterIndex />} />
            <Route path="/chapters/:chapter_id" element={<ChapterDetail />} />
            <Route path="/chapters/:chapter_id/update" element={<ChapterUpdate />} />
            <Route path="/coursePrograms" element={<CourseProgramIndex />} />
            <Route path="/coursePrograms/:course_program_id" element={<CourseProgramDetail />} />
            <Route
              path="/coursePrograms/:course_program_id/update"
              element={<CourseProgramUpdate />}
            />
            <Route path="/courseProgramSteps" element={<CourseProgramStepIndex />} />
            <Route
              path="/courseProgramSteps/:course_program_step_id"
              element={<CourseProgramStepDetail />}
            />
            <Route
              path="/courseProgramSteps/:course_program_step_id/update"
              element={<CourseProgramStepUpdate />}
            />
            <Route path="/tags" element={<TagIndex />} />
            <Route path="/tags/:tag_id" element={<TagDetail />} />
            <Route path="/tags/:tag_id/update" element={<TagUpdate />} />
            <Route path="/questions" element={<QuestionIndex />} />
            <Route path="/questions/:question_id" element={<QuestionDetail />} />
            <Route path="/questions/:question_id/update" element={<QuestionUpdate />} />
            <Route path="/questionAnswers" element={<QuestionAnswerIndex />} />
            <Route path="/questionAnswers/:question_answer_id" element={<QuestionAnswerDetail />} />
            <Route
              path="/questionAnswers/:question_answer_id/update"
              element={<QuestionAnswerUpdate />}
            />
            <Route path="/questionComments" element={<QuestionCommentIndex />} />
            <Route
              path="/questionComments/:question_comment_id"
              element={<QuestionCommentDetail />}
            />
            <Route
              path="/questionComments/:question_comment_id/update"
              element={<QuestionCommentUpdate />}
            />
            <Route path="/chatRooms" element={<ChatRoomIndex />} />
            <Route path="/chatRooms/:chat_room_id" element={<ChatRoomDetail />} />
            <Route path="/chatRooms/:chat_room_id/update" element={<ChatRoomUpdate />} />
            <Route path="/chatMessages" element={<ChatMessageIndex />} />
            <Route path="/chatMessages/:chat_message_id" element={<ChatMessageDetail />} />
            <Route path="/chatMessages/:chat_message_id/update" element={<ChatMessageUpdate />} />
            <Route path="/notices" element={<NoticeIndex />} />
            <Route path="/notices/:notice_id" element={<NoticeDetail />} />
            <Route path="/notices/:notice_id/update" element={<NoticeUpdate />} />
            <Route path="/studyReports" element={<StudyReportIndex />} />
            <Route path="/studyReports/:study_report_id" element={<StudyReportDetail />} />
            <Route path="/studyReports/:study_report_id/update" element={<StudyReportUpdate />} />
            <Route path="/studyReportDetails" element={<StudyReportDetailIndex />} />
            <Route
              path="/studyReportDetails/:study_report_detail_id"
              element={<StudyReportDetailDetail />}
            />
            <Route
              path="/studyReportDetails/:study_report_detail_id/update"
              element={<StudyReportDetailUpdate />}
            />
            <Route path="/studyReportComments" element={<StudyReportCommentIndex />} />
            <Route
              path="/studyReportComments/:study_report_comment_id"
              element={<StudyReportCommentDetail />}
            />
            <Route
              path="/studyReportComments/:study_report_comment_id/update"
              element={<StudyReportCommentUpdate />}
            />
            <Route path="/studyTagSummaries" element={<StudyTagSummaryIndex />} />
            <Route
              path="/studyTagSummaries/:study_tag_summary_id"
              element={<StudyTagSummaryDetail />}
            />
            <Route path="/studyFavoriteSummaries" element={<StudyFavoriteSummaryIndex />} />
            <Route
              path="/studyFavoriteSummaries/:study_favorite_summary_id"
              element={<StudyFavoriteSummaryDetail />}
            />
            <Route path="/studySummaries" element={<StudySummaryIndex />} />
            <Route path="/studySummaries/:study_summary_id" element={<StudySummaryDetail />} />
            <Route path="/banners" element={<BannerIndex />} />
            <Route path="/banners/:banner_id" element={<BannerDetail />} />
            <Route path="/banners/:banner_id/update" element={<BannerUpdate />} />
            <Route path="/banner_cids" element={<BannerCidIndex />} />
            <Route path="/banner_cids/:cid" element={<BannerCidDetail />} />
            <Route path="/banner_cids/:cid/update" element={<BannerCidUpdate />} />
            <Route path="/campaigns" element={<CampaignIndex />} />
            <Route path="/campaigns/:campaign_id" element={<CampaignDetail />} />
            <Route path="/campaigns/:campaign_id/update" element={<CampaignUpdate />} />
            <Route path="/ab_tests" element={<AbTestIndex />} />
            <Route path="/ab_tests/:ab_test_id" element={<AbTestDetail />} />
            <Route path="/ab_tests/:ab_test_id/update" element={<AbTestUpdate />} />
            <Route path="/invoices" element={<InvoiceIndex />} />
            <Route path="/invoices/:invoice_id" element={<InvoiceDetail />} />
            <Route path="/invoiceItems" element={<InvoiceItemIndex />} />
            <Route path="/invoiceItems/:invoice_item_id" element={<InvoiceItemDetail />} />
            <Route path="/paymentMethods" element={<PaymentMethodIndex />} />
            <Route path="/paymentMethods/:payment_method_id" element={<PaymentMethodDetail />} />
            <Route path="/paymentIntents" element={<PaymentIntentIndex />} />
            <Route path="/paymentIntents/:payment_intent_id" element={<PaymentIntentDetail />} />
            <Route path="/subscriptions" element={<SubscriptionIndex />} />
            <Route path="/subscriptions/:subscription_id" element={<SubscriptionDetail />} />
            <Route path="/subscriptionContracts" element={<SubscriptionContractIndex />} />
            <Route
              path="/subscriptionContracts/:subscription_contract_id"
              element={<SubscriptionContractDetail />}
            />
            <Route path="/stripeSubscriptions" element={<StripeSubscriptionIndex />} />
            <Route
              path="/stripeSubscriptions/:stripe_subscription_id"
              element={<StripeSubscriptionDetail />}
            />
            <Route path="/stripeCustomers" element={<StripeCustomerIndex />} />
            <Route path="/stripeCustomers/:stripe_customer_id" element={<StripeCustomerDetail />} />
            <Route path="/subscriptionHistories" element={<SubscriptionHistoryIndex />} />
            <Route
              path="/subscriptionHistories/:subscription_history_id"
              element={<SubscriptionHistoryDetail />}
            />
            <Route path="/products" element={<ProductIndex />} />
            <Route path="/products/:product_id" element={<ProductDetail />} />
            <Route path="/prices" element={<PriceIndex />} />
            <Route path="/prices/:price_id" element={<PriceDetail />} />
            <Route path="/promotionCodeTransactions" element={<PromotionCodeTransactionIndex />} />
            <Route
              path="/promotionCodeTransactions/:promotion_code_transaction_id"
              element={<PromotionCodeTransactionDetail />}
            />
            <Route path="/instructorSchedules" element={<InstructorScheduleIndex />} />
            <Route
              path="/instructorSchedules/:instructor_schedule_id"
              element={<InstructorScheduleDetail />}
            />
            <Route
              path="/instructorSchedules/:instructor_schedule_id/update"
              element={<InstructorScheduleUpdate />}
            />
            <Route path="/tickets" element={<TicketIndex />} />
            <Route path="/tickets/:ticket_id" element={<TicketDetail />} />
            <Route path="/tickets/:ticket_id/update" element={<TicketUpdate />} />
            <Route path="/spotLessons" element={<SpotLessonIndex />} />
            <Route path="/spotLessons/:spot_lesson_id" element={<SpotLessonDetail />} />
            <Route path="/spotLessons/:spot_lesson_id/update" element={<SpotLessonUpdate />} />
            <Route path="/tweets" element={<TweetIndex />} />
            <Route path="/tweets/:tweet_id" element={<TweetDetail />} />
            <Route path="/tweetComments" element={<TweetCommentIndex />} />
            <Route path="/tweetComments/:tweet_comment_id" element={<TweetCommentDetail />} />
            <Route path="/spCoupons" element={<SpCouponIndex />} />
            <Route path="/spCoupons/:sp_coupon_id" element={<SpCouponDetail />} />
            <Route path="/spDiscounts" element={<SpDiscountIndex />} />
            <Route path="/spDiscounts/:sp_discount_id" element={<SpDiscountDetail />} />
            <Route path="/spDiscountCoupons" element={<SpDiscountCouponIndex />} />
            <Route
              path="/spDiscountCoupons/:sp_discount_coupon_id"
              element={<SpDiscountCouponDetail />}
            />
            <Route path="/spPromotionCodes" element={<SpPromotionCodeIndex />} />
            <Route
              path="/spPromotionCodes/:sp_promotion_code_id"
              element={<SpPromotionCodeDetail />}
            />
            <Route path="/teams" element={<TeamIndex />} />
            <Route path="/teams/:team_id" element={<TeamDetail />} />
            <Route path="/teams/:team_id/update" element={<TeamUpdate />} />
            <Route path="/teamMembers" element={<TeamMemberIndex />} />
            <Route path="/teamMembers/:team_member_id" element={<TeamMemberDetail />} />
            <Route path="/teamMembers/:team_member_id/update" element={<TeamMemberUpdate />} />
            <Route path="/programSamples" element={<ProgramSampleIndex />} />
            <Route path="/programSamples/:program_sample_id" element={<ProgramSampleDetail />} />
            <Route
              path="/programSamples/:program_sample_id/update"
              element={<ProgramSampleUpdate />}
            />
            <Route
              path="/graduationDateNotificationSendLogs"
              element={<GraduationDateNotificationSendLogIndex />}
            />
            <Route
              path="/graduationDateNotificationSendLogs/:course_id/:course_end_date"
              element={<GraduationDateNotificationSendLogDetail />}
            />
            <Route path="/practices" element={<PracticeIndex />} />
            <Route path="/practices/:practice_id" element={<PracticeDetail />} />
            <Route path="/practices/:practice_id/update" element={<PracticeUpdate />} />
            <Route path="/practiceSubmits" element={<PracticeSubmitIndex />} />
            <Route path="/practiceSubmits/:practice_submit_id" element={<PracticeSubmitDetail />} />
            <Route
              path="/practiceSubmits/:practice_submit_id/update"
              element={<PracticeSubmitUpdate />}
            />
            <Route path="/practiceSubmitDetails" element={<PracticeSubmitDetailIndex />} />
            <Route
              path="/practiceSubmitDetails/:practice_submit_detail_id"
              element={<PracticeSubmitDetailDetail />}
            />
            <Route
              path="/practiceSubmitDetails/:practice_submit_detail_id/update"
              element={<PracticeSubmitDetailUpdate />}
            />
            <Route path="*" element={<div>404 not found</div>} />
          </Routes>
        </div>
      </div>
    </ThemeProvider>
  );
};
