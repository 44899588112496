import React from 'react';

// Material UI
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetSpCouponForAdminQuery } from '../../../gen/graphql';
import { formatDate } from '../../const/Util';
import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ sp_coupon_id: string }>().sp_coupon_id ?? '0';

  const { data, loading } = useGetSpCouponForAdminQuery({
    variables: { id },
  });
  const spCoupon = data?.spCouponForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                SpCoupon Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          {spCoupon && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{spCoupon.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdId
                  </TableCell>
                  <TableCell>{spCoupon.pvdID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdCreated
                  </TableCell>
                  <TableCell>{formatDate(new Date(spCoupon.pvdCreated))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdAmountOff
                  </TableCell>
                  <TableCell>{spCoupon.pvdAmountOff}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdCurrency
                  </TableCell>
                  <TableCell>{spCoupon.pvdCurrency}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdDuration
                  </TableCell>
                  <TableCell>{spCoupon.pvdDuration}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdDurationInMonths
                  </TableCell>
                  <TableCell>{spCoupon.pvdDurationInMonths}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdMaxRedemptions
                  </TableCell>
                  <TableCell>{spCoupon.pvdMaxRedemptions}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdName
                  </TableCell>
                  <TableCell>{spCoupon.pvdName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdPercentOff
                  </TableCell>
                  <TableCell>{spCoupon.pvdPercentOff}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdRedeemBy
                  </TableCell>
                  <TableCell>
                    {spCoupon.pvdRedeemBy && formatDate(new Date(spCoupon.pvdRedeemBy))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdTimesRedeemed
                  </TableCell>
                  <TableCell>{spCoupon.pvdTimesRedeemed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdValid
                  </TableCell>
                  <TableCell>{ValidLabels[spCoupon.pvdValid]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SpPromotionCodes
                  </TableCell>
                  <TableCell>
                    <Link to={`/spPromotionCodes?spCouponId=${spCoupon.id}`}>SpPromotionCodes</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{formatDate(new Date(spCoupon.createdAt))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{formatDate(new Date(spCoupon.updatedAt))}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
