import React, { useState, useCallback } from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles, Theme } from '@material-ui/core';

// Material UI Form
import { FormControl, Input, InputLabel, TextField, Select } from '@material-ui/core';

// Material UI Other
import { MenuItem, Typography, Button, LinearProgress } from '@material-ui/core';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminSessionInput,
  useGetSessionForAdminQuery,
  useUpdateSessionForAdminMutation,
  SessionPhase,
  Valid,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { ValidLabels, strToValid } from '../../const/Valid';
import { GeneralChangeEvent } from '../../../types/Common';
import { getUTCDatetime } from '../../const/Util';
import { PhaseLabels } from '../../const/Session';

import { strToEnum } from '../../../utils/common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ session_id: string }>().session_id ?? '0';

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminSessionInput>({
    courseID: 0,
    assignedCoachID: 0,
    startAt: '',
    endAt: '',
    title: '',
    phase: SessionPhase.NotCompleted,
    status: Valid.Valid,
  });

  const { loading } = useGetSessionForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ sessionForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        courseID: data.coaching?.course?.id ?? 0,
        assignedCoachID: data.assignedCoach?.id ?? 0,
        startAt: data.startAt,
        endAt: data.endAt,
        title: data.title,
        phase: data.phase,
        status: data.status,
      });
    },
  });

  const [updateProgram, { error: updateError }] = useUpdateSessionForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateProgram({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateProgram, updateInput, id, navigate]),
  );

  const handleChangeTitle = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, title: value }));
  }, []);

  const handleChangeAssignedCoachID = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({
        ...current,
        assignedCoachID: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeStartAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        startAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeEndAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        endAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangePhase = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      const sessionPhase = strToEnum(String(value), SessionPhase);
      if (!sessionPhase) {
        return;
      }
      setUpdateInput((current) => ({ ...current, phase: sessionPhase }));
    },
    [],
  );

  const handleChangeStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Program Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                placeholder="セッションタイトル"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput?.title}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeTitle}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="assignedCoachID"
                name="assignedCoachID"
                label="assignedCoachID"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput?.assignedCoachID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAssignedCoachID}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="startAt"
                  label="開始時間"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput?.startAt}
                  onChange={handleChangeStartAt}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="endAt"
                  label="開始時間"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput?.endAt}
                  onChange={handleChangeEndAt}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="phase">Phase</InputLabel>
                <Select
                  value={updateInput?.phase}
                  onChange={handleChangePhase}
                  input={<Input name="phase" id="phase" />}
                >
                  {Object.entries(PhaseLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput?.status}
                  onChange={handleChangeStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
