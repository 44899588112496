import React, { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  AdminNoticeInput,
  AdminNoticeSearchInput,
  NoticeNotified,
  useCreateNoticeMutation,
  useGetNoticesQuery,
  UserRole,
  Valid,
} from '../../../gen/graphql';
import { strToEnum } from '../../../utils/common';
import { NotifiedLabels } from '../../const/Notice';
import { ValidLabels } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { Form } from './Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NoticeDefaultValues, noticeSchema } from '../../../formSchema/notice';
import { AdminNoticeSearchFormInput, SearchForm } from './SearchForm';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  formControl: {
    display: 'flex',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminNoticeSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const title = params.get('title') ?? '';
    const notified = params.get('notified') ?? '';
    const userRole = params.get('userRole') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      title: title.trim().length > 0 ? title.trim() : undefined,
      userRole: strToEnum(userRole, UserRole),
      notified: strToEnum(notified, NoticeNotified),
      status: strToEnum(status, Valid),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetNoticesQuery({
    variables: { input: currentSearchParams },
  });

  const [createNotice, { error: createError }] = useCreateNoticeMutation();

  // Add Dialog
  const [open, showCreateDialog] = React.useState(false);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminNoticeSearchInput): void => {
      navigate({
        pathname: '/notices',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const searchForm = useForm<AdminNoticeSearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      title: currentSearchParams.title,
      userRole: currentSearchParams.userRole,
      notified: currentSearchParams.notified,
      status: currentSearchParams.status,
    },
    // 検索してもヒットしないだけの為、バリデーションなし
  });

  // 検索
  const searchNotice = useCallback(
    async (input: AdminNoticeSearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  const createFormId = 'notice-create-form';
  const createForm = useForm<AdminNoticeInput>({
    resolver: yupResolver(noticeSchema),
    defaultValues: NoticeDefaultValues,
  });

  const handleClickCreate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminNoticeInput): Promise<void> => {
        try {
          await createNotice({ variables: { input } });
        } catch {
          return;
        }

        // ダイアログを閉じて入力フォーム初期化
        showCreateDialog(false);
        createForm.reset();

        // 再検索
        refetch({ input: currentSearchParams });
      },
      [createForm, createNotice, currentSearchParams, refetch],
    ),
  );

  const handleCreateDialogOpen = useCallback(() => showCreateDialog(true), []);
  const handleCreateDialogClose = useCallback(() => showCreateDialog(false), []);

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Notice
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleCreateDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={searchNotice} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">Notified</TableCell>
                  <TableCell align="right">UserRole</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.noticesForAdmin.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/notices/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.title}</TableCell>
                    <TableCell align="right">{row.content}</TableCell>
                    <TableCell align="right">{NotifiedLabels[row.notified]}</TableCell>
                    <TableCell align="right">
                      {row.noticePermissions.map((n) => n.userRole).join(', ')}
                    </TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.noticesForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      {/* Create */}
      <Dialog open={open} onClose={handleCreateDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <Form
            formId={createFormId}
            form={createForm}
            onSubmit={handleClickCreate}
            error={createError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateDialogClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" form={createFormId} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
