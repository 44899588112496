import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ApolloError } from '@apollo/client';

import { AdminPracticeInput } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import { Box, FormHelperText, Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';

import { ValidLabels } from '../../const/Valid';
import { AuthorizationTypeLabels } from '../../const/Authorization';
import { PracticeDifficultyLabels } from '../../const/PracticeDifficulty';
import { IsAiReviewEnabledLabels } from '../../const/Practice';

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

interface Props {
  formID: string;
  form: UseFormReturn<AdminPracticeInput>;
  onSubmit: (input: AdminPracticeInput) => void;
  error: ApolloError | undefined;
  isUpdate?: boolean;
}

export const Form: React.FC<Props> = ({ formID, form, onSubmit, error }): JSX.Element => {
  const classes = useStyles();
  const formErrors = form.formState.errors;

  return (
    <form id={formID} onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Controller
        name="programID"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="programID"
            label="Program ID"
            type="number"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.programID}
            helperText={formErrors.programID?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="key"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="key"
            label="Key"
            type="text"
            fullWidth
            placeholder="practices/practice_01"
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.key}
            helperText={formErrors.key?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="title"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.title}
            helperText={formErrors.title?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="description"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            multiline
            minRows={5}
            maxRows={Infinity}
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.description}
            helperText={formErrors.description?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="requireTime"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="requireTime"
            label="Require Time"
            type="number"
            fullWidth
            placeholder="36"
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: 1 }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.requireTime}
            helperText={formErrors.requireTime?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="content"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="content"
            label="Content"
            type="text"
            fullWidth
            multiline
            minRows={5}
            maxRows={Infinity}
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.content}
            helperText={formErrors.content?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="answer"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="answer"
            label="Answer"
            type="text"
            fullWidth
            multiline
            minRows={5}
            maxRows={Infinity}
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.answer}
            helperText={formErrors.answer?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="sortNumber"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="sortNumber"
            label="ソート順"
            type="number"
            fullWidth
            placeholder="36"
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: 0 }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.sortNumber}
            helperText={formErrors.sortNumber?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.type}>
        <InputLabel htmlFor="status">公開範囲</InputLabel>
        <Controller
          name="type"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="type" id="type" />}
              error={!!formErrors.type}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(AuthorizationTypeLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.status?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.difficulty}>
        <InputLabel htmlFor="difficulty">難易度</InputLabel>
        <Controller
          name="difficulty"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="difficulty" id="difficulty" />}
              error={!!formErrors.difficulty}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(PracticeDifficultyLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl fullWidth error={!!formErrors.isAiReviewEnabled}>
        <InputLabel htmlFor="isAiReviewEnabled">AI Review</InputLabel>
        <Controller
          name="isAiReviewEnabled"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="isAiReviewEnabled" id="isAiReviewEnabled" />}
              error={!!formErrors.isAiReviewEnabled}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(IsAiReviewEnabledLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.isAiReviewEnabled?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.status}>
        <InputLabel htmlFor="status">Status</InputLabel>
        <Controller
          name="status"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="status" id="status" />}
              error={!!formErrors.status}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(ValidLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.status?.message}</FormHelperText>
      </FormControl>
      {error?.graphQLErrors && (
        <Box className={classes.inputError}>
          <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
          {error.graphQLErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Box>
      )}
    </form>
  );
};
