import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  useGetPlanForAdminQuery,
  AdminPlanInput,
  Bool,
  Valid,
  useUpdatePlanForAdminMutation,
} from '../../../gen/graphql';
import { GeneralChangeEvent } from '../../../types/Common';
import { ValidLabels, strToValid } from '../../const/Valid';
import { BoolLabels, strToBool } from '../../const/Bool';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ plan_id: string }>().plan_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminPlanInput>({
    name: '',
    isExtension: Bool.True,
    isFlexibleCurriculum: Bool.True,
    isLessonPreparationAllowed: Bool.True,
    isQuestionAllowed: Bool.True,
    lessonMinutes: 0,
    status: Valid.Valid,
  });

  const { loading } = useGetPlanForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ planForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        name: data.name,
        isExtension: data.isExtension,
        isFlexibleCurriculum: data.isFlexibleCurriculum,
        isLessonPreparationAllowed: data.isLessonPreparationAllowed,
        isQuestionAllowed: data.isQuestionAllowed,
        lessonMinutes: data.lessonMinutes ?? 0,
        status: data.status,
      });
    },
  });

  const [updatePlan, { error: updateError }] = useUpdatePlanForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updatePlan({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updatePlan, updateInput, id, navigate]),
  );

  const handleChangeAddName = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, name: value }));
  }, []);

  const handleChangeAddLessonMinutes = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({
        ...current,
        lessonMinutes: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeAddIsExtension = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        isExtension: strToBool(String(value)) ?? Bool.True,
      }));
    },
    [],
  );

  const handleChangeAddIsLessonPreparationAllowed = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        isLessonPreparationAllowed: strToBool(String(value)) ?? Bool.True,
      }));
    },
    [],
  );

  const handleChangeAddIsQuestionAllowed = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        isQuestionAllowed: strToBool(String(value)) ?? Bool.True,
      }));
    },
    [],
  );

  const handleChangeAddIsFlexibleCurriculum = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        isFlexibleCurriculum: strToBool(String(value)) ?? Bool.True,
      }));
    },
    [],
  );

  const handleChangeAddStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Plan Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                name="name"
                label="Name"
                type="text"
                placeholder="plan name"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.name}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddName}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="lessonMinutes"
                name="lessonMinutes"
                label="LessonMinutes"
                type="number"
                placeholder="60"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.lessonMinutes}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddLessonMinutes}
                inputProps={{ min: 0 }}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="isExtension">IsExtension</InputLabel>
                <Select
                  value={updateInput.isExtension}
                  onChange={handleChangeAddIsExtension}
                  input={<Input name="isExtension" id="isExtension" />}
                >
                  {Object.entries(BoolLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="isLessonPreparationAllowed">
                  IsLessonPreparationAllowed
                </InputLabel>
                <Select
                  value={updateInput.isLessonPreparationAllowed}
                  onChange={handleChangeAddIsLessonPreparationAllowed}
                  input={
                    <Input name="isLessonPreparationAllowed" id="isLessonPreparationAllowed" />
                  }
                >
                  {Object.entries(BoolLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="isQuestionAllowed">IsQuestionAllowed</InputLabel>
                <Select
                  value={updateInput.isQuestionAllowed}
                  onChange={handleChangeAddIsQuestionAllowed}
                  input={<Input name="isQuestionAllowed" id="isQuestionAllowed" />}
                >
                  {Object.entries(BoolLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="isFlexibleCurriculum">IsFlexibleCurriculum</InputLabel>
                <Select
                  value={updateInput.isFlexibleCurriculum}
                  onChange={handleChangeAddIsFlexibleCurriculum}
                  input={<Input name="isFlexibleCurriculum" id="isFlexibleCurriculum" />}
                >
                  {Object.entries(BoolLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput.status}
                  onChange={handleChangeAddStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
