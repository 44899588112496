import { ClaimApplyStatus } from '../../gen/graphql';

export const ApplyStatusLabels = {
  [ClaimApplyStatus.Editable]: '未申請',
  [ClaimApplyStatus.Applied]: '申請済み',
  [ClaimApplyStatus.Approved]: '承認済み',
};

/**
 * Enumとnumber(DB値)のマッピング
 *
 * CSVダウンロードのみRESTでenumが使えないので、
 * numberに変換する必要があるため例外的に定義している(swaggerの型とあわせている)
 */
export const ApplyStatusNumValues = {
  [ClaimApplyStatus.Editable]: 1,
  [ClaimApplyStatus.Applied]: 2,
  [ClaimApplyStatus.Approved]: 3,
} as const;

export const strToClaimApplyStatus = (str: string | undefined): ClaimApplyStatus | undefined => {
  if (!str) return undefined;
  return Object.values(ClaimApplyStatus).find((v) => v === str);
};
