import React, { useState, useContext, useEffect } from 'react';

// Material UI
import { Paper, Grid, makeStyles, Theme } from '@material-ui/core';
// Material UI Dialog
// Material UI Table
// Material UI Expansion Panel
// Material UI Form
import TextField from '@material-ui/core/TextField';
// Material UI Icon
// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';

// Other
import { Auth } from 'aws-amplify';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/CommonProvider';
import { REGEX_PASSWORD } from './Login';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 360,
    margin: '48px auto',
    padding: '48px',
  },
  mainContent: {
    flex: 1,
    padding: '48px',
    background: '#eaeff1',
  },
  textField: {
    width: '100%',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  button: {
    width: '100%',
    height: 48,
    marginTop: 24,
  },
}));

export interface InputParam {
  email: string;
  verificationCode: string;
  password: string;
}

export const ChangePasswordVerification = (): JSX.Element => {
  const classes = useStyles();

  const [inputData, setInputData] = useState<InputParam>({
    email: '',
    verificationCode: '',
    password: '',
  });
  const { authState } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    checkAuthState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function checkAuthState() {
    // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
    // そもそもisChangePasswordの型がbooleanなので、nullと比較していることが意味不明。
    // eslint-disable-next-line eqeqeq
    if (authState.isChangePassword == null) {
      // 正常な画面遷移でない場合はloginに戻す
      navigate('/login');
    }
  }

  const handleInput: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    setInputData({
      ...inputData,
      [event.target.name]: event.target.value,
    });
    validate(event.target.name, event.target.value);
  };

  const [error, setError] = React.useState('');

  const validate = (field: string, value: string) => {
    let error = '';
    if (field === 'email' && !value) {
      error = 'メールアドレスを入力してください';
    } else if (field === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = '正しい形式のメールアドレスを入力してください';
    } else if (field === 'verificationCode' && !value) {
      error = '確認コードを入力してください';
    } else if (field === 'password' && !value) {
      error = 'パスワードを入力してください';
    } else if (field === 'password' && value.length < 8) {
      error = 'パスワードは8文字以上してください';
    } else if (field === 'password' && !REGEX_PASSWORD.test(value)) {
      error = '大文字・小文字・数字・記号のいずれかが足りません';
    }
    setError(error);
  };

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    try {
      await Auth.forgotPasswordSubmit(
        inputData.email,
        inputData.verificationCode,
        inputData.password,
      );
      navigate('/login');
    } catch (error) {
      setError((error as Error).message);
    }
  };

  return (
    <div className={classes.mainContent}>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit}>
          <Grid container direction="column" alignItems="center">
            <Grid item style={{ margin: 24 }}>
              <Typography variant="h4">侍テラコヤ</Typography>
            </Grid>
            <Grid style={{ width: '100%' }}>
              <Divider />
            </Grid>
            <Grid item style={{ margin: 24 }}>
              新しいパスワードを設定
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <TextField
                id="verificationCode"
                label="確認コード"
                className={classes.textField}
                type="verificationCode"
                name="verificationCode"
                autoComplete="verificationCode"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={handleInput}
              />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <TextField
                id="email"
                label="Email"
                className={classes.textField}
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={handleInput}
              />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <TextField
                id="password"
                label="Password"
                className={classes.textField}
                type="password"
                name="password"
                autoComplete="password"
                margin="normal"
                variant="outlined"
                fullWidth
                onChange={handleInput}
              />
            </Grid>
            {error && (
              <Grid item style={{ marginTop: 12, color: '#FF3333' }}>
                {error}
              </Grid>
            )}
            <Button
              variant="contained"
              size="large"
              color="primary"
              className={classes.button}
              type="submit"
            >
              設定
            </Button>
            <Grid item style={{ marginTop: 36 }}>
              ログイン画面は<Link to="/login">こちら</Link>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};
