import React, { useState, useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, Checkbox, makeStyles } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Date-fns
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';

// Material UI Date
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { formatRequestDate, getUTCDatetime, getYYYYMM } from '../../const/Util';
import {
  useGetClaimsForAdminQuery,
  useAddClaimForAdminMutation,
  AdminClaimSearchInput,
  AdminClaimCreateInput,
  Valid,
  ClaimApplyStatus,
  useUpdateClaimsToApproveForAdminMutation,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { ApplyStatusLabels, strToClaimApplyStatus } from '../../const/Claim';
import { GeneralChangeEvent } from '../../../types/Common';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { startOfMonth } from 'date-fns/esm';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

// 登録フォームの初期値
const addInputDefaultValues = {
  instructorID: 0,
  adminClaimInput: {
    appliedAt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    applyStatus: ClaimApplyStatus.Editable,
    month: format(startOfMonth(new Date()), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    remark: '',
    status: Valid.Valid,
  },
};
type SearchFormInput = Omit<AdminClaimSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminClaimSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const instructorID = params.get('instructorID') ?? '';
    const appliedAt = params.get('appliedAt') ?? '';
    const applyStatus = params.get('applyStatus') ?? undefined;
    const month = params.get('month') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      instructorID: parseInt(instructorID) > 0 ? parseInt(instructorID) : undefined,
      appliedAt: appliedAt ? getUTCDatetime(appliedAt) : undefined,
      applyStatus: strToClaimApplyStatus(applyStatus),
      month: month !== '' ? month : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetClaimsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });
  const claims = data?.claimsForAdmin.items ?? [];
  const total = data?.claimsForAdmin.total ?? 0;

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  const [addInput, setAddInput] = useState<AdminClaimCreateInput>(addInputDefaultValues);

  // checkbox
  const [selectedIDs, setSelectedIDs] = React.useState<number[]>([]);

  // Add Dialog
  const [open, showAddDialog] = React.useState(false);

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  // データ登録
  const [addClaim, { error: addError }] = useAddClaimForAdminMutation();

  const [updateClaimsToApprove] = useUpdateClaimsToApproveForAdminMutation();

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminClaimSearchInput): void => {
      navigate({
        pathname: '/claims',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 再検索
  const refetchClaims = useCallback(
    async (params: AdminClaimSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      instructorID: currentSearchParams.instructorID,
      appliedAt: currentSearchParams.appliedAt,
      applyStatus: currentSearchParams.applyStatus,
      month: currentSearchParams.month,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      setSelectedIDs([]);

      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };

      if (input.month) {
        const date = new Date(input.month);
        params.month = getYYYYMM(`${date.getFullYear()}${`00${date.getMonth() + 1}`.slice(-2)}`);
      }

      params.appliedAt =
        input.appliedAt && !Number.isNaN(new Date(input.appliedAt).getDate())
          ? formatRequestDate(new Date(input.appliedAt))
          : undefined;

      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchClaims(params);
      }
      createHistory(params);
    },
    [refetchClaims, createHistory, currentSearchParams],
  );

  const handleClickBulkApproval = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!window.confirm('請求申請を一括承認しますか')) return;

      setShowLoader(true);
      try {
        await updateClaimsToApprove({
          variables: {
            ids: selectedIDs,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      setSelectedIDs([]);

      // 再検索
      refetchClaims(currentSearchParams);
    }, [currentSearchParams, refetchClaims, selectedIDs, updateClaimsToApprove]),
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      setSelectedIDs([]);
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      setSelectedIDs([]);
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // データ作成ボタンクリック
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await addClaim({
          variables: {
            input: addInput,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      // ダイアログを閉じて入力フォームとチェックボックス初期化
      showAddDialog(false);
      setAddInput(addInputDefaultValues);
      setSelectedIDs([]);

      // 再検索
      refetchClaims(currentSearchParams);
    }, [addClaim, refetchClaims, currentSearchParams, addInput]),
  );

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelectedIDs = claims.map((row) => row.id);
      setSelectedIDs(newSelectedIDs);
      return;
    }
    setSelectedIDs([]);
  };

  const handleSelectOne = (id: number) => {
    const otherSelectedIDs = selectedIDs.filter((selectedID) => selectedID !== id);

    if (selectedIDs.length === otherSelectedIDs.length) {
      setSelectedIDs([...selectedIDs, id]);
    } else {
      setSelectedIDs(otherSelectedIDs);
    }
  };

  const handleChangeAddInstructorID = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        instructorID: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeAddMonth = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setAddInput((current) => ({
        ...current,
        adminClaimInput: {
          ...current.adminClaimInput,
          month: value ? getYYYYMM(value) : '',
        },
      }));
    },
    [],
  );

  const handleChangeAddRemark = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      adminClaimInput: {
        ...current.adminClaimInput,
        remark: value,
      },
    }));
  }, []);

  const handleChangeAddApplyStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        adminClaimInput: {
          ...current.adminClaimInput,
          applyStatus: strToClaimApplyStatus(String(value)) ?? ClaimApplyStatus.Editable,
        },
      }));
    },
    [],
  );

  const handleChangeAddAppliedAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setAddInput((current) => ({
        ...current,
        adminClaimInput: {
          ...current.adminClaimInput,
          appliedAt: value ? getUTCDatetime(value) : '',
        },
      }));
    },
    [],
  );

  const handleChangeAddStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        adminClaimInput: {
          ...current.adminClaimInput,
          status: strToValid(String(value)) ?? Valid.Valid,
        },
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Claim
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="instructorID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="instructorID"
                          label="InstructorID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="month"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month']}
                            margin="normal"
                            id="month"
                            label="Month"
                            format="yyyyMM"
                            value={value ?? null}
                            fullWidth
                            inputRef={ref}
                            {...rest}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="applyStatus">ApplyStatus</InputLabel>
                      <Controller
                        name="applyStatus"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(ApplyStatusLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="appliedAt"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="appliedAt"
                            label="AppliedAt"
                            format="yyyy/MM/dd"
                            value={value ?? null}
                            fullWidth
                            inputRef={ref}
                            {...rest}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={selectedIDs.length > 0 && selectedIDs.length < claims.length}
                      checked={claims.length > 0 && selectedIDs.length === claims.length}
                      onChange={handleSelectAll}
                      inputProps={{
                        'aria-label': 'select all desserts',
                      }}
                    />
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Instructor</TableCell>
                  <TableCell align="right">Month</TableCell>
                  <TableCell align="right">Remark</TableCell>
                  <TableCell align="right">ApplyStatus</TableCell>
                  <TableCell align="right">AppliedAt</TableCell>
                  <TableCell align="right">ClaimDetails</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {claims.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={selectedIDs.includes(row.id)}
                        onChange={() => handleSelectOne(row.id)}
                        inputProps={{
                          'aria-labelledby': `enhanced-table-checkbox-${row.id}`,
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/claims/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/instructors/${row.instructor!.id}`}>
                        {row.instructor?.user.personalInfo?.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{row.month}</TableCell>
                    <TableCell align="right">{row.remark}</TableCell>
                    <TableCell align="right">
                      {row.applyStatus ? ApplyStatusLabels[row.applyStatus] : '-'}
                    </TableCell>
                    <TableCell align="right">{row.appliedAt}</TableCell>
                    <TableCell align="right">
                      <Link to={`/claimDetails?claimID=${row.id}`}>ClaimDetails</Link>
                    </TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleClickBulkApproval}
                disabled={selectedIDs.length === 0}
              >
                一括承認
              </Button>
            </Grid>
            <Grid item>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={total}
                rowsPerPage={currentSearchParams.limit}
                page={currentSearchParams.page - 1}
                backIconButtonProps={{
                  'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                  'aria-label': 'next page',
                }}
                onPageChange={(_, page) => changePage(page)}
                onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
              />
            </Grid>
          </Grid>
        </Paper>
      </main>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="instructorID"
            name="instructorID"
            label="InstructorId"
            type="number"
            defaultValue={addInput.instructorID}
            fullWidth
            onChange={handleChangeAddInstructorID}
            inputProps={{ min: 1 }}
          />
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="month"
                label="Month"
                format="yyyyMM"
                value={addInput.adminClaimInput.month}
                onChange={handleChangeAddMonth}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                views={['year', 'month']}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="remark"
            name="remark"
            label="Remark"
            type="text"
            defaultValue={addInput.adminClaimInput.remark}
            fullWidth
            onChange={handleChangeAddRemark}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="applyStatus">ApplyStatus</InputLabel>
              <Select
                value={addInput.adminClaimInput.applyStatus}
                onChange={handleChangeAddApplyStatus}
                input={<Input name="applyStatus" id="applyStatus" />}
              >
                {Object.entries(ApplyStatusLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="appliedAt"
                label="申請時間"
                format="yyyy-MM-dd HH:mm"
                value={addInput.adminClaimInput.appliedAt}
                onChange={handleChangeAddAppliedAt}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={addInput.adminClaimInput.status}
                onChange={handleChangeAddStatus}
                input={<Input name="status" id="status" />}
              >
                {Object.entries(ValidLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
