import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AdminTagInput, UserRole } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { ApolloError } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { Box, FormHelperText, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

interface Props {
  formId: string;
  form: UseFormReturn<AdminTagInput>;
  onSubmit: (input: AdminTagInput) => void;
  error: ApolloError | undefined;
  isUpdate?: boolean;
}

export const Form: React.FC<Props> = ({ formId, form, onSubmit, error }): JSX.Element => {
  const classes = useStyles();
  const formErrors = form.formState.errors;

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Controller
        name="name"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="name"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.name}
            helperText={formErrors.name?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <span style={{ color: 'red' }}>
        *nameを変更する際は開発チームにご確認願います。
        <br />
        入会後アンケートの「学習予定の言語・技術」に利用している場合、変更するとアンケートデータのSalesforce連携に影響があるためです。
      </span>
      <Controller
        name="overview"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="overview"
            label="overview"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.overview}
            helperText={formErrors.overview?.message}
            inputRef={ref}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.userRoles}>
        <InputLabel htmlFor="userRoles">userRoles</InputLabel>
        <Controller
          name="userRoles"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select multiple error={!!formErrors.userRoles} inputRef={ref} {...rest}>
              {Object.entries(UserRole)
                .filter(
                  ([_, label]) =>
                    // 旧SubscriptionロールはLMSでは使用していないため除く
                    //（Stripe上では旧Subscriptionロールを使っているユーザーが存在する）
                    label !== UserRole.Subscription && label !== UserRole.StudentSubscription,
                )
                .map(([value, label]) => (
                  <MenuItem value={label} key={value}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.userRoles?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.status}>
        <InputLabel htmlFor="status">Status</InputLabel>
        <Controller
          name="status"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="status" id="status" />}
              error={!!formErrors.status}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(ValidLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.status?.message}</FormHelperText>
      </FormControl>
      {error?.graphQLErrors && (
        <Box className={classes.inputError}>
          <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
          {error.graphQLErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Box>
      )}
    </form>
  );
};
