import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AdminBannerSearchInput } from '../../../gen/graphql';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { ValidLabels } from '../../const/Valid';
import { UserTargetLabels } from '../../const/Banner';

const useStyles = makeStyles((_theme: Theme) => ({
  block: {
    display: 'block',
  },
}));

export type AdminBannerSearchFormInput = Omit<AdminBannerSearchInput, 'limit' | 'page'>;

interface Props {
  form: UseFormReturn<AdminBannerSearchFormInput>;
  onSubmit: (input: AdminBannerSearchFormInput) => void;
}

export const SearchForm: React.FC<Props> = ({ form, onSubmit }) => {
  const classes = useStyles();

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div>
            <Typography>検索</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <Controller
                name="id"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="ID"
                    type="number"
                    fullWidth
                    inputProps={{ min: 1 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="title"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Title"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="imageURL"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="imageUrl"
                    label="ImageUrl"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="link"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="link"
                    label="Link"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="sort"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="sort"
                    label="Sort"
                    type="number"
                    fullWidth
                    inputProps={{ min: 0 }}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Controller
                  name="status"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value={undefined}>&nbsp;</MenuItem>
                      {Object.entries(ValidLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="isNotLoginUser">IsNotLoginUser</InputLabel>
                <Controller
                  name="isNotLoginUser"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value={undefined}>&nbsp;</MenuItem>
                      {Object.entries(UserTargetLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="isTargetFree">IsTargetFree</InputLabel>
                <Controller
                  name="isTargetFree"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value={undefined}>&nbsp;</MenuItem>
                      {Object.entries(UserTargetLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="isTargetSubscriptionUser">IsTargetSubscriptionUser</InputLabel>
                <Controller
                  name="isTargetSubscriptionUser"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value={undefined}>&nbsp;</MenuItem>
                      {Object.entries(UserTargetLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="isTargetStudent">IsTargetStudent</InputLabel>
                <Controller
                  name="isTargetStudent"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value={undefined}>&nbsp;</MenuItem>
                      {Object.entries(UserTargetLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="isTargetGraduate">IsTargetGraduate</InputLabel>
                <Controller
                  name="isTargetGraduate"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value={undefined}>&nbsp;</MenuItem>
                      {Object.entries(UserTargetLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="isTargetInstructor">IsTargetInstructor</InputLabel>
                <Controller
                  name="isTargetInstructor"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value={undefined}>&nbsp;</MenuItem>
                      {Object.entries(UserTargetLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="isTargetTeamUser">IsTargetTeamUser</InputLabel>
                <Controller
                  name="isTargetTeamUser"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value={undefined}>&nbsp;</MenuItem>
                      {Object.entries(UserTargetLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider light />
        <AccordionActions>
          <Button size="small" color="primary" type="submit">
            <SearchIcon className={classes.block} />
            Search
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  );
};
