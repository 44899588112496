import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Theme, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { TicketTypeLabels } from '../../../types/Ticket';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useTicketForAdminQuery } from '../../../gen/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const ticketID = useParams<{ ticket_id: string }>().ticket_id;
  const id = Number(ticketID);

  const { data, loading } = useTicketForAdminQuery({
    variables: { id },
  });
  const ticket = data?.ticketForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Ticket Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/tickets/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          {ticket && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{ticket.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UserID
                  </TableCell>
                  <TableCell>{ticket.userID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ExpireAt
                  </TableCell>
                  <TableCell>{ticket.expireAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IsUsed
                  </TableCell>
                  <TableCell>{ticket.isUsed ? '使用済' : '未使用'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Type
                  </TableCell>
                  <TableCell>
                    {TicketTypeLabels[ticket.type] ? TicketTypeLabels[ticket.type] : ''}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{ticket.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{ticket.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
