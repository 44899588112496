import { Bool } from '../../gen/graphql';

export const BoolLabels = {
  [Bool.False]: '無効',
  [Bool.True]: '有効',
} as const;

export const strToBool = (str: string): Bool | undefined => {
  return Object.values(Bool).find((v) => v === str);
};
