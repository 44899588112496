import React, { useState, useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles, Theme } from '@material-ui/core';

// Material UI Table
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

// Material UI Expansion Panel
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions } from '@material-ui/core';

// Material UI Form
import { TextField } from '@material-ui/core';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import { Typography, Button, Divider, LinearProgress } from '@material-ui/core';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { AdminMailSettingSearchInput, useGetMailSettingsForAdminQuery } from '../../../gen/graphql';
import * as Sentry from '@sentry/browser';
import { ValidLabels } from '../../const/Valid';
import { formatDate } from '../../const/Util';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '0 36px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

type SearchFormInput = Omit<AdminMailSettingSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminMailSettingSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const userId = params.get('userID') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      userID: parseInt(userId) > 0 ? parseInt(userId) : undefined,
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetMailSettingsForAdminQuery({
    variables: { input: currentSearchParams },
  });

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminMailSettingSearchInput): void => {
      navigate({
        pathname: '/mailSettings',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      userID: currentSearchParams.userID,
    },
  });

  // 再検索
  const refetchMailSettings = useCallback(
    async (params: AdminMailSettingSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        Sentry.captureException(e);
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchMailSettings(params);
      }
      createHistory(params);
    },
    [refetchMailSettings, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Mail Setting
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="userID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="userId"
                          label="UserID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="center">User</TableCell>
                  <TableCell align="center">AttendanceStatusChanged</TableCell>
                  <TableCell align="center">BackSchool</TableCell>
                  <TableCell align="center">ClaimApplied</TableCell>
                  <TableCell align="center">LessonLogUnregistered</TableCell>
                  <TableCell align="center">LessonRegistered</TableCell>
                  <TableCell align="center">LessonRemind</TableCell>
                  <TableCell align="center">LessonUnregistered</TableCell>
                  <TableCell align="center">LessonScheduleUpdated</TableCell>
                  <TableCell align="center">MessageReceived</TableCell>
                  <TableCell align="center">Notice</TableCell>
                  <TableCell align="center">QuestionAnswerRegistered</TableCell>
                  <TableCell align="center">QuestionCommentRegistered</TableCell>
                  <TableCell align="center">QuestionRegistered</TableCell>
                  <TableCell align="center">StudyReportCommentRegistered</TableCell>
                  <TableCell align="center">StudyReportAchieved</TableCell>
                  <TableCell align="center">SpotLessonRegistered</TableCell>
                  <TableCell align="center">SpotLessonRemind</TableCell>
                  <TableCell align="center">SpotLessonCancel</TableCell>
                  <TableCell align="center">SpotLessonLogUnregistered</TableCell>
                  <TableCell align="center">TicketRemind</TableCell>
                  <TableCell align="center">SessionRemind</TableCell>
                  <TableCell align="center">PracticeSubmitRegistered</TableCell>
                  <TableCell align="center">PracticeSubmitReRegistered</TableCell>
                  <TableCell align="center">PracticeSubmitDetailReviewCommentRegistered</TableCell>
                  <TableCell align="center">CreatedAt</TableCell>
                  <TableCell align="center">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data?.getMailSettingsForAdmin.items.map((row) => (
                    <TableRow>
                      <TableCell component="th" scope="row">
                        <Link to={`/mailSettings/${row.id}`}>{row.id}</Link>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link to={`/users/${row.userID}`}>{row.user?.personalInfo?.name}</Link>
                      </TableCell>
                      <TableCell align="center">
                        {ValidLabels[row.attendanceStatusChanged]}
                      </TableCell>
                      <TableCell align="center">{ValidLabels[row.backSchool]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.claimApplied]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.lessonLogUnregistered]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.lessonRegistered]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.lessonRemind]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.lessonUnregistered]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.lessonScheduleUpdated]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.messageReceived]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.notice]}</TableCell>
                      <TableCell align="center">
                        {ValidLabels[row.questionAnswerRegistered]}
                      </TableCell>
                      <TableCell align="center">
                        {ValidLabels[row.questionCommentRegistered]}
                      </TableCell>
                      <TableCell align="center">{ValidLabels[row.questionRegistered]}</TableCell>
                      <TableCell align="center">
                        {ValidLabels[row.studyReportCommentRegistered]}
                      </TableCell>
                      <TableCell align="center">{ValidLabels[row.studyReportAchieved]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.spotLessonRegistered]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.spotLessonRemind]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.spotLessonCancel]}</TableCell>
                      <TableCell align="center">
                        {ValidLabels[row.spotLessonLogUnregistered]}
                      </TableCell>
                      <TableCell align="center">{ValidLabels[row.ticketRemind]}</TableCell>
                      <TableCell align="center">{ValidLabels[row.sessionRemind]}</TableCell>
                      <TableCell align="center">
                        {ValidLabels[row.practiceSubmitRegistered]}
                      </TableCell>
                      <TableCell align="center">
                        {ValidLabels[row.practiceSubmitReRegistered]}
                      </TableCell>
                      <TableCell align="center">
                        {ValidLabels[row.practiceSubmitDetailReviewCommentRegistered]}
                      </TableCell>
                      <TableCell align="center">{formatDate(new Date(row.createdAt))}</TableCell>
                      <TableCell align="center">{formatDate(new Date(row.updatedAt))}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.getMailSettingsForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
