import React, { useState, useCallback } from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles, Theme } from '@material-ui/core';

// Material UI Form
import {
  FormControl,
  Input,
  InputLabel,
  TextField,
  Select,
  Checkbox,
  ListItemText,
  Chip,
} from '@material-ui/core';

// Material UI Other
import { MenuItem, Typography, Button, LinearProgress } from '@material-ui/core';

// Original Component
import { ImageField } from '../../common/ImageField';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminProgramInput,
  ProgramCategory,
  ProgramType,
  useGetProgramForAdminQuery,
  useGetTagsForAdminQuery,
  Valid,
  useUpdateProgramForAdminMutation,
  UserRole,
} from '../../../gen/graphql';
import {
  ProgramTypeLabels,
  ProgramCategoryLabels,
  strToProgramType,
  strToProgramCategory,
} from '../../const/Program';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { ValidLabels, strToValid } from '../../const/Valid';
import { GeneralChangeEvent } from '../../../types/Common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ program_id: string }>().program_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminProgramInput>({
    category: ProgramCategory.Tech,
    description: '',
    icon: '',
    number: 0,
    repository: '',
    skill1: '',
    skill2: '',
    skill3: '',
    summary: '',
    summaryImage1: '',
    summaryImage2: '',
    summaryImage3: '',
    summaryImage4: '',
    summaryImage5: '',
    status: Valid.Valid,
    tags: [],
    thumbnail: '',
    title: '',
    type: ProgramType.Normal,
    userRoles: [],
  });

  const { loading } = useGetProgramForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ programForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        category: data.category,
        description: data.description,
        icon: data.icon,
        number: data.number,
        repository: data.repository,
        skill1: data.skill1,
        skill2: data.skill2,
        skill3: data.skill3,
        summary: data.summary,
        summaryImage1: data.summaryImage1,
        summaryImage2: data.summaryImage2,
        summaryImage3: data.summaryImage3,
        summaryImage4: data.summaryImage4,
        summaryImage5: data.summaryImage5,
        tags: data.tags && data.tags.length > 0 ? data.tags.map((tag) => Number(tag.id)) : [],
        thumbnail: data.thumbnail,
        title: data.title,
        type: data.type,
        status: data.status,
        userRoles: data.programPermissions
          .filter((e) => e.userRole !== null && e.userRole !== undefined)
          .map((e) => e.userRole) as UserRole[],
      });
    },
  });

  const { data: tagData } = useGetTagsForAdminQuery({
    variables: {
      input: {
        limit: 1500, // NOTE: 2022/06/27 500で足りなかったので1500に上限引き上げ
        page: 1,
      },
    },
  });

  const [updateProgram, { error: updateError }] = useUpdateProgramForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateProgram({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateProgram, updateInput, id, navigate]),
  );

  const handleChangeTitle = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, title: value }));
  }, []);

  const handleChangeDescription = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, description: value }));
  }, []);

  const handleChangeRepository = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, repository: value }));
  }, []);

  const handleChangeSummary = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, summary: value }));
  }, []);

  const handleChangeType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        type: strToProgramType(String(value)) ?? ProgramType.Normal,
      }));
    },
    [],
  );

  const handleChangeCategory = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        category: strToProgramCategory(String(value)) ?? ProgramCategory.Tech,
      }));
    },
    [],
  );

  const handleChangeAddUserRoles = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        userRoles: value as UserRole[],
      }));
    },
    [],
  );

  const handleChangeNumber = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, number: Number(value) }));
  }, []);

  const handleChangeStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  const isNumberArray = (val: unknown): val is number[] => {
    if (!Array.isArray(val)) return false;

    val.forEach((v) => {
      if (typeof v !== 'number') {
        return false;
      }
    });

    return true;
  };

  const handleChangeTag = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setUpdateInput((current) => ({
      ...current,
      tags: isNumberArray(event.target.value) ? event.target.value : [],
    }));
  };

  const handleImageUploaded = (name: string, path: string) => {
    setUpdateInput((current) => ({
      ...current,
      [name]: path,
    }));
  };

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Program Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                placeholder="Ruby on Rails 基礎編"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput?.title}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeTitle}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                placeholder="Webアプリケーションを構築するのに最適なフレームワーク"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput?.description}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeDescription}
              />
            </div>
            <ImageField
              name="icon"
              path={updateInput?.icon ?? ''}
              width={100}
              height={100}
              onImageUploaded={handleImageUploaded}
              setShowLoader={setShowLoader}
            />
            <ImageField
              name="thumbnail"
              path={updateInput?.thumbnail ?? ''}
              width={600}
              height={300}
              onImageUploaded={handleImageUploaded}
              setShowLoader={setShowLoader}
            />
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="repository"
                name="repository"
                label="Repository"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput?.repository}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeRepository}
              />
            </div>
            <TextField
              autoFocus
              margin="dense"
              id="summary"
              name="summary"
              label="Summary"
              type="text"
              placeholder="## 概要
                本教材ではRuby on Railsによるアプリケーションの開発方法を学び、実際にAmazon風のECサイトを作っていきます。
                また、仕様の検討方法や使用するライブラリの選定基準などについても解説します。
                各章ごとに機能を実装していくので、オリジナルのアプリケーションを作る際に、必要な機能だけをピックアップして実装したいときにも役立ちます。"
              InputLabelProps={{
                shrink: true,
              }}
              value={updateInput?.summary}
              multiline={true}
              minRows={5}
              maxRows={Infinity}
              fullWidth
              style={{ margin: '10px 0' }}
              onChange={handleChangeSummary}
            />
            <ImageField
              name="summaryImage1"
              path={updateInput?.summaryImage1 ?? ''}
              width={800}
              height={400}
              onImageUploaded={handleImageUploaded}
              setShowLoader={setShowLoader}
            />
            <ImageField
              name="summaryImage2"
              path={updateInput?.summaryImage2 ?? ''}
              width={800}
              height={400}
              onImageUploaded={handleImageUploaded}
              setShowLoader={setShowLoader}
            />
            <ImageField
              name="summaryImage3"
              path={updateInput?.summaryImage3 ?? ''}
              width={800}
              height={400}
              onImageUploaded={handleImageUploaded}
              setShowLoader={setShowLoader}
            />
            <ImageField
              name="summaryImage4"
              path={updateInput?.summaryImage4 ?? ''}
              width={800}
              height={400}
              onImageUploaded={handleImageUploaded}
              setShowLoader={setShowLoader}
            />
            <ImageField
              name="summaryImage5"
              path={updateInput?.summaryImage5 ?? ''}
              width={800}
              height={400}
              onImageUploaded={handleImageUploaded}
              setShowLoader={setShowLoader}
            />
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="type">Type</InputLabel>
                <Select
                  value={updateInput?.type}
                  onChange={handleChangeType}
                  input={<Input name="type" id="type" />}
                >
                  {Object.entries(ProgramTypeLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="category">Category</InputLabel>
                <Select
                  value={updateInput?.category}
                  onChange={handleChangeCategory}
                  input={<Input name="category" id="category" />}
                >
                  {Object.entries(ProgramCategoryLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="userRoles">UserRoles</InputLabel>
                <Select
                  multiple
                  value={updateInput.userRoles}
                  onChange={handleChangeAddUserRoles}
                  input={<Input name="userRoles" id="userRoles" />}
                >
                  {Object.entries(UserRole)
                    .filter(
                      ([_, label]) =>
                        // 旧SubscriptionロールはLMSでは使用していないため除く
                        //（Stripe上では旧Subscriptionロールを使っているユーザーが存在する）
                        label !== UserRole.Subscription && label !== UserRole.StudentSubscription,
                    )
                    .map(([value, label]) => (
                      <MenuItem value={label} key={value}>
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="number"
                name="number"
                label="Number"
                type="number"
                placeholder="36"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput?.number}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeNumber}
                inputProps={{ min: 0 }}
              />
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="tags">Tags</InputLabel>
                <Select
                  id="tags"
                  multiple
                  value={updateInput.tags}
                  onChange={handleChangeTag}
                  input={<Input name="tags" id="tags" />}
                  renderValue={(selected) => (
                    <div>
                      {isNumberArray(selected) &&
                        tagData?.getTagsForAdmin?.items
                          ?.filter((tag) => selected.indexOf(tag.id) >= 0)
                          .map((tag) => <Chip key={tag.id} label={tag.name} />)}
                    </div>
                  )}
                >
                  {tagData?.getTagsForAdmin?.items.map((tag) => (
                    <MenuItem key={tag.id} value={tag.id}>
                      <Checkbox checked={updateInput.tags.indexOf(tag.id) > -1} />
                      <ListItemText primary={tag.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl fullWidth>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput?.status}
                  onChange={handleChangeStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
