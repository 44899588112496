import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Navigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { AuthContext } from '../../context/CommonProvider';

import axios from 'axios';
import { useGetCurrentUserWithPermissionsLazyQuery } from '../../gen/graphql';

interface AuthenticationProps {
  children: React.ReactNode;
}

const setGlobalAuthorizationHeader = (jwtToken: string) => {
  axios.defaults.headers.common.Authorization = jwtToken ? `Bearer ${jwtToken}` : '';
};

// トークンリフレッシュ用のinterceptor
axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status !== 401 || error.config?.isRetry) {
      throw error;
    }
    const currentSession = await Auth.currentSession();

    const jwtToken = currentSession.getIdToken().getJwtToken();
    error.config.headers.Authorization = `Bearer ${jwtToken}`;
    setGlobalAuthorizationHeader(jwtToken);

    error.config.isRetry = true;
    return await axios.request(error.config);
  },
);

export const Authentication: React.FC<AuthenticationProps> = ({ children }): JSX.Element => {
  const { authState, setAuth } = useContext(AuthContext);
  const [loading, setLoading] = useState(!authState.isLogin); // すでにログインしてたらskip

  const [getCurrentUser] = useGetCurrentUserWithPermissionsLazyQuery();

  const setAuthData = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      setGlobalAuthorizationHeader(cognitoUser.signInUserSession.idToken.jwtToken);

      const { data } = await getCurrentUser();
      // adminだったらlogin状態にする
      if (data?.currentUserWithPermissions?.user?.isAdmin) {
        setAuth({
          ...authState,
          isLogin: true,
          cognitoUser,
          lmsUser: data.currentUserWithPermissions?.user,
        });
      }
    } catch {
      setGlobalAuthorizationHeader('');
    } finally {
      setLoading(false);
    }
  }, [authState, getCurrentUser, setAuth]);

  useEffect(() => {
    if (!authState.isLogin) {
      setAuthData();
    }
  }, [authState.isLogin, setAuthData]);

  return authState.isLogin ? <>{children}</> : loading ? <div /> : <Navigate to="/login" replace />;
};
