import { formatDate, getFileName } from '../components/const/Util';
import { saveAs } from 'file-saver';
import { stringify } from 'csv-stringify/browser/esm/sync';

export const createCSV = (
  csvLabel: string[],
  csvRecords: string[][],
  resourceName: string,
): void => {
  const output = stringify(csvRecords, {
    header: true,
    columns: csvLabel,
    bom: true,
  });
  const blob = new Blob([output], { type: 'text/csv' });
  const templateFileName = `${resourceName}_${formatDate(new Date())}.csv`;
  const fileName = getFileName(templateFileName);
  saveAs(blob, fileName);
};
