import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles, Theme } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetCourseProgramStepForAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ course_program_step_id: string }>().course_program_step_id ?? '0';

  const { data, loading } = useGetCourseProgramStepForAdminQuery({
    variables: { id },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                CourseProgramStep Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/courseProgramSteps/${data?.courseProgramStepForAdmin?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{data?.courseProgramStepForAdmin?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CourseProgramID
                </TableCell>
                <TableCell>
                  <Link
                    className={classes.block}
                    to={`/coursePrograms/${data?.courseProgramStepForAdmin?.courseProgramID}`}
                  >
                    {data?.courseProgramStepForAdmin?.courseProgram?.title}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Title
                </TableCell>
                <TableCell>{data?.courseProgramStepForAdmin?.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Description
                </TableCell>
                <TableCell>
                  <ReactMarkdown children={data?.courseProgramStepForAdmin?.description ?? ''} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Number
                </TableCell>
                <TableCell>{data?.courseProgramStepForAdmin?.number ?? ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CourseProgramStepItems
                </TableCell>
                <TableCell>
                  {data?.courseProgramStepForAdmin?.courseProgramStepItems?.map(
                    (courseProgramStepItem) => (
                      <Link
                        key={courseProgramStepItem.id}
                        className={classes.block}
                        to={`/programs/${courseProgramStepItem.program?.id}`}
                      >
                        {courseProgramStepItem.program?.title}
                      </Link>
                    ),
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>
                  {data?.courseProgramStepForAdmin?.status
                    ? ValidLabels[data?.courseProgramStepForAdmin?.status]
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{data?.courseProgramStepForAdmin?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{data?.courseProgramStepForAdmin?.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
    </div>
  );
};
