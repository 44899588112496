import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';
import ReactMarkdown from 'react-markdown';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetQuestionAnswerForAdminQuery } from '../../../gen/graphql';
import { QuestionAnswerStatusLabels } from '../../const/Question';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ question_answer_id: string }>().question_answer_id;
  const id = Number(paramID);

  const { data, loading } = useGetQuestionAnswerForAdminQuery({
    variables: { id },
  });
  const questionAnswer = data?.getQuestionAnswerForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                QuestionAnswer Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/questionAnswers/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          {questionAnswer && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{questionAnswer.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    QuestionID
                  </TableCell>
                  <TableCell>
                    <Link to={`/questions/${questionAnswer.question.id}`}>
                      {questionAnswer.question.title.substr(0, 20)}...
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/${questionAnswer.user.id}`}>
                      {questionAnswer.user.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Content
                  </TableCell>
                  <TableCell>
                    <ReactMarkdown children={questionAnswer.content} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{QuestionAnswerStatusLabels[questionAnswer.status]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    QuestionComments
                  </TableCell>
                  <TableCell>
                    <Link to={`/questionComments?questionAnswerID=${id}`}>QuestionComments</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{questionAnswer.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{questionAnswer.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
