import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  useGetSessionForAdminQuery,
  useGetSessionReportBySessionIdForAdminQuery,
  HasAnnouncementsType,
} from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import {
  PhaseLabels,
  Preparations,
  Schedules,
  Announcements,
  ResponseDeadline,
} from '../../const/Session';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ session_id: string }>().session_id ?? '0';

  const { data, loading } = useGetSessionForAdminQuery({
    variables: { id },
  });

  const { data: sessionReport } = useGetSessionReportBySessionIdForAdminQuery({
    variables: { sessionID: id },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Session Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/sessions/${data?.sessionForAdmin?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{data?.sessionForAdmin?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Title
                </TableCell>
                <TableCell>{data?.sessionForAdmin?.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Course
                </TableCell>
                <TableCell>
                  <Link to={`/courses/${data?.sessionForAdmin?.coaching.course.id}`}>
                    {data?.sessionForAdmin?.coaching.course.id}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Student
                </TableCell>
                <TableCell>
                  <Link to={`/students/${data?.sessionForAdmin?.coaching.course.student?.id}`}>
                    {data?.sessionForAdmin?.coaching.course.student?.user?.personalInfo?.name}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Coach
                </TableCell>
                <TableCell>
                  <Link to={`/instructors/${data?.sessionForAdmin?.assignedCoach?.id}`}>
                    {data?.sessionForAdmin?.assignedCoach?.user?.personalInfo?.name}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  StartAt
                </TableCell>
                <TableCell>{data?.sessionForAdmin?.startAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  EndAt
                </TableCell>
                <TableCell>{data?.sessionForAdmin?.endAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Phase
                </TableCell>
                <TableCell>
                  {data?.sessionForAdmin?.phase ? PhaseLabels[data?.sessionForAdmin?.phase] : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Content
                </TableCell>
                <TableCell>
                  <Content>
                    {sessionReport?.sessionReportBySessionIDForAdmin?.content ?? '-'}
                  </Content>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Preparations
                </TableCell>
                <TableCell>
                  {sessionReport?.sessionReportBySessionIDForAdmin?.preparations.map((v) => {
                    return <p key={v.id}>{Preparations[v.type]}</p>;
                  }) ?? '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  PreparationOthers
                </TableCell>
                <TableCell>
                  <Content>
                    {sessionReport?.sessionReportBySessionIDForAdmin?.preparationOthers ?? '-'}
                  </Content>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Schedule
                </TableCell>
                <TableCell>
                  {sessionReport?.sessionReportBySessionIDForAdmin?.isOnSchedule
                    ? Schedules[sessionReport?.sessionReportBySessionIDForAdmin?.isOnSchedule]
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  DelayReason
                </TableCell>
                <TableCell>
                  {sessionReport?.sessionReportBySessionIDForAdmin?.delayReason ?? '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  HasAnnouncements
                </TableCell>
                <TableCell>
                  {sessionReport?.sessionReportBySessionIDForAdmin?.hasAnnouncements ===
                  HasAnnouncementsType.Yes
                    ? 'はい'
                    : 'いいえ'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Announcements
                </TableCell>
                <TableCell>
                  {sessionReport?.sessionReportBySessionIDForAdmin?.announcements.map((v) => {
                    return <p key={v.id}>{Announcements[v.type]}</p>;
                  }) ?? '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  HasAnnouncementDetail
                </TableCell>
                <TableCell>
                  <Content>
                    {sessionReport?.sessionReportBySessionIDForAdmin?.hasAnnouncementsDetail ?? '-'}
                  </Content>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ResponseDeadline
                </TableCell>
                <TableCell>
                  {sessionReport?.sessionReportBySessionIDForAdmin?.responseDeadline
                    ? ResponseDeadline[
                        sessionReport?.sessionReportBySessionIDForAdmin?.responseDeadline
                      ]
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>
                  {data?.sessionForAdmin?.status ? ValidLabels[data?.sessionForAdmin?.status] : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{data?.sessionForAdmin?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{data?.sessionForAdmin?.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
    </div>
  );
};

const Content = styled.div`
  white-space: pre-wrap;
`;
