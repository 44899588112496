import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminChapterInput,
  AuthorizationType,
  useUpdateChapterForAdminMutation,
  useGetChapterForAdminQuery,
  Valid,
} from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { AuthorizationTypeLabels } from '../../const/Authorization';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { chapterSchema, ChapterDefaultValues } from '../../../formSchema/chapter';

export interface ChapterInput {
  content: string;
  description?: string | null;
  key?: string | null;
  number?: number | null;
  programID: number;
  qaKey?: string | null;
  requireTime: number;
  sortNumber?: number | null;
  status: Valid;
  title: string;
  type: AuthorizationType;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ chapter_id: string }>().chapter_id;
  const id = Number(paramID);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ChapterInput>({
    resolver: yupResolver(chapterSchema),
    defaultValues: ChapterDefaultValues,
  });

  const { loading } = useGetChapterForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ chapterForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      reset({
        programID: data.programElement?.program.id,
        key: data.key,
        qaKey: data.qaKey ?? undefined, // inputにnullを渡すとwarningが出るのでundefinedに置き換え
        number: data.number,
        title: data.title,
        description: data.description,
        content: data.content,
        requireTime: data.requireTime,
        type: data.type,
        status: data.status,
        sortNumber: data.sortNumber,
      });
    },
  });

  const [updateChapterMutation, { error: updateError, loading: updating }] =
    useUpdateChapterForAdminMutation();

  const updateChapter = useSafeAsyncCallback(
    useCallback(
      async (chapterInput: ChapterInput): Promise<void> => {
        const adminChapterInput: AdminChapterInput = {
          programID: chapterInput.programID,
          key: chapterInput.key ?? '',
          qaKey: chapterInput.qaKey ?? '',
          number: chapterInput.number ?? 0,
          title: chapterInput.title,
          description: chapterInput.description ?? '',
          content: chapterInput.content,
          requireTime: chapterInput.requireTime,
          type: chapterInput.type,
          status: chapterInput.status,
          sortNumber: chapterInput.sortNumber ?? 0,
        };
        try {
          await updateChapterMutation({ variables: { id, input: adminChapterInput } });
        } catch {
          return;
        }

        navigate(-1);
      },
      [updateChapterMutation, id, navigate],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Section Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || updating) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(updateChapter)}>
            <div>
              <Controller
                name="programID"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="programId"
                    label="ProgramId"
                    type="number"
                    placeholder="12"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    inputProps={{ min: 1 }}
                    error={!!errors.programID}
                    helperText={errors.programID?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="title"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="title"
                    label="Title"
                    type="text"
                    placeholder="rails"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.title}
                    helperText={errors.title?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="description"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    placeholder="rails"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    multiline={true}
                    minRows={5}
                    maxRows={Infinity}
                    error={!!errors.description}
                    helperText={errors.description?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="content"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="content"
                    label="Content"
                    type="text"
                    placeholder="rails"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    multiline={true}
                    minRows={5}
                    maxRows={Infinity}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.content}
                    helperText={errors.content?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="requireTime"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="requireTime"
                    label="RequireTime"
                    type="number"
                    placeholder="36"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    inputProps={{ min: 1 }}
                    error={!!errors.requireTime}
                    helperText={errors.requireTime?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="key"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="key"
                    label="Key"
                    type="text"
                    placeholder="http://lms.sejuku.net/ruby_on_rails.jpg"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.key}
                    helperText={errors.key?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="qaKey"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="qaKey"
                    label="QaKey"
                    type="text"
                    placeholder="http://lms.sejuku.net/ruby_on_rails.jpg"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.qaKey}
                    helperText={errors.qaKey?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="number"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="number"
                    label="章番号"
                    type="number"
                    placeholder="36"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    inputProps={{ min: 0 }}
                    error={!!errors.number}
                    helperText={errors.number?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="sortNumber"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="sortNumber"
                    label="ソート順"
                    type="number"
                    placeholder="36"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    inputProps={{ min: 0 }}
                    error={!!errors.sortNumber}
                    helperText={errors.sortNumber?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="type" shrink>
                  公開範囲
                </InputLabel>
                <Controller
                  name="type"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Select error={!!errors.status} inputRef={ref} {...rest}>
                      {Object.entries(AuthorizationTypeLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status" shrink>
                  Status
                </InputLabel>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Select error={!!errors.status} inputRef={ref} {...rest}>
                      {Object.entries(ValidLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
