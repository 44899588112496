import { SubscriptionContractPhase, ExitFeeStatus } from '../../gen/graphql';

export const subscriptionContractPhaseLabels: { [index: string]: string } = {
  [SubscriptionContractPhase.Active]: '継続',
  [SubscriptionContractPhase.Canceled]: 'キャンセル',
  [SubscriptionContractPhase.ChangeContractPeriod]: '契約変更',
  [SubscriptionContractPhase.Completed]: '契約終了',
} as const;

export const exitFeeStatusLabels: { [index: string]: string } = {
  [ExitFeeStatus.Issued]: '支払済み',
  [ExitFeeStatus.NoNeed]: '支払不要',
  [ExitFeeStatus.NotCalculated]: '未計算',
} as const;
