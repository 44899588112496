import React, { useMemo } from 'react';
// Other
import { Link, useNavigate, useParams } from 'react-router-dom';

// Material UI
import { AppBar, Grid, makeStyles, Paper, Theme, Toolbar } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SnsTypeLabels } from '../../const/User';
import { formatDate } from '../../const/Util';
import { ValidLabels } from '../../const/Valid';
import {
  AdminChatRoomSearchInput,
  ChatRoomFragment,
  useGetChatRoomForAdminQuery,
  Valid,
} from '../../../gen/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
  invalidMember: {
    background: '#dddddd',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ chat_room_id: string }>().chat_room_id;
  const id = Number(paramID);

  // 検索パラメータ
  const searchParam = useMemo((): AdminChatRoomSearchInput => {
    return {
      id: id,
      limit: 1,
      page: 1,
    };
  }, [id]);

  //検索
  const { data, loading } = useGetChatRoomForAdminQuery({
    variables: {
      input: searchParam,
    },
  });

  const chatRoomDetail = data?.chatRoomsForAdmin.items[0] as ChatRoomFragment;

  if (!chatRoomDetail) return <LinearProgress />;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                ChatRoom Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/chatRooms/${chatRoomDetail.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{chatRoomDetail.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Title
                </TableCell>
                <TableCell>{chatRoomDetail.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>{ValidLabels[chatRoomDetail.status]}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ChatMessages
                </TableCell>
                <TableCell>
                  <Link to={`/chatMessages?chatRoomId=${chatRoomDetail.id}`}>ChatMessages</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{chatRoomDetail.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{chatRoomDetail.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        {chatRoomDetail?.chatMembers?.length > 0 && (
          <>
            <Typography color="inherit" variant="h6" component="h2">
              Users
            </Typography>
            <Paper className={classes.paperContent}>
              <Grid style={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ChatMember Status</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>StudentID</TableCell>
                      <TableCell align="right">Sub</TableCell>
                      <TableCell align="right">Name</TableCell>
                      <TableCell align="right">NickName</TableCell>
                      <TableCell align="right">KanaName</TableCell>
                      <TableCell align="right">Email</TableCell>
                      <TableCell align="right">Phone</TableCell>
                      <TableCell align="right">MobilePhone</TableCell>
                      <TableCell align="right">Image</TableCell>
                      <TableCell align="right">skypeID</TableCell>
                      <TableCell align="right">IsStudent</TableCell>
                      <TableCell align="right">IsInstructor</TableCell>
                      <TableCell align="right">IsAdmin</TableCell>
                      <TableCell align="right">SnsType</TableCell>
                      <TableCell align="right">ProviderCustomerID</TableCell>
                      <TableCell align="right">Status</TableCell>
                      <TableCell align="right">LastAccessedAt</TableCell>
                      <TableCell align="right">CreatedAt</TableCell>
                      <TableCell align="right">UpdatedAt</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {chatRoomDetail.chatMembers.map((row) => (
                      <TableRow
                        key={row.user?.id}
                        className={row.status === Valid.Invalid ? classes.invalidMember : ''}
                      >
                        <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                        <TableCell component="th" scope="row">
                          <Link to={`/users/${row.user?.id}`}>{row.user?.id}</Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.user?.student?.id ? (
                            <Link to={`/students/${row.user?.student?.id}`}>
                              {row.user?.student?.id}
                            </Link>
                          ) : (
                            '-'
                          )}
                        </TableCell>
                        <TableCell align="right">{row.user?.personalInfo?.sub}</TableCell>
                        <TableCell align="right">{row.user?.personalInfo?.name}</TableCell>
                        <TableCell align="right">{row.user?.nickName}</TableCell>
                        <TableCell align="right">{row.user?.personalInfo?.kanaName}</TableCell>
                        <TableCell align="right">{row.user?.personalInfo?.email}</TableCell>
                        <TableCell align="right">{row.user?.personalInfo?.phone}</TableCell>
                        <TableCell align="right">{row.user?.personalInfo?.mobilePhone}</TableCell>
                        <TableCell align="right">{row.user?.image}</TableCell>
                        <TableCell align="right">{row.user?.personalInfo?.skypeID}</TableCell>
                        <TableCell align="right">{row.user?.isStudent ? '有効' : '無効'}</TableCell>
                        <TableCell align="right">
                          {row.user?.isInstructor ? '有効' : '無効'}
                        </TableCell>
                        <TableCell align="right">{row.user?.isAdmin ? '有効' : '無効'}</TableCell>
                        <TableCell align="right">
                          {row.user?.snsType && SnsTypeLabels[row.user?.snsType]}
                        </TableCell>
                        <TableCell align="right">
                          {row.user?.personalInfo?.providerCustomerID}
                        </TableCell>
                        <TableCell align="right">{row.user?.status ? '有効' : '無効'}</TableCell>
                        <TableCell align="right">{row.user?.lastAccessedAt}</TableCell>
                        <TableCell align="right">
                          {row.user?.createdAt && formatDate(new Date(row.user?.createdAt))}
                        </TableCell>
                        <TableCell align="right">
                          {row.user?.updatedAt && formatDate(new Date(row.user?.updatedAt))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Grid>
            </Paper>
          </>
        )}
      </main>
    </div>
  );
};
