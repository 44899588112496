import React, { useState, useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Dialog
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

// Material UI Table
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

// Material UI Expansion Panel
import { Accordion, AccordionDetails, AccordionSummary, AccordionActions } from '@material-ui/core';

// Material UI Form
import {
  FormControl,
  Input,
  InputLabel,
  TextField,
  Select,
  Checkbox,
  ListItemText,
  Chip,
} from '@material-ui/core';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import { MenuItem, Typography, Button, Divider, LinearProgress } from '@material-ui/core';
//import Tooltip from "@material-ui/core/Tooltip";

// Original Component
import { ImageField } from '../../common/ImageField';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  useGetProgramsForAdminQuery,
  useGetTagsForAdminQuery,
  useAddProgramForAdminMutation,
  AdminProgramSearchInput,
  AdminProgramInput,
  ProgramCategory,
  ProgramType,
  Valid,
  UserRole,
} from '../../../gen/graphql';
import queryString from 'query-string';
import { GeneralChangeEvent } from '../../../types/Common';
import { ValidLabels, strToValid } from '../../const/Valid';
import {
  ProgramCategoryLabels,
  strToProgramCategory,
  ProgramTypeLabels,
  strToProgramType,
} from '../../const/Program';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { minutesToHours, strToEnum } from '../../../utils/common';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '0 36px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export interface SearchParam {
  id?: number;
  type?: number;
  category?: number;
  status?: number;
}

// 登録フォームの初期値
const addInputDefaultValues = {
  category: ProgramCategory.Tech,
  description: '',
  icon: '',
  number: 0,
  repository: '',
  skill1: '',
  skill2: '',
  skill3: '',
  summary: '',
  summaryImage1: '',
  summaryImage2: '',
  summaryImage3: '',
  summaryImage4: '',
  summaryImage5: '',
  tags: [],
  thumbnail: '',
  title: '',
  type: ProgramType.Normal,
  status: Valid.Valid,
  userRoles: [],
};
type SearchFormInput = Omit<AdminProgramSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminProgramSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const status = params.get('status') ?? '';
    const type = params.get('type') ?? '';
    const category = params.get('category') ?? '';
    const userRole = params.get('userRole') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      status: strToValid(status),
      type: strToProgramType(type),
      category: strToProgramCategory(category),
      userRole: strToEnum(userRole, UserRole),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetProgramsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  // Loading
  const [showLoader, setShowLoader] = useState(false);
  // Add Dialog
  const [open, showAddDialog] = useState(false);
  const [addInput, setAddInput] = useState<AdminProgramInput>(addInputDefaultValues);

  // データ登録
  const [addProgram, { error: addError }] = useAddProgramForAdminMutation();

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminProgramSearchInput): void => {
      navigate({
        pathname: '/programs',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 再検索
  const refetchPrograms = useCallback(
    async (params: AdminProgramSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      type: currentSearchParams.type,
      category: currentSearchParams.category,
      status: currentSearchParams.status,
      userRole: currentSearchParams.userRole,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchPrograms(params);
      }
      createHistory(params);
    },
    [refetchPrograms, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // データ作成ボタンクリック
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await addProgram({
          variables: {
            input: addInput,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      // ダイアログを閉じて入力フォーム初期化
      showAddDialog(false);
      setAddInput(addInputDefaultValues);

      // 再検索
      refetchPrograms(currentSearchParams);
    }, [addProgram, refetchPrograms, currentSearchParams, addInput]),
  );

  // フォーム用タグデータ
  const { data: tagData } = useGetTagsForAdminQuery({
    variables: {
      input: {
        limit: 500, // NOTE:既存が500だったため500を指定してるが、数が増えてきてLimit無効にする場合は対応を行う
        page: 1,
      },
    },
  });

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  const handleChangeAddTitle = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, title: value }));
  }, []);

  const handleChangeAddDescription = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({ ...current, description: value }));
    },
    [],
  );

  const handleChangeAddRepository = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({ ...current, repository: value }));
    },
    [],
  );

  const handleChangeAddSummary = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, summary: value }));
  }, []);

  const handleChangeAddType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        type: strToProgramType(String(value)) ?? ProgramType.Normal,
      }));
    },
    [],
  );

  const handleChangeAddCategory = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        category: strToProgramCategory(String(value)) ?? ProgramCategory.Tech,
      }));
    },
    [],
  );

  const handleChangeAddUserRoles = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        userRoles: value as UserRole[],
      }));
    },
    [],
  );

  const handleChangeAddNumber = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, number: Number(value) }));
  }, []);

  const handleChangeAddStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  const handleChangeAddTag = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
  ) => {
    setAddInput((current) => ({
      ...current,
      tags: event.target.value as number[],
    }));
  };

  const handleAddImageUploaded = (name: string, path: string) => {
    setAddInput((current) => ({
      ...current,
      [name]: path,
    }));
  };

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Program
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="type">Type</InputLabel>
                      <Controller
                        name="type"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(ProgramTypeLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="category">Category</InputLabel>
                      <Controller
                        name="category"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(ProgramCategoryLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="userRole">UserRole</InputLabel>
                      <Controller
                        name="userRole"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(UserRole).map(([value, label]) => (
                              <MenuItem value={label} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Chapters</TableCell>
                  <TableCell align="right">ChapterCount</TableCell>
                  <TableCell align="right">RequireTime</TableCell>
                  <TableCell align="right">Icon</TableCell>
                  <TableCell align="right">Thumbnail</TableCell>
                  <TableCell align="right">Repository</TableCell>
                  <TableCell align="right">Summary</TableCell>
                  <TableCell align="right">SummaryImage1</TableCell>
                  <TableCell align="right">SummaryImage2</TableCell>
                  <TableCell align="right">SummaryImage3</TableCell>
                  <TableCell align="right">SummaryImage4</TableCell>
                  <TableCell align="right">SummaryImage5</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Category</TableCell>
                  <TableCell align="right">UserRole</TableCell>
                  <TableCell align="right">Number</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.programsForAdmin.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/programs/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/programs/${row.id}`}>{row.title}</Link>
                    </TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/chapters?programID=${row.id}`}>Chapters</Link>
                    </TableCell>
                    <TableCell align="right">{row.chapterCount}個</TableCell>
                    <TableCell align="right">{minutesToHours(row.requireTime)}時間</TableCell>
                    <TableCell align="right">{row.icon}</TableCell>
                    <TableCell align="right">{row.thumbnail}</TableCell>
                    <TableCell align="right">{row.repository}</TableCell>
                    <TableCell align="right">
                      {row.summary ? row.summary!.substr(0, 20) : ''}
                    </TableCell>
                    <TableCell align="right">{row.summaryImage1}</TableCell>
                    <TableCell align="right">{row.summaryImage2}</TableCell>
                    <TableCell align="right">{row.summaryImage3}</TableCell>
                    <TableCell align="right">{row.summaryImage4}</TableCell>
                    <TableCell align="right">{row.summaryImage5}</TableCell>
                    <TableCell align="right">{ProgramTypeLabels[row.type]}</TableCell>
                    <TableCell align="right">{ProgramCategoryLabels[row.category]}</TableCell>
                    <TableCell align="right">
                      {row.programPermissions.map((n) => n.userRole).join(', ')}
                    </TableCell>
                    <TableCell align="right">{row.number}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.programsForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit ?? 0}
            page={currentSearchParams.page ? currentSearchParams.page - 1 : 0}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="title"
            name="title"
            label="Title"
            type="text"
            defaultValue={addInput.title}
            fullWidth
            onChange={handleChangeAddTitle}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            name="description"
            label="Description"
            type="text"
            defaultValue={addInput.description}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
            fullWidth
            onChange={handleChangeAddDescription}
          />
          <ImageField
            name="icon"
            path={addInput.icon}
            width={100}
            height={100}
            onImageUploaded={handleAddImageUploaded}
            setShowLoader={setShowLoader}
          />
          <ImageField
            name="thumbnail"
            path={addInput.thumbnail}
            width={600}
            height={300}
            onImageUploaded={handleAddImageUploaded}
            setShowLoader={setShowLoader}
          />
          <TextField
            autoFocus
            margin="dense"
            id="repository"
            name="repository"
            label="Repository"
            type="text"
            defaultValue={addInput.repository}
            fullWidth
            onChange={handleChangeAddRepository}
          />
          <TextField
            autoFocus
            margin="dense"
            id="summary"
            name="summary"
            label="summary"
            type="text"
            placeholder="## 概要
              本教材ではRuby on Railsによるアプリケーションの開発方法を学び、実際にAmazon風のECサイトを作っていきます。
              また、仕様の検討方法や使用するライブラリの選定基準などについても解説します。
              各章ごとに機能を実装していくので、オリジナルのアプリケーションを作る際に、必要な機能だけをピックアップして実装したいときにも役立ちます。"
            InputLabelProps={{
              shrink: true,
            }}
            value={addInput.summary}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
            fullWidth
            style={{ margin: '10px 0' }}
            onChange={handleChangeAddSummary}
          />
          <ImageField
            name="summaryImage1"
            path={addInput.summaryImage1}
            width={800}
            height={400}
            onImageUploaded={handleAddImageUploaded}
            setShowLoader={setShowLoader}
          />
          <ImageField
            name="summaryImage2"
            path={addInput.summaryImage2}
            width={800}
            height={400}
            onImageUploaded={handleAddImageUploaded}
            setShowLoader={setShowLoader}
          />
          <ImageField
            name="summaryImage3"
            path={addInput.summaryImage3}
            width={800}
            height={400}
            onImageUploaded={handleAddImageUploaded}
            setShowLoader={setShowLoader}
          />
          <ImageField
            name="summaryImage4"
            path={addInput.summaryImage4}
            width={800}
            height={400}
            onImageUploaded={handleAddImageUploaded}
            setShowLoader={setShowLoader}
          />
          <ImageField
            name="summaryImage5"
            path={addInput.summaryImage5}
            width={800}
            height={400}
            onImageUploaded={handleAddImageUploaded}
            setShowLoader={setShowLoader}
          />
          <Grid>
            <FormControl fullWidth>
              <InputLabel htmlFor="type">Type</InputLabel>
              <Select
                value={addInput.type}
                onChange={handleChangeAddType}
                input={<Input name="type" id="type" />}
              >
                {Object.entries(ProgramTypeLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl fullWidth>
              <InputLabel htmlFor="category">Category</InputLabel>
              <Select
                value={addInput.category}
                onChange={handleChangeAddCategory}
                input={<Input name="category" id="category" />}
              >
                {Object.entries(ProgramCategoryLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl fullWidth>
              <InputLabel htmlFor="userRoles">UserRoles</InputLabel>
              <Select
                multiple
                value={addInput.userRoles}
                onChange={handleChangeAddUserRoles}
                input={<Input name="userRoles" id="userRoles" />}
              >
                {Object.entries(UserRole)
                  .filter(
                    ([_, label]) =>
                      // 旧SubscriptionロールはLMSでは使用していないため除く
                      //（Stripe上では旧Subscriptionロールを使っているユーザーが存在する）
                      label !== UserRole.Subscription && label !== UserRole.StudentSubscription,
                  )
                  .map(([value, label]) => (
                    <MenuItem value={label} key={value}>
                      {label}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <TextField
            autoFocus
            margin="dense"
            id="number"
            name="number"
            label="Number"
            type="number"
            defaultValue={addInput.number}
            fullWidth
            onChange={handleChangeAddNumber}
            inputProps={{ min: 0 }}
          />
          <Grid>
            <FormControl fullWidth>
              <InputLabel htmlFor="tags">Tags</InputLabel>
              <Select
                id="tags"
                multiple
                value={addInput.tags}
                onChange={handleChangeAddTag}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div>
                    {tagData?.getTagsForAdmin?.items
                      .filter((tag) => (selected as number[]).indexOf(tag.id) >= 0)
                      .map((tag) => <Chip key={tag.id} label={tag.name} />)}
                  </div>
                )}
              >
                {tagData?.getTagsForAdmin.items?.map((tag) => (
                  <MenuItem key={tag.id} value={tag.id}>
                    <Checkbox checked={addInput.tags.indexOf(tag.id) > -1} />
                    <ListItemText primary={tag.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl fullWidth>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={addInput.status}
                onChange={handleChangeAddStatus}
                input={<Input name="status" id="status" />}
              >
                {Object.entries(ValidLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
