import React from 'react';

interface StudyReportTimeLabelProps {
  time: number | null | undefined;
}

export const StudyReportTimeLabel: React.FC<StudyReportTimeLabelProps> = (props) => {
  const calcTimeHour = (num: number | null | undefined): number => {
    if (!num) {
      return 0;
    }

    return Math.floor(num / 60);
  };

  const calcTimeMinutes = (num: number | null | undefined): number => {
    if (!num) {
      return 0;
    }

    return num % 60;
  };

  const hour = calcTimeHour(props.time);
  const minutes = calcTimeMinutes(props.time);

  return (
    <>
      {hour > 0 && <>{hour}時間</>}
      {minutes}分
    </>
  );
};
