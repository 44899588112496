import * as yup from 'yup';

export const instructorSchema = yup.object({
  lastName: yup.string().required().max(100).trim(),
  firstName: yup.string().required().max(100).trim(),
  lastKanaName: yup.string().required().max(100).trim(),
  firstKanaName: yup.string().required().max(100).trim(),
  zoomUserID: yup.string().nullable().max(100).trim(),
  googleMeetURL: yup.string().nullable().max(2048).trim(),
});
