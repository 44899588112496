import { NoticeNotified } from '../../gen/graphql';

export const NotifiedLabels = {
  [NoticeNotified.Finished]: '通知済み',
  [NoticeNotified.NotYet]: '未通知',
  [NoticeNotified.Processing]: '処理中',
} as const;

// isStudent, isInstructor, isPlusUser
export const UserPermissionLabels = {
  false: '無効',
  true: '有効',
} as const;

// バッヂを通知しますか？, メールを通知しますか？
export const YesOrNoLabels = {
  false: 'いいえ',
  true: 'はい',
} as const;
