export const stripeURL = (): string => {
  const url = 'https://dashboard.stripe.com';
  return process.env.NODE_ENV === 'production' ? url : url + '/test';
};

export const stripeCustomerURL = (id?: string): string => stripeURL() + '/customers/' + id;

export const stripeSubscriptionURL = (id?: string): string => stripeURL() + '/subscriptions/' + id;

export const stripeEventURL = (id?: string): string => stripeURL() + '/events/' + id;

export const stripePaymentIntentURL = (id?: string): string => stripeURL() + '/payments/' + id;
