import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import {
  useGetStripeSubscriptionForAdminQuery,
  useGetSubscriptionContractsForAdminQuery,
  useGetStripeSubscriptionLogsForAdminQuery,
} from '../../../gen/graphql';
import { providerSubscriptionStatusPhaseLabels } from '../../const/StripeSubscription';
import {
  stripeSubscriptionURL,
  stripeEventURL,
  stripePaymentIntentURL,
} from '../../../utils/stripe';
import {
  subscriptionContractPhaseLabels,
  exitFeeStatusLabels,
} from '../../const/SubscriptionContract';
import { stripeCustomerTypeLabels } from '../../const/stripeCustomer';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Paper, Grid, TableContainer } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'hidden',
    margin: '24px 0',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

// 一旦最大件数を指定する。件数が増えてきてPaging等が必要になったら対応
const CONTRACT_LIMIT = 50;
const LOG_LIMIT = 100;

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ stripe_subscription_id: string }>().stripe_subscription_id ?? '';

  const { data: stripeSubscriptionData, loading: stripeSubscriptionLoading } =
    useGetStripeSubscriptionForAdminQuery({
      variables: { id },
    });
  const stripeSubscription = stripeSubscriptionData?.stripeSubscriptionForAdmin ?? undefined;

  const { data: contractData, loading: contractLoading } = useGetSubscriptionContractsForAdminQuery(
    {
      variables: {
        input: {
          limit: CONTRACT_LIMIT,
          page: 1,
          stripeSubscriptionID: id,
        },
      },
    },
  );
  const contracts = contractData?.subscriptionContractsForAdmin.items ?? [];

  const { data: logsData, loading: logLoading } = useGetStripeSubscriptionLogsForAdminQuery({
    variables: {
      input: {
        limit: LOG_LIMIT,
        page: 1,
        stripeSubscriptionID: id,
      },
    },
  });
  const logs = logsData?.stripeSubscriptionLogsForAdmin.items ?? [];

  const loading = stripeSubscriptionLoading || contractLoading || logLoading;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                StripeSubscription Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/stripeSubscriptions/${stripeSubscription?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{stripeSubscription?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    stripeCustomerID
                  </TableCell>
                  <TableCell>
                    <Link to={`/stripeCustomers/${stripeSubscription?.stripeCustomerID}`}>
                      {stripeSubscription?.stripeCustomerID}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Price
                  </TableCell>
                  <TableCell>
                    <Link to={`/prices/${stripeSubscription?.priceID}`}>
                      {stripeSubscription?.priceID}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Product
                  </TableCell>
                  <TableCell>
                    <Link to={`/products/${stripeSubscription?.price?.product.id}`}>
                      {stripeSubscription?.price?.product.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ProviderID
                  </TableCell>
                  <TableCell>
                    <Link
                      target="_blank"
                      to={stripeSubscriptionURL(stripeSubscription?.providerID)}
                    >
                      {stripeSubscription?.providerID}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ProviderQuantity
                  </TableCell>
                  <TableCell>{stripeSubscription?.providerQuantity}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ProviderStatus
                  </TableCell>
                  <TableCell>
                    {stripeSubscription?.providerStatus
                      ? providerSubscriptionStatusPhaseLabels[stripeSubscription?.providerStatus]
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    isCurrent
                  </TableCell>
                  <TableCell>
                    {!!stripeSubscription?.subscriptionCurrent?.stripeSubscriptionID && '〇'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{stripeSubscription?.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{stripeSubscription?.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Typography color="inherit" variant="h6" component="h2">
          SubscriptionContract 最大{CONTRACT_LIMIT}件
        </Typography>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>ProductID</TableCell>
                  <TableCell align="right">ProductName</TableCell>
                  <TableCell align="right">PriceID</TableCell>
                  <TableCell align="right">PeriodStart</TableCell>
                  <TableCell align="right">PeriodEnd</TableCell>
                  <TableCell align="right">endDate</TableCell>
                  <TableCell align="right">canceledAt</TableCell>
                  <TableCell align="right">phase</TableCell>
                  <TableCell align="right">exitFeeStatus</TableCell>
                  <TableCell align="right">exitFeePaymentIntentID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contracts.map((contract) => (
                  <TableRow key={contract.id}>
                    <TableCell scope="row">
                      <Link to={`/subscriptionContracts/${contract.id}`}>{contract.id}</Link>
                    </TableCell>
                    <TableCell scope="row">
                      <Link to={`/products/${contract.price?.product.id}`}>
                        {contract.price?.product?.id}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{contract.price?.product.name}</TableCell>
                    <TableCell align="right">
                      <Link to={`/prices/${contract.price?.id}`}>{contract.price?.id}</Link>
                    </TableCell>
                    <TableCell align="right">{contract.periodStart}</TableCell>
                    <TableCell align="right">{contract.periodEnd}</TableCell>
                    <TableCell align="right">{contract.endDate}</TableCell>
                    <TableCell align="right">{contract.canceledAt}</TableCell>
                    <TableCell align="right">
                      {contract.phase ? subscriptionContractPhaseLabels[contract.phase] : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {contract.exitFeeStatus ? exitFeeStatusLabels[contract.exitFeeStatus] : '-'}
                    </TableCell>
                    <TableCell align="right">
                      {contract?.exitFeePaymentIntentID ? (
                        <Link
                          target="_blank"
                          to={stripePaymentIntentURL(contract?.exitFeePaymentIntentID)}
                        >
                          {contract?.exitFeePaymentIntentID}
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Paper>

        <Typography color="inherit" variant="h6" component="h2">
          StripeSubscriptionLog 最大{LOG_LIMIT}件
        </Typography>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">StripeCustomerType</TableCell>
                  <TableCell align="right">priceID</TableCell>
                  <TableCell align="right">productName</TableCell>
                  <TableCell align="right">providerID</TableCell>
                  <TableCell align="right">providerQuantity</TableCell>
                  <TableCell align="right">providerStatus</TableCell>
                  <TableCell align="right">changedBy</TableCell>
                  <TableCell align="right">action</TableCell>
                  <TableCell align="right">webhookEventID</TableCell>
                  <TableCell align="right">recordType</TableCell>
                  <TableCell align="right">action</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs?.map((log) => (
                  <TableRow key={log.id}>
                    <TableCell>{log.id}</TableCell>
                    <TableCell align="right">
                      {log.stripeCustomer?.stripeCustomerType
                        ? stripeCustomerTypeLabels[log.stripeCustomer?.stripeCustomerType]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/prices/${log.priceID}`}>{log.priceID}</Link>
                    </TableCell>
                    <TableCell align="right">{log.price?.product.name}</TableCell>
                    <TableCell align="right">
                      <Link target="_blank" to={stripeSubscriptionURL(log.providerID)}>
                        {log.providerID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{log.providerQuantity}</TableCell>
                    <TableCell align="right">
                      {log.providerStatus
                        ? providerSubscriptionStatusPhaseLabels[log.providerStatus]
                        : '-'}
                    </TableCell>
                    <TableCell align="right" scope="row">
                      {log.subscriptionChangeHistory?.changedBy ? (
                        <Link to={`/users/${log.subscriptionChangeHistory?.changedBy}`}>
                          {log.subscriptionChangeHistory?.changedBy}
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell align="right">{log.subscriptionChangeHistory?.action}</TableCell>
                    <TableCell align="right">
                      {log.subscriptionChangeHistory?.webhookEventID ? (
                        <Link
                          target="_blank"
                          to={stripeEventURL(log.subscriptionChangeHistory?.webhookEventID)}
                        >
                          {log.subscriptionChangeHistory?.webhookEventID}
                        </Link>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell>{log.subscriptionChangeHistory?.recordType}</TableCell>
                    <TableCell>{log.subscriptionChangeHistory?.action}</TableCell>
                    <TableCell align="right">{log.createdAt}</TableCell>
                    <TableCell align="right">{log.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Paper>
      </main>
    </div>
  );
};
