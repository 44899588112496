import { ProgramCategory, ProgramType } from '../../gen/graphql';

export const ProgramCategoryLabels = {
  [ProgramCategory.Tech]: '技術',
  [ProgramCategory.Trouble]: 'お悩み',
} as const;

export const strToProgramCategory = (str: string): ProgramCategory | undefined => {
  return Object.values(ProgramCategory).find((v) => v === str);
};

export const ProgramTypeLabels = {
  [ProgramType.Normal]: '教材',
  [ProgramType.Instructor]: 'インストラクター向け資料',
} as const;

export const strToProgramType = (str: string): ProgramType | undefined => {
  return Object.values(ProgramType).find((v) => v === str);
};
