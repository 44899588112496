import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { ApolloError } from '@apollo/client';

import { AdminPracticeSubmitInput } from '../../../gen/graphql';

import { Box, FormHelperText, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';

import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

interface Props {
  formID: string;
  form: UseFormReturn<AdminPracticeSubmitInput>;
  onSubmit: (input: AdminPracticeSubmitInput) => void;
  error: ApolloError | undefined;
  isUpdate?: boolean;
}

export const Form: React.FC<Props> = ({
  formID,
  form,
  onSubmit,
  error,
  isUpdate: _isUpdate,
}): JSX.Element => {
  const classes = useStyles();
  const formErrors = form.formState.errors;

  return (
    <form id={formID} onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Controller
        name="practiceID"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="practiceID"
            label="Practice ID"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.practiceID}
            helperText={formErrors.practiceID?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="userID"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            margin="dense"
            id="userID"
            label="User ID"
            type="number"
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ min: 1 }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.userID}
            helperText={formErrors.userID?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.status}>
        <InputLabel htmlFor="status">Status</InputLabel>
        <Controller
          name="status"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="status" id="status" />}
              error={!!formErrors.status}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(ValidLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.status?.message}</FormHelperText>
      </FormControl>
      {error?.graphQLErrors && (
        <Box className={classes.inputError}>
          <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
          {error.graphQLErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Box>
      )}
    </form>
  );
};
