import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AdminNoticeInput, UserRole } from '../../../gen/graphql';
import { YesOrNoLabels } from '../../const/Notice';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { ApolloError } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { Box, FormHelperText, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

interface Props {
  formId: string;
  form: UseFormReturn<AdminNoticeInput>;
  onSubmit: (input: AdminNoticeInput) => void;
  error: ApolloError | undefined;
  isUpdate?: boolean;
}

export const Form: React.FC<Props> = ({ formId, form, onSubmit, error }): JSX.Element => {
  const classes = useStyles();
  const formErrors = form.formState.errors;

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Controller
        name="title"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="title"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.title}
            helperText={formErrors.title?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="content"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="content"
            label="content"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
            style={{ margin: '10px 0' }}
            error={!!formErrors.content}
            helperText={formErrors.content?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.status}>
        <InputLabel htmlFor="status">Status</InputLabel>
        <Controller
          name="status"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="status" id="status" />}
              error={!!formErrors.status}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(ValidLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.status?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.userRoles}>
        <InputLabel htmlFor="userRoles">userRoles</InputLabel>
        <Controller
          name="userRoles"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select multiple error={!!formErrors.userRoles} inputRef={ref} {...rest}>
              {Object.entries(UserRole)
                .filter(
                  ([_, label]) =>
                    // 旧Subscriptionロールと非ログインは通知対象外なので除く
                    label !== UserRole.Subscription &&
                    label !== UserRole.StudentSubscription &&
                    label !== UserRole.NotLogin,
                )
                .map(([value, label]) => (
                  <MenuItem value={label} key={value}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.userRoles?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.sendMail}>
        <InputLabel htmlFor="sendMail">sendMail</InputLabel>
        <Controller
          name="sendMail"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select error={!!formErrors.sendMail} inputRef={ref} {...rest}>
              {Object.entries(YesOrNoLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.sendMail?.message}</FormHelperText>
      </FormControl>
      <span style={{ color: 'red' }}>
        *一度メールを通知すると、再度通知を送ることはできません。
      </span>
      {error?.graphQLErrors && (
        <Box className={classes.inputError}>
          <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
          {error.graphQLErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Box>
      )}
    </form>
  );
};
