import React, { useCallback, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, TextField, Toolbar } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  AdminPromotionCodeInput,
  Bool,
  useGetSpPromotionCodesForAdminQuery,
} from '../../../gen/graphql';
import { formatDate } from '../../const/Util';
import { strToEnum } from '../../../utils/common';
import {
  PvdActiveLabels,
  PvdRestrictionsFirstTimeTransactionLabels,
} from '../../const/SpPromotionCode';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
}));

type SearchFormInput = Omit<AdminPromotionCodeInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminPromotionCodeInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const spCouponId = params.get('spCouponID') ?? '';
    const pvdID = params.get('pvdID') ?? '';
    const pvdActive = params.get('pvdActive') ?? '';
    const pvdCode = params.get('pvdCode') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
      id: id.length === 26 ? id : undefined, // ULIDなので26の桁数チェック
      spCouponID: spCouponId.length === 26 ? spCouponId : undefined, // ULIDなので26の桁数チェック
      pvdID: pvdID !== '' ? pvdID : undefined,
      pvdActive: strToEnum(pvdActive, Bool),
      pvdCode: pvdCode !== '' ? pvdCode : undefined,
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetSpPromotionCodesForAdminQuery({
    variables: { input: currentSearchParams },
    notifyOnNetworkStatusChange: true,
  });

  // 検索条件からURLを作成してhistoryにpush
  const createHistory = useCallback(
    (params: AdminPromotionCodeInput): void => {
      navigate({
        pathname: '/spPromotionCodes',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      spCouponID: currentSearchParams.spCouponID,
      pvdID: currentSearchParams.pvdID,
      pvdActive: currentSearchParams.pvdActive,
      pvdCode: currentSearchParams.pvdCode,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                SpPromotionCode
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="text"
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="spCouponID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="spCouponId"
                          label="SpCouponID"
                          type="text"
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="pvdID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="pvdID"
                          label="PvdID"
                          type="text"
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="pvdActive">PvdActive</InputLabel>
                      <Controller
                        name="pvdActive"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(PvdActiveLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="pvdCode"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="pvdCode"
                          label="PvdCode"
                          type="text"
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">SpCouponId</TableCell>
                  <TableCell align="right">PvdID</TableCell>
                  <TableCell align="right">PvdActive</TableCell>
                  <TableCell align="right">PvdCode</TableCell>
                  <TableCell align="right">PvdCreated</TableCell>
                  <TableCell align="right">pvdCustomer</TableCell>
                  <TableCell align="right">pvdExpiresAt</TableCell>
                  <TableCell align="right">pvdMaxRedemptions</TableCell>
                  <TableCell align="right">pvdRestrictionsFirstTimeTransaction</TableCell>
                  <TableCell align="right">pvdRestrictionsMinimumAmount</TableCell>
                  <TableCell align="right">pvdRestrictionsCurrencyMinimumAmountCurrency</TableCell>
                  <TableCell align="right">pvdTimesRedeemed</TableCell>
                  <TableCell align="right">SpCoupons</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.spPromotionCodesForAdmin.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/spPromotionCodes/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/spCoupons/${row.spCouponID}`}>{row.spCouponID}</Link>
                    </TableCell>
                    <TableCell align="right">{row.pvdID}</TableCell>
                    <TableCell align="right">{PvdActiveLabels[row.pvdActive]}</TableCell>
                    <TableCell align="right">{row.pvdCode}</TableCell>
                    <TableCell align="right">{formatDate(new Date(row.pvdCreated))}</TableCell>
                    <TableCell align="right">{row.pvdCustomer}</TableCell>
                    <TableCell align="right">
                      {row.pvdExpiresAt && formatDate(new Date(row.pvdExpiresAt))}
                    </TableCell>
                    <TableCell align="right">{row.pvdMaxRedemptions}</TableCell>
                    <TableCell align="right">
                      {row.pvdRestrictionsFirstTimeTransaction &&
                        PvdRestrictionsFirstTimeTransactionLabels[
                          row.pvdRestrictionsFirstTimeTransaction
                        ]}
                    </TableCell>
                    <TableCell align="right">{row.pvdRestrictionsMinimumAmount}</TableCell>
                    <TableCell align="right">
                      {row.pvdRestrictionsCurrencyMinimumAmountCurrency}
                    </TableCell>
                    <TableCell align="right">{row.pvdTimesRedeemed}</TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/spCoupons?id=${row.spCouponID}`}>SpCoupons</Link>
                    </TableCell>
                    <TableCell align="right">{formatDate(new Date(row.createdAt))}</TableCell>
                    <TableCell align="right">{formatDate(new Date(row.updatedAt))}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.spPromotionCodesForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
