export const StatusLabels: { [key: number]: string } = {
  0: '削除',
  1: '受講中',
  2: '初回レッスン待ち',
  3: 'アサイン待ち',
  4: 'インスト交代中',
  5: '休学中',
  6: '卒業済み',
  7: '解約',
  8: '休学/インスト交代中',
  9: '解約中',
} as const;
