import React, { useState, useCallback } from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles, Theme } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Other
import { Typography, Button, LinearProgress } from '@material-ui/core';

// Other
import {
  AdminMailSettingInput,
  useGetMailSettingForAdminQuery,
  useUpdateMailSettingForAdminMutation,
  Valid,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import * as Sentry from '@sentry/browser';
import { strToValid, ValidLabels } from '../../const/Valid';
import { useNavigate, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ mail_setting_id: string }>().mail_setting_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminMailSettingInput>({
    messageReceived: Valid.Valid,
    lessonRegistered: Valid.Valid,
    lessonRemind: Valid.Valid,
    lessonUnregistered: Valid.Valid,
    lessonScheduleUpdated: Valid.Valid,
    questionRegistered: Valid.Valid,
    questionAnswerRegistered: Valid.Valid,
    questionCommentRegistered: Valid.Valid,
    claimApplied: Valid.Valid,
    backSchool: Valid.Valid,
    attendanceStatusChanged: Valid.Valid,
    notice: Valid.Valid,
    lessonLogUnregistered: Valid.Valid,
    studyReportCommentRegistered: Valid.Valid,
    studyReportAchieved: Valid.Valid,
    spotLessonRegistered: Valid.Valid,
    spotLessonRemind: Valid.Valid,
    spotLessonCancel: Valid.Valid,
    spotLessonLogUnregistered: Valid.Valid,
    ticketRemind: Valid.Valid,
    sessionRemind: Valid.Valid,
    practiceSubmitRegistered: Valid.Valid,
    practiceSubmitReRegistered: Valid.Valid,
    practiceSubmitDetailReviewCommentRegistered: Valid.Valid,
  });

  const { loading } = useGetMailSettingForAdminQuery({
    variables: { id },
    onCompleted: ({ getMailSettingForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        messageReceived: data.messageReceived,
        lessonRegistered: data.lessonRegistered,
        lessonRemind: data.lessonRemind,
        lessonUnregistered: data.lessonUnregistered,
        lessonScheduleUpdated: data.lessonScheduleUpdated,
        questionRegistered: data.questionRegistered,
        questionAnswerRegistered: data.questionAnswerRegistered,
        questionCommentRegistered: data.questionCommentRegistered,
        claimApplied: data.claimApplied,
        backSchool: data.backSchool,
        attendanceStatusChanged: data.attendanceStatusChanged,
        notice: data.notice,
        lessonLogUnregistered: data.lessonLogUnregistered,
        studyReportCommentRegistered: data.studyReportCommentRegistered,
        studyReportAchieved: data.studyReportAchieved,
        spotLessonRegistered: data.spotLessonRegistered,
        spotLessonRemind: data.spotLessonRemind,
        spotLessonCancel: data.spotLessonCancel,
        spotLessonLogUnregistered: data.spotLessonLogUnregistered,
        ticketRemind: data.ticketRemind,
        sessionRemind: data.sessionRemind,
        practiceSubmitRegistered: data.practiceSubmitRegistered,
        practiceSubmitReRegistered: data.practiceSubmitReRegistered,
        practiceSubmitDetailReviewCommentRegistered:
          data.practiceSubmitDetailReviewCommentRegistered,
      });
    },
  });
  const [updateMailSetting, { error: updateError }] = useUpdateMailSettingForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateMailSetting({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        Sentry.captureException(e);
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateInput, navigate, updateMailSetting, id]),
  );

  const handleChangeStatus = useCallback(({ target: { value, name } }): void => {
    setUpdateInput((current) => ({
      ...current,
      [name]: strToValid(String(value)) ?? Valid.Valid,
    }));
  }, []);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Mail Setting Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <FormControl>
                <InputLabel htmlFor="attendanceStatusChanged">AttendanceStatusChanged</InputLabel>
                <Select
                  value={updateInput.attendanceStatusChanged}
                  onChange={handleChangeStatus}
                  input={<Input name="attendanceStatusChanged" id="attendanceStatusChanged" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="backSchool">BackSchool</InputLabel>
                <Select
                  value={updateInput.backSchool}
                  onChange={handleChangeStatus}
                  input={<Input name="backSchool" id="backSchool" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="claimApplied">ClaimApplied</InputLabel>
                <Select
                  value={updateInput.claimApplied}
                  onChange={handleChangeStatus}
                  input={<Input name="claimApplied" id="claimApplied" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="lessonLogUnregistered">LessonLogUnregistered</InputLabel>
                <Select
                  value={updateInput.lessonLogUnregistered}
                  onChange={handleChangeStatus}
                  input={<Input name="lessonLogUnregistered" id="lessonLogUnregistered" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="lessonRegistered">LessonRegistered</InputLabel>
                <Select
                  value={updateInput.lessonRegistered}
                  onChange={handleChangeStatus}
                  input={<Input name="lessonRegistered" id="lessonRegistered" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="lessonRemind">LessonRemind</InputLabel>
                <Select
                  value={updateInput.lessonRemind}
                  onChange={handleChangeStatus}
                  input={<Input name="lessonRemind" id="lessonRemind" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="lessonUnregistered">LessonUnregistered</InputLabel>
                <Select
                  value={updateInput.lessonUnregistered}
                  onChange={handleChangeStatus}
                  input={<Input name="lessonUnregistered" id="lessonUnregistered" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="lessonScheduleUpdated">LessonScheduleUpdated</InputLabel>
                <Select
                  value={updateInput.lessonScheduleUpdated}
                  onChange={handleChangeStatus}
                  input={<Input name="lessonScheduleUpdated" id="lessonScheduleUpdated" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="messageReceived">MessageReceived</InputLabel>
                <Select
                  value={updateInput.messageReceived}
                  onChange={handleChangeStatus}
                  input={<Input name="messageReceived" id="messageReceived" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="notice">Notice</InputLabel>
                <Select
                  value={updateInput.notice}
                  onChange={handleChangeStatus}
                  input={<Input name="notice" id="notice" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="questionAnswerRegistered">QuestionAnswerRegistered</InputLabel>
                <Select
                  value={updateInput.questionAnswerRegistered}
                  onChange={handleChangeStatus}
                  input={<Input name="questionAnswerRegistered" id="questionAnswerRegistered" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="questionCommentRegistered">
                  QuestionCommentRegistered
                </InputLabel>
                <Select
                  value={updateInput.questionCommentRegistered}
                  onChange={handleChangeStatus}
                  input={<Input name="questionCommentRegistered" id="questionCommentRegistered" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="questionRegistered">QuestionRegistered</InputLabel>
                <Select
                  value={updateInput.questionRegistered}
                  onChange={handleChangeStatus}
                  input={<Input name="questionRegistered" id="questionRegistered" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="studyReportCommentRegistered">
                  StudyReportCommentRegistered
                </InputLabel>
                <Select
                  value={updateInput.studyReportCommentRegistered}
                  onChange={handleChangeStatus}
                  input={
                    <Input name="studyReportCommentRegistered" id="studyReportCommentRegistered" />
                  }
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="studyReportAchieved">StudyReportAchieved</InputLabel>
                <Select
                  value={updateInput.studyReportAchieved}
                  onChange={handleChangeStatus}
                  input={<Input name="studyReportAchieved" id="studyReportAchieved" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="spotLessonRegistered">SpotLessonRegistered</InputLabel>
                <Select
                  value={updateInput.spotLessonRegistered}
                  onChange={handleChangeStatus}
                  input={<Input name="spotLessonRegistered" id="spotLessonRegistered" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="spotLessonRemind">SpotLessonRemind</InputLabel>
                <Select
                  value={updateInput.spotLessonRemind}
                  onChange={handleChangeStatus}
                  input={<Input name="spotLessonRemind" id="spotLessonRemind" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="spotLessonCancel">SpotLessonCancel</InputLabel>
                <Select
                  value={updateInput.spotLessonCancel}
                  onChange={handleChangeStatus}
                  input={<Input name="spotLessonCancel" id="spotLessonCancel" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="spotLessonLogUnregistered">
                  SpotLessonLogUnregistered
                </InputLabel>
                <Select
                  value={updateInput.spotLessonLogUnregistered}
                  onChange={handleChangeStatus}
                  input={<Input name="spotLessonLogUnregistered" id="spotLessonLogUnregistered" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="ticketRemind">TicketRemind</InputLabel>
                <Select
                  value={updateInput.ticketRemind}
                  onChange={handleChangeStatus}
                  input={<Input name="ticketRemind" id="ticketRemind" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="sessionRemind">SessionRemind</InputLabel>
                <Select
                  value={updateInput.sessionRemind}
                  onChange={handleChangeStatus}
                  input={<Input name="sessionRemind" id="sessionRemind" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="practiceSubmitRegistered">PracticeSubmitRegistered</InputLabel>
                <Select
                  value={updateInput.practiceSubmitRegistered}
                  onChange={handleChangeStatus}
                  input={<Input name="practiceSubmitRegistered" id="practiceSubmitRegistered" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="practiceSubmitReRegistered">
                  PracticeSubmitReRegistered
                </InputLabel>
                <Select
                  value={updateInput.practiceSubmitReRegistered}
                  onChange={handleChangeStatus}
                  input={
                    <Input name="practiceSubmitReRegistered" id="practiceSubmitReRegistered" />
                  }
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="practiceSubmitDetailReviewCommentRegistered">
                  PracticeSubmitDetailReviewCommentRegistered
                </InputLabel>
                <Select
                  value={updateInput.practiceSubmitDetailReviewCommentRegistered}
                  onChange={handleChangeStatus}
                  input={
                    <Input
                      name="practiceSubmitDetailReviewCommentRegistered"
                      id="practiceSubmitDetailReviewCommentRegistered"
                    />
                  }
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
