import React, { useState, useCallback } from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles, Theme } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Original Component
import { ImageField } from '../../common/ImageField';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminCourseProgramInput,
  useUpdateCourseProgramForAdminMutation,
  useGetCourseProgramForAdminQuery,
  Valid,
  useUploadImagesMutation,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { GeneralChangeEvent } from '../../../types/Common';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

const mdParser = new MarkdownIt();

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ course_program_id: string }>().course_program_id ?? '0';

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminCourseProgramInput>({
    title: '',
    description: '',
    overview: '',
    content: '',
    image: '',
    number: 0,
    status: Valid.Valid,
  });

  const { loading } = useGetCourseProgramForAdminQuery({
    variables: { id },
    onCompleted: ({ courseProgramForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        title: data.title,
        description: data.description,
        overview: data.overview,
        content: data.content,
        image: data.image,
        number: data.number,
        status: data.status,
      });
    },
  });

  const [uploadImages] = useUploadImagesMutation();
  const uploadImage = useCallback(
    async (image: File): Promise<string> => {
      try {
        const uploadedFiles = await uploadImages({
          variables: {
            files: [image],
          },
        });
        const files = uploadedFiles.data?.uploadFiles ?? [];
        return files[0].s3FilePath;
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return '';
      }
    },
    [uploadImages],
  );

  const [updateCourseProgram, { error: updateError }] = useUpdateCourseProgramForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateCourseProgram({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateCourseProgram, updateInput, id, navigate]),
  );

  const handleChangeTitle = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, title: value }));
  }, []);

  const handleChangeDescription = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, description: value }));
  }, []);

  const handleChangeOverview = useCallback((data: { html: string; text: string }) => {
    setUpdateInput((current) => ({ ...current, overview: data.text }));
  }, []);

  const handleChangeContent = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, content: value }));
  }, []);

  const handleChangeNumber = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, number: parseInt(value) }));
  }, []);

  const handleChangeStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  const handleImageUploaded = (name: string, path: string) => {
    setUpdateInput((current) => ({
      ...current,
      [name]: path,
    }));
  };

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                CourseProgram Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput?.title}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeTitle}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="description"
                name="description"
                label="Description"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput?.description}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeDescription}
                multiline={true}
                minRows={5}
                maxRows={Infinity}
              />
            </div>
            <div>
              <InputLabel shrink>Overview</InputLabel>
              <MdEditor
                style={{ height: '500px' }}
                renderHTML={(text) => mdParser.render(text)}
                onChange={handleChangeOverview}
                onImageUpload={uploadImage}
                value={updateInput?.overview}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="content"
                name="content"
                label="Content"
                type="text"
                placeholder="rails"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput?.content}
                multiline={true}
                minRows={5}
                maxRows={Infinity}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeContent}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="number"
                name="number"
                label="Number"
                type="number"
                placeholder="1"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.number}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeNumber}
                inputProps={{ min: 0 }}
              />
            </div>
            <ImageField
              name="image"
              path={updateInput?.image ?? ''}
              width={600}
              height={300}
              onImageUploaded={handleImageUploaded}
              setShowLoader={setShowLoader}
            />
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput?.status}
                  onChange={handleChangeStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
