import * as yup from 'yup';

import { AdminTagInput, Valid, UserRole } from '../gen/graphql';

export const tagSchema = yup.object({
  name: yup.string().required().max(2000).trim(),
  overview: yup.string().nullable().max(2000).trim(),
  status: yup.string().required().oneOf(Object.values(Valid)),
  userRoles: yup
    .array()
    .of(yup.string().required().oneOf(Object.values(UserRole)))
    .required()
    .min(1),
});

export const TagDefaultValues: AdminTagInput = {
  name: '',
  overview: '',
  status: Valid.Valid,
  userRoles: [],
};
