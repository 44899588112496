import * as yup from 'yup';

import { AdminPracticeSubmitInput, Valid } from '../gen/graphql';

export const practiceSubmitSchema = yup.object({
  practiceID: yup.string().required().max(100).trim(),
  userID: yup
    .number()
    .required()
    .min(1)
    .transform((value) => (isNaN(value) ? undefined : value)), // TODO number型のNan対応を共通化 (@see https://github.com/jquense/yup/issues/1330)
  status: yup.string().required().oneOf(Object.values(Valid)),
});

export const PracticeSubmitDefaultValues: AdminPracticeSubmitInput = {
  practiceID: '',
  userID: 0,
  status: Valid.Valid,
};
