import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useGetStudySummaryForAdminQuery } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Paper, Grid, TableContainer, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import { StudyReportTimeLabel } from '../../common/StudyReportTimeLabel';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ study_summary_id: string }>().study_summary_id;
  const id = Number(paramID);

  const { data, loading } = useGetStudySummaryForAdminQuery({
    variables: { id },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                StudySummary Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{data?.studySummaryForAdmin?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/${data?.studySummaryForAdmin?.user?.id}`}>
                      {data?.studySummaryForAdmin?.user?.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReport
                  </TableCell>
                  <TableCell>
                    <Link to={`/studyReports?userId=${data?.studySummaryForAdmin?.user?.id}`}>
                      Search
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReportTime
                  </TableCell>
                  <TableCell>
                    <StudyReportTimeLabel time={data?.studySummaryForAdmin?.studyReportTime} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReportCount
                  </TableCell>
                  <TableCell>{data?.studySummaryForAdmin?.studyReportCount}回</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReportTimeMonth
                  </TableCell>
                  <TableCell>
                    <StudyReportTimeLabel time={data?.studySummaryForAdmin?.studyReportTimeMonth} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReportTimeWeek
                  </TableCell>
                  <TableCell>
                    <StudyReportTimeLabel time={data?.studySummaryForAdmin?.studyReportTimeWeek} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StudyReportRunningLayDays
                  </TableCell>
                  <TableCell>{data?.studySummaryForAdmin?.studyReportRunningLayDays}日</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{data?.studySummaryForAdmin?.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{data?.studySummaryForAdmin?.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </main>
    </div>
  );
};
