import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, Theme, makeStyles } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';
import {
  StatusLabels,
  CourseSupportLabels,
  lessonTypeLabels,
  contractTypeLabels,
} from '../../const/Course';
import {
  useGetCourseForAdminQuery,
  useUpdateCourseForAdminMutation,
  AdminCourseInput,
  CourseLessonType,
} from '../../../gen/graphql';
import { strToEnum } from '../../../utils/common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export interface UpdateParam {
  planId?: number;
  instructorId?: number;
  lessonType?: CourseLessonType;
  lessonPlace?: string;
  objective?: string;
  curriculum?: string;
  jobChange?: number;
  careerSupport?: number;
  customerAcquisition?: number;
  contractType?: number;
  instructorMemo?: string;
  status?: number;
}

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ course_id: string }>().course_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  const [updateInput, setUpdateInput] = useState<AdminCourseInput>({
    lessonType: CourseLessonType.Online,
    lessonPlace: '',
    objective: '',
    curriculum: '',
    jobChange: 0,
    careerSupport: 0,
    customerAcquisition: 0,
    contractType: 0,
    instructorMemo: '',
    status: 1,
  });

  const { loading } = useGetCourseForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ courseForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        lessonType: data.lessonType ?? CourseLessonType.Online,
        lessonPlace: data.lessonPlace,
        objective: data.objective,
        curriculum: data.curriculum,
        jobChange: data.jobChange,
        careerSupport: data.careerSupport,
        customerAcquisition: data.customerAcquisition,
        contractType: data.contractType,
        instructorMemo: data.instructorMemo ?? '',
        status: data.status,
      });
    },
  });

  const [updateCourse, { error: updateError }] = useUpdateCourseForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateCourse({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateCourse, updateInput, id, navigate]),
  );

  const handleChangeLessonPlace = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, lessonPlace: value }));
  }, []);

  const handleChangeLessonType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      const type = strToEnum(String(value), CourseLessonType);
      if (type) {
        setUpdateInput((current) => ({ ...current, lessonType: type }));
      }
    },
    [],
  );

  const handleChangeObjective = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, objective: value }));
  }, []);

  const handleChangeCurriculum = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, curriculum: value }));
  }, []);

  const handleChangeJobChange = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        jobChange: parseInt(String(value)),
      }));
    },
    [],
  );

  const handleChangeCareerSupport = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        careerSupport: parseInt(String(value)),
      }));
    },
    [],
  );

  const handleChangeCustomerAcquisition = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        customerAcquisition: parseInt(String(value)),
      }));
    },
    [],
  );

  const handleChangeContractType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        contractType: parseInt(String(value)),
      }));
    },
    [],
  );

  const handleChangeInstructorMemo = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({ ...current, instructorMemo: value }));
    },
    [],
  );

  const handleChangeStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: parseInt(String(value)),
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Course Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <FormControl>
                <InputLabel htmlFor="lessonType">LessonType</InputLabel>
                <Select
                  value={updateInput.lessonType}
                  onChange={handleChangeLessonType}
                  input={<Input name="lessonType" id="lessonType" />}
                >
                  {Object.entries(lessonTypeLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="lessonPlace"
                name="lessonPlace"
                label="LessonPlace"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.lessonPlace}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeLessonPlace}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="objective"
                name="objective"
                label="Objective"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.objective}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeObjective}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="curriculum"
                name="curriculum"
                label="Curriculum"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.curriculum}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeCurriculum}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="jobChange">JobChange</InputLabel>
                <Select
                  value={updateInput.jobChange}
                  onChange={handleChangeJobChange}
                  input={<Input name="jobChange" id="jobChange" />}
                >
                  {Object.entries(CourseSupportLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="careerSupport">CareerSupport</InputLabel>
                <Select
                  value={updateInput.careerSupport}
                  onChange={handleChangeCareerSupport}
                  input={<Input name="careerSupport" id="careerSupport" />}
                >
                  {Object.entries(CourseSupportLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="customerAcquisition">CustomerAcquisition</InputLabel>
                <Select
                  value={updateInput.customerAcquisition}
                  onChange={handleChangeCustomerAcquisition}
                  input={<Input name="customerAcquisition" id="customerAcquisition" />}
                >
                  {Object.entries(CourseSupportLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="contractType">ContractType</InputLabel>
                <Select
                  value={updateInput.contractType}
                  onChange={handleChangeContractType}
                  input={<Input name="contractType" id="contractType" />}
                >
                  {Object.entries(contractTypeLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="instructorMemo"
                name="instructorMemo"
                label="InstructorMemo"
                type="text"
                placeholder="MEMO"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.instructorMemo}
                multiline={true}
                minRows={5}
                maxRows={Infinity}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeInstructorMemo}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput.status}
                  onChange={handleChangeStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(StatusLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
