import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useGetSpDiscountCouponForAdminQuery } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Paper, Grid, TableContainer, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ sp_discount_coupon_id: string }>().sp_discount_coupon_id ?? '0';

  const { data, loading } = useGetSpDiscountCouponForAdminQuery({
    variables: { id },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                SpDiscountCoupon Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SpDiscount
                  </TableCell>
                  <TableCell>
                    <Link to={`/spDiscounts/${data?.spDiscountCouponForAdmin?.spDiscount?.id}`}>
                      {data?.spDiscountCouponForAdmin?.spDiscount?.id}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdId
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdCreated
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdCreated}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdAmountOff
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdAmountOff}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdCurrency
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdCurrency}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdDuration
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdDuration}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdDurationInMonths
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdDurationInMonths}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdMaxRedemptions
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdMaxRedemptions}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdName
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdPercentOff
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdPercentOff}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdRedeemBy
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdRedeemBy}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdTimesRedeemed
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdTimesRedeemed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdValid
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.pvdValid}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{data?.spDiscountCouponForAdmin?.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </main>
    </div>
  );
};
