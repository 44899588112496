import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Theme, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetChapterForAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { AuthorizationTypeLabels } from '../../const/Authorization';
import ReactMarkdown from 'react-markdown';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ chapter_id: string }>().chapter_id;
  const id = Number(paramID);

  const { data, loading } = useGetChapterForAdminQuery({
    variables: {
      id,
    },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Chapter Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/chapters/${data?.chapterForAdmin?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{data?.chapterForAdmin?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ProgramID
                </TableCell>
                <TableCell>
                  <Link to={`/programs/${data?.chapterForAdmin?.programElement?.program.id}`}>
                    {data?.chapterForAdmin?.programElement?.program!.title}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Title
                </TableCell>
                <TableCell>{data?.chapterForAdmin?.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Description
                </TableCell>
                <TableCell>
                  <ReactMarkdown children={data?.chapterForAdmin?.description ?? ''} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Content
                </TableCell>
                <TableCell>
                  <ReactMarkdown children={data?.chapterForAdmin?.content ?? ''} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  RequireTime
                </TableCell>
                <TableCell>{data?.chapterForAdmin?.requireTime}分</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Key
                </TableCell>
                <TableCell>{data?.chapterForAdmin?.key}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  QAKey
                </TableCell>
                <TableCell>{data?.chapterForAdmin?.qaKey}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  章番号
                </TableCell>
                <TableCell>{data?.chapterForAdmin?.number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ソート順
                </TableCell>
                <TableCell>{data?.chapterForAdmin?.sortNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  公開範囲
                </TableCell>
                <TableCell>
                  {data?.chapterForAdmin?.type
                    ? AuthorizationTypeLabels[data?.chapterForAdmin?.type]
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>
                  {data?.chapterForAdmin?.status ? ValidLabels[data?.chapterForAdmin?.status] : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{data?.chapterForAdmin?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{data?.chapterForAdmin?.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
    </div>
  );
};
