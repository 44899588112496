import React, { useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import queryString from 'query-string';
import { AdminInstructorSearchInput, useGetInstructorsQuery } from '../../../gen/graphql';
import { StatusLabels } from '../../const/Instructor';
import * as Sentry from '@sentry/browser';
import { CsvRepositoryOnAPI } from '../../../infrastructure/csv/CsvRepositoryOnAPI';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  csvBtn: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 99,
    backgroundColor: '#009be5',
    color: 'white',
    padding: '10px 13px',
    textDecoration: 'none',
    borderRadius: '10px',
    boxShadow: '3px 3px 4px black',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

type SearchFormInput = Omit<AdminInstructorSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const csvRepository = useMemo(() => new CsvRepositoryOnAPI(), []);
  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminInstructorSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const userId = params.get('userID') ?? '';
    const scInstructorID = params.get('scInstructorID') ?? '';
    const confirmChannelID = params.get('confirmChannelID') ?? '';
    const name = params.get('name') ?? '';
    const zoomUserID = params.get('zoomUserID') ?? '';
    const googleMeetURL = params.get('googleMeetURL') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      userID: parseInt(userId) > 0 ? parseInt(userId) : undefined,
      scInstructorID: scInstructorID.trim() || undefined,
      confirmChannelID: confirmChannelID.trim() || undefined,
      name: name.trim() || undefined,
      zoomUserID: zoomUserID.trim() || undefined,
      googleMeetURL: googleMeetURL.trim() || undefined,
      status: parseInt(status) >= 0 ? parseInt(status) : undefined,
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetInstructorsQuery({
    variables: {
      input: currentSearchParams,
    },
    notifyOnNetworkStatusChange: true,
  });

  const searchForm = useForm<SearchFormInput>({
    defaultValues: currentSearchParams,
  });

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminInstructorSearchInput): void => {
      navigate({
        pathname: '/instructors',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 検索
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  const csvFileDownload = async (): Promise<void> => {
    // HACK: 大量件数ダウンロード時にAPIサーバーが落ちてしまう問題があるため、フロント側での簡易的な制限をしている
    if ((data?.instructorsForAdmin?.total ?? 0) > 10000) {
      alert(
        '10000件以上のデータを出力することはできません。検索条件を追加して出力対象を絞り込んでください。',
      );
      return;
    }

    // 一覧検索のパラメーターからCSVダウンロードで使うものだけを抽出
    // NOTE: xxxID => xxxIdのように変換しないとAPI側でパラメータが取れない
    const query = queryString.stringify({
      id: currentSearchParams.id,
      userID: currentSearchParams.userID,
      scInstructorId: currentSearchParams.scInstructorID,
      confirmChannelId: currentSearchParams.confirmChannelID,
      name: currentSearchParams.name,
      zoomUserId: currentSearchParams.zoomUserID,
      status: currentSearchParams.status,
    });
    const url = `/api/v1/admin/csv/instructors?${query}`;

    try {
      await csvRepository.getCsv('instructors', url);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Instructor
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          value={value ?? ''}
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="userID"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="userId"
                          label="UserID"
                          type="number"
                          value={value ?? ''}
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="scInstructorID"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="scInstructorID"
                          label="ScInstructorID"
                          type="text"
                          value={value ?? ''}
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="confirmChannelID"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="confirmChannelID"
                          label="ConfirmChannelID"
                          type="text"
                          value={value ?? ''}
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="name"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Name"
                          type="text"
                          value={value ?? ''}
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="zoomUserID"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="zoomUserID"
                          label="ZoomUserID"
                          type="text"
                          value={value ?? ''}
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="googleMeetURL"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="googleMeetURL"
                          label="GoogleMeetURL"
                          type="text"
                          value={value ?? ''}
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(StatusLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">ScInstructorID</TableCell>
                  <TableCell align="right">ConfirmChannelID</TableCell>
                  <TableCell align="right">LastName</TableCell>
                  <TableCell align="right">FirstName</TableCell>
                  <TableCell align="right">LastKanaName</TableCell>
                  <TableCell align="right">FirstKanaName</TableCell>
                  <TableCell align="right">ZoomUserID</TableCell>
                  <TableCell align="right">GoogleMeetURL</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.instructorsForAdmin.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/instructors/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/users/${row.user.id}`}>{row.user.personalInfo?.name}</Link>
                    </TableCell>
                    <TableCell align="right">{row.scInstructorID}</TableCell>
                    <TableCell align="right">{row.confirmChannelID}</TableCell>
                    <TableCell align="right">{row.personalInfo?.lastName}</TableCell>
                    <TableCell align="right">{row.personalInfo?.firstName}</TableCell>
                    <TableCell align="right">{row.personalInfo?.lastKanaName}</TableCell>
                    <TableCell align="right">{row.personalInfo?.firstKanaName}</TableCell>
                    <TableCell align="right">{row.zoomUserID}</TableCell>
                    <TableCell align="right">{row.googleMeetURL}</TableCell>
                    <TableCell align="right">{StatusLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.instructorsForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <button onClick={csvFileDownload} id="csvLink" className={classes.csvBtn}>
        CSV出力
      </button>
    </div>
  );
};
