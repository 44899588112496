import { CsvRepository } from '../../domain/repository/csv/CsvRepository';
import axios from 'axios';
import { formatDate, getFileName } from '../../components/const/Util';
import { saveAs } from 'file-saver';
import * as Sentry from '@sentry/browser';

export class CsvRepositoryOnAPI implements CsvRepository {
  public async getCsv(resourceName: string, url: string): Promise<string> {
    const method = 'GET';
    return await axios
      .request({
        url,
        method,
        responseType: 'blob',
      })
      .then((result) => {
        const blob = new Blob([result.data]);
        const templateFileName = `${resourceName}_${formatDate(new Date())}.csv`;
        const fileName = getFileName(templateFileName);
        saveAs(blob, fileName);
        return fileName;
      })
      .catch((error) => {
        Sentry.captureException(error);
        return '';
      });
  }
}
