import React, { useState, useEffect } from 'react';

// Material UI
import {
  AppBar,
  Toolbar,
  Paper,
  Grid,
  Box,
  makeStyles,
  Theme,
  TextFieldProps,
} from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  useGetStudyReportCommentsForAdminQuery,
  useCreateStudyReportCommentForAdminMutation,
  AdminStudyReportCommentInput,
  AdminStudyReportCommentSearchInput,
  Valid,
} from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export interface SearchParam {
  id?: number;
  studyReportId?: number;
  userId?: number;
  status?: number;
}

export interface AddParam {
  studyReportID: number;
  userID: number;
  content: string;
  status: number;
}

const defaultAddData = {
  studyReportID: 0,
  userID: 0,
  content: '',
  status: Valid.Valid,
};

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCurrentQueryObject = () => {
    return queryString.parse(location.search);
  };

  // Add Dialog
  const [open, showAddDialog] = useState(false);
  const [addData, setAddData] = useState<AdminStudyReportCommentInput>(defaultAddData);

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  function handleAdd() {
    postData();
  }

  const handleChange: SelectInputProps['onChange'] & TextFieldProps['onChange'] = (event) => {
    if (!event.target.name) return;
    const val = ['userId'].includes(event.target.name)
      ? parseInt(String(event.target.value))
      : event.target.value === ''
        ? undefined
        : event.target.value;

    setAddData({
      ...addData,
      [event.target.name]: val,
    });
  };

  // Fetch Data
  const [searchParam, setSearchParam] = useState<AdminStudyReportCommentSearchInput>({
    page: 1,
    limit: 15,
  });

  const studyReportCommentsData = useGetStudyReportCommentsForAdminQuery({
    variables: {
      input: {
        id: searchParam.id,
        page: searchParam.page,
        limit: searchParam.limit,
        userID: searchParam.userID,
        studyReportID: searchParam.studyReportID,
        status: searchParam.status,
      },
    },
  });

  const [createStudyReportComment, createStudyReportCommentData] =
    useCreateStudyReportCommentForAdminMutation();

  const loading = studyReportCommentsData.loading || createStudyReportCommentData.loading;

  const postData = useSafeAsyncCallback(
    React.useCallback(async () => {
      try {
        await createStudyReportComment({
          variables: {
            input: {
              userID: addData.userID,
              studyReportID: addData.studyReportID,
              content: addData.content,
              status: addData.status,
            },
          },
        });
        setAddData(defaultAddData);
        showAddDialog(false);
        studyReportCommentsData.refetch();
      } catch {
        return;
      }
    }, [addData, createStudyReportComment, studyReportCommentsData]),
  );

  const setQuery = (queryObject: Record<string, unknown>) => {
    navigate({
      pathname: '/studyReportComments',
      search: queryString.stringify(queryObject),
    });
  };

  const handleSearchInput: SelectInputProps['onChange'] & TextFieldProps['onChange'] = (event) => {
    if (event.target.name === undefined) {
      return;
    }

    setSearchParam({
      ...searchParam,
      [event.target.name]: event.target.value ? event.target.value : undefined,
    });
    const queryObject = getCurrentQueryObject();

    queryObject[event.target.name] = String(event.target.value);
    setQuery(queryObject);
  };

  const handleSearchSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const queryObject = getCurrentQueryObject();
    Object.entries(searchParam).forEach((value, key) => {
      queryObject[key] = String(value);
    });

    queryObject.page = '1';

    setQuery(queryObject);
  };

  function handleChangePerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam((prev) => ({
      ...prev,
      limit: parseInt(event.target.value),
    }));
    const queryObject = getCurrentQueryObject();
    queryObject['limit'] = String(event.target.value);

    setQuery(queryObject);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChangePage(event: any, page: number) {
    // setState(Material UI は page=0 はじまり, LMS API は page=1 はじまり)
    setSearchParam((prev) => ({
      ...prev,
      page: page + 1,
    }));
    const queryObject = getCurrentQueryObject();
    queryObject['page'] = String(event.target.value);

    setQuery(queryObject);
  }

  useEffect(() => {
    const queryObject = getCurrentQueryObject();
    for (const key in queryObject) {
      setSearchParam((prev) => ({
        ...prev,
        [key]: queryObject[key],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                StudyReportComment
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSearchSubmit}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="id"
                      name="id"
                      label="ID"
                      type="number"
                      fullWidth
                      key={searchParam.id ?? undefined}
                      value={searchParam.id}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="studyReportId"
                      name="studyReportID"
                      label="StudyReportId"
                      type="number"
                      fullWidth
                      key={searchParam.studyReportID ?? undefined}
                      value={searchParam.studyReportID}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="userId"
                      name="userID"
                      label="UserId"
                      type="number"
                      fullWidth
                      key={searchParam.userID ?? undefined}
                      value={searchParam.userID}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Select
                        key={searchParam.status ?? undefined}
                        value={searchParam.status}
                        onChange={handleSearchInput}
                        input={<Input name="status" id="status" />}
                      >
                        <MenuItem value={Valid.Invalid}>{ValidLabels.INVALID}</MenuItem>
                        <MenuItem value={Valid.Valid}>{ValidLabels.VALID}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">StudyReport</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studyReportCommentsData.data &&
                  studyReportCommentsData.data.getStudyReportCommentsForAdmin.items.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        <Link to={`/studyReportComments/${row.id}`}>{row.id}</Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`/studyReports/${row.studyReportID}`}>
                          {row.studyReport?.content.substring(0, 20)}...
                        </Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`/users/${row.user.id}`}>{row.user.personalInfo?.name}</Link>
                      </TableCell>
                      <TableCell align="right">{row.content.substring(0, 20)}...</TableCell>
                      <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                      <TableCell align="right">{row.createdAt}</TableCell>
                      <TableCell align="right">{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={studyReportCommentsData.data?.getStudyReportCommentsForAdmin.total ?? 0}
            rowsPerPage={searchParam.limit}
            page={searchParam.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePerPage}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="studyReportId"
            name="studyReportID"
            label="StudyReportId"
            type="number"
            defaultValue={addData.studyReportID}
            fullWidth
            onChange={handleChange}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="userId"
            name="userID"
            label="UserId"
            type="number"
            defaultValue={addData.userID}
            fullWidth
            onChange={handleChange}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="content"
            name="content"
            label="Content"
            type="text"
            multiline={true}
            minRows={5}
            maxRows={Infinity}
            defaultValue={addData.content}
            fullWidth
            onChange={handleChange}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={addData.status}
                onChange={handleChange}
                input={<Input name="status" id="status" />}
              >
                <MenuItem value={Valid.Invalid}>{ValidLabels.INVALID}</MenuItem>
                <MenuItem value={Valid.Valid}>{ValidLabels.VALID}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {createStudyReportCommentData.error && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {createStudyReportCommentData.error.graphQLErrors.map((error, index) => (
                <div key={index}>
                  {error.path} : {error.message}
                </div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
