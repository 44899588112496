import React, { useState, useMemo, useCallback } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  AdminCreateInput,
  AdminSearchInput,
  useCreateAdminMutation,
  useGetAdminsQuery,
  Valid,
} from '../../../gen/graphql';
import { strToEnum } from '../../../utils/common';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { adminCreateSchema } from '../../../formSchema/admin';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  uploadImageBtn: {
    marginLeft: theme.spacing(2),
  },
}));

type SearchFormInput = Omit<AdminSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const userId = params.get('userID') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      userID: parseInt(userId) > 0 ? parseInt(userId) : undefined,
      status: strToEnum(status, Valid),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetAdminsQuery({
    variables: {
      input: currentSearchParams,
    },
    notifyOnNetworkStatusChange: true,
  });

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      userID: currentSearchParams.userID,
      status: currentSearchParams.status,
    },
    // 検索フォームはバリデーションなし(検索してもヒットしないだけなので)
  });

  const addForm = useForm<AdminCreateInput>({
    resolver: yupResolver(adminCreateSchema),
    defaultValues: {
      name: '',
      kanaName: '',
      nickName: '',
      email: '',
      skypeID: '',
    },
  });
  const addFormErrors = addForm.formState.errors;

  // Add Dialog
  const [open, showAddDialog] = useState(false);

  // データ登録
  const [addAdminMutation, { error: addError, loading: adding }] = useCreateAdminMutation();

  const image = addForm.watch('image');
  const imageSrc = useMemo(() => (image ? URL.createObjectURL(image) : ''), [image]);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminSearchInput): void => {
      navigate({
        pathname: '/admins',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 検索
  const searchAdmins = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  const addAdmin = useSafeAsyncCallback(
    useCallback(
      async (input: AdminCreateInput): Promise<void> => {
        try {
          await addAdminMutation({ variables: { input } });
        } catch {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          return;
        }

        // ダイアログを閉じて入力フォーム初期化
        showAddDialog(false);
        addForm.reset();

        // 再検索
        refetch({ input: currentSearchParams });
      },
      [addForm, refetch, currentSearchParams, addAdminMutation],
    ),
  );

  const openAddDialog = useCallback(() => showAddDialog(true), []);
  const closeAddDialog = useCallback(() => showAddDialog(false), []);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Admin
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={openAddDialog}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || adding) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(searchAdmins)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="userID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="userId"
                          label="UserId"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.admins.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/admins/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/users/${row.user.id}`}>{row.user.personalInfo?.name}</Link>
                    </TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.admins.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={closeAddDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <Controller
            name="name"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                error={!!addFormErrors.name}
                helperText={addFormErrors.name?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="kanaName"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="kanaName"
                label="KanaName"
                type="text"
                fullWidth
                error={!!addFormErrors.kanaName}
                helperText={addFormErrors.kanaName?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="nickName"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="nickName"
                label="NickName"
                type="text"
                fullWidth
                error={!!addFormErrors.nickName}
                helperText={addFormErrors.nickName?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <Controller
            name="email"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email"
                type="text"
                fullWidth
                error={!!addFormErrors.email}
                helperText={addFormErrors.email?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          <TextField
            autoFocus
            margin="dense"
            id="image"
            label="Image"
            type="text"
            fullWidth
            error={!!addFormErrors.image}
            helperText={addFormErrors.image?.message}
            disabled
          />
          <Grid container alignItems="flex-start">
            <Grid item>
              <img src={imageSrc} alt="UserImage" />
            </Grid>
            <Grid item>
              <input
                accept="image/*"
                type="file"
                id="upload-img"
                onChange={(e) => {
                  if (!e.target.files?.[0]) return;
                  addForm.setValue('image', e.target.files?.[0], { shouldValidate: true });
                }}
                style={{ display: 'none' }}
              />
              <Button
                htmlFor="upload-img"
                component="label"
                variant="contained"
                className={classes.uploadImageBtn}
              >
                <CloudUploadIcon />
                UPLOAD
              </Button>
            </Grid>
          </Grid>
          <Controller
            name="skypeID"
            control={addForm.control}
            render={({ field: { ref, ...rest } }) => (
              <TextField
                autoFocus
                margin="dense"
                id="skypeID"
                label="SkypeId"
                type="text"
                fullWidth
                error={!!addFormErrors.skypeID}
                helperText={addFormErrors.skypeID?.message}
                inputRef={ref}
                {...rest}
              />
            )}
          />
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeAddDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={addForm.handleSubmit(addAdmin)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
