import React, { BaseSyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

// Material UI
import {
  AppBar,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Toolbar,
} from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import TextField from '@material-ui/core/TextField';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { DESTINATIONS } from '../../const/Tweet';
import { GetAdminTweetsQueryVariables, useGetAdminTweetsQuery } from '../../../gen/graphql';
import queryString from 'query-string';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 20;

// queryパラメータのparse
const parseQuery = (
  query: string,
): {
  userID: string;
  searchWord: string;
  tweetDestination: string;
} => {
  const params = new URLSearchParams(query);
  return {
    userID: params.get('userID') ?? '',
    searchWord: params.get('searchWord') ?? '',
    tweetDestination: params.get('tweetDestination') ?? '',
  };
};

// graphqlのqueryに渡すパラメータ作成
const makeSearchParams = ({
  after,
  userID,
  searchWord,
  tweetDestination,
}: {
  after?: string;
  userID: string;
  searchWord: string;
  tweetDestination: string;
}): GetAdminTweetsQueryVariables => {
  return {
    first: FETCH_ROWS_LIMIT,
    after,
    userID: parseInt(userID) > 0 ? parseInt(userID) : undefined,
    searchWord: searchWord.trim().length > 0 ? searchWord : undefined,
    tweetDestination: DESTINATIONS.find(({ value }) => value === tweetDestination)?.value,
  };
};

const useStyles = makeStyles(() => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  listWrapper: {
    maxHeight: '42.75rem',
    overflowX: 'auto',
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  secondaryBar: {
    zIndex: 0,
  },
  formControl: {
    width: '100%',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // form values
  const [userId, setUserId] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [tweetDestination, setTweetDestination] = useState('');

  // fetchMore, refetchのローディングはhooksのloadingに反映されないため自前でstate管理
  const [reFetching, setReFetching] = useState(false);
  const [fetchingMore, setFetchingMore] = useState(false);

  const { data, loading, fetchMore, refetch } = useGetAdminTweetsQuery({
    variables: makeSearchParams(parseQuery(location.search)),
  });
  const pageInfo = data?.adminTweetsV1.pageInfo;

  // 検索
  const handleClickSearch = useCallback(async (): Promise<void> => {
    setReFetching(true);
    try {
      await refetch(
        makeSearchParams({
          userID: userId,
          searchWord,
          tweetDestination,
        }),
      );
    } catch (e) {
      // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
    } finally {
      setReFetching(false);
    }

    // 詳細画面からの戻り時に検索条件を残しておくための対応
    navigate({
      pathname: '/tweets',
      search: queryString.stringify({
        userID: userId,
        searchWord,
        tweetDestination,
      }),
    });
  }, [navigate, refetch, searchWord, tweetDestination, userId]);

  // 投稿を更に取得
  const fetchMoreTweets = useCallback(async (): Promise<void> => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    setFetchingMore(true);

    try {
      await fetchMore({
        variables: makeSearchParams({ after: pageInfo.endCursor, ...parseQuery(location.search) }),
      });
    } catch (e) {
      // エラーをキャッチしても何も行わない
    } finally {
      setFetchingMore(false);
    }
  }, [pageInfo, fetchMore, location.search]);

  // 一覧スクロール
  const handleListScroll = useCallback(
    ({ currentTarget }: BaseSyntheticEvent) => {
      if (currentTarget.scrollTop + currentTarget.clientHeight >= currentTarget.scrollHeight) {
        fetchMoreTweets();
      }
    },
    [fetchMoreTweets],
  );

  // queryパラメータを検索フォームの初期値にセット
  useEffect(() => {
    const { userID, searchWord, tweetDestination } = parseQuery(location.search);
    setUserId(userID);
    setSearchWord(searchWord);
    setTweetDestination(tweetDestination);
  }, [location.search]);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Tweets
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || reFetching || fetchingMore) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1c-content"
              id="panel1c-header"
            >
              <div>
                <Typography>検索</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="userId"
                    name="userID"
                    label="userId"
                    type="number"
                    fullWidth
                    value={userId}
                    onChange={({ target: { value } }) => setUserId(value)}
                    inputProps={{ min: 1 }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="searchWord"
                    name="searchWord"
                    label="searchWord"
                    type="string"
                    fullWidth
                    value={searchWord}
                    onChange={({ target: { value } }) => setSearchWord(value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl className={classes.formControl} fullWidth>
                    <InputLabel htmlFor="tweetDestination">TweetDestination</InputLabel>
                    {/* Select.onChangeのvalueはunknown型*/}
                    <Select
                      value={tweetDestination}
                      onChange={({ target: { value } }) => setTweetDestination(String(value))}
                      name="tweetDestination"
                    >
                      <MenuItem value="" />
                      {DESTINATIONS.map(({ value, label }) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </AccordionDetails>
            <Divider light />
            <AccordionActions>
              <Button size="small" color="primary" onClick={handleClickSearch}>
                <SearchIcon className={classes.block} />
                Search
              </Button>
            </AccordionActions>
          </Accordion>
        </Paper>
        <Paper className={classes.paper}>
          {/* 再検索時(reFetching)はスクロール位置を戻すためにアンマウントする(スクロール位置が残ってfetchMoreが走ってしまうので) */}
          {!reFetching && (
            <Grid className={classes.listWrapper} onScroll={handleListScroll}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell align="right">UserName</TableCell>
                    <TableCell align="right">Content</TableCell>
                    <TableCell align="right">TweetComments</TableCell>
                    <TableCell align="right">TweetFavorites</TableCell>
                    <TableCell align="right">TweetImages</TableCell>
                    <TableCell align="right">TweetStatus</TableCell>
                    <TableCell align="right">CreatedAt</TableCell>
                    <TableCell align="right">UpdatedAt</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.adminTweetsV1.items.map(({ id, tweetDetail, tweetStatus }) => (
                    <TableRow key={id} color="gray">
                      <TableCell component="th" scope="row">
                        <Link to={`/tweets/${id}`}> {id}</Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`/users/${tweetDetail.user.id}`}>
                          {tweetDetail.user.personalInfo?.name}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{tweetDetail.content}</TableCell>
                      <TableCell align="right">
                        {tweetDetail.tweetComments?.length ?? 0}件
                      </TableCell>
                      <TableCell align="right">
                        {tweetDetail.tweetFavorites?.length ?? 0}件
                      </TableCell>
                      <TableCell align="right">{tweetDetail.tweetImages?.length ?? 0}枚</TableCell>
                      <TableCell align="right">{tweetStatus}</TableCell>
                      <TableCell align="right">{tweetDetail.createdAt}</TableCell>
                      <TableCell align="right">{tweetDetail.updatedAt}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          )}
        </Paper>
      </main>
    </div>
  );
};
