import React, { useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useForm } from 'react-hook-form';

import {
  GraduationDateNotificationSendLogSearchInput,
  useGetGraduationDateNotificationSendLogsForAdminQuery,
} from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Grid, Paper, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';

import { GraduationDateNotificationSendLogSearchFormInput, SearchForm } from './SearchForm';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): GraduationDateNotificationSendLogSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const courseId = params.get('courseID') ?? '';
    const studentId = params.get('studentID') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      courseID: parseInt(courseId) > 0 ? parseInt(courseId) : undefined,
      studentID: parseInt(studentId) > 0 ? parseInt(studentId) : undefined,
    };
  }, [location.search]);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: GraduationDateNotificationSendLogSearchInput): void => {
      navigate({
        pathname: '/graduationDateNotificationSendLogs',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const { data, loading, refetch } = useGetGraduationDateNotificationSendLogsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  const searchForm = useForm<GraduationDateNotificationSendLogSearchFormInput>({
    defaultValues: {
      courseID: currentSearchParams.courseID,
      studentID: currentSearchParams.studentID,
    },
    // 検索してもヒットしないだけの為、バリデーションなし
  });

  // 検索
  const searchSendLogs = useCallback(
    async (input: GraduationDateNotificationSendLogSearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                GraduationDateNotificationSendLog
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={searchSendLogs} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Student</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell align="right">CourseEndDate</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.graduationDateNotificationSendLogsForAdmin?.items?.map((row) => (
                  <TableRow key={row.courseID + row.courseEndDate}>
                    <TableCell>
                      <Link
                        to={`/graduationDateNotificationSendLogs/${row.courseID}/${row.courseEndDate}`}
                      >
                        Detail
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`/students/${row.course?.student?.id}`}>
                        {row.course?.student?.user?.personalInfo?.name}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Link to={`/courses/${row.courseID}`}>{row.course?.plan?.name}</Link>
                    </TableCell>
                    <TableCell align="right">{row.courseEndDate}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.graduationDateNotificationSendLogsForAdmin?.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
