import React, { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Box, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Other
import { Link } from 'react-router-dom';
import queryString from 'query-string';

// fp-ts
import { CsvRepositoryOnAPI } from '../../../infrastructure/csv/CsvRepositoryOnAPI';
import * as Sentry from '@sentry/browser';
import {
  useGetInstructorSchedulesForAdminQuery,
  AdminInstructorScheduleSearchInput,
  useCreateInstructorScheduleForAdminMutation,
  AdminInstructorScheduleInput,
  Valid,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';
import { getUTCDatetime } from '../../const/Util';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  csvBtn: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 99,
    backgroundColor: '#009be5',
    color: 'white',
    padding: '10px 13px',
    textDecoration: 'none',
    borderRadius: '10px',
    boxShadow: '3px 3px 4px black',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

// 登録フォームの初期値
const addInputDefaultValues = {
  startAt: '',
  endAt: '',
  userID: 0,
};
type SearchFormInput = Omit<AdminInstructorScheduleSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminInstructorScheduleSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const userID = params.get('userID') ?? '';
    const startAt = params.get('startAt') ?? '';
    const endAt = params.get('endAt') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      userID: parseInt(userID) > 0 ? parseInt(userID) : undefined,
      startAt: startAt !== '' ? startAt : undefined,
      endAt: endAt !== '' ? endAt : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  const csvRepository = new CsvRepositoryOnAPI();

  const [createInstructorSchedule, { error: addError }] =
    useCreateInstructorScheduleForAdminMutation();
  const [addInput, setAddInput] =
    React.useState<AdminInstructorScheduleInput>(addInputDefaultValues);

  const { data: instructorScheduleData, refetch } = useGetInstructorSchedulesForAdminQuery({
    variables: { input: currentSearchParams },
  });
  const instructorSchedules = instructorScheduleData?.instructorSchedulesForAdmin.items ?? [];
  const total = instructorScheduleData?.instructorSchedulesForAdmin.total ?? 0;

  // 再検索
  const refetchInstructorSchedules = useCallback(
    async (params: AdminInstructorScheduleSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminInstructorScheduleSearchInput): void => {
      navigate({
        pathname: '/instructorSchedules',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // Add Dialog
  const [open, showAddDialog] = React.useState(false);

  const handleAddDialogOpen = () => {
    showAddDialog(true);
  };

  const handleAddDialogClose = () => {
    showAddDialog(false);
  };

  const zeroPadding = (num: number) => `0${num}`.substr(-2);

  const formatStartAt = useCallback((date: Date) => {
    if (!date) return '';
    const d = new Date(date);
    return (
      d.getFullYear() +
      '-' +
      zeroPadding(d.getMonth() + 1) +
      '-' +
      zeroPadding(d.getDate()) +
      'T' +
      '00:00:00+09:00'
    );
  }, []);

  const formatEndAt = useCallback((date: Date) => {
    if (!date) return '';
    const d = new Date(date);
    return (
      d.getFullYear() +
      '-' +
      zeroPadding(d.getMonth() + 1) +
      '-' +
      zeroPadding(d.getDate()) +
      'T' +
      '23:59:59+09:00'
    );
  }, []);

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      userID: currentSearchParams.userID,
      startAt: currentSearchParams.startAt,
      endAt: currentSearchParams.endAt,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };

      if (input.startAt) {
        params.startAt = formatStartAt(new Date(input.startAt));
      }

      if (input.endAt) {
        params.endAt = formatEndAt(new Date(input.endAt));
      }
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchInstructorSchedules(params);
      }
      createHistory(params);
    },
    [currentSearchParams, createHistory, formatStartAt, formatEndAt, refetchInstructorSchedules],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // データ作成ボタンクリック
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await createInstructorSchedule({
          variables: {
            input: addInput,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      // ダイアログを閉じて入力フォーム初期化
      showAddDialog(false);
      setAddInput(addInputDefaultValues);

      // 再検索
      refetchInstructorSchedules(currentSearchParams);
    }, [addInput, createInstructorSchedule, currentSearchParams, refetchInstructorSchedules]),
  );

  const csvFileDownload = () => {
    const query = queryString.stringify({
      id: currentSearchParams.id,
      userID: currentSearchParams.userID,
      startAt: currentSearchParams.startAt,
      endAt: currentSearchParams.endAt,
      status:
        currentSearchParams.status === Valid.Valid
          ? '1'
          : currentSearchParams.status === Valid.Invalid
            ? '0'
            : '',
      limit: currentSearchParams.limit,
      page: currentSearchParams.page,
    });
    const url = `/api/v1/admin/csv/instructor_schedules?${query}`;

    csvRepository.getCsv('instructor_schedule', url).catch((error) => {
      Sentry.captureException(error);
    });
  };

  const handleChangeAddUserID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      userID: parseInt(value),
    }));
  }, []);

  const handleChangeAddStartAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setAddInput((current) => ({
        ...current,
        startAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeAddEndAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setAddInput((current) => ({
        ...current,
        endAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                InstructorSchedules
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="userID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="userID"
                          label="UserID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="startAt"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="startAt"
                            label="StartAt"
                            format="yyyy/MM/dd"
                            value={value ?? null}
                            fullWidth
                            inputRef={ref}
                            maxDate={searchForm.getValues().endAt}
                            {...rest}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="endAt"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            autoOk={true}
                            margin="normal"
                            id="endAt"
                            label="EndAt"
                            format="yyyy/MM/dd"
                            value={value ?? null}
                            fullWidth
                            inputRef={ref}
                            {...rest}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">InstructorName</TableCell>
                  <TableCell align="right">StartAt</TableCell>
                  <TableCell align="right">EndAt</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {instructorSchedules.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/instructorSchedules/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/instructors/${row.instructor?.id}`}>
                        {row.user?.personalInfo?.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{row.startAt}</TableCell>
                    <TableCell align="right">{row.endAt}</TableCell>
                    <TableCell align="right">{row.status ? '有効' : '無効'}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <button onClick={csvFileDownload} id="csvLink" className={classes.csvBtn}>
        CSV出力
      </button>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="userID"
            name="userID"
            label="userID"
            type="number"
            defaultValue={addInput.userID}
            fullWidth
            onChange={handleChangeAddUserID}
            inputProps={{ min: 1 }}
          />
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="startAt"
                label="開始時間"
                format="yyyy-MM-dd HH:mm"
                value={addInput.startAt}
                onChange={handleChangeAddStartAt}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="endAt"
                label="終了時間"
                format="yyyy-MM-dd HH:mm"
                value={addInput.endAt}
                onChange={handleChangeAddEndAt}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </div>
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
