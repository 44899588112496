import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AdminBannerInput } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { ApolloError } from '@apollo/client';
import TextField from '@material-ui/core/TextField';
import { Box, FormHelperText, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Grid } from '@material-ui/core';

import { ValidLabels } from '../../const/Valid';
import { UserTargetLabels } from '../../const/Banner';

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  imageStyle: {
    width: '100%',
  },
  uploadImageBtn: {
    marginLeft: theme.spacing(2),
  },
}));

interface Props {
  formId: string;
  form: UseFormReturn<AdminBannerInput>;
  onSubmit: (input: AdminBannerInput) => void;
  error: ApolloError | undefined;
  imageSrc: string;
  setImage: React.Dispatch<React.SetStateAction<File | null>>;
}

export const Form: React.FC<Props> = ({
  formId,
  form,
  onSubmit,
  error,
  imageSrc,
  setImage,
}): JSX.Element => {
  const classes = useStyles();
  const formErrors = form.formState.errors;

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Controller
        name="title"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.title}
            helperText={formErrors.title?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <TextField
        autoFocus
        margin="dense"
        id="imageUrl"
        label="imageUrl"
        type="text"
        fullWidth
        error={!!formErrors.imageURL}
        helperText={formErrors.imageURL?.message}
      />
      <Grid container alignItems="flex-start">
        <Grid item>
          <img src={imageSrc} alt="バナー画像" className={classes.imageStyle} />
        </Grid>
        <Grid item>
          <input
            accept="image/*"
            type="file"
            id="upload-img"
            onChange={(e) => {
              if (!e.target.files?.[0]) return;
              setImage(e.target.files?.[0]);
              // YupのImageUrlのRequireチェックを通すためにダミーの値を設定
              // 実際のアップロード処理はYupチェックOKの後に、画像アップロードされ設定される
              form.setValue('imageURL', 'ImageURLIsSet');
            }}
            style={{ display: 'none' }}
          />
          <Button
            htmlFor="upload-img"
            component="label"
            variant="contained"
            className={classes.uploadImageBtn}
          >
            <CloudUploadIcon />
            UPLOAD
          </Button>
        </Grid>
      </Grid>
      <Controller
        name="link"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="link"
            label="Link"
            type="text"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.link}
            helperText={formErrors.link?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <Controller
        name="sort"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="sort"
            label="Sort"
            type="number"
            fullWidth
            InputLabelProps={{ shrink: true }}
            style={{ margin: '10px 0' }}
            error={!!formErrors.sort}
            helperText={formErrors.sort?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth error={!!formErrors.status}>
        <InputLabel htmlFor="status">Status</InputLabel>
        <Controller
          name="status"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="status" id="status" />}
              error={!!formErrors.status}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(ValidLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.status?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.isNotLoginUser}>
        <InputLabel htmlFor="isNotLoginUser">isNotLoginUser</InputLabel>
        <Controller
          name="isNotLoginUser"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="isNotLoginUser" id="isNotLoginUser" />}
              error={!!formErrors.isNotLoginUser}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(UserTargetLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.isNotLoginUser?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.isTargetFree}>
        <InputLabel htmlFor="isTargetFree">isTargetFree</InputLabel>
        <Controller
          name="isTargetFree"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="isTargetFree" id="isTargetFree" />}
              error={!!formErrors.isTargetFree}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(UserTargetLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.isTargetFree?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.isTargetSubscriptionUser}>
        <InputLabel htmlFor="isTargetSubscriptionUser">isTargetSubscriptionUser</InputLabel>
        <Controller
          name="isTargetSubscriptionUser"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="isTargetSubscriptionUser" id="isTargetSubscriptionUser" />}
              error={!!formErrors.isTargetSubscriptionUser}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(UserTargetLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.isTargetSubscriptionUser?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.isTargetStudent}>
        <InputLabel htmlFor="isTargetStudent">isTargetStudent</InputLabel>
        <Controller
          name="isTargetStudent"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="isTargetStudent" id="isTargetStudent" />}
              error={!!formErrors.isTargetStudent}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(UserTargetLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.isTargetStudent?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.isTargetGraduate}>
        <InputLabel htmlFor="isTargetGraduate">isTargetGraduate</InputLabel>
        <Controller
          name="isTargetGraduate"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="isTargetGraduate" id="isTargetGraduate" />}
              error={!!formErrors.isTargetGraduate}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(UserTargetLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.isTargetGraduate?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.isTargetInstructor}>
        <InputLabel htmlFor="isTargetInstructor">isTargetInstructor</InputLabel>
        <Controller
          name="isTargetInstructor"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="isTargetInstructor" id="isTargetInstructor" />}
              error={!!formErrors.isTargetInstructor}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(UserTargetLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.isTargetInstructor?.message}</FormHelperText>
      </FormControl>
      <FormControl fullWidth error={!!formErrors.isTargetTeamUser}>
        <InputLabel htmlFor="isTargetTeamUser">isTargetTeamUser</InputLabel>
        <Controller
          name="isTargetTeamUser"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Select
              input={<Input name="isTargetTeamUser" id="isTargetTeamUser" />}
              error={!!formErrors.isTargetTeamUser}
              inputRef={ref}
              {...rest}
            >
              {Object.entries(UserTargetLabels).map(([value, label]) => (
                <MenuItem value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        <FormHelperText>{formErrors.isTargetTeamUser?.message}</FormHelperText>
      </FormControl>
      {error?.graphQLErrors && (
        <Box className={classes.inputError}>
          <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
          {error.graphQLErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Box>
      )}
    </form>
  );
};
