import { AuthorizationType } from '../../gen/graphql';

export const AuthorizationTypeLabels = {
  [AuthorizationType.Public]: '一般公開',
  [AuthorizationType.Registered]: 'ログイン限定',
  [AuthorizationType.Light]: 'ライトプラン以上限定',
  [AuthorizationType.Basic]: 'ベーシックプラン以上限定',
  [AuthorizationType.Premium]: 'プレミアムプラン以上限定',
} as const;

export const strToAuthorizationTypeType = (str: string): AuthorizationType | undefined => {
  return Object.values(AuthorizationType).find((v) => v === str);
};
