import { ProviderSubscriptionStatus } from '../../gen/graphql';

export const providerSubscriptionStatusPhaseLabels: { [index: string]: string } = {
  [ProviderSubscriptionStatus.Active]: 'アクティブ',
  [ProviderSubscriptionStatus.Canceled]: 'キャンセル',
  [ProviderSubscriptionStatus.Incomplete]: '未完了',
  [ProviderSubscriptionStatus.IncompleteExpired]: '未完了期限切れ',
  [ProviderSubscriptionStatus.PastDue]: '支払い失敗',
  [ProviderSubscriptionStatus.Trialing]: 'トライアル',
  [ProviderSubscriptionStatus.Unpaid]: '未払い',
} as const;
