import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Icon

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetClaimDetailForAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ claim_detail_id: string }>().claim_detail_id;
  const id = Number(paramID);

  const { data, loading } = useGetClaimDetailForAdminQuery({
    variables: { id },
  });
  const claimDetail = data?.claimDetailForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                ClaimDetail Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/claimDetails/${claimDetail?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{claimDetail?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ClaimID
                </TableCell>
                <TableCell>
                  <Link to={`/claims/${claimDetail?.claim.id}`}>{claimDetail?.claim.id}</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  ClaimCategory
                </TableCell>
                <TableCell>{claimDetail?.claimCategory.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Date
                </TableCell>
                <TableCell>{claimDetail?.date}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Course
                </TableCell>
                <TableCell>
                  {claimDetail?.course ? (
                    <Link to={`/courses/${claimDetail?.course?.id}`}>
                      {claimDetail?.course?.id}
                    </Link>
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Lesson
                </TableCell>
                <TableCell>
                  {claimDetail?.lesson?.id ? (
                    <Link to={`/lessons/${claimDetail?.lesson?.id}`}>
                      {claimDetail?.lesson?.title}
                    </Link>
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  SpotLesson
                </TableCell>
                <TableCell>
                  {claimDetail?.spotLesson?.id ? (
                    <Link to={`/spotLessons/${claimDetail?.spotLesson?.id}`}>
                      {claimDetail?.spotLesson?.id}
                    </Link>
                  ) : (
                    '-'
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UnitPrice
                </TableCell>
                <TableCell>{claimDetail?.unitPrice} 円</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Amount
                </TableCell>
                <TableCell>{claimDetail?.amount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  AdditionalPayment
                </TableCell>
                <TableCell>
                  {claimDetail?.additionalPayment ? claimDetail?.additionalPayment : '0'} 円
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  SubTotal
                </TableCell>
                <TableCell>{claimDetail?.subTotal} 円</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Remark
                </TableCell>
                <TableCell>{claimDetail?.remark}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>{claimDetail?.status ? ValidLabels[claimDetail.status] : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{claimDetail?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{claimDetail?.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
    </div>
  );
};
