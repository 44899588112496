import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  AdminStudentInput,
  useGetStudentQuery,
  useUpdateStudentMutation,
} from '../../../gen/graphql';
import { studentSchema } from '../../../formSchema/student';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { StatusLabels } from '../../const/student';

export interface StudentInput {
  firstName?: string | null;
  kanaName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
  scStudentID: string;
  status: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ student_id: string }>().student_id;
  const id = Number(paramID);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<StudentInput>({
    resolver: yupResolver(studentSchema),
    defaultValues: {
      scStudentID: '',
      lastName: '',
      firstName: '',
      middleName: '',
      kanaName: '',
      status: 0,
    },
  });

  const { loading } = useGetStudentQuery({
    variables: { id },
    onCompleted: ({ studentForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      reset({
        scStudentID: data.scStudentID,
        lastName: data.personalInfo?.lastName ?? '',
        firstName: data.personalInfo?.firstName ?? '',
        middleName: data.personalInfo?.middleName ?? '',
        kanaName: data.personalInfo?.kanaName ?? '',
        status: data.status ?? 0,
      });
    },
  });

  const [updateStudentMutation, { error: updateError, loading: updating }] =
    useUpdateStudentMutation();

  const updateStudent = useSafeAsyncCallback(
    useCallback(
      async (studentInput: StudentInput): Promise<void> => {
        const adminStudentInput: AdminStudentInput = {
          firstName: studentInput.firstName ?? '',
          kanaName: studentInput.kanaName ?? '',
          lastName: studentInput.lastName ?? '',
          middleName: studentInput.middleName ?? '',
          scStudentID: studentInput.scStudentID,
          status: studentInput.status,
        };
        try {
          await updateStudentMutation({ variables: { id, input: adminStudentInput } });
        } catch {
          return;
        }

        navigate(-1);
      },
      [navigate, updateStudentMutation, id],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Student Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || updating) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(updateStudent)}>
            <div>
              <Controller
                name="scStudentID"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="scStudentID"
                    label="ScStudentID"
                    type="text"
                    placeholder="12"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.scStudentID}
                    helperText={errors.scStudentID?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="lastName"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="lastName"
                    label="LastName"
                    type="text"
                    placeholder="12"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="firstName"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    label="FirstName"
                    type="text"
                    placeholder="12"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="middleName"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="middleName"
                    label="MiddleName"
                    type="text"
                    placeholder="12"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.middleName}
                    helperText={errors.middleName?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="kanaName"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="kanaName"
                    label="KanaName"
                    type="text"
                    placeholder="12"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.kanaName}
                    helperText={errors.kanaName?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Controller
                  name="status"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <Select error={!!errors.status} inputRef={ref} {...rest}>
                      {Object.entries(StatusLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError?.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
