import * as yup from 'yup';
import { StatusLabels } from '../components/const/student';

export const studentSchema = yup.object({
  scStudentID: yup.string().required().max(100).trim(),
  lastName: yup.string().nullable().max(100).trim(),
  firstName: yup.string().nullable().max(100).trim(),
  middleName: yup.string().nullable().max(100).trim(),
  kanaName: yup.string().nullable().max(100).trim(),
  status: yup.number().required().oneOf(Object.keys(StatusLabels).map(Number)),
});
