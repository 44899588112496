import * as yup from 'yup';
import { Valid } from '../gen/graphql';

export const adminCreateSchema = yup.object({
  name: yup.string().required().max(100).trim(),
  kanaName: yup.string().required().max(100).trim(),
  nickName: yup.string().nullable().max(100).trim(),
  email: yup.string().required().email().max(100).trim(),
  image: yup.mixed<File>().required(),
  skypeID: yup.string().nullable().max(255).trim(),
});

export const adminUpdateSchema = yup.object({
  status: yup.string().required().oneOf(Object.values(Valid)),
});
