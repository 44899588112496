import React from 'react';
import styled from 'styled-components';

interface InputProps {
  placeholder?: string;
  unit?: string;
  width?: string;
  name: string;
  value: string;
  type: string;
  error?: boolean;
  min?: number;
  max?: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
}

export const Input: React.FC<InputProps> = (props) => {
  return (
    <Container error={props.error}>
      <StyledInput
        placeholder={props.placeholder}
        name={props.name}
        width={props.width}
        value={props.value}
        type={props.type}
        onChange={props.onChange}
        error={props.error}
        min={props.min}
        max={props.max}
        onFocus={props.onFocus}
      />
      {props.unit ? <Unit error={props.error}>{props.unit}</Unit> : ''}
    </Container>
  );
};

const Container = styled.div<{ error?: boolean; width?: string }>`
  border: 1px solid ${(props) => (props.error ? '#fd2f92' : '#dddddd')};
  border-radius: 2px;
  background-color: ${(props) => (props.error ? '#fce5e8' : '#ffffff')};
`;

const StyledInput = styled.input<{ width?: string; error?: boolean }>`
  padding: 0.5rem;
  appearance: none;
  border: none;
  font-size: 1rem;
  box-sizing: border-box;
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  background-color: ${(props) => (props.error ? '#fce5e8' : '#ffffff')};
`;

const Unit = styled.span<{ error?: boolean }>`
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.6);
`;
