import React, { useCallback, useState } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Date
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Date-fns
import DateFnsUtils from '@date-io/date-fns';

// Other
import { getUTCDatetime, getYYYYMM } from '../../const/Util';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetClaimForAdminQuery,
  AdminClaimInput,
  useUpdateClaimForAdminMutation,
  Valid,
  ClaimApplyStatus,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';
import { ValidLabels, strToValid } from '../../const/Valid';
import { ApplyStatusLabels, strToClaimApplyStatus } from '../../const/Claim';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ claim_id: string }>().claim_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminClaimInput>({
    appliedAt: undefined,
    applyStatus: ClaimApplyStatus.Editable,
    month: '',
    remark: '',
    status: Valid.Valid,
  });
  // InstructorID更新対象ではないが表示用に保持している
  const [instructorID, setInstructorID] = useState(0);

  const { loading } = useGetClaimForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ claimForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        appliedAt: data.appliedAt,
        applyStatus: data.applyStatus,
        month: getYYYYMM(data.month),
        remark: data.remark,
        status: data.status,
      });
      if (data.instructor) {
        setInstructorID(data.instructor?.id);
      }
    },
  });

  const [updateClaim, { error: updateError }] = useUpdateClaimForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateClaim({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateClaim, updateInput, id, navigate]),
  );

  const handleChangeMonth = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        month: value ? getYYYYMM(value) : '',
      }));
    },
    [],
  );

  const handleChangeRemark = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({
      ...current,
      remark: value,
    }));
  }, []);

  const handleChangeApplyStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        applyStatus: strToClaimApplyStatus(String(value)) ?? ClaimApplyStatus.Editable,
      }));
    },
    [],
  );

  const handleChangeAppliedAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        appliedAt: value ? getUTCDatetime(value) : undefined,
      }));
    },
    [],
  );

  const handleChangeStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Claim Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="instructorId"
                name="instructorID"
                label="InstructorId"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={instructorID}
                fullWidth
                style={{ margin: '10px 0' }}
                disabled
              />
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="month"
                  label="Month"
                  format="yyyyMM"
                  value={updateInput.month}
                  onChange={handleChangeMonth}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  views={['year', 'month']}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="remark"
                name="remark"
                label="Remark"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.remark}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeRemark}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="applyStatus">ApplyStatus</InputLabel>
                <Select
                  value={updateInput.applyStatus}
                  onChange={handleChangeApplyStatus}
                  input={<Input name="applyStatus" id="applyStatus" />}
                >
                  {Object.entries(ApplyStatusLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="appliedAt"
                  label="申請時間"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput.appliedAt}
                  onChange={handleChangeAppliedAt}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput.status}
                  onChange={handleChangeStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
