import * as yup from 'yup';

import {
  AdminSessionReportInput,
  SessionPhase,
  IsOnScheduleType,
  SessionReportPreparationType,
  SessionReportAnnouncementType,
  ResponseDeadlineType,
  Valid,
} from '../gen/graphql';

export const sessionReportSchema = yup.object({
  sessionID: yup.string().required().max(100).trim(),
  sessionPhase: yup.string().required().oneOf(Object.values(SessionPhase)),
  content: yup.string().required().max(2000).trim(),
  preparations: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string().required().oneOf(Object.values(SessionReportPreparationType)),
      }),
    )
    .required()
    .min(1, 'preparations is a required field'),
  preparationOthers: yup.string().nullable().max(2000).trim(),
  isOnSchedule: yup.string().required().oneOf(Object.values(IsOnScheduleType)),
  delayReason: yup.string().nullable().max(2000).trim(),
  announcements: yup
    .array()
    .of(
      yup.object().shape({
        type: yup.string().required().oneOf(Object.values(SessionReportAnnouncementType)),
      }),
    )
    .required()
    .min(1),
  hasAnnouncementsDetail: yup.string().nullable().max(2000).trim(),
  responseDeadline: yup.string().nullable().oneOf(Object.values(ResponseDeadlineType)),
  coachingMemo: yup.string().nullable().max(2000).trim(),
  status: yup.string().required().oneOf(Object.values(Valid)),
});

export const SessionReportDefaultValues: AdminSessionReportInput = {
  sessionID: '',
  sessionPhase: SessionPhase.NotCompleted,
  content: '',
  preparations: [],
  preparationOthers: '',
  isOnSchedule: IsOnScheduleType.OnSchedule,
  delayReason: '',
  announcements: [],
  hasAnnouncementsDetail: '',
  responseDeadline: undefined,
  coachingMemo: '',
  status: Valid.Valid,
};
