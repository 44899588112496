import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetBannerQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import { UserTargetLabels } from '../../const/Banner';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
  imageStyle: {
    width: '100%',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ banner_id: string }>().banner_id;
  const id = Number(paramID);

  const { data, loading } = useGetBannerQuery({
    variables: { id },
  });
  const banner = data?.bannerForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Banner Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/banners/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          {banner && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{banner.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Title
                  </TableCell>
                  <TableCell>{banner.title}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ImageUrl
                  </TableCell>
                  <TableCell>
                    <p>{banner.imageURL}</p>
                    <img src={banner.imageURL} className={classes.imageStyle} alt="バナー画像" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Link
                  </TableCell>
                  <TableCell>{banner.link}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Sort
                  </TableCell>
                  <TableCell>{banner.sort}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{ValidLabels[banner.status]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IsNotLoginUser
                  </TableCell>
                  <TableCell>
                    {banner.isNotLoginUser ? UserTargetLabels.true : UserTargetLabels.false}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IsTargetFree
                  </TableCell>
                  <TableCell>
                    {banner.isTargetFree ? UserTargetLabels.true : UserTargetLabels.false}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IsTargetSubscriptionUser
                  </TableCell>
                  <TableCell>
                    {banner.isTargetSubscriptionUser
                      ? UserTargetLabels.true
                      : UserTargetLabels.false}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IsTargetStudent
                  </TableCell>
                  <TableCell>
                    {banner.isTargetStudent ? UserTargetLabels.true : UserTargetLabels.false}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IsTargetGraduate
                  </TableCell>
                  <TableCell>
                    {banner.isTargetGraduate ? UserTargetLabels.true : UserTargetLabels.false}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IsTargetInstructor
                  </TableCell>
                  <TableCell>
                    {banner.isTargetInstructor ? UserTargetLabels.true : UserTargetLabels.false}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    IsTargetTeamUser
                  </TableCell>
                  <TableCell>
                    {banner.isTargetTeamUser ? UserTargetLabels.true : UserTargetLabels.false}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{banner.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{banner.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
