import React, { useState, useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as Sentry from '@sentry/browser';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Date-fns
import { format } from 'date-fns';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  useGetClaimDetailsForAdminQuery,
  useAddClaimDetailForAdminMutation,
  AdminClaimDetailSearchInput,
  AdminClaimDetailInput,
  Valid,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';
import { getUTCDatetime, getYYYYMM } from '../../const/Util';
import { ApplyStatusLabels, ApplyStatusNumValues, strToClaimApplyStatus } from '../../const/Claim';
import { ClaimCategoryLabels } from '../../const/ClaimDetail';
import { CsvRepositoryOnAPI } from '../../../infrastructure/csv/CsvRepositoryOnAPI';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  csvBtn: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 99,
    backgroundColor: '#009be5',
    color: 'white',
    padding: '10px 13px',
    textDecoration: 'none',
    borderRadius: '10px',
    boxShadow: '3px 3px 4px black',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

// 登録フォームの初期値
const addInputDefaultValues = {
  additionalPayment: undefined,
  amount: 0,
  claimCategoryID: 0,
  claimID: 0,
  courseID: undefined,
  date: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
  lessonID: undefined,
  remark: undefined,
  spotLessonID: undefined,
  status: Valid.Valid,
  unitPrice: 0,
};
type SearchFormInput = Omit<AdminClaimDetailSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const csvRepository = useMemo(() => new CsvRepositoryOnAPI(), []);

  const currentSearchParams = useMemo((): AdminClaimDetailSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const instructorID = params.get('instructorID') ?? '';
    const claimCategoryID = params.get('claimCategoryID') ?? '';
    const claimID = params.get('claimID') ?? '';
    const courseID = params.get('courseID') ?? '';
    const lessonID = params.get('lessonID') ?? '';
    const spotLessonID = params.get('spotLessonID') ?? '';
    const applyStatus = params.get('applyStatus') ?? '';
    const month = params.get('month') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      instructorID: parseInt(instructorID) > 0 ? parseInt(instructorID) : undefined,
      claimCategoryID: parseInt(claimCategoryID) > 0 ? parseInt(claimCategoryID) : undefined,
      claimID: parseInt(claimID) > 0 ? parseInt(claimID) : undefined,
      courseID: parseInt(courseID) > 0 ? parseInt(courseID) : undefined,
      lessonID: parseInt(lessonID) > 0 ? parseInt(lessonID) : undefined,
      spotLessonID: parseInt(spotLessonID) > 0 ? parseInt(spotLessonID) : undefined,
      applyStatus: strToClaimApplyStatus(applyStatus),
      month: month !== '' ? month : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetClaimDetailsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });
  const claimDetails = data?.claimDetailsForAdmin.items ?? [];
  const total = data?.claimDetailsForAdmin.total ?? 0;

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);
  const [addInput, setAddInput] = useState<AdminClaimDetailInput>(addInputDefaultValues);

  // Add Dialog
  const [open, showAddDialog] = React.useState(false);

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  // データ登録
  const [addClaimDetail, { error: addError }] = useAddClaimDetailForAdminMutation();

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminClaimDetailSearchInput): void => {
      navigate({
        pathname: '/claimDetails',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 再検索
  const refetchClaimDetails = useCallback(
    async (params: AdminClaimDetailSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      instructorID: currentSearchParams.instructorID,
      claimCategoryID: currentSearchParams.claimCategoryID,
      claimID: currentSearchParams.claimID,
      courseID: currentSearchParams.courseID,
      lessonID: currentSearchParams.lessonID,
      spotLessonID: currentSearchParams.spotLessonID,
      applyStatus: currentSearchParams.applyStatus,
      month: currentSearchParams.month,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };

      if (input.month) {
        const date = new Date(input.month);
        params.month = getYYYYMM(`${date.getFullYear()}${`00${date.getMonth() + 1}`.slice(-2)}`);
      }

      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchClaimDetails(params);
      }
      createHistory(params);
    },
    [refetchClaimDetails, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // データ作成ボタンクリック
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await addClaimDetail({
          variables: {
            input: addInput,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      // ダイアログを閉じて入力フォーム初期化
      showAddDialog(false);
      setAddInput(addInputDefaultValues);

      // 再検索
      refetchClaimDetails(currentSearchParams);
    }, [addClaimDetail, refetchClaimDetails, currentSearchParams, addInput]),
  );

  const handleChangeAddClaimID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      claimID: parseInt(value),
    }));
  }, []);

  const handleChangeAddClaimCategoryID = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        claimCategoryID: parseInt(String(value)),
      }));
    },
    [],
  );

  const handleChangeAddDate = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setAddInput((current) => ({
        ...current,
        date: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeAddCourseID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      courseID: parseInt(value),
    }));
  }, []);

  const handleChangeAddLessonID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      lessonID: parseInt(value),
    }));
  }, []);

  const handleChangeAddSpotLessonID = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        spotLessonID: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeAddUnitPrice = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        unitPrice: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeAddAmount = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      amount: parseInt(value),
    }));
  }, []);

  const handleChangeAddAdditionalPayment = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        additionalPayment: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeAddRemark = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      remark: value,
    }));
  }, []);

  const handleChangeAddStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  const csvFileDownload = async (): Promise<void> => {
    // HACK: 大量件数ダウンロード時にAPIサーバーが落ちてしまう問題があるため、フロント側での簡易的な制限をしている
    if (total > 15000) {
      alert(
        '15000件以上のデータを出力することはできません。検索条件を追加して出力対象を絞り込んでください。',
      );
      return;
    }

    // 一覧検索のパラメーターからCSVダウンロードで使うものだけを抽出
    // NOTE: xxxID => xxxIdのように変換しないとAPI側でパラメータが取れない
    const query = queryString.stringify({
      id: currentSearchParams.id,
      claimId: currentSearchParams.claimID,
      claimCategoryId: currentSearchParams.claimCategoryID,
      courseId: currentSearchParams.courseID,
      lessonId: currentSearchParams.lessonID,
      spotLessonID: currentSearchParams.spotLessonID,
      instructorId: currentSearchParams.instructorID,
      month: currentSearchParams.month,
      applyStatus: currentSearchParams.applyStatus
        ? ApplyStatusNumValues[currentSearchParams.applyStatus]
        : undefined,
      // statusはAPI側で無視されるため渡さない(オペミスを防ぐ為らしい)
    });
    const url = `/api/v1/admin/csv/claim_details?${query}`;

    try {
      await csvRepository.getCsv('claim_details', url);
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                ClaimDetail
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="claimID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="claimID"
                          label="ClaimID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="instructorID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="instructorID"
                          label="InstructorID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="claimCategoryID">ClaimCategory</InputLabel>
                      <Controller
                        name="claimCategoryID"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}></MenuItem>
                            {Object.entries(ClaimCategoryLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="courseID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="courseID"
                          label="CourseID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="lessonID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="lessonID"
                          label="LessonID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="spotLessonID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="spotLessonID"
                          label="SpotLessonID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="month"
                      control={searchForm.control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            views={['year', 'month']}
                            margin="normal"
                            id="month"
                            label="Month"
                            format="yyyyMM"
                            value={value ?? null}
                            fullWidth
                            inputRef={ref}
                            {...rest}
                          />
                        </MuiPickersUtilsProvider>
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}></MenuItem>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="applyStatus">ApplyStatus</InputLabel>
                      <Controller
                        name="applyStatus"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}></MenuItem>
                            {Object.entries(ApplyStatusLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Claim</TableCell>
                  <TableCell align="right">ClaimCategory</TableCell>
                  <TableCell align="right">Date</TableCell>
                  <TableCell align="right">Course</TableCell>
                  <TableCell align="right">Lesson</TableCell>
                  <TableCell align="right">SpotLesson</TableCell>
                  <TableCell align="right">UnitPrice</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">AdditionalPayment</TableCell>
                  <TableCell align="right">SubTotal</TableCell>
                  <TableCell align="right">Remark</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {claimDetails.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/claimDetails/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Link to={`/claims/${row.claim.id}`}>{row.claim.id}</Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.claimCategory?.name}
                    </TableCell>
                    <TableCell align="right">{row.date}</TableCell>
                    <TableCell align="right">
                      {row.course ? (
                        <Link to={`/courses/${row.course?.id}`}>{row.course?.id}</Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.lesson ? (
                        <Link to={`/lessons/${row.lesson?.id}`}>{row.lesson?.title}</Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {row.spotLesson ? (
                        <Link to={`/spotLessons/${row.spotLesson?.id}`}>{row.spotLesson?.id}</Link>
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell align="right">{row.unitPrice} 円</TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.additionalPayment ?? '0'} 円</TableCell>
                    <TableCell align="right">{row.subTotal} 円</TableCell>
                    <TableCell align="right">
                      {row.remark
                        ? row.remark.length > 10
                          ? `${row.remark.substring(0, 10)}...`
                          : row.remark
                        : '-'}
                    </TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <button onClick={csvFileDownload} id="csvLink" className={classes.csvBtn}>
        CSV出力
      </button>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="claimID"
            name="claimID"
            label="ClaimId"
            type="number"
            defaultValue={addInput.claimID}
            fullWidth
            onChange={handleChangeAddClaimID}
            inputProps={{ min: 1 }}
          />
          <div>
            <FormControl>
              <InputLabel htmlFor="claimCategoryID">ClaimCategory</InputLabel>
              <Select
                value={addInput.claimCategoryID}
                onChange={handleChangeAddClaimCategoryID}
                input={<Input name="claimCategoryID" id="claimCategoryID" />}
              >
                {Object.entries(ClaimCategoryLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date"
                label="Date"
                format="yyyy-MM-dd HH:mm"
                value={addInput.date}
                onChange={handleChangeAddDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                InputLabelProps={{ shrink: true }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="courseID"
            name="courseID"
            label="CourseID"
            type="number"
            defaultValue={addInput.courseID ?? null}
            fullWidth
            onChange={handleChangeAddCourseID}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="lessonID"
            name="lessonID"
            label="LessonID"
            type="number"
            defaultValue={addInput.lessonID ?? null}
            fullWidth
            onChange={handleChangeAddLessonID}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="spotLessonID"
            name="spotLessonID"
            label="SpotLessonID"
            type="number"
            defaultValue={addInput.spotLessonID ?? null}
            fullWidth
            onChange={handleChangeAddSpotLessonID}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="unitPrice"
            name="unitPrice"
            label="UnitPrice"
            type="number"
            defaultValue={addInput.unitPrice}
            fullWidth
            onChange={handleChangeAddUnitPrice}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="amount"
            name="amount"
            label="Amount"
            type="number"
            defaultValue={addInput.amount}
            fullWidth
            onChange={handleChangeAddAmount}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="additionalPayment"
            name="additionalPayment"
            label="AdditionalPayment"
            type="number"
            defaultValue={addInput.additionalPayment}
            fullWidth
            onChange={handleChangeAddAdditionalPayment}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="remark"
            name="remark"
            label="Remark"
            type="text"
            defaultValue={addInput.remark}
            fullWidth
            onChange={handleChangeAddRemark}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={addInput.status}
                onChange={handleChangeAddStatus}
                input={<Input name="status" id="status" />}
              >
                {Object.entries(ValidLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
