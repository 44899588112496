// React
import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

// Material UI
import {
  Box,
  Typography,
  makeStyles,
  Theme,
  TextField,
  InputLabel,
  Input,
  InputAdornment,
  FormControl,
  FormHelperText,
} from '@material-ui/core';

//  Apollo
import { ApolloError } from '@apollo/client';

// Others
import { AdminTicketCreateInput } from '../../../gen/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

interface Props {
  formId: string;
  form: UseFormReturn<AdminTicketCreateInput>;
  onSubmit: (input: AdminTicketCreateInput) => void;
  error: ApolloError | undefined;
}

export const Form: React.FC<Props> = ({ formId, form, onSubmit, error }): JSX.Element => {
  const classes = useStyles();
  const formErrors = form.formState.errors;

  return (
    <form id={formId} onSubmit={form.handleSubmit(onSubmit)} noValidate autoComplete="off">
      <Controller
        name="userID"
        control={form.control}
        render={({ field: { ref, ...rest } }) => (
          <TextField
            autoFocus
            margin="dense"
            id="userId"
            label="UserID"
            type="number"
            placeholder="UserID"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            inputProps={{ min: 1 }}
            error={!!formErrors.userID}
            helperText={formErrors.userID?.message}
            inputRef={ref}
            {...rest}
          />
        )}
      />
      <FormControl fullWidth>
        <InputLabel htmlFor="standard-adornment-amount">期限</InputLabel>
        <Controller
          name="expireAt"
          control={form.control}
          render={({ field: { ref, ...rest } }) => (
            <Input
              margin="dense"
              id="expireAt"
              type="number"
              endAdornment={<InputAdornment position="start">日</InputAdornment>}
              inputProps={{ min: 1 }}
              error={!!formErrors.expireAt}
              inputRef={ref}
              {...rest}
            />
          )}
        />
        <FormHelperText error={true}>{formErrors.expireAt?.message}</FormHelperText>
      </FormControl>
      {error?.graphQLErrors && (
        <Box className={classes.inputError}>
          <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
          {error.graphQLErrors.map((error, index) => (
            <div key={index}>{error.message}</div>
          ))}
        </Box>
      )}
    </form>
  );
};
