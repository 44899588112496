import React, { useContext, useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import {
  ApolloClient,
  ApolloProvider,
  FieldPolicy,
  from,
  fromPromise,
  HttpLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';
import { RetryLink } from '@apollo/client/link/retry';
import { ErrorCode } from '../gen/graphql';
import axios from 'axios';
import { AuthContext } from './../context/CommonProvider';
import { useNavigate } from 'react-router-dom';
import { isErrorCode } from '../utils/graphqlError';

interface Props {
  children: React.ReactNode;
}

// fetchMoreの結果を結合するための関数
const customPagination = (): FieldPolicy => ({
  keyArgs: [],
  merge(existing, incoming) {
    if (!incoming?.pageInfo?.hasPreviousPage) {
      return incoming;
    }
    return {
      ...incoming,
      items: [...existing.items, ...incoming.items],
    };
  },
});

export const ApolloClientProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const navigate = useNavigate();

  const { authState, setAuth } = useContext(AuthContext);

  const logout = useCallback(async () => {
    await Auth.signOut();
    // set Authorization header
    axios.defaults.headers.common['Authorization'] = '';

    setAuth({
      ...authState,
      isLogin: false,
      cognitoUser: null,
      lmsUser: null,
    });
  }, [authState, setAuth]);

  // authorizationヘッダーの有無でエンドポイントを切り替える
  const directionalLink = new RetryLink({ attempts: { max: 1 } }).split(
    ({ getContext }) => !!getContext().headers?.authorization,
    createUploadLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT }),
    new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_PUBLIC_ENDPOINT }),
  );

  const authLink = setContext(async (_, { headers }) => {
    try {
      const cognitoUserSession = await Auth.currentSession();
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${cognitoUserSession.getIdToken().getJwtToken()}`,
        },
      };
    } catch (_) {
      // 未ログイン時はこっちに入ってくる
      return { headers };
    }
  });

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (networkError) {
      // 現状、認証エラー時はエラーオブジェクトではなくステータスコード401が返ってくるようになっている
      // networkErrorの型的にstatusCodeにはアクセスできないのでタイプアサーションで無理やりアクセスする
      const serverError = networkError as ServerError | ServerParseError;
      if (serverError.statusCode === 401) {
        return fromPromise(Auth.currentSession()).flatMap((cognitoUserSession) => {
          // Modify the operation context with a new token
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${cognitoUserSession.getIdToken().getJwtToken()}`,
            },
          });
          // Retry the request, returning the new observable
          return forward(operation);
        });
      }
    }

    if (graphQLErrors) {
      // 許可されていないAPIコールをした時にログアウト処理をする
      for (const graphqlError of graphQLErrors) {
        if (isErrorCode(graphqlError.extensions?.code)) {
          if (graphqlError.extensions?.code === ErrorCode.CommonNoPermission) {
            logout();
            navigate('/login');
            break;
          }
        }
      }

      // Server側でgraphqlのエラーはSentryに送っているため、
      // 予期しないエラー（Extensionsが設定されてない）のみSentryに送る
      if (graphQLErrors[0].extensions && !graphQLErrors[0].extensions['code']) {
        // graphQLErrorsや配列の要素をそのままSentryに渡すと型が適合しなそう(※)なので、
        // メッセージを整形してErrorオブジェクトに詰め直す
        // ※ Sentry上で「unknown」や「Non-Error exception captured with keys: message, path」のようになってしまう
        const error = new Error(`${operation.operationName}:${JSON.stringify(graphQLErrors)}`);
        Sentry.captureException(error);
      }
    }
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink.concat(directionalLink)]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-first',
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          // 画面仕様的に無限スクロールが必要なqueryはここに追加(fetchMoreにおけるupdateQueryはdeprecatedなので、現時点ではこのやり方)
          // @see https://www.apollographql.com/blog/announcement/frontend/announcing-the-release-of-apollo-client-3-0/
          fields: {
            // タイムライン投稿一覧
            adminTweetsV1: customPagination(),
            // タイムライン投稿コメント一覧
            getAdminTweetCommentsV1: customPagination(),
          },
        },
      },
    }),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
