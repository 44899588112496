import { BannerCidDeviceType, BannerCidType } from '../../gen/graphql';

// isNotLoginUser, isTargetFree, isTargetSubscriptionUser, isTargetStudent, isTargetGraduate, isTargetInstructor
export const UserTargetLabels = {
  false: '無効',
  true: '有効',
} as const;

export const CidTypeLabels = {
  [BannerCidType.TopCenter]: 'LMS_top_center-cid',
  [BannerCidType.Program]: 'LMS_program-cid',
  [BannerCidType.News]: 'NEWS-cid',
  [BannerCidType.Qa]: 'LMS_question-cid',
} as const;

export const CidDeviceTypeLabels = {
  [BannerCidDeviceType.Pc]: 'PC',
  [BannerCidDeviceType.Sp]: 'SP',
  [BannerCidDeviceType.PcAndSp]: 'PC&SP',
} as const;
