import React from 'react';
import styled from 'styled-components';
import { InputLabel } from './InputLabel';
import { Input } from './Input';

interface HorizontalInputProps {
  name: string;
  label: string;
  unit?: string;
  placeholder?: string;
  width?: string;
  error?: boolean;
  min?: number;
  max?: number;
  value: string;
  type: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const HorizontalInput: React.FC<HorizontalInputProps> = (props) => {
  return (
    <Container>
      <InputLabel for={props.name}>{props.label}</InputLabel>
      <Input
        name={props.name}
        unit={props.unit}
        placeholder={props.placeholder}
        width={props.width}
        value={props.value}
        type={props.type}
        onChange={props.onChange}
        error={props.error}
        min={props.min}
        max={props.max}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
`;
