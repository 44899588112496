import React from 'react';

// Material UI
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetSpPromotionCodeForAdminQuery } from '../../../gen/graphql';
import { formatDate } from '../../const/Util';
import {
  PvdActiveLabels,
  PvdRestrictionsFirstTimeTransactionLabels,
} from '../../const/SpPromotionCode';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ sp_promotion_code_id: string }>().sp_promotion_code_id ?? '0';

  const { data, loading } = useGetSpPromotionCodeForAdminQuery({
    variables: { id },
  });
  const spPromotionCode = data?.spPromotionCodeForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                SpPromotionCode Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          {spPromotionCode && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{spPromotionCode.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SpCouponId
                  </TableCell>
                  <TableCell>
                    <Link to={`/spCoupons/${spPromotionCode.spCouponID}`}>
                      {spPromotionCode.spCouponID}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdID
                  </TableCell>
                  <TableCell>{spPromotionCode.pvdID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdActive
                  </TableCell>
                  <TableCell>{PvdActiveLabels[spPromotionCode.pvdActive]}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdCode
                  </TableCell>
                  <TableCell>{spPromotionCode.pvdCode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdCreated
                  </TableCell>
                  <TableCell>{formatDate(new Date(spPromotionCode.pvdCreated))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdCustomer
                  </TableCell>
                  <TableCell>{spPromotionCode.pvdCustomer}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdExpiresAt
                  </TableCell>
                  <TableCell>
                    {spPromotionCode.pvdExpiresAt &&
                      formatDate(new Date(spPromotionCode.pvdExpiresAt))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdMaxRedemptions
                  </TableCell>
                  <TableCell>{spPromotionCode.pvdMaxRedemptions}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdRestrictionsFirstTimeTransaction
                  </TableCell>
                  <TableCell>
                    {spPromotionCode.pvdRestrictionsFirstTimeTransaction &&
                      PvdRestrictionsFirstTimeTransactionLabels[
                        spPromotionCode.pvdRestrictionsFirstTimeTransaction
                      ]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdRestrictionsMinimumAmount
                  </TableCell>
                  <TableCell>{spPromotionCode.pvdRestrictionsMinimumAmount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdRestrictionsCurrencyMinimumAmountCurrency
                  </TableCell>
                  <TableCell>
                    {spPromotionCode.pvdRestrictionsCurrencyMinimumAmountCurrency}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdTimesRedeemed
                  </TableCell>
                  <TableCell>{spPromotionCode.pvdTimesRedeemed}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SpCoupons
                  </TableCell>
                  <TableCell>
                    <Link to={`/spCoupons?id=${spPromotionCode.spCouponID}`}>SpCoupons</Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{formatDate(new Date(spPromotionCode.createdAt))}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{formatDate(new Date(spPromotionCode.updatedAt))}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
      </main>
    </div>
  );
};
