import React, { useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import queryString from 'query-string';
import {
  AdminInvoiceItemSearchInput,
  useGetInvoiceItemsForAdminQuery,
  Valid,
} from '../../../gen/graphql';
import { strToEnum } from '../../../utils/common';
import { ValidLabels } from '../../const/Valid';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  block: {
    display: 'block',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
}));

type SearchFormInput = Omit<AdminInvoiceItemSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminInvoiceItemSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const invoiceID = params.get('invoiceID') ?? '';
    const priceID = params.get('priceID') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : 25,
      page: parseInt(page) > 0 ? parseInt(page) : 1,
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      invoiceID: parseInt(invoiceID) > 0 ? parseInt(invoiceID) : undefined,
      priceID: parseInt(priceID) > 0 ? parseInt(priceID) : undefined,
      status: strToEnum(status, Valid),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetInvoiceItemsForAdminQuery({
    variables: { input: currentSearchParams },
    notifyOnNetworkStatusChange: true,
  });

  // 検索条件からURLを作成してhistoryにpush
  const createHistory = useCallback(
    (params: AdminInvoiceItemSearchInput): void => {
      navigate({
        pathname: '/invoiceItems',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      invoiceID: currentSearchParams.invoiceID,
      priceID: currentSearchParams.priceID,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                InvoiceItem
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Alert severity="error">
          こちらは古いデータのため参照するのは避けてください。Stripeの管理画面、もしくは「課金データ」メニューから最新の情報をご確認ください。
        </Alert>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="invoiceID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="invoiceID"
                          label="InvoiceID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="priceID"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="priceID"
                          label="PriceID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Invoice</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Amount</TableCell>
                  <TableCell align="right">Quantity</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.getInvoiceItemsForAdmin.items.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/invoiceItems/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/invoices/${row.invoiceID}`}>{row.invoiceID}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/prices/${row.price.id}`}>{row.price.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.amount}</TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.getInvoiceItemsForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
