import * as yup from 'yup';
import { AdminTicketInput, AdminTicketCreateInput } from '../gen/graphql';
import { TicketTypeValues } from '../types/Ticket';

const isInvalidISODateTime = (value: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}$/;
  return !(regex.test(value) && !isNaN(new Date(value).getTime()));
};

export const ticketSchema = yup.object({
  userID: yup
    .number()
    .required()
    .min(1)
    .transform((value) => (isNaN(value) ? undefined : value)), // TODO number型のNan対応を共通化 (@see https://github.com/jquense/yup/issues/1330)
  expireAt: yup
    .string()
    .required()
    .test(
      'is-ISO8601-date',
      '有効なISO 8601日付形式で入力してください（例：2021-05-21T12:27:52+09:00）',
      isInvalidISODateTime,
    )
    .transform((_, originalValue) => {
      const date = new Date(originalValue);
      if (isNaN(date.getTime())) {
        return;
      }
      return date.toISOString(); // 有効な日付をISOフォーマットに変換
    }),
  isUsed: yup.number().required(),
  type: yup.number().required().oneOf(Object.values(TicketTypeValues)),
});

export const ticketCreateSchema = yup.object({
  userID: yup
    .number()
    .required()
    .min(1)
    .transform((value) => (isNaN(value) ? undefined : value)), // TODO number型のNan対応を共通化 (@see https://github.com/jquense/yup/issues/1330)
  expireAt: yup
    .number()
    .required()
    .min(1)
    .transform((value) => (isNaN(value) ? undefined : value)), // TODO number型のNan対応を共通化 (@see https://github.com/jquense/yup/issues/1330)
});

export const TicketDefaultValues: AdminTicketInput = {
  userID: 0,
  expireAt: '',
  isUsed: 0,
  type: 0,
};

export const TicketCreateDefaultValues: AdminTicketCreateInput = {
  userID: 0,
  expireAt: 0,
};
