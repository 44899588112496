import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Theme, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link } from 'react-router-dom';
import {
  AdminPaymentIntentSearchInput,
  useGetPaymentIntentsForAdminQuery,
  Valid,
} from '../../../gen/graphql';
import queryString from 'query-string';
import { ValidLabels, strToValid } from '../../const/Valid';
import { GeneralChangeEvent } from '../../../types/Common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export interface SearchParam {
  id?: number;
  userId?: number;
  providerPaymentIntentId?: string;
  status?: number;
}

const FETCH_ROWS_LIMIT = 25;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = React.useMemo((): AdminPaymentIntentSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const userId = params.get('userID') ?? '';
    const providerPaymentIntentID = params.get('providerPaymentIntentID') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      userID: parseInt(userId) > 0 ? parseInt(userId) : undefined,
      providerPaymentIntentID:
        providerPaymentIntentID.length > 0 ? providerPaymentIntentID : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  const [searchInput, setSearchInput] =
    React.useState<Omit<AdminPaymentIntentSearchInput, 'limit' | 'page'>>(currentSearchParams);

  const { data, loading, refetch } = useGetPaymentIntentsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  const createHistory = React.useCallback(
    (params: AdminPaymentIntentSearchInput): void => {
      navigate({
        pathname: '/paymentIntents',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const handleSearchSubmit = React.useCallback(async () => {
    const params = {
      ...searchInput,
      limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
      page: 1,
    };

    if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
      await refetch({ input: params });
    }
    createHistory(params);
  }, [createHistory, refetch, currentSearchParams, searchInput]);

  // 一覧表示件数変更
  const changePerPage = React.useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = React.useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  const handleChangeSearchId = React.useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setSearchInput((current) => ({ ...current, id: parseInt(value) ?? undefined }));
    },
    [],
  );

  const handleChangeSearchUserId = React.useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setSearchInput((current) => ({ ...current, userID: parseInt(value) ?? undefined }));
    },
    [],
  );

  const handleChangeSearchProviderPaymentIntentId = React.useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setSearchInput((current) => ({ ...current, providerPaymentIntentID: value ?? undefined }));
    },
    [],
  );

  const handleChangeSearchStatus = React.useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setSearchInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? undefined,
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                PaymentIntent
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSearchSubmit}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="id"
                      name="id"
                      label="ID"
                      type="number"
                      fullWidth
                      value={searchInput.id}
                      onChange={handleChangeSearchId}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="userId"
                      name="userID"
                      label="UserId"
                      type="number"
                      fullWidth
                      value={searchInput.userID}
                      onChange={handleChangeSearchUserId}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="providerPaymentIntentId"
                      name="providerPaymentIntentID"
                      label="providerPaymentIntentId"
                      type="text"
                      fullWidth
                      value={searchInput.providerPaymentIntentID}
                      onChange={handleChangeSearchProviderPaymentIntentId}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Select
                        value={searchInput.status}
                        onChange={handleChangeSearchStatus}
                        input={<Input name="status" id="status" />}
                      >
                        <MenuItem value={Valid.Invalid}>{ValidLabels[Valid.Invalid]}</MenuItem>
                        <MenuItem value={Valid.Valid}>{ValidLabels[Valid.Valid]}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">User</TableCell>
                  <TableCell align="right">AmountPaid</TableCell>
                  <TableCell align="right">Currency</TableCell>
                  <TableCell align="right">ProviderPaymentIntentID</TableCell>
                  <TableCell align="right">Tax</TableCell>
                  <TableCell align="right">TaxPercentage</TableCell>
                  <TableCell align="right">TotalAmount</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  data.getPaymentIntentsForAdmin.items.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        <Link to={`/paymentIntents/${row.id}`}>{row.id}</Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`/users/${row.user!.id}`}>
                          {row.user.maskedPersonalInfo?.name}
                        </Link>
                      </TableCell>
                      <TableCell align="right">{row.amountPaid}</TableCell>
                      <TableCell align="right">{row.currency}</TableCell>
                      <TableCell align="right">{row.providerPaymentIntentID}</TableCell>
                      <TableCell align="right">{row.tax}</TableCell>
                      <TableCell align="right">{row.taxPercentage}</TableCell>
                      <TableCell align="right">{row.totalAmount}</TableCell>
                      <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                      <TableCell align="right">{row.createdAt}</TableCell>
                      <TableCell align="right">{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.getPaymentIntentsForAdmin.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
