import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { InputLabel } from './InputLabel';
import styled from 'styled-components';
import media from 'styled-media-query';
import lodash from 'lodash';

export interface Option {
  id: number;
  name: string;
}

interface InputWithSearchProps {
  label: string;
  options: Option[];
  width?: string;
  error?: boolean;
  defaultValue?: Option;
  deletable?: boolean;
  handleInput: (value: string) => void;
  onSelect?: (id: number | null) => void;
}

interface Handler {
  getValue: () => Option | null;
}

const useOutsideClick = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openRef: React.MutableRefObject<any>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeRef: React.MutableRefObject<any>,
  callback: () => void,
): void => {
  const handleClick = (e: Event): void => {
    if (openRef.current && openRef.current.contains(e.target)) return;

    if (closeRef.current && !closeRef.current.contains(e.target)) {
      callback();
    }
  };

  useEffect((): (() => void) => {
    document.addEventListener('click', handleClick);

    return (): void => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export const InputWithSearch = forwardRef<Handler, InputWithSearchProps>(
  (props, ref): JSX.Element => {
    const [currentChoise, setCurrentChoise] = useState<Option | null>(
      props.defaultValue ? props.defaultValue : null,
    );
    const [isOpen, setIsOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const inputRef: React.MutableRefObject<any> = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const openRef: React.MutableRefObject<any> = useRef(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const optionsRef: React.MutableRefObject<any> = useRef(null);

    useOutsideClick(openRef, optionsRef, (): void => {
      setIsOpen(false);
      if (inputRef.current.value !== '') {
        props.handleInput('');
        inputRef.current.value = '';
      }
    });

    const handleOpen = (): void => {
      props.handleInput(''); // fetch default value
      setIsOpen(true);
    };

    const emitInput = (): void => {
      props.handleInput(inputRef.current?.value ?? '');
    };

    const handleChoise = (option: Option | null): void => {
      setCurrentChoise(option);
      setIsOpen(false);
      props.handleInput('');
      inputRef.current.value = '';
      if (typeof props.onSelect !== 'undefined') props.onSelect(option ? option.id : null);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useImperativeHandle(ref, (): any => {
      return {
        getValue: (): Option | null => currentChoise,
      };
    });

    useEffect(() => {
      setCurrentChoise(props.defaultValue ?? null);
    }, [props.defaultValue]);

    return (
      <Container ref={optionsRef}>
        <InputLabel for="">{props.label}</InputLabel>
        <SearchContainer>
          <Input
            onClick={handleOpen}
            width={props.width ? props.width : '12rem'}
            error={props.error}
          >
            <CurrentChoise selected={Boolean(currentChoise && currentChoise.name)}>
              {currentChoise && currentChoise.name ? currentChoise.name : '選択なし'}
            </CurrentChoise>
            <span>▼</span>
          </Input>
          <Options isOpen={isOpen}>
            <div>
              <SearchInput
                placeholder="検索"
                onKeyUp={lodash.debounce(emitInput, 500)}
                ref={inputRef}
              ></SearchInput>
            </div>
            <Ul>
              {props.deletable ? <Li onClick={(): void => handleChoise(null)}>選択なし</Li> : ''}
              {props.options?.map((option): JSX.Element => {
                return (
                  <Li key={option?.id} onClick={(): void => handleChoise(option)}>
                    {option?.name}
                  </Li>
                );
              })}
            </Ul>
          </Options>
        </SearchContainer>
      </Container>
    );
  },
);

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

const SearchContainer = styled.div`
  position: relative;
`;

const Options = styled.div<{ isOpen: boolean }>`
  z-index: 2;
  background-color: #ffffff;
  display: ${(props): string => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  top: 2rem;
  left: 0;
  width: 320px;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.25);

  ${media.lessThan('small')`
    width: 280px;
  `}
`;

const SearchInput = styled.input`
  appearance: none;
  border: none;
  background: #f2f2f2;
  border-radius: 2px;
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  width: calc(100% - 2rem);
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

const Ul = styled.ul`
  max-height: 12rem;
  overflow-y: scroll;
`;

const Li = styled.li`
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  cursor: pointer;
  list-style: none;
`;

const Input = styled.div<{ width?: string; error?: boolean }>`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(props): string => (props.error ? '#fd2f92' : '#dddddd')};
  border-radius: 2px;
  background-color: ${(props): string => (props.error ? '#fce5e8' : '#ffffff')};
  ${(props): string => (props.width ? `width: ${props.width};` : '')}
  box-sizing: border-box;
  padding: 0.5rem;
`;

const CurrentChoise = styled.span<{ selected: boolean }>`
  color: ${(props): string => (props.selected ? 'rgba(0, 0, 0, 0.9)' : 'rgba(0, 0, 0, 0.36)')};
`;
