import React, { useState, useEffect } from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles, Theme } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import TextField from '@material-ui/core/TextField';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  useGetStudyReportDetailsForAdminQuery,
  useCreateStudyReportDetailForAdminMutation,
  AdminStudyReportDetailInput,
  AdminStudyReportDetailSearchInput,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export interface SearchParam {
  id?: number;
  tagId?: number;
  programId?: number;
  studyReportId?: number;
  time?: number;
}

export interface AddParam {
  studyReportId?: number;
  tagId?: number;
  programId?: number | null;
  time?: number;
}

const defaultAddData = {
  studyReportID: 0,
  tagID: 0,
  programID: null,
  time: 0,
};

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getCurrentQueryObject = () => {
    return queryString.parse(location.search);
  };

  // Add Dialog
  const [open, showAddDialog] = useState(false);
  const [addData, setAddData] = useState<AdminStudyReportDetailInput>(defaultAddData);

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  function handleAdd() {
    postData();
  }

  // Fetch Data
  const [searchParam, setSearchParam] = React.useState<AdminStudyReportDetailSearchInput>({
    page: 1,
    limit: 15,
  });

  const studyReportDetailsData = useGetStudyReportDetailsForAdminQuery({
    variables: {
      input: {
        id: searchParam.id,
        page: searchParam.page,
        limit: searchParam.limit,
        programID: searchParam.programID,
        studyReportID: searchParam.studyReportID,
        tagID: searchParam.tagID,
        time: searchParam.time,
      },
    },
  });

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (
    event,
  ) => {
    if (!event.target.name) return;
    const val = ['studyReportId', 'tagId', 'programId', 'time'].includes(event.target.name)
      ? parseInt(String(event.target.value))
      : event.target.value === ''
        ? undefined
        : event.target.value;

    setAddData({
      ...addData,
      [event.target.name]: val,
    });
  };

  const [createStudyReportDetail, createStudyReportDetailData] =
    useCreateStudyReportDetailForAdminMutation();

  const loading = studyReportDetailsData.loading || createStudyReportDetailData.loading;

  const postData = useSafeAsyncCallback(
    React.useCallback(async () => {
      try {
        await createStudyReportDetail({
          variables: {
            input: {
              studyReportID: addData.studyReportID,
              programID: addData.programID,
              tagID: addData.tagID,
              time: addData.time,
            },
          },
        });

        setAddData(defaultAddData);
        showAddDialog(false);
        studyReportDetailsData.refetch();
      } catch {
        return;
      }
    }, [addData, createStudyReportDetail, studyReportDetailsData]),
  );

  const handleSearchInput: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (
    event,
  ) => {
    setSearchParam({
      ...searchParam,
      [event.target.name]: event.target.value ? event.target.value : undefined,
    });
    const queryObject = getCurrentQueryObject();

    queryObject[event.target.name] = String(event.target.value);
    setQuery(queryObject);
  };

  const handleSearchSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    const queryObject = getCurrentQueryObject();
    for (const [key, value] of Object.entries(searchParam)) {
      queryObject[key] = String(value);
    }
    queryObject.page = '1';

    setQuery(queryObject);
  };

  function handleChangePerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchParam((prev) => ({
      ...prev,
      limit: parseInt(event.target.value),
    }));
    const queryObject = getCurrentQueryObject();
    queryObject['limit'] = String(event.target.value);

    setQuery(queryObject);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChangePage(event: any, page: number) {
    // setState(Material UI は page=0 はじまり, LMS API は page=1 はじまり)
    setSearchParam((prev) => ({
      ...prev,
      page: page + 1,
    }));
    const queryObject = getCurrentQueryObject();
    queryObject['page'] = String(event.target.value);

    setQuery(queryObject);
  }

  const setQuery = (queryObject: Record<string, unknown>) => {
    navigate({
      pathname: '/studyReportDetails',
      search: queryString.stringify(queryObject),
    });
  };

  useEffect(() => {
    const queryObject = getCurrentQueryObject();
    for (const key in queryObject) {
      setSearchParam((prev) => ({
        ...prev,
        [key]: queryObject[key],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                StudyReportDetail
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSearchSubmit}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="id"
                      name="id"
                      label="ID"
                      type="number"
                      fullWidth
                      value={searchParam.id}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="time"
                      name="time"
                      label="Time(指定回数以上)"
                      type="number"
                      fullWidth
                      value={searchParam.time}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="tagId"
                      name="tagID"
                      label="TagId"
                      type="number"
                      fullWidth
                      value={searchParam.tagID}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="programId"
                      name="programID"
                      label="ProgramId"
                      type="number"
                      fullWidth
                      value={searchParam.programID}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="studyReportId"
                      name="studyReportID"
                      label="StudyReportId"
                      type="number"
                      fullWidth
                      value={searchParam.studyReportID}
                      onChange={handleSearchInput}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">StudyReport</TableCell>
                  <TableCell align="right">Tag</TableCell>
                  <TableCell align="right">Time</TableCell>
                  <TableCell align="right">Program</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studyReportDetailsData.data &&
                  studyReportDetailsData.data.getStudyReportDetailsForAdmin.items.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        <Link to={`/studyReportDetails/${row.id}`}>{row.id}</Link>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Link to={`/studyReports/${row.studyReportID}`}>StudyReport</Link>
                      </TableCell>
                      <TableCell align="right">
                        <Link to={`/tags/${row.tag?.id}`}>{row.tag?.name}</Link>
                      </TableCell>
                      <TableCell align="right">{row.time}</TableCell>
                      <TableCell align="right">
                        <Link to={`/programs/${row.program?.id}`}>{row.program?.title}</Link>
                      </TableCell>
                      <TableCell align="right">{row.createdAt}</TableCell>
                      <TableCell align="right">{row.updatedAt}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={studyReportDetailsData.data?.getStudyReportDetailsForAdmin.total ?? 0}
            rowsPerPage={searchParam.limit}
            page={searchParam.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePerPage}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="studyReportId"
            name="studyReportID"
            label="StudyReportID"
            type="number"
            defaultValue={addData.studyReportID}
            fullWidth
            onChange={handleChange}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="tagId"
            name="tagID"
            label="tagID"
            type="number"
            defaultValue={addData.tagID}
            fullWidth
            onChange={handleChange}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="programId"
            name="programID"
            label="ProgramId"
            type="number"
            defaultValue={addData.programID}
            fullWidth
            onChange={handleChange}
            inputProps={{ min: 1 }}
          />
          <TextField
            autoFocus
            margin="dense"
            id="time"
            name="time"
            label="Time"
            type="number"
            defaultValue={addData.time}
            fullWidth
            onChange={handleChange}
            inputProps={{ min: 1 }}
          />
          {createStudyReportDetailData.error && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {createStudyReportDetailData.error.graphQLErrors.map((error, index) => (
                <div key={index}>
                  {error.path} : {error.message}
                </div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
