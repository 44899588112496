import React from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';

import { AdminStripeSubscriptionSearchInput } from '../../../gen/graphql';
import { providerSubscriptionStatusPhaseLabels } from '../../const/StripeSubscription';
import { stripeCustomerTypeLabels } from '../../const/stripeCustomer';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionActions from '@material-ui/core/AccordionActions';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((_: Theme) => ({
  block: {
    display: 'block',
  },
}));

export type AdminStripeSubscriptionSearchFormInput = Omit<
  AdminStripeSubscriptionSearchInput,
  'limit' | 'page'
>;

interface Props {
  form: UseFormReturn<AdminStripeSubscriptionSearchFormInput>;
  onSubmit: (input: AdminStripeSubscriptionSearchFormInput) => void;
}

export const SearchForm: React.FC<Props> = ({ form, onSubmit }) => {
  const classes = useStyles();

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1c-content"
          id="panel1c-header"
        >
          <div>
            <Typography>検索</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={4}>
              <Controller
                name="id"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="id"
                    label="ID"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="teamID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="teamID"
                    label="teamID"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="userID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="userID"
                    label="userID"
                    type="number"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="stripeCustomerID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="stripeCustomerID"
                    label="stripeCustomerID"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="providerID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="providerID"
                    label="providerID"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="providerCustomerID"
                control={form.control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="providerCustomerID"
                    label="providerCustomerID"
                    type="text"
                    fullWidth
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="providerStatus">providerStatus</InputLabel>
                <Controller
                  name="providerStatus"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value="">&nbsp;</MenuItem>
                      {Object.entries(providerSubscriptionStatusPhaseLabels).map(
                        ([value, label]) => (
                          <MenuItem value={value} key={value}>
                            {label}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel htmlFor="stripeCustomerType">stripeCustomerType</InputLabel>
                <Controller
                  name="stripeCustomerType"
                  control={form.control}
                  render={({ field: { ref, value, ...rest } }) => (
                    <Select inputRef={ref} value={value ?? ''} {...rest}>
                      <MenuItem value="">&nbsp;</MenuItem>
                      {Object.entries(stripeCustomerTypeLabels).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Divider light />
        <AccordionActions>
          <Button size="small" color="primary" type="submit">
            <SearchIcon className={classes.block} />
            Search
          </Button>
        </AccordionActions>
      </Accordion>
    </form>
  );
};
