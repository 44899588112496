import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles, Theme } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetCourseProgramForAdminQuery } from '../../../gen/graphql';
import { ValidLabels } from '../../const/Valid';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ course_program_id: string }>().course_program_id ?? '0';

  const { data, loading } = useGetCourseProgramForAdminQuery({
    variables: { id },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                CourseProgram Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/coursePrograms/${data?.courseProgramForAdmin?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{data?.courseProgramForAdmin?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Title
                </TableCell>
                <TableCell>{data?.courseProgramForAdmin?.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Description
                </TableCell>
                <TableCell>
                  <ReactMarkdown children={data?.courseProgramForAdmin?.description ?? ''} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Overview
                </TableCell>
                <TableCell>
                  <OverflowPreventedMarkdown
                    children={data?.courseProgramForAdmin?.overview ?? ''}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Content
                </TableCell>
                <TableCell>
                  <ReactMarkdown children={data?.courseProgramForAdmin?.content ?? ''} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Number
                </TableCell>
                <TableCell>{data?.courseProgramForAdmin?.number ?? ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CourseProgramSteps
                </TableCell>
                <TableCell>
                  {data?.courseProgramForAdmin?.courseProgramSteps?.map((courseProgramStep) => (
                    <Link
                      key={courseProgramStep.id}
                      className={classes.block}
                      to={`/courseProgramSteps/${courseProgramStep.id}`}
                    >
                      {courseProgramStep.title !== ''
                        ? courseProgramStep.title
                        : courseProgramStep.id}
                    </Link>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Image
                </TableCell>
                <TableCell>
                  <img
                    width="600px"
                    height="300px"
                    src={data?.courseProgramForAdmin?.image}
                    alt=""
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Tags
                </TableCell>
                <TableCell>
                  {data?.courseProgramForAdmin?.tags?.map((tag) => (
                    <Link key={tag.id} className={classes.block} to={`/tags/${tag.id}`}>
                      {tag.name}
                    </Link>
                  ))}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>
                  {data?.courseProgramForAdmin?.status
                    ? ValidLabels[data?.courseProgramForAdmin?.status]
                    : ''}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{data?.courseProgramForAdmin?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{data?.courseProgramForAdmin?.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </main>
    </div>
  );
};

const OverflowPreventedMarkdown = styled(ReactMarkdown)`
  img {
    max-width: 100%;
  }
`;
