import React, { useState, useMemo, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { GeneralChangeEvent } from '../../../types/Common';
import {
  useGetPlansForAdminQuery,
  AdminPlanSearchInput,
  Valid,
  Bool,
  useAddPlanForAdminMutation,
  AdminPlanInput,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { BoolLabels, strToBool } from '../../const/Bool';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  csvBtn: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 99,
    backgroundColor: '#009be5',
    color: 'white',
    padding: '10px 13px',
    textDecoration: 'none',
    borderRadius: '10px',
    boxShadow: '3px 3px 4px black',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

// 登録フォームの初期値
const addInputDefaultValues = {
  name: '',
  isExtension: Bool.True,
  isFlexibleCurriculum: Bool.True,
  isLessonPreparationAllowed: Bool.True,
  isQuestionAllowed: Bool.True,
  lessonMinutes: 0,
  status: Valid.Valid,
};
type SearchFormInput = Omit<AdminPlanSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminPlanSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const name = params.get('name') ?? '';
    const isExtension = params.get('isExtension') ?? '';
    const isFlexibleCurriculum = params.get('isFlexibleCurriculum') ?? '';
    const isLessonPreparationAllowed = params.get('isLessonPreparationAllowed') ?? '';
    const isQuestionAllowed = params.get('isQuestionAllowed') ?? '';
    const lessonMinutes = params.get('lessonMinutes') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      name: name ?? undefined,
      isExtension: strToBool(isExtension),
      isFlexibleCurriculum: strToBool(isFlexibleCurriculum),
      isLessonPreparationAllowed: strToBool(isLessonPreparationAllowed),
      isQuestionAllowed: strToBool(isQuestionAllowed),
      lessonMinutes: parseInt(lessonMinutes) > 0 ? parseInt(lessonMinutes) : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetPlansForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });
  const plans = data?.plansForAdmin.items ?? [];
  const total = data?.plansForAdmin.total ?? 0;

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  // Add Dialog
  const [open, showAddDialog] = useState(false);

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  const [addInput, setAddInput] = useState<AdminPlanInput>(addInputDefaultValues);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminPlanSearchInput): void => {
      navigate({
        pathname: '/plans',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 再検索
  const refetchPlans = useCallback(
    async (params: AdminPlanSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      name: currentSearchParams.name,
      isExtension: currentSearchParams.isExtension,
      isFlexibleCurriculum: currentSearchParams.isFlexibleCurriculum,
      isLessonPreparationAllowed: currentSearchParams.isLessonPreparationAllowed,
      isQuestionAllowed: currentSearchParams.isQuestionAllowed,
      lessonMinutes: currentSearchParams.lessonMinutes,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchPlans(params);
      }
      createHistory(params);
    },
    [refetchPlans, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // データ登録
  const [addPlan, { error: addError }] = useAddPlanForAdminMutation();

  // データ作成ボタンクリック
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await addPlan({
          variables: {
            input: addInput,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      // ダイアログを閉じて入力フォーム初期化
      showAddDialog(false);
      setAddInput(addInputDefaultValues);

      // 再検索
      refetchPlans(currentSearchParams);
    }, [addPlan, refetchPlans, currentSearchParams, addInput]),
  );

  // 更新系Handler
  const handleChangeAddName = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, name: value }));
  }, []);

  const handleChangeAddLessonMinutes = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        lessonMinutes: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeAddIsExtension = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        isExtension: strToBool(String(value)) ?? Bool.True,
      }));
    },
    [],
  );

  const handleChangeAddIsLessonPreparationAllowed = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        isLessonPreparationAllowed: strToBool(String(value)) ?? Bool.True,
      }));
    },
    [],
  );

  const handleChangeAddIsQuestionAllowed = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        isQuestionAllowed: strToBool(String(value)) ?? Bool.True,
      }));
    },
    [],
  );

  const handleChangeAddIsFlexibleCurriculum = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        isFlexibleCurriculum: strToBool(String(value)) ?? Bool.True,
      }));
    },
    [],
  );

  const handleChangeAddStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({ ...current, status: strToValid(String(value)) ?? Valid.Valid }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Plan
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="number"
                          fullWidth
                          inputProps={{ min: 1 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="name"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Name"
                          type="text"
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Controller
                      name="lessonMinutes"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="lessonMinutes"
                          label="LessonMinutes"
                          type="number"
                          fullWidth
                          inputProps={{ min: 0 }}
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="isExtension">IsExtension</InputLabel>
                      <Controller
                        name="isExtension"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(BoolLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="isLessonPreparationAllowed">
                        IsLessonPreparationAllowed
                      </InputLabel>
                      <Controller
                        name="isLessonPreparationAllowed"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(BoolLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="isQuestionAllowed">IsQuestionAllowed</InputLabel>
                      <Controller
                        name="isQuestionAllowed"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(BoolLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="isFlexibleCurriculum">IsFlexibleCurriculum</InputLabel>
                      <Controller
                        name="isFlexibleCurriculum"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(BoolLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Name</TableCell>
                  <TableCell align="right">lessonMinutes</TableCell>
                  <TableCell align="right">IsExtension</TableCell>
                  <TableCell align="right">IsLessonPreparationAllowed</TableCell>
                  <TableCell align="right">IsQuestionAllowed</TableCell>
                  <TableCell align="right">IsFlexibleCurriculum</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {plans?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/plans/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">
                      {row.lessonMinutes ? row.lessonMinutes : '制限無し'}
                    </TableCell>
                    <TableCell align="right">{BoolLabels[row.isExtension]}</TableCell>
                    <TableCell align="right">
                      {BoolLabels[row.isLessonPreparationAllowed]}
                    </TableCell>
                    <TableCell align="right">{BoolLabels[row.isQuestionAllowed]}</TableCell>
                    <TableCell align="right">{BoolLabels[row.isFlexibleCurriculum]}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Name"
            type="text"
            defaultValue={addInput.name}
            fullWidth
            onChange={handleChangeAddName}
          />
          <TextField
            autoFocus
            margin="dense"
            id="lessonMinutes"
            name="lessonMinutes"
            label="LessonMinutes"
            type="number"
            defaultValue={addInput.lessonMinutes}
            fullWidth
            onChange={handleChangeAddLessonMinutes}
            inputProps={{ min: 0 }}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="isExtension">IsExtension</InputLabel>
              <Select
                value={addInput.isExtension}
                onChange={handleChangeAddIsExtension}
                input={<Input name="isExtension" id="isExtension" />}
              >
                {Object.entries(BoolLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="isLessonPreparationAllowed">
                IsLessonPreparationAllowed
              </InputLabel>
              <Select
                value={addInput.isLessonPreparationAllowed}
                onChange={handleChangeAddIsLessonPreparationAllowed}
                input={<Input name="isLessonPreparationAllowed" id="isLessonPreparationAllowed" />}
              >
                {Object.entries(BoolLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="isQuestionAllowed">IsQuestionAllowed</InputLabel>
              <Select
                value={addInput.isQuestionAllowed}
                onChange={handleChangeAddIsQuestionAllowed}
                input={<Input name="isQuestionAllowed" id="isQuestionAllowed" />}
              >
                {Object.entries(BoolLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="isFlexibleCurriculum">IsFlexibleCurriculum</InputLabel>
              <Select
                value={addInput.isFlexibleCurriculum}
                onChange={handleChangeAddIsFlexibleCurriculum}
                input={<Input name="isFlexibleCurriculum" id="isFlexibleCurriculum" />}
              >
                {Object.entries(BoolLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={addInput.status}
                onChange={handleChangeAddStatus}
                input={<Input name="status" id="status" />}
              >
                {Object.entries(ValidLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
