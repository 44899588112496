import React, { useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';

import {
  AdminStripeSubscriptionSearchInput,
  useGetStripeSubscriptionsForAdminQuery,
  ProviderSubscriptionStatus,
  StripeCustomerType,
} from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { Paper, Grid, Toolbar, AppBar } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import { strToEnum } from '../../../utils/common';
import { providerSubscriptionStatusPhaseLabels } from '../../const/StripeSubscription';
import { stripeCustomerTypeLabels } from '../../const/stripeCustomer';
import { AdminStripeSubscriptionSearchFormInput, SearchForm } from './SearchForm';
import { stripeSubscriptionURL } from '../../../utils/stripe';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminStripeSubscriptionSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const userID = params.get('userID') ?? '';
    const teamID = params.get('teamID') ?? '';
    const stripeCustomerID = params.get('stripeCustomerID') ?? '';
    const providerID = params.get('providerID') ?? '';
    const providerCustomerID = params.get('providerCustomerID') ?? '';
    const providerStatus = params.get('providerStatus') ?? '';
    const stripeCustomerType = params.get('stripeCustomerType') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: id.length === 26 ? id : undefined, // ULIDなので26の桁数チェック
      userID: parseInt(userID) > 0 ? parseInt(userID) : undefined,
      teamID: teamID.length === 26 ? teamID : undefined, // ULIDなので26の桁数チェック
      stripeCustomerID: stripeCustomerID.length === 26 ? stripeCustomerID : undefined,
      providerID: providerID !== '' ? providerID : undefined,
      providerCustomerID: providerCustomerID !== '' ? providerCustomerID : undefined,
      providerStatus: strToEnum(providerStatus, ProviderSubscriptionStatus),
      stripeCustomerType: strToEnum(stripeCustomerType, StripeCustomerType),
    };
  }, [location.search]);

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminStripeSubscriptionSearchInput): void => {
      navigate({
        pathname: '/stripeSubscriptions',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  const { data, loading, refetch } = useGetStripeSubscriptionsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  const searchForm = useForm<AdminStripeSubscriptionSearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      userID: currentSearchParams.userID,
      teamID: currentSearchParams.teamID,
      stripeCustomerID: currentSearchParams.stripeCustomerID,
      providerID: currentSearchParams.providerID,
    },
    // 検索してもヒットしないだけの為、バリデーションなし
  });
  const stripeSubscriptions = data?.stripeSubscriptionsForAdmin.items ?? [];
  const total = data?.stripeSubscriptionsForAdmin.total ?? 0;

  // 検索
  const searchStripeSubscriptions = useCallback(
    async (input: AdminStripeSubscriptionSearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetch({ input: params });
      }
      createHistory(params);
    },
    [refetch, createHistory, currentSearchParams],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                stripeSubscription
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <SearchForm form={searchForm} onSubmit={searchStripeSubscriptions} />
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">stripeCustomerID</TableCell>
                  <TableCell align="right">StripeCustomerType</TableCell>
                  <TableCell align="right">priceID</TableCell>
                  <TableCell align="right">productName</TableCell>
                  <TableCell align="right">providerID</TableCell>
                  <TableCell align="right">providerQuantity</TableCell>
                  <TableCell align="right">providerStatus</TableCell>
                  <TableCell align="right">isCurrent</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stripeSubscriptions?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell>
                      <Link to={`/stripeSubscriptions/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/stripeCustomers/${row.stripeCustomerID}`}>
                        {row.stripeCustomerID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      {row.stripeCustomer?.stripeCustomerType
                        ? stripeCustomerTypeLabels[row.stripeCustomer?.stripeCustomerType]
                        : '-'}
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/prices/${row.priceID}`}>{row.priceID}</Link>
                    </TableCell>
                    <TableCell align="right">{row.price?.product.name}</TableCell>
                    <TableCell align="right">
                      <Link target="_blank" to={stripeSubscriptionURL(row.providerID)}>
                        {row.providerID}
                      </Link>
                    </TableCell>
                    <TableCell align="right">{row.providerQuantity}</TableCell>
                    <TableCell align="right">
                      {row.providerStatus
                        ? providerSubscriptionStatusPhaseLabels[row.providerStatus]
                        : '-'}
                    </TableCell>
                    <TableCell align="center">
                      {!!row.subscriptionCurrent?.stripeSubscriptionID && '〇'}
                    </TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
    </div>
  );
};
