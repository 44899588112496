import React from 'react';
import styled from 'styled-components';
import { Control, Controller, FieldErrors, useFieldArray } from 'react-hook-form';
import { Accordion, AccordionDetails, AccordionSummary, TextField } from '@material-ui/core';

import TrashIcon from '../../../static/image/icon_trash.svg';
import { AdminAbTestSettingInput } from '../../../gen/graphql';

interface Props {
  control: Control<AdminAbTestSettingInput>;
  errors: FieldErrors<AdminAbTestSettingInput>;
  type: number;
}

export const RepeatInputFields: React.FC<Props> = ({ control, errors, type }): JSX.Element => {
  const { fields, append, remove } = useFieldArray({
    control: control,
    name: `abTestSettingDeviceInputs.${type}.abTestSettingDeviceDestinationInputs`,
  });

  return (
    <>
      {fields.map((item, index) => {
        return (
          <Accordion key={item.id} style={{ margin: '8px 0' }}>
            <AccordionSummary>リダイレクト先{index + 1}</AccordionSummary>
            <AccordionDetails>
              <Container>
                <Controller
                  name={`abTestSettingDeviceInputs.${type}.abTestSettingDeviceDestinationInputs.${index}.destinationURL`}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      margin="dense"
                      id={`${index}.destinationURL`}
                      label="URL"
                      type="text"
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      style={{ margin: '10px 0' }}
                      error={
                        !!errors.abTestSettingDeviceInputs?.[type]
                          ?.abTestSettingDeviceDestinationInputs?.[index]?.destinationURL
                      }
                      helperText={
                        errors.abTestSettingDeviceInputs?.[type]
                          ?.abTestSettingDeviceDestinationInputs?.[index]?.destinationURL?.message
                      }
                      inputRef={ref}
                      {...rest}
                    />
                  )}
                />
                <Controller
                  name={`abTestSettingDeviceInputs.${type}.abTestSettingDeviceDestinationInputs.${index}.destinationRate`}
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <TextField
                      margin="dense"
                      id={`${index}.destinationRate`}
                      label="配信比率"
                      type="number"
                      InputProps={{ inputProps: { min: 0, max: 100 } }}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      style={{ margin: '10px 0' }}
                      error={
                        !!errors.abTestSettingDeviceInputs?.[type]
                          ?.abTestSettingDeviceDestinationInputs?.[index]?.destinationRate
                      }
                      helperText={
                        errors.abTestSettingDeviceInputs?.[type]
                          ?.abTestSettingDeviceDestinationInputs?.[index]?.destinationRate?.message
                      }
                      inputRef={ref}
                      {...rest}
                    />
                  )}
                />
              </Container>
              <Icon onClick={() => remove(index)} src={TrashIcon} alt="ゴミ箱" />
            </AccordionDetails>
          </Accordion>
        );
      })}
      <Add
        onClick={() => {
          append({ destinationURL: '', destinationRate: 0 });
        }}
      >
        + リダイレクト先を追加する
      </Add>
    </>
  );
};

const Container = styled.div`
  flex: 1;
`;
const Icon = styled.img`
  margin-left: 1rem;
`;
const Add = styled.p`
  cursor: pointer;
  color: #e2001b;
  font-weight: 600;
`;
