import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useGetPracticeSubmitDetailForAdminQuery } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Grid, Paper, TableContainer } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import { ValidLabels } from '../../const/Valid';
import { PageHeader } from '../../common/PageHeader';
import { PracticeSubmitDetailReviewStatusLabels } from '../../const/PracticeSubmitDetail';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ practice_submit_detail_id: string }>().practice_submit_detail_id ?? '';

  const { data, loading } = useGetPracticeSubmitDetailForAdminQuery({
    variables: { id },
  });
  const practiceSubmitDetail = data?.practiceSubmitDetailForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <PageHeader title="Practice Submit Detail Detail" />
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/practiceSubmitDetails/${practiceSubmitDetail?.id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{practiceSubmitDetail?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Practice Submit
                  </TableCell>
                  <TableCell>
                    <Link to={`/practiceSubmits/${practiceSubmitDetail?.practiceSubmitID}`}>
                      {practiceSubmitDetail?.practiceSubmitID}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    URL
                  </TableCell>
                  <TableCell>
                    <Content>{practiceSubmitDetail?.url ?? '-'}</Content>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Content
                  </TableCell>
                  <TableCell>
                    <Content>{practiceSubmitDetail?.content ?? '-'}</Content>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Review Status
                  </TableCell>
                  <TableCell>
                    {practiceSubmitDetail?.reviewStatus
                      ? PracticeSubmitDetailReviewStatusLabels[practiceSubmitDetail?.reviewStatus]
                      : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>
                    {practiceSubmitDetail?.status ? ValidLabels[practiceSubmitDetail?.status] : '-'}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{practiceSubmitDetail?.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{practiceSubmitDetail?.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </main>
    </div>
  );
};

const Content = styled.div`
  white-space: pre-wrap;
`;
