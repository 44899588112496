import React from 'react';

// Material UI
import { AppBar, Toolbar, Paper, Grid, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetCoachingAssignLogQuery } from '../../../gen/graphql';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ coaching_assign_log_id: string }>().coaching_assign_log_id ?? '0';

  const { data, loading } = useGetCoachingAssignLogQuery({
    variables: { id },
  });
  const coachingAssignLog = data?.coachingAssignLog;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Coaching Assign Log Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        {coachingAssignLog && (
          <Paper className={classes.paperContent}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{coachingAssignLog.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Student
                  </TableCell>
                  <TableCell>
                    <Link to={`/students/${coachingAssignLog.coaching.course?.student.id}`}>
                      {coachingAssignLog.coaching?.course?.student.user.personalInfo?.name ?? ''}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Course
                  </TableCell>
                  <TableCell>
                    <Link to={`/courses/${coachingAssignLog.coaching?.course?.id}`}>
                      {coachingAssignLog.coaching?.course.plan.name ?? ''}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Coach
                  </TableCell>
                  <TableCell>
                    <Link to={`/courses/${coachingAssignLog.coachID}`}>
                      {coachingAssignLog.coaching?.coach?.user.personalInfo?.name ?? ''}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{coachingAssignLog.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{coachingAssignLog.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        )}
      </main>
    </div>
  );
};
