import React, { useState, useCallback } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Box, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Date
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import {
  useGetInstructorScheduleForAdminQuery,
  AdminUpdateInstructorScheduleInput,
  useUpdateInstructorScheduleForAdminMutation,
  Valid,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { getUTCDatetime } from '../../const/Util';
import { GeneralChangeEvent } from '../../../types/Common';
import { ValidLabels, strToValid } from '../../const/Valid';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ instructor_schedule_id: string }>().instructor_schedule_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminUpdateInstructorScheduleInput>({
    startAt: '',
    endAt: '',
    status: Valid.Valid,
  });

  const { loading } = useGetInstructorScheduleForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ instructorScheduleForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        startAt: data.startAt ?? undefined,
        endAt: data.endAt ?? undefined,
        status: data.status ? Valid.Valid : Valid.Invalid,
      });
    },
  });

  const [updateInstruvtorSchedule, { error: updateError }] =
    useUpdateInstructorScheduleForAdminMutation();
  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateInstruvtorSchedule({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [navigate, updateInstruvtorSchedule, id, updateInput]),
  );

  const handleChangeAddStartAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        startAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeAddEndAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        endAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeAddStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                InstructorSchedule Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="startAt"
                  label="開始時間"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput.startAt}
                  onChange={handleChangeAddStartAt}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="endAt"
                  label="終了時間"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput.endAt}
                  onChange={handleChangeAddEndAt}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput.status}
                  onChange={handleChangeAddStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
