import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  AdminPracticeInput,
  useGetPracticeForAdminQuery,
  useUpdatePracticeForAdminMutation,
} from '../../../gen/graphql';

import { AppBar, Button, Grid, LinearProgress, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

import { Form } from './Form';

import { PracticeDefaultValues, practiceSchema } from '../../../formSchema/practice';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { PageHeader } from '../../common/PageHeader';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ practice_id: string }>().practice_id ?? '';

  const [showLoader, setShowLoader] = useState(false);

  const formID = 'practice-edit-form';
  const form = useForm<AdminPracticeInput>({
    resolver: yupResolver(practiceSchema),
    defaultValues: PracticeDefaultValues,
  });

  const { loading } = useGetPracticeForAdminQuery({
    variables: { id },
    onCompleted: ({ practiceForAdmin: data }) => {
      if (!data) {
        return;
      }

      form.reset({
        programID: data?.programID,
        key: data?.key,
        title: data?.title,
        description: data?.description,
        requireTime: data?.requireTime,
        content: data?.content,
        answer: data?.answer,
        sortNumber: data?.sortNumber,
        type: data?.type,
        difficulty: data?.difficulty,
        isAiReviewEnabled: data?.isAiReviewEnabled,
        status: data?.status,
      });
    },
  });

  const [updatePractice, { error }] = useUpdatePracticeForAdminMutation();

  const handleClickUpdate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminPracticeInput): Promise<void> => {
        setShowLoader(true);

        try {
          await updatePractice({
            variables: {
              id: id,
              input: input,
            },
          });
        } catch {
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [updatePractice, id, navigate],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <PageHeader title="Practice Edit" />
      </AppBar>
      {(loading || showLoader) && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Form formID={formID} form={form} onSubmit={handleClickUpdate} error={error} isUpdate />
          <Button
            type="submit"
            form={formID}
            variant="contained"
            color="primary"
            style={{ margin: '20px 0 0 0' }}
          >
            Save
          </Button>
        </Paper>
      </main>
    </div>
  );
};
