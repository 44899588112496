import * as yup from 'yup';

import { AuthorizationType, PracticeDifficulty, AdminPracticeInput, Valid } from '../gen/graphql';

export const practiceSchema = yup.object({
  programID: yup
    .number()
    .required()
    .min(1)
    .transform((value) => (isNaN(value) ? undefined : value)), // TODO number型のNan対応を共通化 (@see https://github.com/jquense/yup/issues/1330)
  key: yup.string().nullable().max(100).trim(),
  title: yup.string().required().max(100).trim(),
  description: yup.string().required().max(255).trim(),
  requireTime: yup
    .number()
    .required()
    .min(1)
    .transform((value) => (isNaN(value) ? undefined : value)),
  content: yup.string().nullable().trim(),
  answer: yup.string().nullable().trim(),
  type: yup.string().required().oneOf(Object.values(AuthorizationType)),
  difficulty: yup.string().required().oneOf(Object.values(PracticeDifficulty)),
  isAiReviewEnabled: yup.bool().required(),
  status: yup.string().required().oneOf(Object.values(Valid)),
  sortNumber: yup
    .number()
    .nullable()
    .min(0)
    .transform((value) => (isNaN(value) ? undefined : value)),
});

export const PracticeDefaultValues: AdminPracticeInput = {
  programID: 0,
  key: '',
  title: '',
  description: '',
  requireTime: 0,
  content: '',
  answer: '',
  type: AuthorizationType.Public,
  difficulty: PracticeDifficulty.Normal,
  isAiReviewEnabled: false,
  status: Valid.Valid,
  sortNumber: 0,
};
