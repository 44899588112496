import React, { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import { yupResolver } from '@hookform/resolvers/yup';

// Other
import { AdminNoticeInput, useGetNoticeQuery, useUpdateNoticeMutation } from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { Form } from './Form';
import { NoticeDefaultValues, noticeSchema } from '../../../formSchema/notice';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  formControl: {
    display: 'flex',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ notice_id: string }>().notice_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  const formId = 'session-report-edit-form';
  const form = useForm<AdminNoticeInput>({
    resolver: yupResolver(noticeSchema),
    defaultValues: NoticeDefaultValues,
  });

  const { loading } = useGetNoticeQuery({
    variables: { id },
    onCompleted: ({ noticeForAdmin: data }) => {
      if (!data) {
        return;
      }

      form.reset({
        title: data.title,
        content: data.content,
        status: data.status,
        userRoles: data.noticePermissions.map((n) => n.userRole),
        // 登録データから取得する値ではないためFalseで設定
        sendMail: false,
      });
    },
  });

  const [updateNotice, { error }] = useUpdateNoticeMutation();

  const handleClickUpdate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminNoticeInput): Promise<void> => {
        setShowLoader(true);

        try {
          await updateNotice({
            variables: {
              id: id,
              input: input,
            },
          });
        } catch {
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [updateNotice, id, navigate],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Notice Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Form formId={formId} form={form} onSubmit={handleClickUpdate} error={error} isUpdate />
          <Button
            type="submit"
            form={formId}
            variant="contained"
            color="primary"
            style={{ margin: '20px 0 0 0' }}
          >
            Save
          </Button>
        </Paper>
      </main>
    </div>
  );
};
