import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminTagInput,
  useUpdateTagForAdminMutation,
  UserRole,
  useGetTagForAdminQuery,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { TagDefaultValues, tagSchema } from '../../../formSchema/tag';
import { Form } from './Form';
import { yupResolver } from '@hookform/resolvers/yup';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ tag_id: string }>().tag_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = useState(false);

  const formId = 'tag-edit-form';
  const form = useForm<AdminTagInput>({
    resolver: yupResolver(tagSchema),
    defaultValues: TagDefaultValues,
  });

  const { loading } = useGetTagForAdminQuery({
    variables: { id },
    onCompleted: ({ getTagForAdmin: data }) => {
      if (!data) {
        return;
      }

      form.reset({
        name: data.name,
        overview: data.overview,
        status: data.status,
        userRoles: data.tagPermissions
          .filter((e) => e.userRole !== null && e.userRole !== undefined)
          .map((e) => e.userRole) as UserRole[],
      });
    },
  });

  const [updateTag, { error }] = useUpdateTagForAdminMutation();

  const handleClickUpdate = useSafeAsyncCallback(
    useCallback(
      async (input: AdminTagInput): Promise<void> => {
        setShowLoader(true);

        try {
          await updateTag({
            variables: {
              id: id,
              input: input,
            },
          });
        } catch {
          return;
        } finally {
          setShowLoader(false);
        }

        navigate(-1);
      },
      [updateTag, id, navigate],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Tag Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <Form formId={formId} form={form} onSubmit={handleClickUpdate} error={error} isUpdate />
          <Button
            type="submit"
            form={formId}
            variant="contained"
            color="primary"
            style={{ margin: '20px 0 0 0' }}
          >
            Save
          </Button>
        </Paper>
      </main>
    </div>
  );
};
