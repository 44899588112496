import React, { useState, useCallback, useMemo } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Box, Grid, Paper, Toolbar, makeStyles } from '@material-ui/core';

// Material UI Date
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Other
import { useNavigate, useParams } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import {
  useGetSpotLessonForAdminQuery,
  AdminSpotLessonUpdateInput,
  useUpdateSpotLessonForAdminMutation,
  useGetReservableInstructorsForAdminQuery,
  Valid,
  SpotLessonCancelType,
  SpotLessonPhase,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { CancelTypeLabels, PhaseLabels } from '../../const/SpotLesson';
import { GeneralChangeEvent } from '../../../types/Common';
import { getUTCDatetime } from '../../const/Util';
import { strToEnum } from '../../../utils/common';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

// 表示専用Parameter
export interface ReadOnlyParam {
  instructorUserID: number;
  instructorUserName: string;
}

export const Update = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();

  const paramID = useParams<{ spot_lesson_id: string }>().spot_lesson_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminSpotLessonUpdateInput>({
    canceledAt: undefined,
    cancelType: undefined,
    instructorScheduleID: 0,
    instructorUserID: 0,
    phase: SpotLessonPhase.Incomplete,
    status: Valid.Valid,
  });
  // InstructorID更新対象ではないが表示用に保持している
  const [readOnlyInput, setReadOnlyInput] = useState<ReadOnlyParam>({
    instructorUserID: 0,
    instructorUserName: '',
  });

  const { loading } = useGetSpotLessonForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ spotLessonForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        canceledAt: data.canceledAt ?? undefined,
        cancelType: data.cancelType ? data.cancelType : undefined,
        instructorScheduleID: data.instructorScheduleID ?? 0,
        instructorUserID: data.instructorUser?.id ?? 0,
        phase: data.phase,
        status: data.status ? Valid.Valid : Valid.Invalid,
      });
      setReadOnlyInput({
        instructorUserID: data.instructorUser?.id ?? 0,
        instructorUserName: data.instructorUser?.personalInfo?.name ?? '',
      });
    },
  });

  const { data: instructorScheduleDate } = useGetReservableInstructorsForAdminQuery({
    variables: {
      spotLessonID: id,
    },
  });
  const instructorSchedules = useMemo(
    () => instructorScheduleDate?.reservableInstructorsForAdmin ?? [],
    [instructorScheduleDate?.reservableInstructorsForAdmin],
  );

  const [updateSpotLesson, { error: updateError }] = useUpdateSpotLessonForAdminMutation();
  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateSpotLesson({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [navigate, updateSpotLesson, id, updateInput]),
  );

  const handleChangeAddInstructor = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        instructorUserID: parseInt(String(value)) > 0 ? parseInt(String(value)) : undefined,
        instructorScheduleID: value
          ? instructorSchedules.find((schedule) => {
              return schedule.user?.id === Number(value);
            })?.id
          : undefined,
      }));
    },
    [instructorSchedules],
  );

  const handleChangeAddStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  const handleChangeAddPhase = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        phase: strToEnum(String(value), SpotLessonPhase),
      }));
    },
    [],
  );

  const handleChangeAddCanceledAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        canceledAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeAddCancelType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        cancelType: value ? strToEnum(String(value), SpotLessonCancelType) : undefined,
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                SpotLesson Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {showLoader || loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <FormControl>
                <InputLabel htmlFor="instructor">Instructor</InputLabel>
                <Select
                  value={updateInput.instructorUserID}
                  onChange={handleChangeAddInstructor}
                  input={<Input name="instructor" id="instructor" />}
                >
                  <MenuItem value={readOnlyInput.instructorUserID}>
                    {readOnlyInput.instructorUserName}
                  </MenuItem>
                  {instructorSchedules &&
                    instructorSchedules.map((instructorSchedule) => (
                      <MenuItem value={instructorSchedule.user?.id}>
                        {instructorSchedule.user?.personalInfo?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <h4>※インストラクターを交代させるとメールが送信されます！</h4>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput.status}
                  onChange={handleChangeAddStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="phase">Phase</InputLabel>
                <Select
                  value={updateInput.phase}
                  onChange={handleChangeAddPhase}
                  input={<Input name="phase" id="phase" />}
                >
                  {Object.entries(PhaseLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div hidden={updateInput.phase !== SpotLessonPhase.Cancel}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="canceledAt"
                  label="キャンセル時間"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput.canceledAt ?? null}
                  onChange={handleChangeAddCanceledAt}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div hidden={updateInput.phase !== SpotLessonPhase.Cancel}>
              <FormControl>
                <InputLabel htmlFor="cancelType">cancelType</InputLabel>
                <Select
                  value={updateInput.cancelType}
                  onChange={handleChangeAddCancelType}
                  input={<Input name="cancelType" id="cancelType" />}
                >
                  {Object.entries(CancelTypeLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
