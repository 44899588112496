import React, { useCallback } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Snackbar, Box, makeStyles } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  findProgress,
  phaseLabels,
  lessonTypeLabels,
  completedLabels,
  canceledLabels,
  studentAssistanceLabels,
  instructorAssistanceLabels,
} from '../../const/Lesson';
import { ValidLabels } from '../../const/Valid';
import {
  useGetLessonForAdminQuery,
  useDeleteLessonForAdminMutation,
  Valid,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ lesson_id: string }>().lesson_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);
  const [snackBar, setSnackBar] = React.useState('');

  const { data, loading, refetch } = useGetLessonForAdminQuery({
    variables: { id },
  });
  const lesson = data?.lessonForAdmin;

  const [deleteLesson, { error: updateError }] = useDeleteLessonForAdminMutation();

  const lessonType = () => {
    if (!lesson) {
      return '-';
    }

    return lessonTypeLabels[lesson.type];
  };

  // 削除処理
  const handleClickDelete = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (
        window.confirm(
          '対象のレッスンは、ただちに無効化されます。本当に無効化しますか？こちらの処理は取り消せません。',
        )
      ) {
        setShowLoader(true);

        try {
          await deleteLesson({
            variables: {
              lessonID: id,
            },
          });
        } catch (e) {
          return;
        } finally {
          setShowLoader(false);
        }

        refetch({ id: id });
      }
    }, [deleteLesson, id, refetch]),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Lesson Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/lessons/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        <Paper className={classes.paperContent}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>項目</TableCell>
                <TableCell>値</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  ID
                </TableCell>
                <TableCell>{lesson?.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Title
                </TableCell>
                <TableCell>{lesson?.title}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Course
                </TableCell>
                <TableCell>
                  <Link to={`/courses/${lesson?.course!.id}`}>{lesson?.course!.plan!.name}</Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Instructor
                </TableCell>
                <TableCell>
                  <Link to={`/instructors/${lesson?.instructor?.id}`}>
                    {lesson?.instructor?.user?.personalInfo?.name}
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Type
                </TableCell>
                <TableCell>{lessonType()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  StartAt
                </TableCell>
                <TableCell>{lesson?.startAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  EndAt
                </TableCell>
                <TableCell>{lesson?.endAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  TaughtSkill
                </TableCell>
                <TableCell>
                  <ReactMarkdown children={lesson?.taughtSkill ?? ''} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Homework
                </TableCell>
                <TableCell>
                  <ReactMarkdown children={lesson?.homework ?? ''} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Prepare
                </TableCell>
                <TableCell>
                  <ReactMarkdown children={lesson?.prepare ?? ''} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  DelayReason
                </TableCell>
                <TableCell>
                  <ReactMarkdown children={lesson?.delayReason ?? ''} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Completed
                </TableCell>
                <TableCell>
                  {lesson?.completed ? completedLabels[lesson?.completed] : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Canceled
                </TableCell>
                <TableCell> {lesson?.canceled ? canceledLabels[lesson?.canceled] : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Phase
                </TableCell>
                <TableCell>{lesson?.phase ? phaseLabels[lesson?.phase] : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Progress
                </TableCell>
                <TableCell>{findProgress(lesson?.progress).label}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Assistances
                </TableCell>
                <TableCell>{lesson?.lessonsAssistances?.map((a) => a.name).join('/')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  AssistanceDetail
                </TableCell>
                <TableCell>{lesson?.assistanceDetail}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  StudentAssistance
                </TableCell>
                <TableCell>
                  {lesson?.studentAssistance
                    .filter((a) => studentAssistanceLabels.length > a)
                    .map((a) => studentAssistanceLabels[a])
                    .join('/')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  InstructorAssistance
                </TableCell>
                <TableCell>
                  {lesson?.instructorAssistance
                    .filter((a) => instructorAssistanceLabels.length > a)
                    .map((a) => instructorAssistanceLabels[a])
                    .join('/')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Status
                </TableCell>
                <TableCell>{lesson?.status ? ValidLabels[lesson?.status] : '-'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  無効処理
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{ margin: '20px 0 0 0' }}
                    onClick={handleClickDelete}
                    disabled={lesson?.status === Valid.Invalid}
                  >
                    無効にする
                  </Button>
                  <ul>
                    <li>一度無効にすると処理は取り消せません。</li>
                  </ul>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  CreatedAt
                </TableCell>
                <TableCell>{lesson?.createdAt}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  UpdatedAt
                </TableCell>
                <TableCell>{lesson?.updatedAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        {updateError?.graphQLErrors && (
          <Box className={classes.inputError}>
            <Typography style={{ color: '#ff0000' }}>Oops, following errors occurred...</Typography>
            {updateError.graphQLErrors.map((error, index) => (
              <div key={index}>{error.message}</div>
            ))}
          </Box>
        )}
        <Snackbar
          open={snackBar !== ''}
          autoHideDuration={6000}
          onClose={() => {
            setSnackBar('');
          }}
          message={snackBar}
        />
      </main>
    </div>
  );
};
