import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

import { useGetUsersForAdminLazyQuery, UserFragment } from '../../../gen/graphql';

import { makeStyles, Theme } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import FaceIcon from '@material-ui/icons/Face';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { CreateParam } from './Index';
import { UpdateParam } from './Update';

const useStyles = makeStyles((theme: Theme) => ({
  tag: {
    margin: theme.spacing(0.5),
  },
  chipFont: {
    color: '#fff',
    fontWeight: 'bold',
  },
  student: {
    background: '#009be5',
  },
  instructor: {
    background: '#ff8856',
  },
  admin: {
    background: '#ffaaff',
  },
  user: {
    background: '#999999',
  },
  avatar: {
    margin: theme.spacing(0.5),
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    fontSize: 'medium',
  },
}));

// Control<CreateParam>型にControl<UpdateParam>のオブジェクトを代入することはできない。
// Control<UpdateParam>型にControl<CreateParam>のオブジェクトを代入することはできない。
// Control<CreateParam | UpdateParam>にControl<UpdateParam>のオブジェクトを代入することはできない。
// Control<CreateParam>とControl<UpdateParam>の両方を受け付けるために、Control<TFieldValues>としておく。
// このままでは<Controller>のcontrolプロパティに割り当てられないため、下記のようにしているので、この部分にはCreateParamかUpdateParamのどちらかしか渡してはならない。
// control={control as unknown as Control<CreateParam | UpdateParam>}
// この方法で問題がないのは、本コンポーネントではCreateParamとUpdateParamに共通なプロパティだけを利用しているため。
interface Props<TFieldValues extends CreateParam | UpdateParam> {
  control: Control<TFieldValues>;
  onChange: (data: UserFragment[]) => void;
  defaultValue?: UserFragment[];
}

export const UserSelectBox = <T extends CreateParam | UpdateParam>({
  control,
  onChange,
  defaultValue,
  ..._props
}: Props<T>): JSX.Element => {
  const classes = useStyles();

  const [getUsers] = useGetUsersForAdminLazyQuery();
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<UserFragment[]>([]);
  const [options, setOptions] = useState<UserFragment[]>([]);

  const getUserName = useCallback((user: UserFragment): string => {
    if (user) {
      if (user.personalInfo) {
        return user.personalInfo.name;
      }

      return user.nickName;
    }

    return '';
  }, []);

  const getUserColor = useCallback(
    (option: UserFragment): string => {
      if (option?.isInstructor) {
        return classes.instructor;
      } else if (option?.isStudent) {
        return classes.student;
      } else if (option?.isAdmin) {
        return classes.admin;
      }

      return classes.user;
    },
    [classes.admin, classes.instructor, classes.student, classes.user],
  );

  const getUserAuthPrefix = useCallback((option: UserFragment): string => {
    if (option?.isInstructor) {
      return 'I';
    } else if (option?.isStudent) {
      return 'S';
    } else if (option?.isAdmin) {
      return 'A';
    }

    return 'U';
  }, []);

  const fetch = useMemo(
    () =>
      debounce(
        (request: { input: string }, callback: (results?: readonly UserFragment[]) => void) => {
          try {
            getUsers({
              variables: {
                input: {
                  limit: 20,
                  page: 1,
                  name: request.input,
                },
              },
            }).then((res) => callback(res.data?.usersForAdmin.items));
          } catch {
            // エラーをキャッチしても何も行わない
          }
        },
        400,
      ),
    [getUsers],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly UserFragment[]) => {
      if (active) {
        let newOptions: UserFragment[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [fetch, inputValue, value]);

  useEffect(() => {
    if (defaultValue) {
      setValue([...defaultValue]);
    }
  }, [defaultValue]);

  return (
    <Controller
      name="users"
      control={control as unknown as Control<CreateParam | UpdateParam>}
      render={({ field }) => (
        <Autocomplete
          {...field}
          multiple
          autoComplete
          filterSelectedOptions
          id="users"
          getOptionLabel={(option) => (typeof option === 'string' ? option : getUserName(option))}
          options={options}
          value={value}
          onChange={(event, value) => {
            setValue(value);

            onChange(value);
          }}
          noOptionsText="No users"
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Add a user"
              variant="outlined"
              inputProps={{
                ...params.inputProps,
                onKeyDown: (e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                  }
                },
              }}
            />
          )}
          renderOption={(option) => {
            const color = getUserColor(option);
            const prefix = getUserAuthPrefix(option);

            return (
              <>
                {`${option.id} ${getUserName(option)}`}
                <div className={classes.avatar}>
                  <Avatar className={`${classes.small} ${color}`}>{prefix}</Avatar>
                </div>
              </>
            );
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              const color = getUserColor(option);

              return (
                <div className={classes.tag}>
                  <Chip
                    {...getTagProps({ index })}
                    icon={<FaceIcon className={`${classes.chipFont} ${color}`} />}
                    label={getUserName(option)}
                    className={`${classes.chipFont} ${color}`}
                  />
                </div>
              );
            });
          }}
        />
      )}
    />
  );
};
