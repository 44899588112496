import React, { useState, useCallback } from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Box, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { getUTCDatetime } from '../../const/Util';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AdminClaimDetailInput,
  Valid,
  useGetClaimDetailForAdminQuery,
  useUpdateClaimDetailForAdminMutation,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { ClaimCategoryLabels } from '../../const/ClaimDetail';
import { ValidLabels, strToValid } from '../../const/Valid';
import { GeneralChangeEvent } from '../../../types/Common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export interface UpdateParam {
  claimId: number;
  claimCategoryId: number;
  date: string;
  courseId?: number;
  lessonId?: number;
  spotLessonId?: number;
  unitPrice: number;
  amount: number;
  additionalPayment: number;
  remark: string;
  status: number;
}

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ claim_detail_id: string }>().claim_detail_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminClaimDetailInput>({
    additionalPayment: undefined,
    amount: 0,
    claimCategoryID: 0,
    claimID: 0,
    courseID: undefined,
    date: '',
    lessonID: undefined,
    remark: undefined,
    spotLessonID: undefined,
    status: Valid.Valid,
    unitPrice: 0,
  });

  const { loading } = useGetClaimDetailForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ claimDetailForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        additionalPayment: data.additionalPayment,
        amount: data.amount ?? 0,
        claimCategoryID: data.claimCategory.id,
        claimID: data.claim.id,
        courseID: data.course?.id,
        date: data.date,
        lessonID: data.lesson?.id,
        spotLessonID: data.spotLesson?.id,
        unitPrice: data.unitPrice,
        remark: data.remark,
        status: data.status,
      });
    },
  });

  const [updateClaimDetail, { error: updateError }] = useUpdateClaimDetailForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateClaimDetail({
          variables: {
            id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateClaimDetail, updateInput, id, navigate]),
  );

  const handleChangeClaimID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({
      ...current,
      claimID: parseInt(value),
    }));
  }, []);

  const handleChangeClaimCategoryID = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        claimCategoryID: parseInt(String(value)),
      }));
    },
    [],
  );

  const handleChangeDate = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        date: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeCourseID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({
      ...current,
      courseID: parseInt(value),
    }));
  }, []);

  const handleChangeLessonID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({
      ...current,
      lessonID: parseInt(value),
    }));
  }, []);

  const handleChangeSpotLessonID = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({
        ...current,
        spotLessonID: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeUnitPrice = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({
      ...current,
      unitPrice: parseInt(value),
    }));
  }, []);

  const handleChangeAmount = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({
      ...current,
      amount: parseInt(value),
    }));
  }, []);

  const handleChangeAdditionalPayment = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({
        ...current,
        additionalPayment: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeRemark = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({
      ...current,
      remark: value,
    }));
  }, []);

  const handleChangeStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                ClaimDetail Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="claimId"
                name="claimID"
                label="ClaimId"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.claimID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeClaimID}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="claimCategoryId">ClaimCategory</InputLabel>
                <Select
                  value={updateInput.claimCategoryID}
                  onChange={handleChangeClaimCategoryID}
                  input={<Input name="claimCategoryId" id="claimCategoryId" />}
                >
                  {Object.entries(ClaimCategoryLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date"
                  label="Date"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput.date}
                  onChange={handleChangeDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="courseId"
                name="courseID"
                label="CourseId"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.courseID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeCourseID}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="lessonId"
                name="lessonID"
                label="LessonId"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.lessonID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeLessonID}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="spotLessonId"
                name="spotLessonID"
                label="SpotLessonId"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.spotLessonID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeSpotLessonID}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="unitPrice"
                name="unitPrice"
                label="UnitPrice"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.unitPrice}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeUnitPrice}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="amount"
                name="amount"
                label="Amount"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.amount}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAmount}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="additionalPayment"
                name="additionalPayment"
                label="AdditionalPayment"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.additionalPayment}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAdditionalPayment}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="remark"
                name="remark"
                label="Remark"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.remark}
                multiline={true}
                minRows={5}
                maxRows={Infinity}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeRemark}
              />
            </div>
            <div>
              <FormControl>
                <InputLabel htmlFor="status">Status</InputLabel>
                <Select
                  value={updateInput.status}
                  onChange={handleChangeStatus}
                  input={<Input name="status" id="status" />}
                >
                  {Object.entries(ValidLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
