import React, { useState, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, makeStyles } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  progresses,
  phaseLabels,
  lessonTypeLabels,
  completedLabels,
  studentAssistanceLabels,
  instructorAssistanceLabels,
} from '../../const/Lesson';
import { getUTCDatetime } from '../../const/Util';
import {
  useGetLessonForAdminQuery,
  useUpdateLessonForAdminMutation,
  useGetLessonAssistancesQuery,
  AdminLessonInput,
} from '../../../gen/graphql';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { GeneralChangeEvent } from '../../../types/Common';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputForm: {
    margin: '20px 0',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ lesson_id: string }>().lesson_id;
  const id = Number(paramID);

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // Form inputs
  const [updateInput, setUpdateInput] = useState<AdminLessonInput>({
    courseID: 0,
    instructorID: 0,
    title: '',
    phase: 0,
    completed: 0,
    type: 0,
    startAt: '',
    endAt: '',
    taughtSkill: '',
    homework: '',
    prepare: '',
    delayReason: '',
    progress: 0,
    lessonsAssistances: [],
    assistanceDetail: '',
    studentAssistance: [],
    instructorAssistance: [],
  });

  const { loading } = useGetLessonForAdminQuery({
    variables: {
      id: id,
    },
    onCompleted: ({ lessonForAdmin: data }) => {
      // 現在の値をフォームの初期値にセット
      if (!data) {
        return;
      }
      setUpdateInput({
        courseID: data.course?.id ?? 0,
        instructorID: data.instructor?.id ?? 0,
        title: data.title,
        phase: data.phase,
        completed: data.completed,
        type: data.type,
        startAt: data.startAt,
        endAt: data.endAt,
        taughtSkill: data.taughtSkill ?? '',
        homework: data.homework ?? '',
        prepare: data.prepare ?? '',
        delayReason: data.delayReason ?? '',
        progress: data.progress,
        lessonsAssistances: data.lessonsAssistances ? data.lessonsAssistances.map((l) => l.id) : [],
        assistanceDetail: data.assistanceDetail ?? '',
        studentAssistance: data.studentAssistance ?? [],
        instructorAssistance: data.instructorAssistance ?? [],
      });
    },
  });

  const { data: lessonsAssistancesData, loading: lessonsAssistancesLoading } =
    useGetLessonAssistancesQuery();
  const lessonsAssistances = lessonsAssistancesData?.lessonAssistances ?? [];

  const [updateLesson, { error: updateError }] = useUpdateLessonForAdminMutation();

  const handleClickSave = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);

      try {
        await updateLesson({
          variables: {
            lessonID: id,
            input: updateInput,
          },
        });
      } catch (e) {
        return;
      } finally {
        setShowLoader(false);
      }

      navigate(-1);
    }, [updateLesson, updateInput, id, navigate]),
  );

  const handleChangeAddTitle = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, title: value }));
  }, []);

  const handleChangeAddInstructorID = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({
        ...current,
        instructorID: parseInt(value),
      }));
    },
    [],
  );

  const handleChangeAddPhase = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({ ...current, phase: parseInt(String(value)) }));
    },
    [],
  );

  const handleChangeAddCompleted = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({ ...current, completed: parseInt(String(value)) }));
    },
    [],
  );

  const handleChangeAddType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({ ...current, type: parseInt(String(value)) }));
    },
    [],
  );

  const handleChangeAddStartAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        startAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeAddEndAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      setUpdateInput((current) => ({
        ...current,
        endAt: value ? getUTCDatetime(value) : '',
      }));
    },
    [],
  );

  const handleChangeAddTaughtSkill = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({ ...current, taughtSkill: value }));
    },
    [],
  );

  const handleChangeAddHomework = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, homework: value }));
  }, []);

  const handleChangeAddPrepare = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setUpdateInput((current) => ({ ...current, prepare: value }));
  }, []);

  const handleChangeAddProgress = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setUpdateInput((current) => ({ ...current, progress: parseInt(String(value)) }));
    },
    [],
  );

  const handleChangeAddDelayReason = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({ ...current, delayReason: value }));
    },
    [],
  );

  const handleChangeAddAssistanceDetail = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({ ...current, assistanceDetail: value }));
    },
    [],
  );

  // CheckBoxListへの保存処理
  // CheckOff（すでに存在している）：除外
  // CheckON(存在してない場合)：追加
  const addNumValueToCheckBoxList = (val: number, arry: number[]): number[] => {
    return arry.includes(val) ? arry.filter((a) => a !== val) : arry.concat(val);
  };

  const handleChangeAddLessonsAssistances = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({
        ...current,
        lessonsAssistances: addNumValueToCheckBoxList(parseInt(value), current.lessonsAssistances),
      }));
    },
    [],
  );

  const handleChangeAddInstructorAssistance = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({
        ...current,
        instructorAssistance: addNumValueToCheckBoxList(
          parseInt(value),
          current.instructorAssistance,
        ),
      }));
    },
    [],
  );

  const handleChangeAddStudentAssistance = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setUpdateInput((current) => ({
        ...current,
        studentAssistance: addNumValueToCheckBoxList(parseInt(value), current.studentAssistance),
      }));
    },
    [],
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Lesson Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading || lessonsAssistancesLoading || showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off">
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                name="title"
                label="Title"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.title}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddTitle}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="courseId"
                name="courseID"
                label="CourseId"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.courseID}
                fullWidth
                style={{ margin: '10px 0' }}
                disabled
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="instructorId"
                name="instructorID"
                label="InstructorId"
                type="number"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.instructorID}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddInstructorID}
                inputProps={{ min: 1 }}
              />
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="startAt"
                  label="開始時間"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput.startAt}
                  onChange={handleChangeAddStartAt}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  margin="normal"
                  id="endAt"
                  label="終了時間"
                  format="yyyy-MM-dd HH:mm"
                  value={updateInput.endAt}
                  onChange={handleChangeAddEndAt}
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <Grid>
              <FormControl>
                <InputLabel htmlFor="type">Type</InputLabel>
                <Select
                  value={updateInput.type}
                  onChange={handleChangeAddType}
                  input={<Input name="type" id="type" />}
                >
                  {Object.entries(lessonTypeLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="taughtSkill"
                name="taughtSkill"
                label="TaughtSkill"
                InputLabelProps={{
                  shrink: true,
                }}
                type="text"
                value={updateInput.taughtSkill}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddTaughtSkill}
                multiline={true}
                minRows={5}
                maxRows={Infinity}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="homework"
                name="homework"
                label="Homework"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.homework}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddHomework}
                multiline={true}
                minRows={5}
                maxRows={Infinity}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="prepare"
                name="prepare"
                label="Prepare"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.prepare}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddPrepare}
                multiline={true}
                minRows={5}
                maxRows={Infinity}
              />
            </div>
            <Grid>
              <FormControl>
                <InputLabel htmlFor="progress">Progress</InputLabel>
                <Select
                  value={updateInput.progress}
                  onChange={handleChangeAddProgress}
                  input={<Input name="progress" id="progress" />}
                >
                  {progresses.map((p) => (
                    <MenuItem value={p.id} key={p.id}>
                      {p.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="delayReason"
                name="delayReason"
                label="DelayReason"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.delayReason}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddDelayReason}
                multiline={true}
                minRows={5}
                maxRows={Infinity}
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="assistanceDetail"
                name="assistanceDetail"
                label="AssistanceDetail"
                type="text"
                placeholder="12"
                InputLabelProps={{
                  shrink: true,
                }}
                value={updateInput.assistanceDetail}
                fullWidth
                style={{ margin: '10px 0' }}
                onChange={handleChangeAddAssistanceDetail}
                multiline={true}
                minRows={5}
                maxRows={Infinity}
              />
            </div>
            <Grid>
              <FormControl>
                <InputLabel htmlFor="phase">Phase</InputLabel>
                <Select
                  value={updateInput.phase}
                  onChange={handleChangeAddPhase}
                  input={<Input name="phase" id="phase" />}
                >
                  {Object.entries(phaseLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel htmlFor="completed">Completed</InputLabel>
                <Select
                  value={updateInput.completed}
                  onChange={handleChangeAddCompleted}
                  input={<Input name="completed" id="completed" />}
                >
                  {Object.entries(completedLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <div>
              <FormControl component="fieldset">
                <FormLabel component="legend">Assistances</FormLabel>
                <FormGroup>
                  {lessonsAssistances.map((assistance) => {
                    return (
                      <FormControlLabel
                        key={assistance.id}
                        control={
                          <Checkbox
                            name="assistance"
                            onChange={handleChangeAddLessonsAssistances}
                            value={assistance.id}
                            checked={updateInput.lessonsAssistances?.includes(assistance.id)}
                          />
                        }
                        label={assistance.name}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
              <FormControl component="fieldset">
                <FormLabel component="legend">StudentAssistance</FormLabel>
                <FormGroup>
                  {studentAssistanceLabels.map((studentAssistanceLabel: string, index: number) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="studentAssistance"
                            onChange={handleChangeAddStudentAssistance}
                            value={index}
                            checked={updateInput.studentAssistance?.includes(index)}
                          />
                        }
                        label={studentAssistanceLabel}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
              <FormControl component="fieldset">
                <FormLabel component="legend">InstructorAssistance</FormLabel>
                <FormGroup>
                  {instructorAssistanceLabels.map(
                    (instructorAssistanceLabel: string, index: number) => {
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="instructorAssistance"
                              onChange={handleChangeAddInstructorAssistance}
                              value={index}
                              checked={updateInput.instructorAssistance?.includes(index)}
                            />
                          }
                          label={instructorAssistanceLabel}
                        />
                      );
                    },
                  )}
                </FormGroup>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                onClick={handleClickSave}
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
