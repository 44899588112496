import { Valid } from '../../gen/graphql';

export const ValidLabels = {
  [Valid.Invalid]: '無効',
  [Valid.Valid]: '有効',
} as const;

export const StatusValidLabels = {
  [Valid.Invalid]: '削除済み',
  [Valid.Valid]: '未削除',
} as const;

export const strToValid = (str: string): Valid | undefined => {
  return Object.values(Valid).find((v) => v === str);
};
