import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { useGetSpDiscountForAdminQuery } from '../../../gen/graphql';

import { makeStyles } from '@material-ui/styles';
import { AppBar, Paper, Grid, TableContainer, Toolbar } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

const useStyles = makeStyles(() => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const id = useParams<{ sp_discount_id: string }>().sp_discount_id ?? '0';

  const { data, loading } = useGetSpDiscountForAdminQuery({
    variables: { id },
  });

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                SpDiscount Detail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {loading && <LinearProgress />}
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Subscription
                  </TableCell>
                  <TableCell>
                    <Link to={`/subscriptions/${data?.spDiscountForAdmin?.subscription?.id}`}>
                      {data?.spDiscountForAdmin?.subscription?.id}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    User
                  </TableCell>
                  <TableCell>
                    <Link to={`/users/${data?.spDiscountForAdmin?.subscription?.user?.id}`}>
                      {data?.spDiscountForAdmin?.subscription?.user?.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdId
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.pvdID}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdCheckoutSession
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.pvdCheckoutSession}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdCustomer
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.pvdCustomer}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdStart
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.pvdStart}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdEnd
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.pvdEnd}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdInvoice
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.pvdInvoice}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdInvoiceItem
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.pvdInvoiceItem}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdPromotionCode
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.pvdPromotionCode}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    PvdSubscription
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.pvdSubscription}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{data?.spDiscountForAdmin?.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </main>
    </div>
  );
};
