import React, { useMemo, useState, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';

// Material UI
import { AppBar, Toolbar, Paper, Grid, Box, Theme, makeStyles } from '@material-ui/core';

// Material UI Dialog
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Original Component
import { ImageField } from '../../common/ImageField';

// Other
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  useGetCourseProgramsForAdminQuery,
  useAddCourseProgramForAdminMutation,
  AdminCourseProgramSearchInput,
  AdminCourseProgramInput,
  Valid,
  useUploadImagesMutation,
} from '../../../gen/graphql';
import { ValidLabels, strToValid } from '../../const/Valid';
import { GeneralChangeEvent } from '../../../types/Common';
import queryString from 'query-string';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  inputLabel: {
    martginTop: theme.spacing(2),
  },
}));

// 登録フォームの初期値
const addInputDefaultValues = {
  title: '',
  content: '',
  description: '',
  overview: '',
  image: '',
  number: 0,
  status: Valid.Valid,
};

const mdParser = new MarkdownIt();
type SearchFormInput = Omit<AdminCourseProgramSearchInput, 'limit' | 'page'>;

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  // リクエストパラメータから現在の検索パラメータを取得
  const currentSearchParams = useMemo((): AdminCourseProgramSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const status = params.get('status') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: id.length === 26 ? id : undefined,
      status: strToValid(status),
    };
  }, [location.search]);

  const { data, loading, refetch } = useGetCourseProgramsForAdminQuery({
    variables: {
      input: currentSearchParams,
    },
  });

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);
  const [addInput, setAddInput] = useState<AdminCourseProgramInput>(addInputDefaultValues);

  // Add Dialog
  const [open, showAddDialog] = React.useState(false);

  // データ登録
  const [uploadImages] = useUploadImagesMutation();
  const [addCourseProgram, { error: addError }] = useAddCourseProgramForAdminMutation();

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminCourseProgramSearchInput): void => {
      navigate({
        pathname: '/coursePrograms',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 再検索
  const refetchCoursePrograms = useCallback(
    async (params: AdminCourseProgramSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  const uploadImage = useCallback(
    async (image: File): Promise<string> => {
      try {
        const uploadedFiles = await uploadImages({
          variables: {
            files: [image],
          },
        });
        const files = uploadedFiles.data?.uploadFiles ?? [];
        return files[0].s3FilePath;
      } catch {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return '';
      }
    },
    [uploadImages],
  );

  const searchForm = useForm<SearchFormInput>({
    defaultValues: {
      id: currentSearchParams.id,
      status: currentSearchParams.status,
    },
  });

  // 検索ボタン押下
  const search = useCallback(
    async (input: SearchFormInput): Promise<void> => {
      const params = {
        ...input,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      // NOTE:
      // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
      // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchCoursePrograms(params);
      }
      createHistory(params);
    },
    [refetchCoursePrograms, createHistory, currentSearchParams],
  );

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  // データ作成ボタンクリック
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await addCourseProgram({
          variables: {
            input: addInput,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      // ダイアログを閉じて入力フォーム初期化
      showAddDialog(false);
      setAddInput(addInputDefaultValues);

      // 再検索
      refetchCoursePrograms(currentSearchParams);
    }, [addCourseProgram, refetchCoursePrograms, currentSearchParams, addInput]),
  );

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  const handleChangeAddTitle = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, title: value }));
  }, []);

  const handleChangeAddDescription = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({ ...current, description: value }));
    },
    [],
  );

  const handleChangeAddOverview = useCallback((data: { html: string; text: string }) => {
    setAddInput((current) => ({ ...current, overview: data.text }));
  }, []);

  const handleChangeAddContent = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({ ...current, content: value }));
  }, []);

  const handleChangeAddNumber = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      number: parseInt(value) ?? 0,
    }));
  }, []);

  const handleChangeAddStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({
        ...current,
        status: strToValid(String(value)) ?? Valid.Valid,
      }));
    },
    [],
  );

  const handleAddImageUploaded = (name: string, path: string) => {
    setAddInput((current) => ({
      ...current,
      [name]: path,
    }));
  };

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                CourseProgram
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || showLoader) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form onSubmit={searchForm.handleSubmit(search)}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <Controller
                      name="id"
                      control={searchForm.control}
                      render={({ field: { ref, ...rest } }) => (
                        <TextField
                          autoFocus
                          margin="dense"
                          id="id"
                          label="ID"
                          type="text"
                          fullWidth
                          inputRef={ref}
                          {...rest}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Controller
                        name="status"
                        control={searchForm.control}
                        render={({ field: { ref, value, ...rest } }) => (
                          <Select inputRef={ref} value={value ?? ''} {...rest}>
                            <MenuItem value={undefined}>&nbsp;</MenuItem>
                            {Object.entries(ValidLabels).map(([value, label]) => (
                              <MenuItem value={value} key={value}>
                                {label}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="submit">
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">Title</TableCell>
                  <TableCell align="right">Description</TableCell>
                  <TableCell align="right">Overview</TableCell>
                  <TableCell align="right">Content</TableCell>
                  <TableCell align="right">Number</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.courseProgramsForAdmin?.items?.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/coursePrograms/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">{row.title}</TableCell>
                    <TableCell align="right">{row.description}</TableCell>
                    <TableCell align="right">{row.overview.substring(0, 20)}...</TableCell>
                    <TableCell align="right">{row.content.substring(0, 20)}...</TableCell>
                    <TableCell align="right">{row.number}</TableCell>
                    <TableCell align="right">{ValidLabels[row.status]}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={data?.courseProgramsForAdmin?.total ?? 0}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <TextField
            autoFocus
            margin="dense"
            id="title"
            name="title"
            label="Title"
            type="text"
            defaultValue={addInput.title}
            fullWidth
            onChange={handleChangeAddTitle}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            name="description"
            label="Description"
            type="text"
            defaultValue={addInput.description}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
            fullWidth
            onChange={handleChangeAddDescription}
          />
          <Grid>
            <InputLabel>Overview</InputLabel>
            <MdEditor
              style={{ height: '400px' }}
              renderHTML={(text) => mdParser.render(text)}
              onChange={handleChangeAddOverview}
              onImageUpload={uploadImage}
            />
          </Grid>
          <TextField
            autoFocus
            margin="dense"
            id="content"
            name="content"
            label="Content"
            type="text"
            defaultValue={addInput.content}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
            fullWidth
            onChange={handleChangeAddContent}
          />
          <ImageField
            name="image"
            path={addInput.image ?? ''}
            width={600}
            height={300}
            onImageUploaded={handleAddImageUploaded}
            setShowLoader={setShowLoader}
          />
          <TextField
            autoFocus
            margin="dense"
            id="number"
            name="number"
            label="Number"
            type="number"
            defaultValue={addInput.number}
            fullWidth
            onChange={handleChangeAddNumber}
            inputProps={{ min: 0 }}
          />
          <Grid>
            <FormControl>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select
                value={addInput.status}
                onChange={handleChangeAddStatus}
                input={<Input name="status" id="status" />}
              >
                {Object.entries(ValidLabels).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
