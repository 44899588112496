import React, { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Paper, Grid, Box, FormHelperText, makeStyles } from '@material-ui/core';

// Material UI Form
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import {
  AdminInstructorInput,
  useGetInstructorQuery,
  useUpdateInstructorMutation,
} from '../../../gen/graphql';
import { instructorSchema } from '../../../formSchema/instructor';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { StatusLabels } from '../../const/Instructor';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    margin: '24px 0',
    padding: theme.spacing(4),
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
}));

export interface InstructorInput {
  firstKanaName: string;
  firstName: string;
  googleMeetURL?: string | null;
  lastKanaName: string;
  lastName: string;
  zoomUserID?: string | null;
}

export const Update = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ instructor_id: string }>().instructor_id;
  const id = Number(paramID);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<InstructorInput>({
    resolver: yupResolver(instructorSchema),
    defaultValues: {
      lastName: '',
      firstName: '',
      lastKanaName: '',
      firstKanaName: '',
      zoomUserID: '',
      googleMeetURL: '',
    },
  });

  const { data, loading } = useGetInstructorQuery({
    variables: { id },
    onCompleted: ({ instructorForAdmin: instructor }) => {
      // 現在の値をフォームの初期値にセット
      if (!instructor) {
        return;
      }
      reset({
        lastName: instructor.personalInfo?.lastName ?? '',
        firstName: instructor.personalInfo?.firstName ?? '',
        lastKanaName: instructor.personalInfo?.lastKanaName ?? '',
        firstKanaName: instructor.personalInfo?.firstKanaName ?? '',
        zoomUserID: instructor.zoomUserID ?? '',
        googleMeetURL: instructor.googleMeetURL ?? '',
      });
    },
  });
  const instructor = data?.instructorForAdmin;

  const [updateInstructorMutation, { error: updateError, loading: updating }] =
    useUpdateInstructorMutation();

  const updateInstructor = useSafeAsyncCallback(
    useCallback(
      async (instructorInput: InstructorInput): Promise<void> => {
        const adminInstructorInput: AdminInstructorInput = {
          lastName: instructorInput.lastName ?? '',
          firstName: instructorInput.firstName ?? '',
          lastKanaName: instructorInput.lastKanaName ?? '',
          firstKanaName: instructorInput.firstKanaName ?? '',
          zoomUserID: instructorInput.zoomUserID ?? '',
          googleMeetURL: instructorInput.googleMeetURL ?? '',
        };
        try {
          await updateInstructorMutation({ variables: { id, input: adminInstructorInput } });
        } catch {
          return;
        }

        navigate(-1);
      },
      [navigate, updateInstructorMutation, id],
    ),
  );

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                Instructor Edit
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {(loading || updating) && <LinearProgress />}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid>
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
        </Grid>
        <Paper className={classes.paperContent}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(updateInstructor)}>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="scInstructorID"
                name="scInstructorID"
                label="ScInstructorID ※SFからの連携項目のため、こちらでは変更できません。"
                type="text"
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
                value={instructor?.scInstructorID ?? ''}
                fullWidth
                style={{ margin: '10px 0' }}
                disabled
              />
            </div>
            <div>
              <TextField
                autoFocus
                margin="dense"
                id="confirmChannelID"
                name="confirmChannelID"
                label="ConfirmChannelID ※SFからの連携項目のため、こちらでは変更できません。"
                type="text"
                placeholder=""
                InputLabelProps={{
                  shrink: true,
                }}
                value={instructor?.confirmChannelID ?? ''}
                fullWidth
                style={{ margin: '10px 0' }}
                disabled
              />
            </div>
            <div>
              <Controller
                name="lastName"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="lastName"
                    label="LastName"
                    type="text"
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="firstName"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="firstName"
                    label="FirstName"
                    type="text"
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.firstKanaName}
                    helperText={errors.firstKanaName?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="lastKanaName"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="lastKanaName"
                    label="LastKanaName"
                    type="text"
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.lastKanaName}
                    helperText={errors.lastKanaName?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="firstKanaName"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="firstKanaName"
                    label="FirstKanaName"
                    type="text"
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.firstKanaName}
                    helperText={errors.firstKanaName?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="zoomUserID"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="zoomUserID"
                    label="zoomUserID"
                    type="text"
                    placeholder=""
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.zoomUserID}
                    helperText={errors.zoomUserID?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <Controller
                name="googleMeetURL"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="googleMeetURL"
                    label="GoogleMeetURL"
                    type="text"
                    placeholder="https://meet.google.com/xxx-xxx-xxx"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{ margin: '10px 0' }}
                    error={!!errors.googleMeetURL}
                    helperText={errors.googleMeetURL?.message}
                    inputRef={ref}
                    {...rest}
                  />
                )}
              />
            </div>
            <div>
              <FormControl>
                <FormHelperText>
                  Status ※SFからの連携項目のため、こちらでは変更できません。
                </FormHelperText>
                <Select
                  name="status"
                  defaultValue={instructor?.status ?? ''}
                  value={instructor?.status ?? ''}
                  input={<Input name="status" id="status" />}
                  disabled
                >
                  {Object.entries(StatusLabels).map(([value, label]) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {updateError?.graphQLErrors && (
              <Box className={classes.inputError}>
                <Typography style={{ color: '#ff0000' }}>
                  Oops, following errors occurred...
                </Typography>
                {updateError?.graphQLErrors.map((error, index) => (
                  <div key={index}>{error.message}</div>
                ))}
              </Box>
            )}
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: '20px 0 0 0' }}
                type="submit"
              >
                Save
              </Button>
            </div>
          </form>
        </Paper>
      </main>
    </div>
  );
};
