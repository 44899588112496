import { SpotLessonCancelType, SpotLessonPhase } from '../../gen/graphql';

export const CancelTypeLabels: { [index: string]: string } = {
  [SpotLessonCancelType.Student]: '受講生',
  [SpotLessonCancelType.Instructor]: '講師',
} as const;

export const PhaseLabels: { [index: string]: string } = {
  [SpotLessonPhase.Incomplete]: '未完了',
  [SpotLessonPhase.Complete]: '完了',
  [SpotLessonPhase.Cancel]: 'キャンセル',
  [SpotLessonPhase.NonAttendance]: '不参加',
} as const;
