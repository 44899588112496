import React from 'react';

// Material UI
import { Theme } from '@material-ui/core/styles';
import { AppBar, Grid, makeStyles, Paper, Toolbar } from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// Material UI Other
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

// Other
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetInstructorScheduleForAdminQuery } from '../../../gen/graphql';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  paperContent: {
    flex: 1,
    margin: '24px 0',
  },
}));

export const Detail = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();

  const paramID = useParams<{ instructor_schedule_id: string }>().instructor_schedule_id;
  const id = Number(paramID);

  const { data, loading } = useGetInstructorScheduleForAdminQuery({
    variables: { id },
  });
  const instructorSchedule = data?.instructorScheduleForAdmin;

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                InstructorSchedulesDetail
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
        {loading ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container justifyContent="space-between">
          <Button onClick={() => navigate(-1)} variant="contained">
            ＜ Back
          </Button>
          <Link to={`/instructorSchedules/${id}/update`}>
            <Button variant="contained">Edit</Button>
          </Link>
        </Grid>
        {instructorSchedule && (
          <Paper className={classes.paperContent}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>項目</TableCell>
                  <TableCell>値</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    ID
                  </TableCell>
                  <TableCell>{instructorSchedule.id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Instructor
                  </TableCell>
                  <TableCell>
                    <Link to={`/instructors/${instructorSchedule.instructor?.id}`}>
                      {instructorSchedule.user?.personalInfo?.name}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    StartAt
                  </TableCell>
                  <TableCell>{instructorSchedule.startAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    EndAt
                  </TableCell>
                  <TableCell>{instructorSchedule.endAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Status
                  </TableCell>
                  <TableCell>{instructorSchedule.status ? '有効' : '無効'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    CreatedAt
                  </TableCell>
                  <TableCell>{instructorSchedule.createdAt}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    UpdatedAt
                  </TableCell>
                  <TableCell>{instructorSchedule.updatedAt}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Paper>
        )}
      </main>
    </div>
  );
};
