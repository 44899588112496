import React from 'react';
import { CommonProvider } from './context/CommonProvider';
import { AuthorizedProvider } from './context/AuthorizedProvider';
import { Layout } from './components/Layout';
import { Login } from './components/auth/Login';
import { ChangePassword } from './components/auth/ChangePassword';
import { ChangePasswordVerification } from './components/auth/ChangePasswordVerification';
import { ChangeTemporaryPassword } from './components/auth/ChangeTemporaryPassword';
import { Authentication } from './components/common/Authentication';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <CommonProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/changePasswordVerification" element={<ChangePasswordVerification />} />
          <Route path="/changeTemporaryPassword" element={<ChangeTemporaryPassword />} />
          <Route
            path="/*"
            element={
              <Authentication>
                <AuthorizedProvider>
                  <Layout />
                </AuthorizedProvider>
              </Authentication>
            }
          />
        </Routes>
      </CommonProvider>
    </BrowserRouter>
  );
};

export default App;
