import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
  mainContent: {
    flex: 1,
    padding: '48px 36px 48px',
    background: '#eaeff1',
  },
}));

export const Top = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                侍テラコヤ
              </Typography>
            </Grid>
            <Grid item>
              {/* <Tooltip title="Help">
                <IconButton color="inherit">
                  <HelpIcon />
                </IconButton>
              </Tooltip> */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Tabs value={0} textColor="inherit">
          <Tab textColor="inherit" label="KPIs" />
          {/* <Tab textColor="inherit" label="Sign-in method" />
          <Tab textColor="inherit" label="Templates" />
          <Tab textColor="inherit" label="Usage" /> */}
        </Tabs>
      </AppBar>
      <main className={classes.mainContent}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  LMS利用状況
                </Typography>
                <Typography color="textSecondary">
                  Google Analyticsから取得した受講生の利用状況
                </Typography>
                <Typography variant="body2" component="p">
                  <br />
                  - 未取得 - <br />
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Google Analyticsへ</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  質問対応速度
                </Typography>
                <Typography color="textSecondary">質問への平均回答時間</Typography>
                <Typography variant="body2" component="p">
                  <br />
                  - 未取得 - <br />
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">質問一覧へ</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  質問解決率
                </Typography>
                <Typography color="textSecondary">質問の解決率</Typography>
                <Typography variant="body2" component="p">
                  <br />
                  - 未取得 - <br />
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">質問一覧へ</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  受講中の受講生
                </Typography>
                <Typography color="textSecondary">現在受講ステータスの受講生数</Typography>
                <Typography variant="body2" component="p">
                  <br />
                  - 未取得 - <br />
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">受講生一覧へ</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  入塾受講生数
                </Typography>
                <Typography color="textSecondary">今月入塾した受講生の数</Typography>
                <Typography variant="body2" component="p">
                  <br />
                  - 未取得 - <br />
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">受講生一覧へ</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  卒業受講生数
                </Typography>
                <Typography color="textSecondary">今月卒業した受講生の数</Typography>
                <Typography variant="body2" component="p">
                  <br />
                  - 未取得 - <br />
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">受講生一覧へ</Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2">
                  稼働可能インスト
                </Typography>
                <Typography color="textSecondary">稼働可能インストラクター数</Typography>
                <Typography variant="body2" component="p">
                  <br />
                  - 未取得 - <br />
                  <br />
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">インスト一覧へ</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </main>
    </div>
  );
};
