import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Material UI
import { Theme } from '@material-ui/core/styles';
import {
  AppBar,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  Toolbar,
  makeStyles,
} from '@material-ui/core';

// Material UI Table
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

// Material UI Expansion Panel
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';

// Material UI Form
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

// Material UI Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';

// Material UI Other
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';

// Material UI Date
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

// Other
import { Link } from 'react-router-dom';
import queryString from 'query-string';

// fp-ts
import { CsvRepositoryOnAPI } from '../../../infrastructure/csv/CsvRepositoryOnAPI';

import * as Sentry from '@sentry/browser';
import {
  AdminSpotLessonInput,
  useAddSpotLessonForAdminMutation,
  useGetSpotLessonCategoriesForAdminQuery,
  useGetSpotLessonInstructorsByTimeForAdminLazyQuery,
  useGetSpotLessonsForAdminQuery,
  AdminSpotLessonSearchInput,
  SpotLessonCancelType,
  SpotLessonPhase,
  Valid,
} from '../../../gen/graphql';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { GeneralChangeEvent } from '../../../types/Common';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { ValidLabels, strToValid } from '../../const/Valid';
import { CancelTypeLabels, PhaseLabels } from '../../const/SpotLesson';
import { getUTCDatetime } from '../../const/Util';
import { strToEnum } from '../../../utils/common';

// 一度にフェッチするレコード数
const FETCH_ROWS_LIMIT = 25;

// 登録フォームの初期値
const addInputDefaultValues = {
  categories: [],
  instructorUserID: 0,
  researchContent: '',
  startAt: '',
  troubleContent: '',
  userID: 0,
};

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: '24px auto',
    overflow: 'hidden',
  },
  searchBar: {
    padding: '12px 24px',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addButton: {
    marginRight: theme.spacing(1),
  },
  searchButton: {
    margin: theme.spacing(1),
  },
  mainContent: {
    flex: 1,
    padding: '24px 36px 48px',
    background: '#eaeff1',
  },
  inputError: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    padding: '12px 12px',
    background: '#f8d7da',
  },
  csvBtn: {
    position: 'fixed',
    bottom: '10px',
    right: '10px',
    zIndex: 99,
    backgroundColor: '#009be5',
    color: 'white',
    padding: '10px 13px',
    textDecoration: 'none',
    borderRadius: '10px',
    boxShadow: '3px 3px 4px black',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
}));

export const Index = (): JSX.Element => {
  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const currentSearchParams = useMemo((): AdminSpotLessonSearchInput => {
    const params = new URLSearchParams(location.search);
    const limit = params.get('limit') ?? '';
    const page = params.get('page') ?? '';
    const id = params.get('id') ?? '';
    const userID = params.get('userID') ?? '';
    const instructorUserID = params.get('instructorUserID') ?? '';
    const cancelType = params.get('cancelType') ?? '';
    const URL = params.get('URL') ?? '';
    const startAt = params.get('startAt') ?? '';
    const endAt = params.get('endAt') ?? '';
    const canceledAt = params.get('canceledAt') ?? '';
    const status = params.get('status') ?? '';
    const phases = params.getAll('phases') ?? '';

    return {
      limit: parseInt(limit) > 0 ? parseInt(limit) : FETCH_ROWS_LIMIT,
      page: parseInt(page) > 0 ? parseInt(page) : 1, // 未指定の場合は1ページ目から表示する
      id: parseInt(id) > 0 ? parseInt(id) : undefined,
      userID: parseInt(userID) > 0 ? parseInt(userID) : undefined,
      instructorUserID: parseInt(instructorUserID) > 0 ? parseInt(instructorUserID) : undefined,
      cancelType: strToEnum(cancelType, SpotLessonCancelType),
      URL: URL !== '' ? URL : undefined,
      startAt: startAt !== '' ? startAt : undefined,
      endAt: endAt !== '' ? endAt : undefined,
      canceledAt: canceledAt !== '' ? canceledAt : undefined,
      status: strToValid(status),
      phases: phases
        ?.map((p) => strToEnum(p, SpotLessonPhase))
        .filter((v) => v !== undefined) as SpotLessonPhase[],
    };
  }, [location.search]);
  // OnChangeのタイミングでText→Split→number[]の変換をすると表示等が面倒なので、
  // Phasesは一旦値を保持し、検索実行時にSplitして配列に変換してリクエストを送信する。
  const [searchPhases, setSearchPhases] = React.useState('');

  const csvRepository = new CsvRepositoryOnAPI();

  // Loading
  const [showLoader, setShowLoader] = React.useState(false);

  // Add Dialog
  const [open, showAddDialog] = React.useState(false);
  const [addInput, setAddInput] = React.useState<AdminSpotLessonInput>(addInputDefaultValues);

  // Search form inputs
  const [searchInput, setSearchInput] =
    React.useState<Omit<AdminSpotLessonSearchInput, 'limit' | 'page'>>(currentSearchParams);

  const zeroPadding = (num: number) => `0${num}`.substr(-2);

  const { data: spotLessonData, refetch } = useGetSpotLessonsForAdminQuery({
    variables: { input: currentSearchParams },
  });
  const spotLessons = spotLessonData?.spotLessonsForAdmin.items ?? [];
  const total = spotLessonData?.spotLessonsForAdmin.total ?? 0;

  const { data: categoriesData } = useGetSpotLessonCategoriesForAdminQuery();
  const categories = categoriesData?.spotLessonCategoriesForAdmin ?? [];

  const [getInstructors, { data: instructorsData }] =
    useGetSpotLessonInstructorsByTimeForAdminLazyQuery();
  const instructors = useMemo(() => {
    return addInput.startAt ? (instructorsData?.spotLessonInstructorsByTimeForAdmin ?? []) : [];
  }, [addInput.startAt, instructorsData?.spotLessonInstructorsByTimeForAdmin]);
  const fetchInstructors = useCallback(
    (startAt: string) => {
      getInstructors({
        variables: {
          startAt: startAt,
        },
      });
    },
    [getInstructors],
  );

  const [addSpotLesson, { error: addError }] = useAddSpotLessonForAdminMutation();

  // 検索条件からURLを作成して遷移
  const createHistory = useCallback(
    (params: AdminSpotLessonSearchInput): void => {
      navigate({
        pathname: '/spotLessons',
        search: queryString.stringify(params),
      });
    },
    [navigate],
  );

  // 再検索
  const refetchSpotLessons = useCallback(
    async (params: AdminSpotLessonSearchInput): Promise<void> => {
      setShowLoader(true);
      try {
        await refetch({ input: params });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }
    },
    [refetch],
  );

  const formatStartAt = useCallback((date: Date) => {
    if (!date) return '';
    const d = new Date(date);
    return (
      d.getFullYear() +
      '-' +
      zeroPadding(d.getMonth() + 1) +
      '-' +
      zeroPadding(d.getDate()) +
      'T' +
      '00:00:00+09:00'
    );
  }, []);

  const formatEndAt = useCallback((date: Date) => {
    if (!date) return '';
    const d = new Date(date);
    return (
      d.getFullYear() +
      '-' +
      zeroPadding(d.getMonth() + 1) +
      '-' +
      zeroPadding(d.getDate()) +
      'T' +
      '23:59:59+09:00'
    );
  }, []);

  const handleStartAtChange = useCallback(
    (date: MaterialUiPickersDate): void => {
      setSearchInput((current) => ({
        ...current,
        startAt: date ? formatStartAt(date) : undefined,
      }));
    },
    [formatStartAt],
  );

  const handleEndAtChange = useCallback(
    (date: MaterialUiPickersDate): void => {
      setSearchInput((current) => ({
        ...current,
        endAt: date ? formatEndAt(date) : undefined,
      }));
    },
    [formatEndAt],
  );

  const handleChangeSearchId = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setSearchInput((current) => ({
      ...current,
      id: parseInt(value) > 0 ? parseInt(value) : undefined,
    }));
  }, []);

  const handleChangeSearchInstructorUserID = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setSearchInput((current) => ({
        ...current,
        instructorUserID: parseInt(value) > 0 ? parseInt(value) : undefined,
      }));
    },
    [],
  );

  const handleChangeSearchUserID = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setSearchInput((current) => ({
        ...current,
        userID: parseInt(value) > 0 ? parseInt(value) : undefined,
      }));
    },
    [],
  );

  const handleCanceledAtChange = useCallback(
    (date: MaterialUiPickersDate): void => {
      setSearchInput((current) => ({
        ...current,
        canceledAt: date ? formatStartAt(date) : undefined,
      }));
    },
    [formatStartAt],
  );

  const handleChangeSearchURL = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setSearchInput((current) => ({ ...current, URL: value ?? undefined }));
  }, []);

  const handleChangeSearchCancelType = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setSearchInput((current) => ({
        ...current,
        cancelType: value ? strToEnum(String(value), SpotLessonCancelType) : undefined,
      }));
    },
    [],
  );

  const handleChangeSearchPhases = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setSearchPhases(value);
    },
    [],
  );

  const handleChangeSearchStatus = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setSearchInput((current) => ({ ...current, status: strToValid(String(value)) }));
    },
    [],
  );

  // 検索ボタン押下
  const handleClickSearch = useCallback(async (): Promise<void> => {
    const phases = searchPhases
      ? (searchPhases
          .split(',')
          .map((p) => strToEnum(p, SpotLessonPhase))
          .filter((v) => v !== undefined) as SpotLessonPhase[])
      : [];

    const params = {
      ...searchInput,
      phases: phases,
      limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
      page: 1,
    };
    // NOTE:
    // 検索前後でURLが変わればhooksで勝手にデータを再取得してくれるが、変わらない場合はrefetchを呼び出して再取得
    // 無条件にrefetchを呼んでしまうと同じqueryが2回連続で走る場合がある
    if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
      await refetchSpotLessons(params);
    }
    createHistory(params);
  }, [searchPhases, searchInput, currentSearchParams, createHistory, refetchSpotLessons]);

  // 一覧表示件数変更
  const changePerPage = useCallback(
    (perPage: number): void => {
      createHistory({ ...currentSearchParams, limit: perPage, page: 1 });
    },
    [currentSearchParams, createHistory],
  );

  // 表示ページ変更
  const changePage = useCallback(
    (page: number): void => {
      // Material UI は page=0 はじまり, LMS API は page=1 はじまり
      createHistory({ ...currentSearchParams, page: page + 1 });
    },
    [currentSearchParams, createHistory],
  );

  function handleAddDialogOpen() {
    showAddDialog(true);
  }

  function handleAddDialogClose() {
    showAddDialog(false);
  }

  const handleChangeAddStartAt = useCallback(
    (date: MaterialUiPickersDate, value?: string | null | undefined): void => {
      const startAt = value ? getUTCDatetime(value) : '';

      setAddInput((current) => ({
        ...current,
        startAt: startAt,
        // インストラクターの入力値を初期化
        instructorUserID: 0,
      }));

      if (!startAt) return;
      fetchInstructors(startAt);
    },
    [fetchInstructors],
  );

  const handleChangeAddInstructorUserID = useCallback(
    ({ target: { value } }: GeneralChangeEvent<unknown>): void => {
      setAddInput((current) => ({ ...current, instructorUserID: parseInt(String(value)) }));
    },
    [],
  );

  const handleChangeAddUserID = useCallback(({ target: { value } }: GeneralChangeEvent): void => {
    setAddInput((current) => ({
      ...current,
      userID: parseInt(value),
    }));
  }, []);

  // CheckBoxListへの保存処理
  // CheckOff（すでに存在している）：除外
  // CheckON(存在してない場合)：追加
  const addNumValueToCheckBoxList = (val: number, array: number[]): number[] => {
    return array.includes(val) ? array.filter((a) => a !== val) : array.concat(val);
  };

  const handleChangeAddCategories = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({
        ...current,
        categories: addNumValueToCheckBoxList(parseInt(value), current.categories),
      }));
    },
    [],
  );

  const handleChangeAddTroubleContent = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({ ...current, troubleContent: value }));
    },
    [],
  );

  const handleChangeAddResearchContent = useCallback(
    ({ target: { value } }: GeneralChangeEvent): void => {
      setAddInput((current) => ({ ...current, researchContent: value }));
    },
    [],
  );

  const checkedCategories = useCallback(
    (categoryID: number): boolean => {
      return !!addInput.categories.find((category) => category === categoryID);
    },
    [addInput.categories],
  );

  const disabledCategories = useCallback(
    (categoryID: number): boolean => {
      return addInput.categories.length >= 2 && !checkedCategories(categoryID);
    },
    [addInput.categories, checkedCategories],
  );

  const disabledCondition = useMemo(
    (): boolean =>
      addInput.startAt.trim().length === 0 ||
      !addInput.instructorUserID ||
      !addInput.userID ||
      addInput.categories.length === 0 ||
      addInput.categories.length > 2 ||
      addInput.troubleContent.trim().length === 0 ||
      addInput.researchContent.trim().length === 0,
    [
      addInput.startAt,
      addInput.instructorUserID,
      addInput.userID,
      addInput.categories,
      addInput.troubleContent,
      addInput.researchContent,
    ],
  );

  // データ作成ボタンクリック
  const handleClickAdd = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      setShowLoader(true);
      try {
        await addSpotLesson({
          variables: {
            input: addInput,
          },
        });
      } catch (e) {
        // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
        return;
      } finally {
        setShowLoader(false);
      }

      // ダイアログを閉じて入力フォーム初期化
      showAddDialog(false);
      setAddInput(addInputDefaultValues);

      // 再検索
      const params = {
        ...searchInput,
        limit: currentSearchParams.limit, // limitは現在の検索条件を引き継ぐ
        page: 1,
      };
      if (queryString.stringify(params) === queryString.stringify(currentSearchParams)) {
        await refetchSpotLessons(params);
      }
    }, [addInput, addSpotLesson, currentSearchParams, refetchSpotLessons, searchInput]),
  );

  const csvFileDownload = () => {
    const query = queryString.stringify({
      id: currentSearchParams.id,
      userId: currentSearchParams.userID,
      instructorId: currentSearchParams.instructorUserID,
      cancelType:
        currentSearchParams.cancelType === SpotLessonCancelType.Instructor
          ? '1'
          : currentSearchParams.cancelType === SpotLessonCancelType.Student
            ? '0'
            : '',
      zoomUrl: currentSearchParams.URL,
      phases: currentSearchParams.phases,
      status:
        currentSearchParams.status === Valid.Valid
          ? true
          : currentSearchParams.status === Valid.Invalid
            ? false
            : '',
      startAt: currentSearchParams.startAt,
      endAt: currentSearchParams.endAt,
      canceledAt: currentSearchParams.canceledAt,
      // 検索では利用しないがSwaggerの型変換で怒られるため設定
      limit: currentSearchParams.limit,
      page: currentSearchParams.page,
    });
    const url = `/api/v1/admin/csv/spot_lessons?${query}`;

    csvRepository.getCsv('spot_lesson', url).catch((error) => {
      Sentry.captureException(error);
    });
  };

  return (
    <div>
      <AppBar component="div" color="primary" position="static" elevation={0}>
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs>
              <Typography color="inherit" variant="h5" component="h1">
                SpotLessons
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="inherit" onClick={handleAddDialogOpen}>
                Create Record
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
        {showLoader ? <LinearProgress /> : ''}
      </AppBar>
      <main className={classes.mainContent}>
        <Paper className={classes.paper}>
          <form>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >
                <div>
                  <Typography>検索</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="id"
                      name="id"
                      label="id"
                      type="number"
                      fullWidth
                      value={searchInput.id}
                      onChange={handleChangeSearchId}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="instructorUserID"
                      name="instructorUserID"
                      label="instructorUserID"
                      type="number"
                      fullWidth
                      value={searchInput.instructorUserID}
                      onChange={handleChangeSearchInstructorUserID}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="userId"
                      name="userID"
                      label="userId"
                      type="number"
                      fullWidth
                      value={searchInput.userID}
                      onChange={handleChangeSearchUserID}
                      inputProps={{ min: 1 }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk={true}
                        margin="normal"
                        id="startAt"
                        label="startAt"
                        format="yyyy/MM/dd"
                        value={searchInput.startAt ? searchInput.startAt : null}
                        onChange={handleStartAtChange}
                        maxDate={searchInput.endAt}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="endAt"
                        label="endAt"
                        format="yyyy/MM/dd"
                        value={searchInput.endAt ? searchInput.endAt : null}
                        onChange={handleEndAtChange}
                        minDate={searchInput.startAt}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="canceledAt"
                        label="canceledAt"
                        format="yyyy/MM/dd"
                        value={searchInput.canceledAt ? searchInput.canceledAt : null}
                        onChange={handleCanceledAtChange}
                        fullWidth
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="URL"
                      name="URL"
                      label="URL"
                      type="string"
                      fullWidth
                      value={searchInput.URL}
                      onChange={handleChangeSearchURL}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="cancelType">CancelType</InputLabel>
                      <Select
                        value={searchInput.cancelType}
                        onChange={handleChangeSearchCancelType}
                        input={<Input name="cancelType" id="cancelType" />}
                      >
                        <MenuItem value={undefined}>未選択</MenuItem>
                        {Object.entries(CancelTypeLabels).map(([value, label]) => (
                          <MenuItem value={value} key={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="phases"
                      name="phases"
                      label="phases ※,区切りで複数指定可"
                      type="string"
                      fullWidth
                      value={searchPhases}
                      onChange={handleChangeSearchPhases}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth>
                      <InputLabel htmlFor="status">Status</InputLabel>
                      <Select
                        value={searchInput.status}
                        onChange={handleChangeSearchStatus}
                        input={<Input name="status" id="status" />}
                      >
                        <MenuItem value={undefined}>未選択</MenuItem>
                        {Object.entries(ValidLabels).map(([value, label]) => (
                          <MenuItem value={value} key={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </AccordionDetails>
              <Divider light />
              <AccordionActions>
                <Button size="small" color="primary" type="button" onClick={handleClickSearch}>
                  <SearchIcon className={classes.block} />
                  Search
                </Button>
              </AccordionActions>
            </Accordion>
          </form>
        </Paper>
        <Paper className={classes.paper}>
          <Grid style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell align="right">InstructorUserName</TableCell>
                  <TableCell align="right">UserName</TableCell>
                  <TableCell align="right">StartAt</TableCell>
                  <TableCell align="right">EndAt</TableCell>
                  <TableCell align="right">Phase</TableCell>
                  <TableCell align="right">URL</TableCell>
                  <TableCell align="right">ResearchContent</TableCell>
                  <TableCell align="right">TroubleContent</TableCell>
                  <TableCell align="right">CancelType</TableCell>
                  <TableCell align="right">CanceledAt</TableCell>
                  <TableCell align="right">Status</TableCell>
                  <TableCell align="right">CreatedAt</TableCell>
                  <TableCell align="right">UpdatedAt</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spotLessons.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      <Link to={`/spotLessons/${row.id}`}>{row.id}</Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/users/${row.instructorUser?.id}`}>
                        {row.instructorUser?.personalInfo?.name}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <Link to={`/users/${row.user?.id}`}>{row.user?.personalInfo?.name}</Link>
                    </TableCell>
                    <TableCell align="right">{row.startAt}</TableCell>
                    <TableCell align="right">{row.endAt}</TableCell>
                    <TableCell align="right">
                      {row.phase !== undefined && row.phase !== null ? PhaseLabels[row.phase] : ''}
                    </TableCell>
                    <TableCell align="right">{row.zoomURL}</TableCell>
                    <TableCell align="right">{row.researchContent}</TableCell>
                    <TableCell align="right">{row.troubleContent}</TableCell>
                    <TableCell align="right">
                      {row.cancelType !== undefined && row.cancelType !== null
                        ? CancelTypeLabels[row.cancelType]
                        : ''}
                    </TableCell>
                    <TableCell align="right">{row.canceledAt}</TableCell>
                    <TableCell align="right">{row.status ? '有効' : '無効'}</TableCell>
                    <TableCell align="right">{row.createdAt}</TableCell>
                    <TableCell align="right">{row.updatedAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={total}
            rowsPerPage={currentSearchParams.limit}
            page={currentSearchParams.page - 1}
            backIconButtonProps={{
              'aria-label': 'previous page',
            }}
            nextIconButtonProps={{
              'aria-label': 'next page',
            }}
            onPageChange={(_, page) => changePage(page)}
            onRowsPerPageChange={({ target: { value } }) => changePerPage(parseInt(value))}
          />
        </Paper>
      </main>
      <button onClick={csvFileDownload} id="csvLink" className={classes.csvBtn}>
        CSV出力
      </button>
      <Dialog open={open} onClose={handleAddDialogClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Create Record</DialogTitle>
        <DialogContent>
          <DialogContentText />
          <div>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                margin="normal"
                id="startAt"
                label="開始時間"
                format="yyyy-MM-dd HH:00"
                value={addInput.startAt ? addInput.startAt : null}
                onChange={handleChangeAddStartAt}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
                InputLabelProps={{ shrink: true }}
                views={['date', 'hours']}
              />
            </MuiPickersUtilsProvider>
          </div>
          <Grid>
            <FormControl>
              <InputLabel htmlFor="phase">Instructor</InputLabel>
              <Select
                value={addInput.instructorUserID}
                onChange={handleChangeAddInstructorUserID}
                input={<Input name="instructorUserID" id="instructorUserID" />}
              >
                {instructors.map((instructor) => (
                  <MenuItem value={instructor.userID} key={instructor.userID}>
                    {instructor.user.personalInfo?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <TextField
            autoFocus
            margin="dense"
            id="userID"
            name="userID"
            label="UserID"
            type="number"
            defaultValue={addInput.userID}
            fullWidth
            onChange={handleChangeAddUserID}
            inputProps={{ min: 1 }}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">Categories</FormLabel>
            <FormGroup>
              {categories.map((category) => {
                return (
                  <FormControlLabel
                    key={category.id}
                    control={
                      <Checkbox
                        name="category"
                        checked={checkedCategories(category.id)}
                        onChange={handleChangeAddCategories}
                        value={category.id}
                      />
                    }
                    label={category.name}
                    disabled={disabledCategories(category.id)}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
          <TextField
            autoFocus
            margin="dense"
            id="troubleContent"
            name="troubleContent"
            label="TroubleContent"
            type="text"
            defaultValue={addInput.troubleContent}
            fullWidth
            onChange={handleChangeAddTroubleContent}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
          />
          <TextField
            autoFocus
            margin="dense"
            id="researchContent"
            name="researchContent"
            label="ResearchContent"
            type="text"
            defaultValue={addInput.researchContent}
            fullWidth
            onChange={handleChangeAddResearchContent}
            multiline={true}
            minRows={5}
            maxRows={Infinity}
          />
          {addError?.graphQLErrors && (
            <Box className={classes.inputError}>
              <Typography style={{ color: '#ff0000' }}>
                Oops, following errors occurred...
              </Typography>
              {addError.graphQLErrors.map((error, index) => (
                <div key={index}>{error.message}</div>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClickAdd} color="primary" disabled={disabledCondition}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
